import React from 'react'

export const morpho_circle_color = {
  path: (
    <>
      <path
        d="M29 16.0004C29 23.1803 23.1797 29.0008 16 29.0008C8.82032 29.0008 3 23.1803 3 16.0004C3 8.82047 8.82032 3 16 3C23.1797 3 29 8.82047 29 16.0004Z"
        fill="url(#paint0_linear_14600_2527)"
      />
      <path
        d="M14.9284 18.2449C14.6137 17.5645 13.8567 16.9861 12.9636 16.5183L11.4582 17.3518C10.9648 17.6325 10.6501 18.1343 10.6501 18.6702V22.4721C10.6501 22.7103 10.8458 22.8038 10.9138 22.8294C10.9734 22.8549 11.186 22.9144 11.3646 22.7443L14.2395 19.98C14.4861 19.7419 14.7243 19.4952 14.8944 19.206C14.9794 19.0699 15.0134 18.9934 15.0134 18.9934C15.039 18.9339 15.0645 18.8743 15.0815 18.8148C15.073 18.6192 15.0134 18.432 14.9284 18.2449Z"
        fill="#DAE2EA"
      />
      <path
        d="M20.542 17.3518L19.0365 16.5098C18.1434 16.9861 17.3865 17.5644 17.0718 18.2364C16.9867 18.432 16.9272 18.6191 16.9102 18.8147C16.9272 18.8743 16.9527 18.9253 16.9782 18.9849C16.9782 18.9849 17.0292 19.0954 17.1483 19.274C17.3014 19.5037 17.4885 19.7078 17.6927 19.9034L20.6355 22.7273C20.8227 22.8974 21.0268 22.8378 21.0863 22.8123C21.1459 22.7868 21.35 22.6932 21.35 22.4551V18.6531C21.35 18.1343 21.0438 17.641 20.542 17.3518Z"
        fill="#DAE2EA"
      />
      <path
        d="M22.9999 10.2241V14.2047C22.9999 14.7065 22.6682 15.1488 22.1919 15.2849C20.5759 15.7527 17.7521 16.7563 17.0716 18.2362C16.9866 18.4319 16.927 18.619 16.91 18.8231C16.4592 17.9981 16.2466 17.0455 16.3486 16.0844C16.4847 14.7235 17.1992 13.4817 18.3304 12.6907L22.345 9.87538C22.413 9.82434 22.4981 9.79883 22.5832 9.79883C22.6512 9.79883 22.7192 9.81584 22.7788 9.84986C22.9149 9.92641 22.9999 10.0625 22.9999 10.2241Z"
        fill="white"
      />
      <path
        d="M9 10.2241V14.2047C9 14.7065 9.33171 15.1488 9.80802 15.2849C11.4241 15.7527 14.2479 16.7563 14.9283 18.2363C15.0134 18.4319 15.0729 18.619 15.0899 18.8232C15.5407 17.9981 15.7533 17.0455 15.6513 16.0844C15.5152 14.7235 14.8007 13.4817 13.6695 12.6907L9.65492 9.88392C9.58688 9.83289 9.50182 9.80737 9.41677 9.80737C9.34872 9.80737 9.28068 9.82438 9.22114 9.85841C9.08506 9.92645 9 10.0625 9 10.2241Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14600_2527"
          x1="7.5"
          y1="7.5"
          x2="26.5"
          y2="25.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2470FF" />
          <stop offset="1" stopColor="#0F53D2" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
