import React from 'react'
export const apxeth_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="#0D0D0D" />
      <path
        d="M16.1483 15.6054L16.3016 15.5085L16.4374 15.0728L16.6204 14.714L22.6827 10.4675H24.9999L24.5057 14.5676L21.9249 16.6762L22.2408 16.7442L22.5655 19.3193L19.7321 23.0239L17.787 20.5803L16.9569 21.4471L16.5765 21.9696L16 21.437L15.4234 21.9696L15.043 21.4471L14.2129 20.5803L12.2678 23.0239L9.43441 19.3193L9.75909 16.7442L10.075 16.6762L7.4942 14.5676L7 10.4675H9.31726L15.3795 14.714L15.5625 15.0728L15.6983 15.5085L15.8517 15.6054L16 16.2726L16.1483 15.6054Z"
        fill="#F6F6F6"
      />
      <path d="M17.894 20.1599L20.2093 16.3072L20.5154 15.7979L17.894 20.1599Z" fill="#E4E5E6" />
      <path
        d="M16.6205 14.7141C16.4448 15.0436 16.4375 15.0729 16.4375 15.0729L22.6356 10.5024C22.6356 10.5024 16.7084 14.6556 16.6205 14.7141Z"
        fill="#FBFBFB"
      />
      <path d="M22.6828 10.4675L16.4375 15.0728L20.5156 15.7976L22.6828 10.4675Z" fill="#FBFBFB" />
      <path
        d="M24.5058 14.5676L25 10.4675H22.6827L20.5155 15.7976L20.2095 16.3069L21.9249 16.6762L24.5058 14.5676Z"
        fill="#F6F6F6"
      />
      <g filter="url(#filter0_f_14381_2361)">
        <path d="M21.5 16.5L24.5 14L19 16.5H21.5Z" fill="url(#paint0_linear_14381_2361)" />
      </g>
      <path
        d="M17.8941 20.1599L20.5154 15.7978L16.4373 15.073L16.3015 15.5088L17.8941 20.1599Z"
        fill="#FBFBFB"
      />
      <path
        d="M17.8942 20.1598L17.8942 20.1598L17.7346 20.4254L17.787 20.5805L19.7321 23.0241L22.5656 19.3194L22.2409 16.7444L20.2095 16.3071L17.8942 20.1598Z"
        fill="#F6F6F6"
      />
      <path d="M16.9553 21.4507L16.9572 21.4473L16.9565 21.4483L16.9553 21.4507Z" fill="#E4E5E6" />
      <path d="M14.1059 20.1599L11.7907 16.3072L11.4846 15.7979L14.1059 20.1599Z" fill="#E4E5E6" />
      <path
        d="M15.3798 14.7141C15.5555 15.0436 15.5628 15.0729 15.5628 15.0729L9.36475 10.5024C9.36475 10.5024 15.2919 14.6556 15.3798 14.7141Z"
        fill="#FBFBFB"
      />
      <path d="M9.31714 10.4675L15.5624 15.0728L11.4843 15.7976L9.31714 10.4675Z" fill="#FBFBFB" />
      <path
        d="M7.4942 14.5676L7 10.4675H9.31726L11.4844 15.7976L11.7905 16.3069L10.075 16.6762L7.4942 14.5676Z"
        fill="#F6F6F6"
      />
      <g filter="url(#filter1_f_14381_2361)">
        <path d="M11 16.5L8 14L13.5 16.5H11Z" fill="url(#paint1_linear_14381_2361)" />
      </g>
      <path
        d="M14.1059 20.1599L11.4846 15.7978L15.5627 15.073L15.6985 15.5088L14.1059 20.1599Z"
        fill="#FBFBFB"
      />
      <path
        d="M14.2653 20.4255L14.1057 20.1599L14.1057 20.1599L15.6983 15.5088L15.7382 15.6368L14.4023 20.1192L14.2653 20.4255Z"
        fill="#FBFBFB"
      />
      <path
        d="M14.1059 20.1598L14.1059 20.1598L14.2655 20.4254L14.2131 20.5805L12.268 23.0241L9.43457 19.3194L9.75926 16.7444L11.7907 16.3071L14.1059 20.1598Z"
        fill="#F6F6F6"
      />
      <g filter="url(#filter2_d_14381_2361)">
        <path
          d="M14.2655 20.4256L13.7618 19.5874L9.5767 18.1924L9.43457 19.3196L12.2495 23.0001H12.2872L14.2131 20.5806L14.2655 20.4256Z"
          fill="url(#paint2_linear_14381_2361)"
        />
      </g>
      <path d="M15.0451 21.4507L15.0432 21.4473L15.044 21.4483L15.0451 21.4507Z" fill="#E4E5E6" />
      <path
        opacity="0.15"
        d="M21.9249 16.6762L20.2095 16.3069L20.5155 15.7976L22.6827 10.4675H25L24.5058 14.5676L21.9249 16.6762Z"
        fill="url(#paint3_linear_14381_2361)"
      />
      <path
        opacity="0.15"
        d="M17.8942 20.1598L17.8942 20.1598L17.7346 20.4254L17.787 20.5805L19.7321 23.0241L22.5656 19.3194L22.2409 16.7444L20.2095 16.3071L17.8942 20.1598Z"
        fill="url(#paint4_linear_14381_2361)"
      />
      <path
        d="M17.7346 20.4256L18.2383 19.5874L22.4234 18.1924L22.5656 19.3196L19.7506 23.0002H19.7129L17.787 20.5807L17.7346 20.4256Z"
        fill="#D9D9D9"
      />
      <g filter="url(#filter3_d_14381_2361)">
        <path
          d="M18.2383 19.5872L22.4234 18.1921L22.5656 19.3194L19.7506 23H19.7129L17.787 20.5804L17.7346 20.4254L18.2383 19.5872Z"
          fill="url(#paint5_linear_14381_2361)"
        />
      </g>
      <path
        d="M19.1081 19.2974L19.382 22.5843L17.787 20.5805L17.7346 20.4255L18.2383 19.5873L19.1081 19.2974Z"
        fill="url(#paint6_linear_14381_2361)"
      />
      <path
        opacity="0.15"
        d="M21.9249 16.6762L20.2095 16.3069L20.5155 15.7976L22.6827 10.4675H25L24.5058 14.5676L21.9249 16.6762Z"
        fill="url(#paint7_linear_14381_2361)"
      />
      <path
        opacity="0.15"
        d="M10.075 16.6762L11.7905 16.3069L11.4844 15.7976L9.31726 10.4675H7L7.4942 14.5676L10.075 16.6762Z"
        fill="url(#paint8_linear_14381_2361)"
      />
      <path
        opacity="0.15"
        d="M13.6713 19.8527L13.6713 19.8527L13.831 20.1183L13.7785 20.2733L11.8334 22.717L9 19.0123L9.32469 16.4373L11.3561 16L13.6713 19.8527Z"
        fill="url(#paint9_linear_14381_2361)"
      />
      <path
        opacity="0.5"
        d="M14.2655 20.4256L12.9766 18.2808L12.6179 22.5846L14.2131 20.5806L14.2655 20.4256Z"
        fill="url(#paint10_linear_14381_2361)"
      />
      <path
        opacity="0.15"
        d="M10.075 16.6762L11.7905 16.3069L11.4844 15.7976L9.31726 10.4675H7L7.4942 14.5676L10.075 16.6762Z"
        fill="url(#paint11_linear_14381_2361)"
      />
      <path
        opacity="0.04"
        d="M17.7346 20.4252L20.5155 15.7976L22.6827 10.4675L16.4374 15.0728L16.2617 15.6365L17.5976 20.1189L17.7346 20.4252Z"
        fill="url(#paint12_linear_14381_2361)"
      />
      <path
        opacity="0.04"
        d="M14.2652 20.4252L11.4843 15.7976L9.31714 10.4675L15.5624 15.0728L15.7381 15.6365L14.4022 20.1189L14.2652 20.4252Z"
        fill="url(#paint13_linear_14381_2361)"
      />
      <path
        opacity="0.03"
        d="M16.6205 14.7141C16.4448 15.0436 16.4375 15.0729 16.4375 15.0729L22.6356 10.5024C22.6356 10.5024 16.7084 14.6556 16.6205 14.7141Z"
        fill="url(#paint14_linear_14381_2361)"
      />
      <path
        opacity="0.03"
        d="M15.3798 14.7141C15.5555 15.0436 15.5628 15.0729 15.5628 15.0729L9.36475 10.5024C9.36475 10.5024 15.2919 14.6556 15.3798 14.7141Z"
        fill="url(#paint15_linear_14381_2361)"
      />
      <g filter="url(#filter4_f_14381_2361)">
        <path d="M12 17H20V18L16 20L12 18V17Z" fill="#212F38" />
      </g>
      <g filter="url(#filter5_f_14381_2361)">
        <path d="M13 17.5L11 13L14 16.5L13 17.5Z" fill="url(#paint16_linear_14381_2361)" />
      </g>
      <g filter="url(#filter6_f_14381_2361)">
        <path
          d="M13.5 20L10 18.5L12 17.5H19.5L21.5 18.5L19.5 20H13.5Z"
          fill="url(#paint17_linear_14381_2361)"
        />
      </g>
      <g filter="url(#filter7_f_14381_2361)">
        <path d="M19 17.5L21 13L18 16.5L19 17.5Z" fill="url(#paint18_linear_14381_2361)" />
      </g>
      <path d="M16 10L20 17L16 19L12 17L16 10Z" fill="#CCD5DB" />
      <path d="M20 17L16 10V19L20 17Z" fill="#94A4B0" />
      <path d="M12 18L16 20L20 18L16 23L12 18Z" fill="#CCD5DB" />
      <path d="M16 20V23L20 18L16 20Z" fill="#94A4B0" />
      <defs>
        <filter
          id="filter0_f_14381_2361"
          x="18"
          y="13"
          width="7.5"
          height="4.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <filter
          id="filter1_f_14381_2361"
          x="7"
          y="13"
          width="7.5"
          height="4.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <filter
          id="filter2_d_14381_2361"
          x="9.13457"
          y="17.6924"
          width="5.43105"
          height="5.40786"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.2" />
          <feGaussianBlur stdDeviation="0.15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.141176 0 0 0 0 0.196078 0 0 0 0 0.231373 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14381_2361" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14381_2361"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_14381_2361"
          x="17.4346"
          y="17.6921"
          width="5.43105"
          height="5.40786"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.2" />
          <feGaussianBlur stdDeviation="0.15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.141176 0 0 0 0 0.196078 0 0 0 0 0.231373 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14381_2361" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14381_2361"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_14381_2361"
          x="9"
          y="14"
          width="14"
          height="9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <filter
          id="filter5_f_14381_2361"
          x="10.2"
          y="12.2"
          width="4.6"
          height="6.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <filter
          id="filter6_f_14381_2361"
          x="9"
          y="16.5"
          width="13.5"
          height="4.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <filter
          id="filter7_f_14381_2361"
          x="17.2"
          y="12.2"
          width="4.6"
          height="6.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_14381_2361" />
        </filter>
        <linearGradient
          id="paint0_linear_14381_2361"
          x1="19.5"
          y1="17"
          x2="24.5"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14381_2361"
          x1="15.3333"
          y1="15.9444"
          x2="13.9467"
          y2="11.9405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14381_2361"
          x1="14.2"
          y1="19"
          x2="10.7"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14381_2361"
          x1="27.6071"
          y1="15.4248"
          x2="21.6114"
          y2="12.9826"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.69949" stopColor="white" stopOpacity="0" />
          <stop offset="0.95753" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14381_2361"
          x1="23.004"
          y1="21.1399"
          x2="18.8529"
          y2="18.5564"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.48224" stopColor="white" stopOpacity="0" />
          <stop offset="0.92682" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14381_2361"
          x1="22.5"
          y1="19"
          x2="19"
          y2="21.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_14381_2361"
          x1="19.9312"
          y1="21.8539"
          x2="17.2242"
          y2="20.0733"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#96ACBA" />
          <stop offset="1" stopColor="#212F38" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_14381_2361"
          x1="23.5763"
          y1="10.8664"
          x2="21.9085"
          y2="17.1218"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.54718" stopColor="white" stopOpacity="0" />
          <stop offset="0.90853" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="0.96801" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_14381_2361"
          x1="4.39288"
          y1="15.4248"
          x2="10.3886"
          y2="12.9826"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.69949" stopColor="white" stopOpacity="0" />
          <stop offset="0.95753" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_14381_2361"
          x1="8.56156"
          y1="20.8327"
          x2="12.7127"
          y2="18.2493"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.48224" stopColor="white" stopOpacity="0" />
          <stop offset="0.92682" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_14381_2361"
          x1="14"
          y1="20.5"
          x2="11.5951"
          y2="21.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_14381_2361"
          x1="8.42368"
          y1="10.8664"
          x2="10.0915"
          y2="17.1218"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.54718" stopColor="white" stopOpacity="0" />
          <stop offset="0.90853" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="0.96801" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_14381_2361"
          x1="18.9302"
          y1="12.8867"
          x2="21.2794"
          y2="17.5901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_14381_2361"
          x1="13.0697"
          y1="12.8867"
          x2="10.7205"
          y2="17.5901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_14381_2361"
          x1="19.7866"
          y1="13.141"
          x2="19.4938"
          y2="12.7273"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04311" stopColor="white" stopOpacity="0" />
          <stop offset="0.86475" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_14381_2361"
          x1="12.2137"
          y1="13.141"
          x2="12.5065"
          y2="12.7273"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.04311" stopColor="white" stopOpacity="0" />
          <stop offset="0.86475" stopColor="#878787" stopOpacity="0.46839" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_14381_2361"
          x1="15"
          y1="16.5"
          x2="11"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_14381_2361"
          x1="25.3333"
          y1="19.4444"
          x2="24.6105"
          y2="15.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96ACBA" />
          <stop offset="0.525" stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_14381_2361"
          x1="17"
          y1="16.5"
          x2="21"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212F38" />
          <stop offset="1" stopColor="#96ACBA" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
