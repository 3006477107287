import React from 'react'
export const usdc_circle_color = {
  path: (
    <g key="6gu1ZCrLq">
      <path
        d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63616 2.6665 2.66663 8.63604 2.66663 15.9998C2.66663 23.3636 8.63616 29.3332 16 29.3332Z"
        fill="url(#usdc_paint0_linear)"
        key="2KgAcl7YS"
      />
      <path
        d="M13.5556 25.2219C13.5556 25.5553 13.3333 25.6664 13 25.6664C8.88889 24.333 6 20.5553 6 16.1108C6 11.6664 8.88889 7.8886 13 6.55527C13.3333 6.44416 13.5556 6.66638 13.5556 6.99972V7.77749C13.5556 7.99971 13.4444 8.22194 13.2222 8.33305C10 9.55527 7.77778 12.5553 7.77778 16.1108C7.77778 19.6664 10.1111 22.7775 13.2222 23.8886C13.4444 23.9997 13.5556 24.2219 13.5556 24.4442V25.2219Z"
        fill="white"
        key="hWScD_np7f"
      />
      <path
        d="M16.8887 22.4443C16.8887 22.6666 16.6665 22.8888 16.4443 22.8888H15.5554C15.3332 22.8888 15.111 22.6666 15.111 22.4443V21.111C13.3332 20.8888 12.4443 19.8888 12.111 18.4443C12.111 18.2221 12.2221 17.9999 12.4443 17.9999H13.4443C13.6665 17.9999 13.7776 18.111 13.8887 18.3332C14.111 19.111 14.5554 19.7777 15.9999 19.7777C17.111 19.7777 17.8887 19.2221 17.8887 18.3332C17.8887 17.4443 17.4443 17.111 15.8887 16.8888C13.5554 16.5554 12.4443 15.8888 12.4443 13.9999C12.4443 12.5554 13.5554 11.4443 15.111 11.2221V9.88878C15.111 9.66656 15.3332 9.44434 15.5554 9.44434H16.4443C16.6665 9.44434 16.8887 9.66656 16.8887 9.88878V11.2221C18.2221 11.4443 19.111 12.2221 19.3332 13.4443C19.3332 13.6666 19.2221 13.8888 18.9999 13.8888H18.111C17.8887 13.8888 17.7776 13.7777 17.6665 13.5554C17.4443 12.7777 16.8887 12.4443 15.8887 12.4443C14.7776 12.4443 14.2221 12.9999 14.2221 13.7777C14.2221 14.5554 14.5554 14.9999 16.2221 15.2221C18.5554 15.5554 19.6665 16.2221 19.6665 18.111C19.6665 19.5554 18.5554 20.7777 16.8887 21.111V22.4443Z"
        fill="white"
        key="9QB15nqot9"
      />
      <path
        d="M18.9999 25.6668C18.6666 25.7779 18.4443 25.5557 18.4443 25.2223V24.4446C18.4443 24.2223 18.5554 24.0001 18.7777 23.889C21.9999 22.6668 24.2221 19.6668 24.2221 16.1112C24.2221 12.5557 21.8888 9.44455 18.7777 8.33344C18.5554 8.22233 18.4443 8.00011 18.4443 7.77789V7.00011C18.4443 6.66678 18.6666 6.55566 18.9999 6.55566C22.9999 7.889 25.9999 11.6668 25.9999 16.1112C25.9999 20.5557 23.111 24.3334 18.9999 25.6668Z"
        fill="white"
        key="LAk13k81Kl"
      />
      <defs key="khVP5fYN9">
        <linearGradient
          id="usdc_paint0_linear"
          key="usdc_paint0_linear"
          x1="9.94236"
          y1="2.33295"
          x2="23.7823"
          y2="28.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0666CE" key="xV0z0-BTmI" />
          <stop offset="1" stopColor="#61A9F8" key="0Xf_PJYdey" />
        </linearGradient>
      </defs>
    </g>
  ),
  viewBox: '0 0 32 32',
}
