import React from 'react'
export const cseth = {
  path: (
    <>
      <g clipPath="url(#clip0_14480_4437)">
        <path
          d="M28.1875 16C28.1875 22.731 22.731 28.1875 16 28.1875C9.26903 28.1875 3.8125 22.731 3.8125 16C3.8125 9.26903 9.26903 3.8125 16 3.8125C22.731 3.8125 28.1875 9.26903 28.1875 16Z"
          fill="#130E19"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4.625C9.71776 4.625 4.625 9.71776 4.625 16C4.625 22.2822 9.71776 27.375 16 27.375C22.2822 27.375 27.375 22.2822 27.375 16C27.375 9.71776 22.2822 4.625 16 4.625ZM3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16Z"
          fill="url(#paint0_linear_14480_4437)"
        />
        <path
          d="M15.9998 7.33325V13.7406L21.4152 16.1606L15.9998 7.33325Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M15.9996 7.33325L10.5833 16.1606L15.9996 13.7406V7.33325Z" fill="white" />
        <path
          d="M15.9998 20.3129V24.6667L21.4188 17.1692L15.9998 20.3129Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M15.9996 24.6667V20.3123L10.5833 17.1692L15.9996 24.6667Z" fill="white" />
        <path
          d="M15.9998 19.3052L21.4152 16.1607L15.9998 13.7422V19.3052Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M10.5833 16.1607L15.9996 19.3052V13.7422L10.5833 16.1607Z"
          fill="white"
          fillOpacity="0.602"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14480_4437"
          x1="16"
          y1="3"
          x2="16"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A57BFF" />
          <stop offset="0.239583" stopColor="#FF66D4" />
          <stop offset="0.5" stopColor="#FF5B78" />
          <stop offset="0.739583" stopColor="#FF9E57" />
          <stop offset="1" stopColor="#FFD595" />
        </linearGradient>
        <clipPath id="clip0_14480_4437">
          <rect width="26" height="26" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
