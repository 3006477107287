import React from 'react'
export const close_squared = {
  path: (
    <path
      d="M13.0024 11.328L24.328 0L26 1.67437L14.672 13.0024L26 24.328L24.328 26L13 14.672L1.67437 26L0.00236504 24.328L11.3304 13L0 1.67437L1.67437 0.0023649L13.0024 11.3304V11.328Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
}
