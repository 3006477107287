import React from 'react'
export const op = {
  path: (
    <>
      <circle cx="12" cy="12" r="10" fill="#080E14" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16311 14.2823C7.54724 14.5626 8.04005 14.7028 8.64153 14.7028C9.36937 14.7028 9.95062 14.5351 10.3853 14.1998C10.82 13.8594 11.1258 13.3464 11.3027 12.6607C11.4088 12.2403 11.4998 11.8074 11.5756 11.3619C11.6009 11.2018 11.6135 11.0691 11.6135 10.964C11.6135 10.6137 11.5251 10.3134 11.3482 10.0631C11.1713 9.80787 10.9287 9.61768 10.6203 9.49256C10.312 9.36243 9.96327 9.29736 9.57408 9.29736C8.14367 9.29736 7.25661 9.98555 6.91291 11.3619C6.79161 11.8674 6.6981 12.3004 6.63239 12.6607C6.60712 12.8209 6.59448 12.956 6.59448 13.0661C6.59448 13.5917 6.78402 13.9971 7.16311 14.2823ZM9.46794 13.3739C9.27081 13.5391 9.03072 13.6217 8.74767 13.6217C8.26245 13.6217 8.01983 13.3914 8.01983 12.931C8.01983 12.8209 8.02994 12.7158 8.05016 12.6157C8.14114 12.1302 8.23212 11.7198 8.3231 11.3844C8.40903 11.0441 8.55055 10.7914 8.74767 10.6262C8.94985 10.461 9.19246 10.3784 9.47551 10.3784C9.95567 10.3784 10.1958 10.6062 10.1958 11.0616C10.1958 11.1717 10.1857 11.2793 10.1655 11.3844C10.1048 11.7348 10.0163 12.1452 9.90008 12.6157C9.81416 12.956 9.6701 13.2088 9.46794 13.3739ZM12.254 14.5751C12.2843 14.6102 12.3273 14.6277 12.3829 14.6277H13.414C13.4645 14.6277 13.5125 14.6102 13.558 14.5751C13.6035 14.5401 13.6313 14.4951 13.6414 14.44L13.9902 12.7958H15.0137C15.6758 12.7958 16.1964 12.6582 16.5755 12.383C16.9597 12.1077 17.2149 11.6822 17.3413 11.1067C17.3716 10.9715 17.3868 10.8414 17.3868 10.7163C17.3868 10.2808 17.2149 9.94801 16.8712 9.71778C16.5326 9.48755 16.0827 9.37244 15.5217 9.37244H13.505C13.4544 9.37244 13.4064 9.38995 13.3609 9.42499C13.3154 9.46002 13.2876 9.50507 13.2775 9.56012L12.2312 14.44C12.2211 14.4901 12.2287 14.5351 12.254 14.5751ZM15.6202 11.5872C15.4636 11.7023 15.2791 11.7598 15.0668 11.7598H14.1949L14.483 10.416H15.3928C15.6 10.416 15.7466 10.456 15.8325 10.5361C15.9184 10.6112 15.9614 10.7213 15.9614 10.8664C15.9614 10.9315 15.9538 11.0066 15.9387 11.0917C15.8881 11.3069 15.782 11.472 15.6202 11.5872Z"
        fill="white"
      />
    </>
  ),
}
