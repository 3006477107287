import React from 'react'
export const gho_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="#1F1F30" key="QyWZqZCjE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4599 10H18.5624L19.8512 12.2856H14.4833V12.2858C12.6087 12.2858 11.0889 13.9487 11.0889 16C11.0889 18.0321 12.5803 19.6831 14.4308 19.7138H21.3648L19.2764 16.0002H21.8875L23.976 19.7138H24V21.996H14.4833V22C14.4165 22 14.3501 21.9986 14.2839 21.996H14.0414V21.9807C11.2197 21.7345 9 19.1508 9 16C9 12.6949 11.4423 10.0138 14.4599 10.0001V10ZM15.2667 14.3078C15.2667 14.939 14.7991 15.4507 14.2222 15.4507C13.6455 15.4507 13.1778 14.939 13.1778 14.3078C13.1778 13.6766 13.6454 13.1649 14.2222 13.1649C14.7991 13.1649 15.2667 13.6766 15.2667 14.3078ZM14.2222 18.6157C14.7991 18.6157 15.2667 18.1039 15.2667 17.4728C15.2667 16.8416 14.7991 16.3299 14.2222 16.3299C13.6455 16.3299 13.1778 16.8416 13.1778 17.4728C13.1778 18.1039 13.6454 18.6157 14.2222 18.6157Z"
        fill="#C9B9EE"
        key="n4WuLFwPWo"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
