import React from 'react'
export const aave_circle_color = {
  path: (
    <>
      <path
        d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63616 2.6665 2.66663 8.63604 2.66663 15.9998C2.66663 23.3636 8.63616 29.3332 16 29.3332Z"
        fill="url(#aave_paint0_linear)"
      />
      <path
        d="M22.0601 20.7202L17.6867 10.1468C17.4401 9.60016 17.0734 9.3335 16.5901 9.3335H16.2034C15.7201 9.3335 15.3534 9.60016 15.1067 10.1468L13.2034 14.7535H11.7634C11.3334 14.7568 10.9834 15.1035 10.9801 15.5368V15.5468C10.9834 15.9768 11.3334 16.3268 11.7634 16.3302H12.5367L10.7201 20.7202C10.6867 20.8168 10.6667 20.9168 10.6667 21.0202C10.6667 21.2668 10.7434 21.4602 10.8801 21.6102C11.0167 21.7602 11.2134 21.8335 11.4601 21.8335C11.6234 21.8302 11.7801 21.7802 11.9101 21.6835C12.0501 21.5869 12.1467 21.4468 12.2234 21.2868L14.2234 16.3268H15.6101C16.0401 16.3235 16.3901 15.9768 16.3934 15.5435V15.5235C16.3901 15.0935 16.0401 14.7435 15.6101 14.7402H14.8701L16.3967 10.9368L20.5567 21.2835C20.6334 21.4435 20.7301 21.5835 20.8701 21.6802C21.0001 21.7769 21.1601 21.8269 21.3201 21.8302C21.5667 21.8302 21.7601 21.7569 21.9001 21.6069C22.0401 21.4569 22.1134 21.2635 22.1134 21.0168C22.1167 20.9168 22.1001 20.8135 22.0601 20.7202Z"
        fill="white"
      />
      <linearGradient
        id="aave_paint0_linear"
        key="aave_paint0_linear"
        x1="25.9429"
        y1="7.6342"
        x2="6.10286"
        y2="24.3275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6509E" />
        <stop offset="1" stopColor="#2EBAC6" />
      </linearGradient>
    </>
  ),
  viewBox: '0 0 32 32',
}
