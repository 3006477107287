import React from 'react'
export const cbeth_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="#FFFFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16,27.9256c6.5863,0 11.9256,-5.3393 11.9256,-11.9256c0,-6.58634 -5.3393,-11.92562 -11.9256,-11.92562c-6.58634,0 -11.92562,5.33928 -11.92562,11.92562c0,6.5863 5.33928,11.9256 11.92562,11.9256zm0,1.0744c7.1797,0 13,-5.8203 13,-13c0,-7.1797 -5.8203,-13 -13,-13c-7.1797,0 -13,5.8203 -13,13c0,7.1797 5.8203,13 13,13z"
        fill="url(#paint0_linear_12916_917)"
        id="svg_1"
        key="svg_1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.9641,6.32999l-5.55059,9.34741l5.54999,3.4383l5.6236,-3.4383l-5.623,-9.34741zm0,18.47991l-5.55079,-7.8965l5.55009,3.384l5.6235,-3.384l-5.6228,7.8965z"
        fill="url(#paint1_linear_12916_917)"
        id="svg_2"
        key="svg_2"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m21.5368,15.7081l-5.5733,3.4076l-5.50052,-3.4077l5.50042,-2.6089l5.5734,2.609z"
        fill="white"
        id="svg_3"
        key="svg_3"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16,19.0934l0,-12.7037l5.5871,9.2877l-5.5871,3.416zm0,5.6661l0,-4.4841l5.5869,-3.362l-5.5869,7.8461z"
        fill="white"
        id="svg_4"
        key="svg_4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12916_917"
          key="paint0_linear_12916_917"
          x1="0.12521"
          y1="0.1625"
          x2="0.78146"
          y2="0.9875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#164BE1" />
          <stop offset="1" stopColor="#8DA6EC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12916_917"
          key="paint1_linear_12916_917"
          x1="0.12521"
          y1="0.1625"
          x2="1.19848"
          y2="0.65578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#164BE1" />
          <stop offset="1" stopColor="#8DA6EC" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
