import React from 'react'
export const crv = {
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.58079 13.2144L9.67768 13.9232L8.12795 14.2665L7.92969 13.5141L9.58079 13.2144ZM7.9889 13.5518L8.16236 14.21L9.62452 13.8862L9.54031 13.2701L7.9889 13.5518Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.97633 13.4937L8.17404 14.249L6.82071 14.7067L6.52222 13.9225L7.97633 13.4937ZM6.58507 13.9536L6.84892 14.6468L8.11669 14.2181L7.94268 13.5533L6.58507 13.9536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.50067 12.4287L9.58653 13.257L7.94057 13.5558L7.76416 12.6838L9.50067 12.4287ZM7.82084 12.7236L7.97802 13.5006L9.53457 13.218L9.4584 12.4831L7.82084 12.7236Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.80598 12.6794L7.98713 13.5449L6.53743 13.9725L6.26611 13.0681L7.80598 12.6794ZM6.32607 13.1021L6.56945 13.9134L7.93148 13.5117L7.7695 12.7378L6.32607 13.1021Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67024 13.8713L9.77887 14.4559L8.33029 14.834L8.11401 14.2161L9.67024 13.8713ZM8.17673 14.251L8.36076 14.7768L9.72391 14.421L9.63239 13.9285L8.17673 14.251Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.16002 14.2083L8.37567 14.8193L7.12844 15.3073L6.80103 14.6678L8.16002 14.2083ZM6.86862 14.6953L7.15121 15.2472L8.31539 14.7917L8.13073 14.2685L6.86862 14.6953Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.42016 11.5574L9.5005 12.4762L7.76797 12.7307L7.61255 11.7631L9.42016 11.5574ZM7.66752 11.8048L7.80756 12.6767L9.44911 12.4356L9.37694 11.6103L7.66752 11.8048Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.6645 11.7554L7.81465 12.7263L6.27832 13.1141L6.04834 12.1041L7.6645 11.7554ZM6.10551 12.1406L6.31396 13.0559L7.76092 12.6907L7.62513 11.8126L6.10551 12.1406Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.314 13.0398L11.3243 13.7064L9.63098 13.9315L9.52881 13.2164L11.314 13.0398ZM9.58302 13.2589L9.67148 13.8781L11.276 13.6648L11.2672 13.0923L9.58302 13.2589Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.77294 14.4143L9.88978 14.8551L8.54747 15.2583L8.31567 14.7947L9.77294 14.4143ZM8.38459 14.8259L8.57217 15.2011L9.8319 14.8227L9.73904 14.4724L8.38459 14.8259Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.56663 13.9155L6.86448 14.693L5.75175 15.2667L5.35352 14.4651L6.56663 13.9155ZM5.41817 14.4882L5.7729 15.2021L6.80471 14.6702L6.54022 13.9798L5.41817 14.4882Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.35196 14.78L8.58767 15.241L7.44624 15.7499L7.09741 15.2709L8.35196 14.78ZM7.1723 15.2928L7.46206 15.6907L8.52236 15.2179L8.32917 14.8401L7.1723 15.2928Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3143 12.3066L11.3193 13.0829L9.53958 13.2589L9.44849 12.434L11.3143 12.3066ZM9.50131 12.4781L9.58178 13.2068L11.2714 13.0397L11.2669 12.3576L9.50131 12.4781Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.31167 13.0532L6.57749 13.956L5.36797 14.504L5.01221 13.572L6.31167 13.0532ZM5.07347 13.5989L5.39443 14.4397L6.52007 13.9297L6.28073 13.1169L5.07347 13.5989Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3284 13.6536L11.3336 14.2013L9.73686 14.4658L9.62793 13.8796L11.3284 13.6536ZM9.68395 13.9203L9.77518 14.4111L11.2856 14.161L11.2813 13.7079L9.68395 13.9203Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.84494 14.6494L7.17111 15.2865L6.16002 15.8763L5.72778 15.2254L6.84494 14.6494ZM5.79692 15.2433L6.17485 15.8124L7.10814 15.268L6.8243 14.7137L5.79692 15.2433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.36841 10.6145L9.42844 11.6049L7.62392 11.8103L7.49902 10.7662L9.36841 10.6145ZM7.55222 10.8097L7.66561 11.7576L9.37813 11.5627L9.32378 10.6659L7.55222 10.8097Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.54645 10.76L7.67117 11.8026L6.0578 12.1508L5.86841 11.0593L7.54645 10.76ZM5.92347 11.0979L6.09627 12.0938L7.61869 11.7652L7.50513 10.8158L5.92347 11.0979Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3096 11.4973L11.3146 12.3592L9.45313 12.4862L9.37769 11.5658L11.3096 11.4973ZM9.42926 11.6116L9.49679 12.4355L11.2667 12.3147L11.2622 11.5467L9.42926 11.6116Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.09422 12.0911L6.32391 13.0997L5.0274 13.6173L4.71802 12.5794L6.09422 12.0911ZM4.77662 12.6092L5.05817 13.5537L6.26839 13.0706L6.05965 12.1539L4.77662 12.6092Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3279 14.158L11.3387 14.5676L9.83873 14.8666L9.72144 14.4241L11.3279 14.158ZM9.78093 14.4625L9.87332 14.8111L11.29 14.5287L11.2817 14.2139L9.78093 14.4625Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.86937 14.8101L9.99832 15.096L8.76172 15.5197L8.51416 15.2171L9.86937 14.8101ZM8.59627 15.2422L8.77762 15.4639L9.93336 15.0679L9.84309 14.8677L8.59627 15.2422Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.1417 15.241L7.48811 15.7218L6.58336 16.3233L6.125 15.834L7.1417 15.241ZM6.20065 15.8451L6.59057 16.2613L7.42052 15.7095L7.12833 15.3039L6.20065 15.8451Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.56229 15.1956L8.80668 15.4997L7.77501 16.0205L7.41016 15.7093L8.56229 15.1956ZM7.49856 15.722L7.78183 15.9637L8.73267 15.4837L8.54815 15.254L7.49856 15.722Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3096 10.6067V11.54L9.38092 11.6084L9.3208 10.6165L11.3096 10.6067ZM9.37142 10.6639L9.42568 11.5591L11.2619 11.494V10.6546L9.37142 10.6639Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.90989 11.0479L6.09908 12.1382L4.72522 12.6257L4.47144 11.5011L5.90989 11.0479ZM4.52757 11.5333L4.75989 12.5628L6.04525 12.1067L5.87225 11.1097L4.52757 11.5333Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3418 14.5193L11.3534 14.7861L9.95687 15.1095L9.8269 14.8213L11.3418 14.5193ZM9.89498 14.8563L9.98423 15.0542L11.3041 14.7486L11.2966 14.5769L9.89498 14.8563Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.32619 9.61914L9.3711 10.6621L7.50413 10.8136L7.40942 9.71693L9.32619 9.61914ZM7.46115 9.76201L7.54753 10.7622L9.32151 10.6183L9.28064 9.66918L7.46115 9.76201Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1142 12.9792L13.0368 13.5984L11.2764 13.7109L11.2712 13.043L13.1142 12.9792ZM11.3193 13.089L11.3236 13.6601L12.9944 13.5534L13.06 13.0288L11.3193 13.089Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.45684 9.70752L7.55142 10.8076L5.87546 11.1066L5.7312 9.96244L7.45684 9.70752ZM5.78432 10.0028L5.91647 11.0508L7.50021 10.7684L7.41369 9.76207L5.78432 10.0028Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0442 13.5454L12.96 14.0506L11.2863 14.2072L11.2759 13.6584L13.0442 13.5454ZM11.3244 13.703L11.333 14.155L12.919 14.0066L12.9873 13.5968L11.3244 13.703Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1803 12.3081L13.1089 13.027L11.2717 13.0906L11.2666 12.313L13.1803 12.3081ZM11.3146 12.3605L11.319 13.0412L13.0656 12.9809L13.1276 12.3559L11.3146 12.3605Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3949 14.4365L5.7923 15.2364L4.93624 15.9083L4.4436 15.0989L5.3949 14.4365ZM4.50761 15.1124L4.94904 15.8376L5.73236 15.2228L5.3768 14.5072L4.50761 15.1124Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.04977 13.5547L5.41012 14.4806L4.46124 15.1413L4.021 14.1958L5.04977 13.5547ZM4.08201 14.2139L4.48052 15.0698L5.3521 14.4629L5.0262 13.6255L4.08201 14.2139Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.45596 15.6907L7.81652 15.9929L7.01702 16.6025L6.54346 16.2973L7.45596 15.6907ZM6.63047 16.2967L7.01472 16.5443L7.74023 15.9911L7.45266 15.7501L6.63047 16.2967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.76336 15.1948L6.19399 15.8484L5.43562 16.5269L4.9043 15.8691L5.76336 15.1948ZM4.97174 15.8767L5.4412 16.458L6.13158 15.8403L5.75205 15.2643L4.97174 15.8767Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.97016 15.0503L10.1231 15.17L8.98327 15.608L8.70435 15.484L9.97016 15.0503ZM8.83461 15.4897L8.98459 15.5564L10.0275 15.1556L9.96115 15.1038L8.83461 15.4897Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9702 14.0066L12.8826 14.3791L11.296 14.575L11.2852 14.1643L12.9702 14.0066ZM11.334 14.2076L11.3422 14.5212L12.8438 14.3359L12.9086 14.0602L11.334 14.2076Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.262 11.4878L13.2369 11.5416L13.1762 12.351L11.267 12.3559L11.262 11.4878ZM11.3099 11.5368L11.3144 12.3081L13.132 12.3034L13.1856 11.5878L11.3099 11.5368Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.76891 15.4653L9.03663 15.5843L8.10523 16.118L7.71484 15.9974L8.76891 15.4653ZM8.77021 15.5181L7.84176 15.9867L8.09951 16.0663L8.93139 15.5897L8.77021 15.5181Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27832 9.61987L11.3096 9.6687V10.6545L9.3233 10.6643L9.27832 9.61987ZM9.32813 9.66874L9.36893 10.6164L11.2619 10.6071V9.71523L9.32813 9.66874Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.76171 12.5588L5.07087 13.596L4.04402 14.2409L3.66016 13.1791L4.76171 12.5588ZM3.71869 13.2008L4.06874 14.1691L5.01486 13.5749L4.73308 12.6296L3.71869 13.2008Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3426 14.7385L11.3661 14.8562L10.07 15.1936L9.91187 15.0698L11.3426 14.7385ZM10.0198 15.0938L10.0808 15.1416L11.3105 14.8214L11.3055 14.796L10.0198 15.0938Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16907 15.811L6.61978 16.2925L5.96418 16.9781L5.40723 16.4927L6.16907 15.811ZM5.47922 16.4922L5.96118 16.9123L6.55417 16.2921L6.16613 15.8776L5.47922 16.4922Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.76812 9.95215L5.91722 11.0953L4.48038 11.548L4.28662 10.3656L5.76812 9.95215ZM4.34055 10.4L4.51851 11.486L5.8648 11.0619L5.72797 10.0128L4.34055 10.4Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8955 14.3289L12.7992 14.5696L11.3012 14.7948L11.2896 14.5271L12.8955 14.3289ZM11.3391 14.569L11.3465 14.7398L12.7651 14.5265L12.8213 14.386L11.3391 14.569Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30264 8.6001L9.32756 9.66686L7.41254 9.76457L7.35278 8.63919L9.30264 8.6001ZM7.40298 8.68585L7.45761 9.71455L9.27884 9.62163L9.2561 8.6487L7.40298 8.68585Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.262 10.6055L13.2839 10.7227L13.2336 11.594L11.262 11.5403V10.6055ZM11.3096 10.656V11.4939L13.1887 11.5451L13.2336 10.7675L11.3096 10.656Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.40051 8.63599L7.4602 9.76004L5.73604 10.0147L5.6416 8.84176L7.40051 8.63599ZM5.69279 8.88375L5.77945 9.96016L7.41031 9.71923L7.35561 8.68922L5.69279 8.88375Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.77834 15.9656L8.14953 16.0819L7.45601 16.6995L6.96582 16.5851L7.77834 15.9656ZM7.78762 16.0184L7.07514 16.5617L7.44277 16.6475L8.05504 16.1022L7.78762 16.0184Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5208 11.4819L4.77451 12.6063L3.67445 13.2257L3.36108 12.0767L4.5208 11.4819ZM3.41723 12.1014L3.70434 13.1541L4.72022 12.5821L4.48784 11.5524L3.41723 12.1014Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3816 14.9756L10.0818 15.1841L9.30946 15.4809L9.19766 15.5282L8.98739 15.6047L8.9707 15.5601L9.29163 15.4367L10.3816 14.9756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.58819 16.2615L7.05574 16.568L6.49774 17.2516L5.92334 16.9515L6.58819 16.2615ZM6.5951 16.323L6.00182 16.9387L6.48538 17.1914L6.98536 16.5788L6.5951 16.323Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.25024 8.59448L11.3097 8.70673V9.71636L9.28019 9.66757L9.25024 8.59448ZM9.29933 8.64488L9.32657 9.62102L11.2621 9.66754V8.75186L9.29933 8.64488Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8829 14.5049L12.7153 14.6232L11.3179 14.8617L11.302 14.7425L12.8829 14.5049ZM11.3554 14.7827L11.3586 14.8064L12.5462 14.6037L11.3554 14.7827Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0663 12.9797L14.8766 13.019L14.726 13.5853L12.9888 13.6L13.0663 12.9797ZM13.1083 13.0283L13.0428 13.5518L14.6892 13.5379L14.815 13.0653L13.1083 13.0283Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.741 13.5281L14.5817 13.99L12.9111 14.0488L12.9955 13.5428L14.741 13.5281ZM13.0359 13.5901L12.9677 13.9991L14.5473 13.9436L14.674 13.5763L13.0359 13.5901Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37013 15.4253L8.42638 16.0332L7.94507 16.1499L8.97411 15.5603L9.37013 15.4253ZM8.99383 15.6039L8.25959 16.0246L8.40734 15.9888L9.01718 15.596L8.99383 15.6039Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.262 9.66577L13.3266 9.85124L13.2814 10.7702L11.262 10.6532V9.66577ZM11.3096 9.7179V10.6082L13.2361 10.7199L13.2767 9.89462L11.3096 9.7179Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3756 14.7251L11.36 14.8578L9.95947 15.2225L10.1696 15.0697L11.3756 14.7251ZM10.198 15.1111L11.3164 14.8199L11.3199 14.7906L10.198 15.1111Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1325 12.3071L15.0035 12.4051L14.8651 13.0664L13.061 13.0273L13.1325 12.3071ZM13.1755 12.3571L13.1135 12.9808L14.8265 13.0179L14.9455 12.4498L13.1755 12.3571Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.68392 8.82812L5.77829 10.0002L4.29778 10.4134L4.1687 9.19709L5.68392 8.82812ZM4.22049 9.23353L4.33922 10.3524L5.72763 9.96491L5.6409 8.88765L4.22049 9.23353Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6047 13.9414L14.4269 14.2803L12.8328 14.3784L12.9204 14.0007L14.6047 13.9414ZM12.9585 14.047L12.8936 14.3269L14.3972 14.2344L14.5244 13.9919L12.9585 14.047Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.47464 15.0659L4.96696 15.8747L4.36977 16.6275L3.79712 15.8187L4.47464 15.0659ZM3.85803 15.8223L4.37194 16.5481L4.90898 15.8711L4.46723 15.1454L3.85803 15.8223Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.9378 15.8347L5.46804 16.4912L4.95525 17.2403L4.33936 16.5891L4.9378 15.8347ZM4.93814 15.911L4.40236 16.5863L4.94929 17.1646L5.40868 16.4936L4.93814 15.911Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.32495 7.55664L9.28871 7.57129L9.29868 8.64291L7.34985 8.68198L7.32495 7.55664ZM7.37368 7.60466L7.39644 8.63338L9.25059 8.59621L9.24149 7.6186L7.37368 7.60466Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1844 11.5486L15.1068 11.7149L14.9898 12.4524L13.1235 12.3547L13.1844 11.5486ZM13.2283 11.6002L13.1747 12.3096L14.9494 12.4025L15.0517 11.758L13.2283 11.6002Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.06359 14.1641L4.50394 15.1098L3.82746 15.8615L3.31201 14.9106L4.06359 14.1641ZM3.37092 14.9193L3.83719 15.7794L4.44743 15.1014L4.04903 14.2457L3.37092 14.9193Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.33698 10.3479L4.53075 11.5304L3.37199 12.1246L3.13354 10.9125L4.33698 10.3479ZM3.18749 10.9398L3.40654 12.0533L4.47811 11.5038L4.30015 10.4178L3.18749 10.9398Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.37261 7.56445L7.39748 8.68378L5.63938 8.88946L5.59961 7.71628L7.37261 7.56445ZM5.64877 7.75991L5.68525 8.83611L7.34887 8.64148L7.32609 7.61627L5.64877 7.75991Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4657 14.2395L14.2706 14.4472L12.7456 14.58L12.8418 14.3394L14.4657 14.2395ZM12.8749 14.3851L12.8186 14.5258L14.2483 14.4013L14.3487 14.2944L12.8749 14.3851Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49097 15.9685L7.88103 16.605L7.37207 16.7103L8.0958 16.0658L8.49097 15.9685ZM8.11867 16.1092L7.53661 16.6276L7.85687 16.5613L8.34303 16.054L8.11867 16.1092Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.01149 16.5422L7.48848 16.6535L7.03173 17.3336L6.44727 17.2335L7.01149 16.5422ZM7.02961 16.5954L6.53586 17.2004L7.00937 17.2814L7.41044 16.6843L7.02961 16.5954Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.4385 16.4617L5.99295 16.9398L5.56976 17.6854L4.92432 17.2128L5.4385 16.4617ZM5.44772 16.5326L4.98972 17.2016L5.55474 17.6154L5.93218 16.9503L5.44772 16.5326Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6442 14.4839L12.7495 14.6173L11.3108 14.8629L11.3261 14.7329L12.6442 14.4839ZM11.3693 14.7732L11.3656 14.8052L12.6623 14.5838L12.6247 14.5361L11.3693 14.7732Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.70465 13.1436L4.08388 14.2064L3.33309 14.9522L2.88892 13.8792L3.70465 13.1436ZM2.94582 13.8921L3.34993 14.8683L4.02883 14.1939L3.68369 13.2266L2.94582 13.8921Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.24121 7.56909L11.3146 7.73984L11.3096 8.75436L9.25119 8.64217L9.24121 7.56909ZM9.28935 7.62087L9.29843 8.59702L11.2622 8.70405L11.2667 7.78372L9.28935 7.62087Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.262 8.70239L13.3499 8.95119L13.3248 9.89899L11.262 9.71367V8.70239ZM11.3096 8.75606V9.6701L13.2785 9.84698L13.3011 8.99337L11.3096 8.75606Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2365 10.7185L15.2011 10.9482L15.105 11.7573L13.186 11.5913L13.2365 10.7185ZM13.2811 10.7717L13.2363 11.5478L15.0631 11.7059L15.1481 10.99L13.2811 10.7717Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.326 14.8008L10.1984 15.1047L9.11108 15.5647L9.36925 15.2435L10.326 14.8008ZM9.3994 15.2821L9.26679 15.4471L10.162 15.0683L10.2345 14.8957L9.3994 15.2821Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3873 14.4868L11.3757 14.7711L10.1438 15.1231L10.2674 14.8287L11.3873 14.4868ZM10.3027 14.8678L10.2262 15.05L11.3295 14.7348L11.3369 14.552L10.3027 14.8678Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2623 14.4004L14.2694 14.4474L14.1156 14.481L12.6199 14.6388L12.7786 14.5296L14.2623 14.4004Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.51431 15.1428L9.21006 15.5287L8.42992 16.0312L8.40137 15.9932L8.69764 15.7352L9.51431 15.1428ZM8.72734 15.7725L8.68133 15.8126L9.17757 15.4929L9.2641 15.3832L8.72734 15.7725Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.64711 7.7041L5.68685 8.87657L4.17206 9.24543L4.11743 8.02873L5.64711 7.7041ZM4.16685 8.06696L4.21707 9.18542L5.63791 8.83944L5.60141 7.76252L4.16685 8.06696Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.95321 16.9087L6.52248 17.2062L6.19399 17.9389L5.52832 17.6573L5.95321 16.9087ZM5.97193 16.9722L5.59619 17.6343L6.16959 17.8769L6.46063 17.2276L5.97193 16.9722Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.39759 12.0471L3.71637 13.1977L2.90112 13.933L2.53247 12.7672L3.39759 12.0471ZM2.58756 12.7834L2.92422 13.8479L3.66258 13.1821L3.37136 12.131L2.58756 12.7834Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.21476 9.18555L4.34389 10.3974L3.14084 10.9618L2.98193 9.71531L4.21476 9.18555ZM3.03375 9.74492L3.17984 10.8908L4.2929 10.3686L4.17422 9.25484L3.03375 9.74492Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2744 9.84546L15.2719 10.1434L15.1963 10.9955L13.2341 10.7661L13.2744 9.84546ZM13.3197 9.9004L13.2837 10.7239L15.1531 10.9425L15.2205 10.1839L13.3197 9.9004Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.33541 6.50757L9.29398 6.57595L9.28898 7.62374L7.32544 7.60909L7.33541 6.50757ZM7.38262 6.5569L7.37353 7.56179L9.24156 7.57573L9.2461 6.62196L7.38262 6.5569Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5841 14.2537L12.6697 14.5333L11.3274 14.7868L11.3389 14.5047L12.5841 14.2537ZM11.385 14.544L11.3775 14.7289L12.6086 14.4963L12.5512 14.3089L11.385 14.544Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.90909 16.5496L7.54625 17.2286L6.96411 17.3418L7.43045 16.6475L7.90909 16.5496ZM7.45913 16.6902L7.06765 17.2731L7.5149 17.1861L7.81924 16.6166L7.45913 16.6902Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6958 13.5371L16.3343 13.6111L16.1033 14.0194L14.5308 13.9949L14.6958 13.5371ZM14.7287 13.5863L14.5982 13.9482L16.0757 13.9713L16.2546 13.6552L14.7287 13.5863Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8279 13.0122L16.5149 13.1401L16.3055 13.653L14.677 13.5794L14.8279 13.0122ZM14.8638 13.0627L14.7383 13.5344L16.2741 13.6038L16.446 13.1827L14.8638 13.0627Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2716 7.74365L13.3736 8.05577L13.3535 9.00374L11.2666 8.75506L11.2716 7.74365ZM11.319 7.79887L11.3145 8.71277L13.307 8.9502L13.325 8.09675L11.319 7.79887Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.3829 6.50708L7.37294 7.60744L5.6001 7.75926L5.61005 6.60992L7.3829 6.50708ZM5.65731 6.65492L5.64821 7.70731L7.32568 7.56366L7.33478 6.55761L5.65731 6.65492Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9703 14.3328L14.1729 14.4746L12.6954 14.6304L12.5894 14.4961L13.9703 14.3328ZM12.6795 14.5334L12.7165 14.5802L14.0412 14.4406L13.9578 14.3822L12.6795 14.5334Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.43327 16.0331L8.05093 16.432L8.24318 16.2984L8.51983 15.9577L8.43327 16.0331ZM8.69663 15.7405L8.73078 15.7735L8.27592 16.3337L7.88237 16.6072L7.85156 16.5712L8.40035 15.9985L8.69663 15.7405Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5504 13.9426L16.1398 13.9675L15.8913 14.2668L14.3726 14.2816L14.5504 13.9426ZM14.579 13.9907L14.4518 14.2332L15.8687 14.2194L16.0396 14.0136L14.579 13.9907Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9507 12.4014L16.6833 12.5928L16.4976 13.1913L14.8171 13.0639L14.9507 12.4014ZM14.9888 12.4535L14.8745 13.0205L16.4633 13.1409L16.6207 12.6338L14.9888 12.4535Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.2512 6.57251L11.3242 6.80176L11.3142 7.79252L9.24121 7.6218L9.2512 6.57251ZM9.29836 6.62567L9.28929 7.57795L11.2671 7.74082L11.2761 6.84439L9.29836 6.62567Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.47735 17.1902L7.05191 17.2885L6.81302 18.0052L6.14673 17.9277L6.47735 17.1902ZM6.50572 17.2434L6.21681 17.8879L6.78005 17.9534L6.98914 17.3261L6.50572 17.2434Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4101 14.4138L10.3057 14.8698L9.3418 15.3159L9.55191 14.8404L10.4101 14.4138ZM9.58854 14.8754L9.43646 15.2196L10.2644 14.8364L10.3412 14.5013L9.58854 14.8754Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9647 14.2185L15.6727 14.4002L14.2029 14.4451L14.4018 14.2337L15.9647 14.2185ZM14.4226 14.2812L14.3165 14.3939L15.6584 14.353L15.7953 14.2678L14.4226 14.2812Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3963 14.1055L11.3855 14.5373L10.2527 14.8831L10.3555 14.4342L11.3963 14.1055ZM10.3958 14.4714L10.3175 14.8135L11.3387 14.5017L11.347 14.171L10.3958 14.4714Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0593 11.7046L16.8336 11.9595L16.6701 12.6392L14.9421 12.4482L15.0593 11.7046ZM15.099 11.7584L14.997 12.4064L16.6336 12.5872L16.775 11.9992L15.099 11.7584Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3026 8.94385L15.3187 9.30997L15.2684 10.1911L13.2725 9.89337L13.3026 8.94385ZM13.3485 9.00061L13.3214 9.8525L15.2238 10.1362L15.2688 9.34933L13.3485 9.00061Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.17634 10.8838L3.41527 12.0983L2.55003 12.8185L2.27124 11.5839L3.17634 10.8838ZM2.32441 11.603L2.57937 12.7321L3.36305 12.0798L3.14448 10.9687L2.32441 11.603Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.82431 15.7747L4.39809 16.585L4.04328 17.396L3.40381 16.5954L3.82431 15.7747ZM3.83086 15.8664L3.46027 16.5897L4.03126 17.3046L4.34364 16.5906L3.83086 15.8664Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36359 16.5452L4.97976 17.1967L4.69584 17.9927L4.00879 17.3561L4.36359 16.5452ZM4.37841 16.6302L4.06603 17.3442L4.67523 17.9086L4.92506 17.2082L4.37841 16.6302Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.63132 14.7727L9.40835 15.2832L8.62573 15.8509L8.96598 15.3136L9.63132 14.7727ZM9.00208 15.3457L8.79905 15.6663L9.36987 15.2522L9.50909 14.9335L9.00208 15.3457Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.34723 14.8613L3.85937 15.8203L3.43863 16.6415L2.86597 15.6921L3.34723 14.8613ZM2.92133 15.6916L3.43523 16.5437L3.80559 15.8208L3.34554 14.9593L2.92133 15.6916Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.65784 6.60352L5.6479 7.75246L4.11792 8.07715L4.13284 6.88884L5.65784 6.60352ZM4.18 6.9285L4.16632 8.01816L5.60057 7.71379L5.60969 6.66101L4.18 6.9285Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4617 14.4146L14.1131 14.4813L14.1067 14.4342L14.2567 14.4004L15.6648 14.3574L15.6666 14.405L15.4618 14.4146L15.4617 14.4146Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8438 14.0984L13.9973 14.377L12.6124 14.5409L12.5325 14.2611L13.8438 14.0984ZM12.5936 14.3015L12.6471 14.4888L13.9214 14.338L13.8176 14.1497L12.5936 14.3015Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16449 8.01318L4.21916 9.23064L2.98607 9.76052L2.9165 8.50842L4.16449 8.01318ZM2.96599 8.54005L3.02987 9.68983L4.17007 9.19986L4.11989 8.08216L2.96599 8.54005Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.94049 17.1646L5.58959 17.6362L5.37659 18.4121L4.65649 17.9608L4.94049 17.1646ZM4.96399 17.2405L4.71424 17.9407L5.34759 18.3377L5.5349 17.6553L4.96399 17.2405Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5251 13.8813L12.595 14.2953L11.3374 14.5488L11.3482 14.1187L12.5251 13.8813ZM11.3948 14.1579L11.3865 14.4903L12.5403 14.2577L12.4863 13.9378L11.3948 14.1579Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1492 10.9412L16.9561 11.2643L16.824 12.0062L15.053 11.7518L15.1492 10.9412ZM15.1906 10.997L15.1058 11.7113L16.7851 11.9525L16.9008 11.3029L15.1906 10.997Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2716 6.79443L13.3684 7.1699V8.09911L11.2666 7.78702L11.2716 6.79443ZM11.319 6.85133L11.3145 7.74594L13.3207 8.04385V7.20978L11.319 6.85133Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.37894 5.50635L9.31807 5.62357L9.29306 6.62375L7.33398 6.55536L7.37894 5.50635ZM7.42448 5.55684L7.38365 6.5094L9.24662 6.57445L9.26927 5.66837L7.42448 5.55684Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.30537 16.2527L8.03568 16.9377L7.46948 17.2707L7.84576 16.5666L8.30537 16.2527ZM7.88229 16.5993L7.59137 17.1438L7.99736 16.9049L8.20424 16.3795L7.88229 16.5993Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9219 13.844L3.36807 14.9168L2.88643 15.7483L2.38989 14.6749L2.9219 13.844ZM2.44411 14.6786L2.89117 15.645L3.31501 14.9133L2.91289 13.9464L2.44411 14.6786Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.42673 5.50684L7.38182 6.55484L5.6084 6.65771L5.67323 5.56561L7.42673 5.50684ZM5.71823 5.61179L5.65915 6.60703L7.33605 6.50976L7.37692 5.55619L5.71823 5.61179Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27529 5.62329L11.3287 5.90618L11.3237 6.84947L9.25024 6.62017L9.27529 5.62329ZM9.32159 5.67778L9.29898 6.57762L11.2763 6.79629L11.2808 5.9477L9.32159 5.67778Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.56628 17.177L7.41223 17.8805L6.76099 18.0108L7.00236 17.2867L7.56628 17.177ZM7.03877 17.3281L6.83217 17.9479L7.37234 17.8399L7.50422 17.2376L7.03877 17.3281Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3212 8.04224L15.3516 8.47174L15.3214 9.35804L13.3062 8.99207L13.3212 8.04224ZM13.368 8.10083L13.3544 8.95241L15.2756 9.30129L15.3026 8.51008L13.368 8.10083Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2659 14.2571L15.546 14.4105L14.1049 14.4818L13.8992 14.3378L15.2659 14.2571ZM14.0387 14.3773L14.1189 14.4334L15.3751 14.3713L15.255 14.3055L14.0387 14.3773Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.59778 15.8541L8.95943 15.3164L8.99943 15.3423L8.73177 15.7725L8.27822 16.3311L8.27809 16.3312L8.24097 16.3013L8.24109 16.3012L8.59778 15.8541Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.54652 17.6133L6.2087 17.8934L6.07163 18.6448L5.33325 18.3902L5.54652 17.6133ZM5.57808 17.6784L5.39104 18.3597L6.0347 18.5817L6.15497 17.9224L5.57808 17.6784Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.02654 9.68213L3.18584 10.9367L2.28017 11.6372L2.09595 10.3626L3.02654 9.68213ZM2.14721 10.3841L2.31565 11.5495L3.13513 10.9156L2.98944 9.76829L2.14721 10.3841Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2247 10.1348L17.0549 10.5262L16.9535 11.3115L15.1489 10.9887L15.2247 10.1348ZM15.2674 10.1926L15.2003 10.9495L16.9127 11.2558L17.002 10.5637L15.2674 10.1926Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4056 13.5862L11.3952 14.1559L10.3464 14.487L10.4353 13.8913L11.4056 13.5862ZM10.478 13.9278L10.4048 14.4186L11.3481 14.1207L11.3567 13.6515L10.478 13.9278Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.487 13.8669L10.3973 14.4686L9.53296 14.8982L9.70728 14.2696L10.487 13.8669ZM9.74764 14.3024L9.60744 14.808L10.3539 14.437L10.4262 13.952L9.74764 14.3024Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7278 13.7454L13.8678 14.1439L12.5482 14.3077L12.4783 13.8935L13.7278 13.7454ZM12.5336 13.935L12.5876 14.2547L13.8033 14.1039L13.6955 13.7972L12.5336 13.935Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.57277 12.7219L2.94343 13.894L2.40577 14.7258L1.99487 13.5533L2.57277 12.7219ZM2.0479 13.5605L2.41857 14.6183L2.89122 13.887L2.55657 12.8287L2.0479 13.5605Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2766 13.603L17.7119 13.7471L17.4352 14.1105L16.0508 14.0116L16.2766 13.603ZM16.3031 13.6536L16.1286 13.9694L17.4129 14.0611L17.6223 13.786L16.3031 13.6536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.079 13.9658L17.4838 14.0662L17.1796 14.3244L15.8308 14.2647L16.079 13.9658ZM16.1 14.0151L15.9288 14.2214L17.163 14.276L17.3639 14.1054L16.1 14.0151Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4654 13.1448L17.9337 13.3425L17.6803 13.7966L16.2556 13.6536L16.4654 13.1448ZM16.4955 13.1969L16.3241 13.6126L17.6539 13.746L17.858 13.3804L16.4955 13.1969Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4743 13.3855L12.5317 13.9285L11.3472 14.1674L11.3576 13.5989L12.4743 13.3855ZM11.4045 13.6385L11.3959 14.1089L12.4798 13.8903L12.4324 13.442L11.4045 13.6385Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7729 14.2144L9.58625 14.8621L8.92261 15.4017L9.19755 14.7198L9.7729 14.2144ZM9.23752 14.7481L9.0359 15.2481L9.5446 14.8346L9.68202 14.3576L9.23752 14.7481Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0853 14.0278L15.3082 14.3022L13.9557 14.382L13.7954 14.1072L15.0853 14.0278ZM13.8756 14.15L13.9821 14.3327L15.2126 14.2601L15.0638 14.0769L13.8756 14.15Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2862 5.89795L13.3683 6.32706L13.3734 7.21852L11.2761 6.84297L11.2862 5.89795ZM11.3332 5.9563L11.3242 6.80316L13.3254 7.16152L13.3209 6.36594L11.3332 5.9563Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.15769 17.8914L6.82253 17.963L6.75637 18.6958L6.02026 18.6447L6.15769 17.8914ZM6.19662 17.9435L6.07671 18.6008L6.71311 18.645L6.77085 18.0053L6.19662 17.9435Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.18065 6.86963L4.16572 8.06404L2.91675 8.55967L2.93665 7.33055L4.18065 6.86963ZM2.98378 7.36391L2.96555 8.48903L4.11847 8.03152L4.13213 6.93843L2.98378 7.36391Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8686 14.2236L17.2789 14.2861L16.9183 14.429L15.5859 14.4035L15.8686 14.2236ZM15.8815 14.2719L15.7448 14.3589L16.9097 14.3811L17.0542 14.3238L15.8815 14.2719Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.72608 5.56055L5.65628 6.65248L4.13013 6.93802L4.21984 5.80174L5.72608 5.56055ZM4.26439 5.84287L4.18253 6.87973L5.61108 6.61245L5.67472 5.61704L4.26439 5.84287Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6352 12.5862L18.134 12.8475L17.9107 13.3875L16.4492 13.1907L16.6352 12.5862ZM16.6684 12.6403L16.5113 13.151L17.8806 13.3354L18.0672 12.8842L16.6684 12.6403Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2813 9.2998L17.1304 9.75961L17.0545 10.574L15.2258 10.1828L15.2813 9.2998ZM15.3253 9.35985L15.276 10.1448L17.012 10.5162L17.0791 9.79598L15.3253 9.35985Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3256 7.15967L15.3508 7.65256V8.52513L13.3206 8.09565L13.3256 7.15967ZM13.3729 7.22023L13.3684 8.05707L15.3032 8.46634V7.69001L13.3729 7.22023Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4611 4.59058L9.3664 4.75668L9.32119 5.67578L7.38086 5.55847L7.4611 4.59058ZM7.50464 4.64221L7.43238 5.51385L9.27596 5.6253L9.31654 4.80017L7.50464 4.64221Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66962 15.7605L8.4813 16.4567L7.94897 17.0234L8.2341 16.2992L8.66962 15.7605ZM8.27576 16.3234L8.08432 16.8097L8.43841 16.4328L8.56462 15.9662L8.27576 16.3234Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6648 14.3528L16.9145 14.3766L16.9136 14.4243L16.6612 14.4195L15.4556 14.4148L15.4541 14.3672L15.6648 14.3528Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.04481 16.8765L7.97702 17.5701L7.35767 17.9112L7.51406 17.197L8.04481 16.8765ZM7.55623 17.2272L7.42669 17.8188L7.93203 17.5404L7.98814 16.9664L7.55623 17.2272Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7915 11.9465L18.3222 12.2714L18.1218 12.8881L16.6277 12.6276L16.7915 11.9465ZM16.827 12.0028L16.6859 12.5894L18.0893 12.834L18.2606 12.307L16.827 12.0028Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.31894 4.74585L11.3385 5.07756L11.3284 5.95427L9.27368 5.6712L9.31894 4.74585ZM9.36393 4.80153L9.32341 5.62994L11.2814 5.89968L11.2903 5.11795L9.36393 4.80153Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6254 13.2688L13.7406 13.787L12.4847 13.936L12.4272 13.3926L13.6254 13.2688ZM12.4797 13.4351L12.527 13.8829L13.6826 13.7459L13.5881 13.3206L12.4797 13.4351Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50389 4.58765L7.42373 5.55457L5.6687 5.6134L5.78882 4.60235L7.50389 4.58765ZM5.8312 4.64964L5.72257 5.56391L7.37974 5.50836L7.45208 4.63575L5.8312 4.64964Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.96267 8.48535L3.03247 9.74173L2.10064 10.4231L2.01587 9.1416L2.96267 8.48535ZM2.0652 9.16539L2.14247 10.3335L2.98345 9.71854L2.91981 8.57304L2.0652 9.16539Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.31446 11.542L2.59018 12.7802L2.01579 13.6138L1.70483 12.365L2.31446 11.542ZM1.75651 12.3753L2.0365 13.4998L2.53914 12.7703L2.29063 11.6542L1.75651 12.3753Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.19688 14.7222L9.23976 14.7427L9.00055 15.336L8.63697 15.8766L8.59595 15.8525L8.9107 15.2325L9.19688 14.7222ZM8.95275 15.255L8.81463 15.527L8.95822 15.3135L9.05627 15.0704L8.95275 15.255Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9174 13.6799L15.1067 14.0693L13.8138 14.1488L13.679 13.7443L14.9174 13.6799ZM13.744 13.7887L13.8474 14.099L15.0327 14.0261L14.8883 13.7292L13.744 13.7887Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.01895 17.301L4.70997 17.9412L4.64814 18.7244L3.92627 18.094L4.01895 17.301ZM4.05539 17.3998L3.97652 18.0746L4.60809 18.6261L4.66064 17.9605L4.05539 17.3998Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.41653 16.5349L4.06217 17.3432L3.96883 18.1418L3.29736 17.3432L3.41653 16.5349ZM3.44769 16.6503L3.34764 17.3289L3.9343 18.0267L4.01253 17.3574L3.44769 16.6503Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4152 12.9385L11.3998 13.6363L10.4243 13.9431L10.5012 13.2096L11.4152 12.9385ZM10.5453 13.2463L10.4793 13.8758L11.3529 13.6011L11.3661 13.0028L10.5453 13.2463Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4287 14.2527L16.7698 14.4199L15.4544 14.4147L15.168 14.2579L16.4287 14.2527ZM15.3528 14.3048L15.4667 14.3671L16.5627 14.3714L16.4177 14.3004L15.3528 14.3048Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5565 13.1914L10.479 13.9248L9.69507 14.3295L9.83967 13.56L10.5565 13.1914ZM9.8822 13.5918L9.75993 14.2424L10.4343 13.8942L10.4998 13.2741L9.8822 13.5918Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9144 11.2495L18.4678 11.6379L18.3044 12.3172L16.7769 11.9931L16.9144 11.2495ZM16.952 11.308L16.8322 11.9561L18.2689 12.261L18.4104 11.6727L16.952 11.308Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.66473 17.9097L5.38648 18.362L5.35574 19.1254L4.60327 18.6882L4.66473 17.9097ZM4.70605 17.9918L4.65314 18.662L5.3113 19.0445L5.33775 18.3877L4.70605 17.9918Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.41593 17.8313L7.42104 18.537L6.70801 18.6909L6.77449 17.9545L7.41593 17.8313ZM6.81872 17.9945L6.7613 18.6306L7.37311 18.4986L7.36869 17.8889L6.81872 17.9945Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3042 8.45972L17.1627 8.98302L17.1222 9.80773L15.2739 9.34811L15.3042 8.45972ZM15.3498 8.52205L15.3229 9.31117L17.0774 9.74749L17.1132 9.0186L15.3498 8.52205Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.87641 15.6252L3.45685 16.5875L3.33662 17.403L2.73022 16.4449L2.87641 15.6252ZM2.90108 15.7584L2.78039 16.4351L3.30821 17.269L3.40721 16.5975L2.90108 15.7584Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4372 12.7761L12.4833 13.4382L11.3616 13.6526L11.3718 12.9553L12.4372 12.7761ZM11.4189 12.9957L11.4101 13.5948L12.4329 13.3993L12.3933 12.8318L11.4189 12.9957Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2909 5.06787L13.3441 5.54581L13.3643 6.37579L11.2808 5.9464L11.2909 5.06787ZM11.3378 5.12774L11.3289 5.90765L13.3152 6.31701L13.2973 5.58386L11.3378 5.12774Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3157 6.31519L15.3266 6.86672L15.3519 7.70193L13.3258 7.20882L13.3157 6.31519ZM13.364 6.37787L13.373 7.17127L15.3024 7.64082L15.28 6.90337L13.364 6.37787Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.89782 13.5083L9.74941 14.3033L9.17407 14.8088L9.39647 13.9827L9.89782 13.5083ZM9.439 14.0081L9.26108 14.6689L9.70559 14.2784L9.82406 13.6437L9.439 14.0081Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3397 18.3467L6.07229 18.6011V19.3512L5.30908 19.107L5.3397 18.3467ZM5.38473 18.4128L5.35816 19.0726L6.02464 19.2859V18.635L5.38473 18.4128Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0198 14.0239H16.2044L16.4758 14.2953L15.2444 14.3004L15.0198 14.0239ZM15.1199 14.0716L15.267 14.2526L16.3612 14.2481L16.1847 14.0716H15.1199Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.26895 5.79272L4.17909 6.92594L2.93311 7.3876L3.04292 6.21464L4.26895 5.79272ZM3.0875 6.2497L2.98762 7.31658L4.13399 6.89183L4.21569 5.86145L3.0875 6.2497Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0134 10.5144L18.5855 10.9713L18.4582 11.6895L16.9067 11.3016L17.0134 10.5144ZM17.0532 10.5756L16.9597 11.2657L18.4202 11.6308L18.5311 11.0051L17.0532 10.5756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.84206 4.59814L5.72192 5.60929L4.21362 5.85082L4.37372 4.80016L5.84206 4.59814ZM4.41547 4.84252L4.27057 5.79344L5.67884 5.56793L5.78746 4.65376L4.41547 4.84252Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.40331 14.5964L2.91367 15.6886L2.76608 16.5161L2.23486 15.4282L2.40331 14.5964ZM2.42133 14.7476L2.28478 15.4218L2.74481 16.364L2.8641 15.6951L2.42133 14.7476Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7771 13.2322L14.9402 13.7267L13.6975 13.7914L13.582 13.2718L14.7771 13.2322ZM13.641 13.3176L13.7353 13.7417L14.8754 13.6824L14.743 13.281L13.641 13.3176Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.546 12.6877L13.6387 13.3158L12.4361 13.44L12.3899 12.7771L13.546 12.6877ZM12.4407 12.8209L12.4803 13.3875L13.5843 13.2735L13.5054 12.7387L12.4407 12.8209Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.56721 3.77051L9.41935 3.97576L9.35871 4.79955L7.45117 4.63325L7.56721 3.77051ZM7.60824 3.823L7.50506 4.59011L9.31472 4.74788L9.36845 4.01807L7.60824 3.823Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.13702 10.3145L2.32278 11.5997L1.71129 12.4252L1.50537 11.1295L2.13702 10.3145ZM1.55567 11.1424L1.74054 12.3057L2.2728 11.5871L2.10591 10.4324L1.55567 11.1424Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48262 16.3909L8.49324 17.0648L7.92456 17.6125L7.99359 16.9062L8.48262 16.3909ZM8.03943 16.9271L7.98456 17.4885L8.44526 17.0449L8.43681 16.5084L8.03943 16.9271Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37691 3.96851L11.3481 4.34421L11.338 5.12578L9.31616 4.7937L9.37691 3.96851ZM9.42051 4.02533L9.36689 4.75374L11.291 5.06978L11.2999 4.38354L9.42051 4.02533Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.97741 15.1079L8.86868 15.8146L8.38818 16.6057L8.58999 15.8596L8.97741 15.1079ZM8.63467 15.8769L8.52195 16.2937L8.82302 15.798L8.88586 15.3896L8.63467 15.8769Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3034 7.63867L17.157 8.22558V9.03699L15.2983 8.51362L15.3034 7.63867ZM15.3507 7.70363L15.3462 8.47759L17.1094 8.97406V8.26048L15.3507 7.70363Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4166 14.0608L18.5487 14.2189L18.2065 14.4393L17.1138 14.3179L17.4166 14.0608ZM17.4312 14.1109L17.2288 14.2827L18.1949 14.3901L18.415 14.2483L17.4312 14.1109Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6571 13.75L18.8033 13.9561L18.4904 14.2635L17.3801 14.1085L17.6571 13.75ZM17.6771 13.802L17.468 14.0726L18.4737 14.2131L18.7041 13.9867L17.6771 13.802Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.97981 7.30713L2.96481 8.53688L2.01587 9.19461L2.03587 7.93475L2.97981 7.30713ZM2.08312 7.96056L2.06499 9.10258L2.91746 8.51172L2.93106 7.39677L2.08312 7.96056Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1681 14.2759L18.3296 14.4049L17.9171 14.515L16.8135 14.4204L17.1681 14.2759ZM17.1749 14.3246L17.0148 14.3898L17.9128 14.4668L18.0721 14.4243L17.1749 14.3246Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8835 13.3296L19.0512 13.5841L18.7642 13.988L17.6299 13.784L17.8835 13.3296ZM17.9079 13.3837L17.704 13.7489L18.7429 13.9357L18.9706 13.6153L17.9079 13.3837Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.95732 3.74878L7.61974 3.7733L7.5038 4.6353L5.78613 4.65003L5.95732 3.74878ZM5.99666 3.79702L5.84379 4.60187L7.46207 4.588L7.56535 3.82016L5.99666 3.79702Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4241 12.1819L11.419 12.9933L10.502 13.2654L10.5577 12.4136L11.4241 12.1819ZM10.603 12.4508L10.554 13.2002L11.3716 12.9577L11.3761 12.2441L10.603 12.4508Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02466 18.5923L6.75766 18.6432L6.78822 19.3768L6.02466 19.3411V18.5923ZM6.07231 18.6434V19.2956L6.73844 19.3267L6.71182 18.6878L6.07231 18.6434Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.9742 17.512L8.05152 18.1975L7.3782 18.5625L7.37305 17.8511L7.9742 17.512ZM7.42091 17.8788L7.42528 18.4827L8.00056 18.1709L7.93491 17.5888L7.42091 17.8788Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8694 13.6804L16.0076 13.6904L16.2417 14.0715H15.0595L14.8694 13.6804ZM14.9459 13.7287L15.0893 14.0239H16.1565L15.9808 13.7378L14.9459 13.7287Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.39611 13.9807L9.4408 13.9968L9.23988 14.7431L8.95248 15.2556L8.90918 15.2362L9.17643 14.4631L9.39611 13.9807ZM9.22073 14.4808L9.04135 14.9997L9.1954 14.725L9.31958 14.2637L9.22073 14.4808Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0788 9.74561L18.6652 10.2711L18.5787 11.0189L17.0078 10.5624L17.0788 9.74561ZM17.1211 9.80981L17.0587 10.5276L18.5378 10.9574L18.6134 10.3042L17.1211 9.80981Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4047 12.0571L12.4351 12.819L11.3665 12.9988L11.3766 12.1919L12.4047 12.0571ZM11.4237 12.2338L11.4148 12.9423L12.3858 12.779L12.3591 12.1112L11.4237 12.2338Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.01622 13.47L2.43683 14.6703L2.26621 15.5127L1.82983 14.3114L2.01622 13.47ZM2.02657 13.6436L1.87936 14.3081L2.25314 15.3371L2.38752 14.6736L2.02657 13.6436Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6599 14.4194L16.6624 14.3718L16.9156 14.3814L17.3725 14.4205L17.6358 14.4386L17.9173 14.4672L17.9129 14.5147L17.3688 14.4681L16.6599 14.4194Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6069 12.3926L10.5509 13.2429L9.83154 13.6129L9.94359 12.7165L10.6069 12.3926ZM9.98768 12.748L9.89003 13.5292L10.5051 13.2129L10.5539 12.4715L9.98768 12.748Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0915 12.8374L19.2745 13.1506L19.0236 13.6316L17.8625 13.3785L18.0915 12.8374ZM18.1193 12.8941L17.9288 13.3442L18.9981 13.5772L19.2047 13.1814L18.1193 12.8941Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3008 5.53735L15.2878 6.13786L15.3337 6.91677L13.3211 6.36478L13.3008 5.53735ZM13.35 5.60202L13.3679 6.32819L15.2822 6.85324L15.2422 6.17386L13.35 5.60202Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3005 4.33789L13.3103 4.85986L13.346 5.5999L11.2903 5.12137L11.3005 4.33789ZM11.3473 4.3993L11.3384 5.08364L13.2954 5.53918L13.2644 4.89718L11.3473 4.3993Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.66 12.6846L14.7893 13.2794L13.5909 13.3192L13.498 12.6895L14.66 12.6846ZM13.5532 12.737L13.6319 13.2701L14.7306 13.2337L14.6216 12.7324L13.5532 12.737Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3059 14.2454L17.3729 14.31L17.7625 14.4946L16.66 14.4189L16.3059 14.2454ZM16.5406 14.3073L16.6726 14.372L17.5144 14.4298L17.3608 14.357L16.5406 14.3073Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.271 12.2595L19.4763 12.6315L19.254 13.1898L18.0752 12.8778L18.271 12.2595ZM18.3021 12.319L18.1357 12.8445L19.2254 13.1329L19.4129 12.6618L18.3021 12.319Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4806 12.0166L13.5469 12.7352L12.3877 12.8247L12.3572 12.0613L13.4806 12.0166ZM12.4067 12.1071L12.4334 12.7734L13.495 12.6914L13.4373 12.066L12.4067 12.1071Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99773 12.6677L9.88338 13.5825L9.38135 14.0575L9.55275 13.1018L9.99773 12.6677ZM9.59696 13.1253L9.45383 13.9234L9.83821 13.5596L9.93355 12.7969L9.59696 13.1253Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2832 6.85107L17.1278 7.49692L17.1634 8.27626L15.3086 7.68897L15.2832 6.85107ZM15.3329 6.91898L15.3552 7.65374L17.1127 8.21021L17.0817 7.53126L15.3329 6.91898Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7268 13.2317L15.8341 13.2666L16.03 13.743L14.8904 13.733L14.7268 13.2317ZM14.7932 13.2815L14.9251 13.6856L15.9586 13.6947L15.8018 13.3133L14.7932 13.2815Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.111 8.97192L18.7022 9.56126L18.6614 10.32L17.0754 9.79462L17.111 8.97192ZM17.1558 9.03933L17.1246 9.7607L18.6172 10.2552L18.6527 9.59375L17.1558 9.03933Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.41847 18.4937L7.47971 19.2132L6.74058 19.3815L6.70996 18.6466L7.41847 18.4937ZM6.75925 18.6847L6.78581 19.3223L7.42871 19.176L7.37558 18.5517L6.75925 18.6847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.06525 9.08569L2.14589 10.3809L1.51115 11.1999L1.42041 9.88919L2.06525 9.08569ZM1.46924 9.90448L1.55006 11.0719L2.09721 10.3659L2.02534 9.21157L1.46924 9.90448Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41935 4.78198L4.25889 5.83996L3.02979 6.26293L3.2253 5.17005L4.41935 4.78198ZM3.26719 5.20654L3.09098 6.19147L4.2161 5.80429L4.36065 4.85117L3.26719 5.20654Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.71165 3.0791L9.49629 3.31868L9.41971 4.02318L7.56396 3.81753L7.71165 3.0791ZM7.74961 3.13228L7.62089 3.77589L9.3775 3.97055L9.44389 3.35973L7.74961 3.13228Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.0114 3.74561L5.83508 4.64733L4.36328 4.84983L4.59484 3.90849L6.0114 3.74561ZM4.63321 3.95204L4.42633 4.79305L5.79485 4.60476L5.95213 3.80039L4.63321 3.95204Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.44928 3.31055L11.3576 3.72052L11.3473 4.39272L9.37256 4.01634L9.44928 3.31055ZM9.49094 3.36824L9.42469 3.97776L11.3006 4.33529L11.3094 3.7589L9.49094 3.36824Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1338 14.0198L17.1241 14.0865L17.4285 14.3616L16.4079 14.2997L16.1338 14.0198ZM16.2553 14.0757L16.4291 14.2533L17.2957 14.3058L17.1044 14.1329L16.2553 14.0757Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.92505 18.03L4.65075 18.6631L4.74042 19.3963L4.01472 18.7632L3.92505 18.03ZM3.98745 18.1477L4.05981 18.7393L4.67802 19.2786L4.60566 18.687L3.98745 18.1477Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4221 11.6335L19.6406 12.0694L19.4606 12.6813L18.2581 12.3101L18.4221 11.6335ZM18.4559 11.6963L18.3149 12.2778L19.4285 12.6215L19.5821 12.0991L18.4559 11.6963Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28369 17.259L3.97431 18.0743L4.06593 18.8234L3.38074 18.0084L3.28369 17.259ZM3.35169 17.413L3.4262 17.9885L3.99914 18.6699L3.92873 18.0942L3.35169 17.413Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86531 15.7349L8.95167 16.3933L8.45151 17.1357L8.44067 16.4478L8.86531 15.7349ZM8.48854 16.4606L8.49677 16.9832L8.90208 16.3817L8.83593 15.8773L8.48854 16.4606Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.7304 12.27L2.05075 13.5566L1.86138 14.4115L1.52515 13.1235L1.7304 12.27ZM1.73121 12.4705L1.57428 13.123L1.85758 14.2083L2.0018 13.5572L1.73121 12.4705Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.59863 18.6301L5.35568 19.0751L5.44398 19.7971L4.68693 19.3521L4.59863 18.6301ZM4.65765 18.7201L4.73138 19.323L5.38496 19.7072L5.31123 19.1043L4.65765 18.7201Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.22966 14.3076L9.1982 15.0061L8.7915 16.0022L8.90831 15.243L9.22966 14.3076ZM8.95476 15.2544L8.9 15.6104L9.15096 14.9957L9.16716 14.6362L8.95476 15.2544Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.434 11.3352L11.4239 12.2313L10.5554 12.4636L10.5957 11.512L11.434 11.3352ZM10.6417 11.5509L10.6058 12.4008L11.3767 12.1946L11.3857 11.3941L10.6417 11.5509Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48375 17.0078L8.62418 17.6683L8.00774 18.2334L7.92969 17.5414L8.48375 17.0078ZM7.97968 17.5594L8.0446 18.135L8.57191 17.6516L8.45499 17.1017L7.97968 17.5594Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.262 4.84033L15.2158 5.4851L15.2877 6.18894L13.2978 5.58755L13.262 4.84033ZM13.3129 4.90732L13.3438 5.55167L15.2331 6.12263L15.1715 5.52068L13.3129 4.90732Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3909 11.2578L12.406 12.1051L11.376 12.2401L11.3861 11.3378L12.3909 11.2578ZM11.4332 11.3819L11.4242 12.1857L12.3576 12.0633L12.3442 11.3093L11.4332 11.3819Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.08842 6.18555L2.98297 7.36552L2.0354 7.99556L2.16094 6.78539L3.08842 6.18555ZM2.20598 6.81301L2.09324 7.89987L2.93754 7.3385L3.03225 6.27862L2.20598 6.81301Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.71802 16.3364L3.34542 17.3276L3.44563 18.1015L2.81818 17.1155L2.71802 16.3364ZM2.79269 16.5435L2.86409 17.0988L3.37086 17.8952L3.29951 17.3442L2.79269 16.5435Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4329 12.0166L14.5713 12.0564L14.6635 12.7323L13.4993 12.7372L13.4329 12.0166ZM13.4853 12.0661L13.5427 12.6894L14.6089 12.6848L14.5295 12.1026L13.4853 12.0661Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16886 3.02417L7.75949 3.08308L7.61191 3.82099L5.94531 3.79641L6.16886 3.02417ZM6.20429 3.07317L6.00845 3.74968L7.57296 3.77276L7.70178 3.12863L6.20429 3.07317Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3101 3.70825L13.2621 4.26458L13.3138 4.90539L11.2998 4.38234L11.3101 3.70825ZM11.3568 3.77112L11.348 4.34563L13.2609 4.84242L13.2173 4.30135L11.3568 3.77112Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6442 11.4941L10.6038 12.4521L9.93896 12.7768L10.0146 11.7728L10.6442 11.4941ZM10.06 11.8048L9.99273 12.6975L10.5574 12.4217L10.5933 11.5688L10.06 11.8048Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9495 13.6914L16.9033 13.7675L17.1673 14.1416L16.1854 14.0754L15.9495 13.6914ZM16.0392 13.7464L16.2131 14.0295L17.0707 14.0873L16.8772 13.8132L16.0392 13.7464Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.55268 13.1052L9.59848 13.1176L9.44546 13.9975L9.12939 14.6915L9.38532 13.5547L9.55268 13.1052ZM9.4311 13.5683L9.27718 14.252L9.39955 13.9833L9.50734 13.3636L9.4311 13.5683Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6108 12.6826L15.6934 12.7525L15.8496 13.3147L14.7406 13.2797L14.6108 12.6826ZM14.6708 12.7342L14.7793 13.2332L15.7863 13.265L15.6566 12.7978L14.6708 12.7342Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5399 10.9597L19.7725 11.4646L19.6289 12.1158L18.4121 11.6806L18.5399 10.9597ZM18.5765 11.0262L18.4661 11.6493L19.594 12.0527L19.7173 11.4935L18.5765 11.0262Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.30542 19.051L6.07023 19.2958L6.15762 20.0103L5.39281 19.7655L5.30542 19.051ZM5.36176 19.1191L5.4364 19.7294L6.10128 19.9422L6.02663 19.3319L5.36176 19.1191Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1094 8.20752L18.7011 8.86091V9.61167L17.1094 9.02214V8.20752ZM17.157 8.2786V8.98897L18.6534 9.5432V8.89287L17.157 8.2786Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2341 6.12036L17.0602 6.81554L17.132 7.54886L15.2852 6.90226L15.2341 6.12036ZM15.2865 6.1913L15.3307 6.86769L17.0773 7.47922L17.0156 6.84957L15.2865 6.1913Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3433 11.2595L13.4434 11.2695L13.4889 12.0642L12.3635 12.109L12.3433 11.2595ZM12.3921 11.3076L12.41 12.0594L13.4386 12.0185L13.3984 11.3167L12.3921 11.3076Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.22217 15.2827L2.77848 16.4331L2.88343 17.2495L2.32712 16.099L2.22217 15.2827ZM2.30679 15.5672L2.3734 16.0853L2.79881 16.965L2.7322 16.4469L2.30679 15.5672Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4782 14.2126L19.265 14.4225L18.9043 14.597L18.1392 14.4252L18.4782 14.2126ZM18.4859 14.264L18.2626 14.4041L18.8985 14.5469L19.1288 14.4355L18.4859 14.264Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.04563 18.147L8.13288 18.8552L7.43366 19.2278L7.37207 18.5042L8.04563 18.147ZM7.42223 18.5315L7.47502 19.1518L8.08158 18.8286L8.0068 18.2215L7.42223 18.5315Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.205 14.3901L19.0286 14.575L18.6007 14.6486L17.8137 14.4945L18.205 14.3901ZM18.206 14.4392L18.0254 14.4874L18.6013 14.6001L18.7834 14.5688L18.206 14.4392Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7469 13.9395L19.5322 14.1909L19.2022 14.455L18.4358 14.2506L18.7469 13.9395ZM18.7601 13.9937L18.5279 14.2258L19.1912 14.4027L19.4331 14.2092L18.7601 13.9937Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6294 14.4857L17.6356 14.4385L17.9181 14.4624L18.6008 14.5961L18.5932 14.6431L18.2972 14.6049L17.6294 14.4857Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0022 13.571L19.7947 13.8708L19.4905 14.223L18.7153 13.9748L19.0022 13.571ZM19.0197 13.6286L18.7919 13.9493L19.475 14.168L19.7141 13.8912L19.0197 13.6286Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0657 11.729L9.98894 12.7478L9.54443 13.1815L9.65715 12.1158L10.0657 11.729ZM9.70269 12.1383L9.60572 13.0551L9.94278 12.7263L10.009 11.8484L9.70269 12.1383Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.53604 11.0181L1.75029 12.3663L1.54064 13.238L1.31567 11.883L1.53604 11.0181ZM1.36433 11.8851L1.5492 12.9986L1.70174 12.3644L1.52521 11.2536L1.36433 11.8851Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7808 13.2571L16.7106 13.3582L16.9298 13.8125L15.9779 13.7366L15.7808 13.2571ZM15.8553 13.3131L16.0109 13.6914L16.8508 13.7584L16.6792 13.4027L15.8553 13.3131Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53651 2.79077L11.3721 3.22527L11.3564 3.76892L9.44287 3.35782L9.53651 2.79077ZM9.57522 2.84891L9.49729 3.32077L11.3105 3.7103L11.3234 3.2627L9.57522 2.84891Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6193 10.2476L19.8566 10.8167L19.7644 11.5033L18.5325 10.9987L18.6193 10.2476ZM18.6591 10.3183L18.584 10.9683L19.7253 11.4358L19.8047 10.8453L18.6591 10.3183Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.08931 7.87964L2.06903 9.15728L1.41968 9.9664L1.44503 8.66824L2.08931 7.87964ZM1.49236 8.68564L1.47006 9.82748L2.02164 9.14019L2.03949 8.01595L1.49236 8.68564Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02124 19.2883L6.78456 19.329L6.86634 20.0395L6.10813 19.9988L6.02124 19.2883ZM6.07544 19.3389L6.15058 19.9533L6.81254 19.9889L6.74182 19.3745L6.07544 19.3389Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.88082 2.53564L9.58343 2.80473L9.4899 3.3659L7.69971 3.12557L7.88082 2.53564ZM7.91424 2.58917L7.76176 3.08582L9.45048 3.31252L9.52852 2.8443L7.91424 2.58917Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2134 4.24829L15.1197 4.92771L15.2187 5.53793L13.2601 4.89157L13.2134 4.24829ZM13.2662 4.31771L13.3053 4.85631L15.1591 5.46807L15.0771 4.96314L13.2662 4.31771Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2058 14.2825L18.0272 14.4204L18.4631 14.6347L17.6263 14.4852L17.2058 14.2825ZM17.5279 14.3849L17.6412 14.4395L18.1388 14.5283L18.0124 14.4662L17.5279 14.3849Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3958 11.2671L14.5153 11.3616L14.5763 12.1042L13.4364 12.0644L13.3958 11.2671ZM13.4461 11.3192L13.4817 12.0183L14.5245 12.0547L14.4711 11.4057L13.4461 11.3192Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2337 13.126L20.0371 13.4746L19.7642 13.9102L18.9773 13.6126L19.2337 13.126ZM19.2552 13.1873L19.0446 13.5871L19.7446 13.8518L19.9673 13.4963L19.2552 13.1873Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.63747 3.89502L4.41009 4.8399L3.21094 5.22963L3.49383 4.2496L4.63747 3.89502ZM3.53249 4.2875L3.28164 5.15654L4.36999 4.80282L4.57152 3.96536L3.53249 4.2875Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.21913 3.0166L5.99534 3.79477L4.57349 3.95826L4.86825 3.15021L6.21913 3.0166ZM4.90275 3.19468L4.6447 3.9021L5.95832 3.75106L6.15391 3.07094L4.90275 3.19468Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5222 12.0479L15.5854 12.1627L15.7039 12.7964L14.6199 12.7264L14.5222 12.0479ZM14.5781 12.1018L14.6616 12.6814L15.6458 12.7449L15.5451 12.2062L14.5781 12.1018Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3865 10.3967L12.3965 11.3104L11.3906 11.3904L11.3956 10.4167L12.3865 10.3967ZM11.4431 10.4635L11.4385 11.3388L12.3484 11.2664L12.3394 10.4453L11.4431 10.4635Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4431 10.4126L11.4381 11.3841L10.5989 11.561L10.6189 10.534L11.4431 10.4126ZM10.6658 10.5752L10.6477 11.502L11.3907 11.3454L11.3952 10.4678L10.6658 10.5752Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0742 7.47656L18.6572 8.18448L18.7034 8.91358L17.11 8.25952L17.0742 7.47656ZM17.1254 7.55164L17.1563 8.22697L18.651 8.84057L18.6115 8.21623L17.1254 7.55164Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.18575 14.9229L9.33318 15.5563L8.914 16.468L8.82617 15.7983L9.18575 14.9229ZM8.87507 15.8047L8.93975 16.2979L9.28307 15.5512L9.17315 15.079L8.87507 15.8047Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3198 3.21191L13.2044 3.7929L13.2677 4.31566L11.3093 3.75752L11.3198 3.21191ZM11.3663 3.2761L11.3577 3.72175L13.2118 4.25017L13.1608 3.82933L11.3663 3.2761Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.80396 14.1003L2.28311 15.4194L2.39649 16.3013L1.91721 14.9875L1.80396 14.1003ZM1.90551 14.5195L1.96381 14.9762L2.29469 15.8832L2.23651 15.4307L1.90551 14.5195Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1609 5.46558L16.9593 6.20552L17.0682 6.8695L15.2383 6.17286L15.1609 5.46558ZM15.217 5.5402L15.2825 6.13869L17.0078 6.7955L16.9166 6.23947L15.217 5.5402Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.93512 16.333L9.12934 16.9734L8.58577 17.7221L8.44385 17.0546L8.93512 16.333ZM8.49469 17.0646L8.61012 17.6075L9.07686 16.9646L8.91843 16.4422L8.49469 17.0646Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.42188 13.3877L9.46065 14.0596L9.15039 15.2187L9.18418 14.4687L9.42188 13.3877ZM9.2316 14.4749L9.21773 14.7829L9.41263 14.0547L9.39421 13.7354L9.2316 14.4749Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0366 14.0669L17.7632 14.197L18.102 14.4813L17.3559 14.3561L17.0366 14.0669ZM17.1917 14.1431L17.3776 14.3114L17.9373 14.4054L17.7423 14.2417L17.1917 14.1431Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3389 10.3958L13.4299 10.4655L13.4451 11.3221L12.3439 11.3121L12.3389 10.3958ZM12.3868 10.4466L12.3913 11.2649L13.3966 11.274L13.3831 10.5103L12.3868 10.4466Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4322 12.6184L20.2434 13.0265L20.0091 13.5211L19.209 13.1739L19.4322 12.6184ZM19.4571 12.6843L19.2705 13.1486L19.9858 13.4591L20.1804 13.0482L19.4571 12.6843Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.41918 2.4519L7.92915 2.54044L7.74808 3.13022L6.15088 3.07106L6.41918 2.4519ZM6.44966 2.50143L6.22234 3.02602L7.71327 3.08124L7.86579 2.58446L6.44966 2.50143Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28424 5.1438L3.08191 6.24648L2.14868 6.85004L2.38136 5.71697L3.28424 5.1438ZM2.42398 5.74637L2.21822 6.74831L3.03875 6.21764L3.21767 5.2425L2.42398 5.74637Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6671 10.5154L10.642 11.5423L10.012 11.8212L10.0521 10.7376L10.6671 10.5154ZM10.0985 10.7715L10.0624 11.7467L10.5951 11.5109L10.6178 10.5839L10.0985 10.7715Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6423 12.7466L16.5508 12.8778L16.7293 13.4131L15.7994 13.312L15.6423 12.7466ZM15.7078 12.8042L15.8367 13.2681L16.6606 13.3577L16.5149 12.9208L15.7078 12.8042Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6606 9.53979L19.9033 10.1686L19.8519 10.8716L18.6145 10.3023L18.6606 9.53979ZM18.7038 9.61506L18.664 10.2727L19.8094 10.7995L19.8534 10.1968L18.7038 9.61506Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.72895 11.8943L9.59351 13.1191L9.34985 13.7735L9.52301 12.5268L9.72895 11.8943ZM9.56963 12.5376L9.45685 13.3496L9.54679 13.108L9.63057 12.3504L9.56963 12.5376Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.47482 19.1655L7.55656 19.8756L6.81879 20.0435L6.73706 19.3334L7.47482 19.1655ZM6.78929 19.3704L6.86006 19.9852L7.50433 19.8386L7.43356 19.2238L6.78929 19.3704Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.61447 17.6177L8.72847 18.3172L8.08908 18.8901L8.00098 18.1749L8.61447 17.6177ZM8.05129 18.1936L8.12522 18.7937L8.67724 18.2991L8.58155 17.712L8.05129 18.1936Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8357 13.7505L17.5278 13.8878L17.8148 14.2537L17.1052 14.1266L16.8357 13.7505ZM16.9446 13.8207L17.1326 14.0831L17.7002 14.1848L17.5012 13.9311L16.9446 13.8207Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5969 12.0486L20.4171 12.5165L20.2254 13.0709L19.416 12.6637L19.5969 12.0486ZM19.6256 12.1198L19.473 12.639L20.1981 13.0038L20.3591 12.5383L19.6256 12.1198Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4668 11.3555L15.5155 11.5203L15.5922 12.216L14.5281 12.1011L14.4668 11.3555ZM14.5193 11.412L14.5724 12.0579L15.5384 12.1622L15.4721 11.5617L14.5193 11.412Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3823 10.4602L14.4926 10.6145L14.5179 11.4096L13.3975 11.315L13.3823 10.4602ZM13.431 10.5151L13.4444 11.2711L14.4685 11.3576L14.4462 10.6562L13.431 10.5151Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.63402 2.42871L11.3815 2.87779L11.3707 3.27874L9.52661 2.84224L9.63402 2.42871ZM9.6682 2.4867L9.58498 2.80708L11.3246 3.21886L11.3329 2.91449L9.6682 2.4867Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.99829 18.6758L4.73097 19.3201L4.87661 19.989L4.14927 19.3552L3.99829 18.6758ZM4.0765 18.808L4.1924 19.3296L4.79943 19.8585L4.68773 19.3456L4.0765 18.808Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.01 6.79736L18.5801 7.55534L18.6682 8.23935L17.0823 7.53011L17.01 6.79736ZM17.0658 6.8772L17.127 7.4979L18.6101 8.16116L18.5361 7.58704L17.0658 6.8772Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1594 3.77979L15.009 4.48439L15.1321 4.98746L13.2178 4.30519L13.1594 3.77979ZM13.2154 3.85211L13.2619 4.2703L15.0648 4.91288L14.9687 4.52001L13.2154 3.85211Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.36768 17.9124L4.05916 18.7358L4.21502 19.4372L3.52912 18.6194L3.36768 17.9124ZM3.45806 18.0941L3.57302 18.5975L4.12619 19.2571L4.01517 18.7575L3.45806 18.0941Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.45315 9.74976L1.55461 11.1381L1.32682 12.0322L1.22534 10.6382L1.45315 9.74976ZM1.27344 10.6425L1.35306 11.7362L1.50652 11.1339L1.42688 10.0441L1.27344 10.6425Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.101 10.7012L10.0656 11.7945L9.65796 12.1804L9.71376 11.0339L10.101 10.7012ZM9.76037 11.0567L9.71132 12.0642L10.0186 11.7734L10.0499 10.8079L9.76037 11.0567Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.06966 2.1499L9.68066 2.43391L9.57342 2.84676L7.86182 2.57625L8.06966 2.1499ZM8.09676 2.20307L7.93288 2.53923L9.53816 2.79294L9.62156 2.47188L8.09676 2.20307Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5166 13.1262L1.56325 13.1169L1.87819 14.3051L1.96417 14.9786L1.91748 14.9877L1.60734 13.8044L1.5166 13.1262ZM1.61389 13.494L1.65419 13.7952L1.87031 14.6197L1.83131 14.3143L1.61389 13.494Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4003 9.44287L12.3915 9.48793L12.3865 10.4443L11.3953 10.4643L11.4003 9.44287ZM11.4477 9.49273L11.4432 10.4157L12.3391 10.3976L12.3436 9.53346L11.4477 9.49273Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.67456 19.2898L5.4359 19.7322L5.56771 20.386L4.81696 19.9438L4.67456 19.2898ZM4.74414 19.3853L4.85909 19.9133L5.49989 20.2907L5.39342 19.7626L4.74414 19.3853Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0679 4.91064L16.8296 5.68601L16.9723 6.2672L15.1676 5.52467L15.0679 4.91064ZM15.129 4.9896L15.2104 5.49074L16.9036 6.18743L16.7889 5.72018L15.129 4.9896Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4481 9.44238L11.4382 10.4559L10.6138 10.5773L10.6188 9.49801L11.4481 9.44238ZM10.6662 9.54259L10.6617 10.5221L11.3909 10.4147L11.4 9.49338L10.6662 9.54259Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5308 12.1541L16.4289 12.3306L16.5587 12.9228L15.65 12.7915L15.5308 12.1541ZM15.5905 12.2144L15.6905 12.7492L16.4974 12.8658L16.389 12.3714L15.5905 12.2144Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3344 2.86353L13.1463 3.45935L13.2178 3.85027L11.3235 3.26626L11.3344 2.86353ZM11.3803 2.92879L11.3721 3.23137L13.1568 3.78158L13.1045 3.49578L11.3803 2.92879Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6582 8.83667L19.902 9.52548V10.2216L18.6582 9.5923V8.83667ZM18.7059 8.91754V9.563L19.8544 10.1441V9.55357L18.7059 8.91754Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.21533 6.72314L2.08731 7.95732L1.43579 8.75477L1.5792 7.49485L2.21533 6.72314ZM1.62495 7.51427L1.50148 8.59904L2.04138 7.93821L2.15163 6.87534L1.62495 7.51427Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.78467 16.969L3.43045 17.9892L3.60051 18.734L2.96649 17.7315L2.78467 16.969ZM2.89736 17.2361L3.01101 17.7127L3.49215 18.4735L3.38581 18.0078L2.89736 17.2361Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6519 13.3508L17.3257 13.5075L17.5592 13.9474L16.874 13.8115L16.6519 13.3508ZM16.738 13.4198L16.9066 13.7693L17.4701 13.8812L17.2937 13.549L16.738 13.4198Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.344 9.48389L13.4402 9.6234L13.4251 10.5085L12.334 10.4387L12.344 9.48389ZM12.3911 9.53792L12.3821 10.394L13.3783 10.4577L13.3918 9.66528L12.3911 9.53792Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7238 11.4355L20.5488 11.9637L20.4039 12.5638L19.5845 12.0963L19.7238 11.4355ZM19.7562 11.5129L19.6383 12.0721L20.3724 12.491L20.4945 11.9855L19.7562 11.5129Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.4721 2.44971L6.20291 3.07092L4.84302 3.20541L5.19969 2.55408L6.4721 2.44971ZM5.22917 2.59948L4.92819 3.1491L6.17033 3.02625L6.39677 2.5037L5.22917 2.59948Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.92421 3.13599L4.62742 3.94958L3.4751 4.30685L3.84344 3.46273L4.92421 3.13599ZM3.87831 3.50197L3.56041 4.23051L4.59074 3.91106L4.84678 3.20918L3.87831 3.50197Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9061 14.5469L19.2943 14.7844L18.9058 14.8203L18.5305 14.6125L18.9061 14.5469ZM18.6717 14.6362L18.916 14.7715L19.147 14.7502L18.8966 14.5969L18.6717 14.6362Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1942 14.4014L19.5669 14.659L19.2134 14.7937L18.8469 14.5694L19.1942 14.4014ZM18.9462 14.5743L19.2183 14.7408L19.4653 14.6467L19.1899 14.4564L18.9462 14.5743Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37842 19.7073L6.15026 19.9577L6.26983 20.5972L5.50837 20.3519L5.37842 19.7073ZM5.44125 19.7778L5.54957 20.3151L6.20827 20.5273L6.10862 19.9943L5.44125 19.7778Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1682 14.5374L18.6088 14.6013L19.0525 14.8471L18.6027 14.7614L18.1682 14.5374ZM18.4429 14.6254L18.6184 14.7159L18.7691 14.7446L18.5933 14.6472L18.4429 14.6254Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6663 9.4895V10.5664L10.0515 10.7886V9.64965L10.6663 9.4895ZM10.0992 9.68648V10.7207L10.6186 10.533V9.55116L10.0992 9.68648Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4778 14.1648L19.8418 14.4592L19.5159 14.6819L19.1523 14.4306L19.4778 14.1648ZM19.478 14.2262L19.2316 14.4274L19.5161 14.6241L19.7621 14.456L19.478 14.2262Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.42963 13.9736L9.62847 14.5812L9.30496 15.6456L9.15479 15.0004L9.42963 13.9736ZM9.43421 14.1408L9.2039 15.0012L9.31107 15.4617L9.5785 14.5817L9.43421 14.1408Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.12679 18.8042L8.20888 19.5225L7.51081 19.8945L7.42871 19.1813L8.12679 18.8042ZM7.47974 19.2079L7.55015 19.8195L8.15785 19.4957L8.08745 18.8796L7.47974 19.2079Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30976 15.4929L9.55331 16.1124L9.09774 17.0287L8.90137 16.3812L9.30976 15.4929ZM8.95222 16.3846L9.10851 16.9L9.50121 16.1101L9.30633 15.6145L8.95222 16.3846Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.70392 2.05151L8.12014 2.16007L7.91196 2.58712L6.39038 2.49791L6.70392 2.05151ZM6.72732 2.1011L6.47852 2.45534L7.88304 2.53769L8.04657 2.20223L6.72732 2.1011Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4446 10.6106L15.4884 10.8353L15.519 11.5691L14.47 11.4042L14.4446 10.6106ZM14.4942 10.67L14.5164 11.3633L15.469 11.513L15.4424 10.8742L14.4942 10.67Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0088 14.4645L18.0297 14.4216L18.316 14.5553L18.6214 14.7127L18.6003 14.7554L18.309 14.617L18.0088 14.4645Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.2749 15.8875L2.86365 17.0993L3.06249 17.9331L2.48017 16.7342L2.2749 15.8875ZM2.42554 16.3066L2.52528 16.718L2.91515 17.5206L2.81851 17.1154L2.42554 16.3066Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.54817 12.3523L9.62768 12.995L9.4158 14.272L9.37476 13.5606L9.54817 12.3523ZM9.42261 13.5626L9.43876 13.8425L9.57954 12.994L9.54467 12.7121L9.42261 13.5626Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9019 6.18433L18.4504 6.98817L18.5871 7.61403L17.0116 6.85345L16.9019 6.18433ZM16.9643 6.27042L17.0547 6.8213L18.5197 7.52854L18.4086 7.02018L16.9643 6.27042Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3927 9.61523L14.5079 9.83927L14.4927 10.6628L13.3826 10.5085L13.3927 9.61523ZM13.4397 9.67329L13.4307 10.4671L14.446 10.6082L14.4595 9.87816L13.4397 9.67329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7523 13.8467L20.1176 14.1802L19.8135 14.4897L19.4536 14.1987L19.7523 13.8467ZM19.7566 13.9152L19.5215 14.1923L19.8098 14.4254L20.0489 14.1821L19.7566 13.9152Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4956 12.8635L17.156 13.0559L17.3478 13.5568L16.6764 13.4007L16.4956 12.8635ZM16.5699 12.9348L16.7131 13.3603L17.2712 13.4901L17.12 13.0951L16.5699 12.9348Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0942 3.43921L14.8835 4.1598L15.0301 4.54331L13.1664 3.83333L13.0942 3.43921ZM13.1567 3.51574L13.2084 3.79835L14.9476 4.46087L14.8464 4.19624L13.1567 3.51574Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5582 4.2146L3.27133 5.20838L2.35913 5.78747L2.69722 4.76296L3.5582 4.2146ZM2.73715 4.79403L2.44618 5.67576L3.23055 5.17782L3.47744 4.32254L2.73715 4.79403Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8172 10.7937L20.6475 11.3825L20.544 12.0143L19.719 11.4862L19.8172 10.7937ZM19.8534 10.8778L19.7705 11.4625L20.5086 11.9351L20.5957 11.4042L19.8534 10.8778Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4663 11.5093L16.354 11.7362L16.4417 12.3809L15.5435 12.2043L15.4663 11.5093ZM15.5213 11.5725L15.587 12.1643L16.3855 12.3212L16.3111 11.7745L15.5213 11.5725Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.74392 2.22949L11.3916 2.68286L11.3798 2.92651L9.61621 2.47331L9.74392 2.22949ZM9.76831 2.28563L9.68634 2.44213L11.335 2.8658L11.3422 2.71867L9.76831 2.28563Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6077 8.15625L19.8437 8.90581L19.9009 9.5819L18.6543 8.89154L18.6077 8.15625ZM18.661 8.24433L18.7002 8.86248L19.8459 9.49696L19.7983 8.93399L18.661 8.24433Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.76994 10.8599L9.70827 12.1268L9.51538 12.7193L9.59908 11.408L9.76994 10.8599ZM9.64627 11.4168L9.587 12.3454L9.66098 12.1181L9.70416 11.2311L9.64627 11.4168Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5784 14.1086L18.0664 14.3565L18.4946 14.7159L18.0066 14.468L17.5784 14.1086ZM17.9275 14.3394L18.033 14.428L18.1455 14.4851L18.0399 14.3965L17.9275 14.3394Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9539 4.45947L16.6709 5.26143L16.8514 5.7427L15.0781 4.96718L14.9539 4.45947ZM15.0238 4.54473L15.1188 4.93296L16.7672 5.65385L16.6331 5.29637L15.0238 4.54473Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.1161 16.9058L9.25257 17.6039L8.68853 18.3679L8.573 17.6589L9.1161 16.9058ZM8.62321 17.6708L8.71737 18.2486L9.20177 17.5924L9.09043 17.0228L8.62321 17.6708Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4052 8.44995L12.4159 8.56504L12.3909 9.53568L11.4001 9.49065L11.4052 8.44995ZM11.4526 8.50331L11.448 9.44512L12.3445 9.48587L12.3671 8.60745L11.4526 8.50331Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9933 13.4514L20.3619 13.8358L20.0868 14.2167L19.7236 13.8851L19.9933 13.4514ZM20.0014 13.5287L19.785 13.8766L20.0795 14.1455L20.3 13.8401L20.0014 13.5287Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.10034 19.9507L6.85955 19.9914L6.97301 20.6258L6.21896 20.5851L6.10034 19.9507ZM6.15833 20.0015L6.25893 20.5395L6.91551 20.575L6.81929 20.037L6.15833 20.0015Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3445 2.66772L13.0671 3.26796L13.1664 3.51611L11.3325 2.91307L11.3445 2.66772ZM11.389 2.7337L11.3819 2.87914L13.0842 3.43891L13.0309 3.30581L11.389 2.7337Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.32104 11.8873L1.36819 11.8804L1.56845 13.1153L1.65899 13.792L1.61185 13.799L1.41164 12.5692L1.32104 11.8873Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3633 8.55762L13.4739 8.77177L13.4388 9.67126L12.3433 9.53183L12.3633 8.55762ZM12.4098 8.61511L12.3918 9.48997L13.3932 9.61742L13.4247 8.81081L12.4098 8.61511Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.604 8.43799L11.4527 8.45314L11.4477 9.49009L10.619 9.54568L10.604 8.43799ZM10.6523 8.48652L10.6659 9.49477L11.4003 9.44551L11.4048 8.49995L10.6523 8.48652Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.28012 1.9458L9.79194 2.24031L9.66418 2.48423L8.03467 2.19696L8.28012 1.9458ZM8.29624 1.99749L8.13185 2.16571L9.63813 2.43125L9.72005 2.27486L8.29624 1.99749Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0992 9.62158V10.7703L9.71313 11.102V9.89886L10.0992 9.62158ZM9.76079 9.92331V10.9982L10.0515 10.7484V9.71449L9.76079 9.92331Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.49635 8.46899L1.46851 9.90001L1.22632 10.8445L1.25417 9.40743L1.49635 8.46899ZM1.30171 9.41394L1.2819 10.4359L1.42097 9.89355L1.44078 8.87503L1.30171 9.41394Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4241 13.8311L17.8255 14.0576L18.1437 14.4445L17.7371 14.238L17.4241 13.8311ZM17.6048 13.9878L17.7683 14.2004L17.9622 14.2989L17.7943 14.0948L17.6048 13.9878Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.92212 14.9825L1.96766 14.9688L2.37319 16.0851L2.526 16.7155L2.48045 16.7293L2.07969 15.6272L1.92212 14.9825ZM2.07243 15.3967L2.12537 15.6133L2.37858 16.3097L2.3275 16.0989L2.07243 15.3967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3765 12.3142L17.0295 12.5474L17.1661 13.1099L16.5077 12.918L16.3765 12.3142ZM16.4413 12.388L16.5483 12.8802L17.1004 13.0411L16.9892 12.5837L16.4413 12.388Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2061 12.989L20.579 13.425L20.3366 13.8782L19.969 13.4948L20.2061 12.989ZM20.2179 13.0761L20.026 13.4854L20.3256 13.7978L20.5216 13.4312L20.2179 13.0761Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4603 9.82739L15.5038 10.122L15.4885 10.8793L14.4451 10.6547L14.4603 9.82739ZM14.5068 9.89004L14.4934 10.6163L15.442 10.8206L15.4554 10.1578L14.5068 9.89004Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8527 10.1411L20.6845 10.7914L20.6376 11.4372L19.8059 10.8475L19.8527 10.1411ZM19.8943 10.2341L19.8552 10.824L20.5962 11.3494L20.6351 10.8133L19.8943 10.2341Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7732 5.65112L18.2969 6.49214L18.4747 7.04707L16.9174 6.23866L16.7732 5.65112ZM16.8454 5.74542L16.9585 6.20632L18.3941 6.95151L18.2573 6.52471L16.8454 5.74542Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4315 8.76074L14.5561 9.05432L14.5106 9.89234L13.3962 9.66846L13.4315 8.76074ZM13.4769 8.82183L13.4454 9.62974L14.466 9.83477L14.5064 9.0906L13.4769 8.82183Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6517 8.43457L10.6616 9.53863L10.0476 9.69859L10.0127 8.53248L10.6517 8.43457ZM10.0616 8.5732L10.0934 9.6374L10.6136 9.50189L10.6045 8.49001L10.0616 8.5732Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4399 10.8167L16.3221 11.104L16.358 11.7818L15.4707 11.5549L15.4399 10.8167ZM15.4904 10.8832L15.5168 11.5175L16.307 11.7195L16.2762 11.1392L15.4904 10.8832Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0081 3.23901L14.7325 3.96328L14.9287 4.23136L13.102 3.49568L13.0081 3.23901ZM13.0904 3.32527L13.1394 3.45939L14.7916 4.1248L14.7019 4.0021L13.0904 3.32527Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.44543 5.6416L2.20557 6.80963L1.55713 7.59626L1.82303 6.40231L2.44543 5.6416ZM1.86708 6.42373L1.64689 7.41245L2.16127 6.78845L2.35984 5.82147L1.86708 6.42373Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5281 7.52319L19.7573 8.31946L19.8573 8.9667L18.6171 8.21464L18.5281 7.52319ZM18.5885 7.6191L18.6615 8.18579L19.7946 8.87294L19.7135 8.34784L18.5885 7.6191Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.76384 2.04932L6.44756 2.49961L5.15723 2.60546L5.57962 2.1296L6.76384 2.04932ZM5.60231 2.17583L5.27173 2.54825L6.4214 2.45394L6.66747 2.10362L5.60231 2.17583Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2461 13.4546L17.6181 13.6925L17.8736 14.1396L17.4968 13.9269L17.2461 13.4546ZM17.3732 13.5924L17.5324 13.8922L17.7465 14.0131L17.5826 13.7263L17.3732 13.5924Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72125 18.2593L8.80411 18.9896L8.16491 19.5623L8.08203 18.8371L8.72125 18.2593ZM8.13216 18.8561L8.20178 19.4653L8.75399 18.9705L8.68436 18.3569L8.13216 18.8561Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8152 4.125L16.4927 4.94149L16.7089 5.32709L14.97 4.51497L14.8152 4.125ZM14.9048 4.22163L15.0073 4.47979L16.5949 5.22127L16.4584 4.97781L14.9048 4.22163Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.01881 1.82788L8.34039 1.94757L8.09264 2.20108L6.64795 2.09033L7.01881 1.82788ZM7.032 1.87693L6.78332 2.05291L8.07411 2.15186L8.23621 1.98599L7.032 1.87693Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.405 7.45679L12.4494 7.64668L12.4144 8.60802L11.405 8.49309V7.45679ZM11.4527 7.51389V8.45056L12.3686 8.55485L12.4003 7.68618L11.4527 7.51389Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.86605 2.2041L11.4137 2.65186L11.3806 2.7291L9.66406 2.25679L9.86605 2.2041ZM9.84816 2.25802L10.5178 2.44227L9.8654 2.25352L9.84816 2.25802Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26853 2.54199L4.90159 3.19723L3.80493 3.52878L4.25559 2.84231L5.26853 2.54199ZM4.28586 2.88304L3.91683 3.44517L4.8694 3.15718L5.16965 2.62101L4.28586 2.88304Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3756 12.4775L20.7488 12.9717L20.5534 13.4789L20.1802 13.0426L20.3756 12.4775ZM20.3914 12.5776L20.2341 13.0324L20.5377 13.3872L20.6948 12.9793L20.3914 12.5776Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.54988 19.8281L7.65795 20.4662L6.93012 20.6291L6.81689 19.9961L7.54988 19.8281ZM6.8718 20.0324L6.96827 20.5717L7.60341 20.4296L7.51132 19.8859L6.8718 20.0324Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3653 2.63623L12.9876 3.22221L13.0656 3.26607L13.0461 3.30934L11.3425 2.71576L11.3653 2.63623Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4021 7.63623L13.5365 7.92481L13.4762 8.81979L12.3669 8.6059L12.4021 7.63623ZM12.4476 7.69698L12.416 8.56684L13.4323 8.76279L13.4863 7.96121L12.4476 7.69698Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5701 7.3877L11.4526 7.46838V8.50564L10.605 8.4905L10.5701 7.3877ZM10.6194 7.44006L10.6512 8.44366L11.4049 8.45712V7.51188L10.6194 7.44006Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59867 12.9062L9.83312 13.4924L9.60976 14.6819L9.40747 14.0638L9.59867 12.9062ZM9.61785 13.0825L9.45639 14.06L9.59723 14.4904L9.78372 13.4972L9.61785 13.0825Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3037 11.717L16.95 11.9962L17.0387 12.6014L16.3871 12.3687L16.3037 11.717ZM16.3616 11.794L16.4307 12.3337L16.9801 12.5299L16.9067 12.0295L16.3616 11.794Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59878 14.5154L9.88183 15.1135L9.52934 16.1812L9.28296 15.5545L9.59878 14.5154ZM9.60849 14.6473L9.33338 15.5525L9.52543 16.041L9.8306 15.1167L9.60849 14.6473Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.60669 11.2134L9.71811 11.8401L9.60887 13.2315L9.52246 12.533L9.60669 11.2134ZM9.62904 11.6114L9.5703 12.5316L9.59827 12.7576L9.67012 11.8425L9.62904 11.6114Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50833 1.91406L9.9695 2.22168L9.75676 2.27717L8.13965 1.96215L8.50833 1.91406ZM8.4367 1.97147L9.75522 2.22832L9.7617 2.22663L8.50645 1.96237L8.4367 1.97147Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.12695 19.2722L4.85914 19.9155L5.00332 20.4922L4.28773 19.8654L4.12695 19.2722ZM4.21433 19.4124L4.32996 19.8391L4.92015 20.356L4.81652 19.9415L4.21433 19.4124Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8545 9.49243L20.6885 10.2051V10.8511L19.8545 10.1991V9.49243ZM19.9021 9.59584V10.1759L20.6408 10.7534V10.2271L19.9021 9.59584Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.49829 18.5081L4.19247 19.3357L4.36024 19.9547L3.67751 19.144L3.49829 18.5081ZM3.60434 18.7087L3.72059 19.1212L4.25786 19.7592L4.14922 19.3583L3.60434 18.7087Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5095 9.03955L15.5621 9.40374L15.5012 10.1707L14.4587 9.87638L14.5095 9.03955ZM14.5532 9.10512L14.5086 9.84095L15.4583 10.109L15.5117 9.43672L14.5532 9.10512Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.76091 9.77197V11.0537L9.59888 11.5735V10.2311L9.76091 9.77197ZM9.64653 10.2392V11.2605L9.71325 11.0464V10.0502L9.64653 10.2392Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0867 13.0024L17.4435 13.27L17.6515 13.7703L17.2901 13.5393L17.0867 13.0024ZM17.1894 13.1391L17.3291 13.5077L17.5491 13.6484L17.4045 13.3004L17.1894 13.1391Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2527 14.74L19.1416 15.0147L18.7888 15.0259L18.8944 14.7736L19.2527 14.74ZM18.9273 14.8184L18.8614 14.9759L19.1091 14.968L19.1791 14.7947L18.9273 14.8184Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6067 5.22046L18.1049 6.08652L18.33 6.56489L16.79 5.71492L16.6067 5.22046ZM16.6974 5.32792L16.8286 5.68175L18.2242 6.45209L18.0681 6.12031L16.6974 5.32792Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.60742 13.8057L1.65361 13.7939L1.96347 14.9762L2.12074 15.6196L2.07454 15.6312L1.76945 14.4633L1.60742 13.8057Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4899 7.91089L14.6382 8.28369L14.5472 9.10241L13.4243 8.80927L13.4899 7.91089ZM13.533 7.97499L13.4747 8.77318L14.5059 9.04238L14.5865 8.31703L13.533 7.97499Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5961 14.7117L18.9441 14.7779L18.8391 15.0287L18.4966 14.9458L18.5961 14.7117ZM18.6249 14.7657L18.5624 14.9127L18.8108 14.9728L18.8774 14.8138L18.6249 14.7657Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.91453 3.41919L3.53684 4.28474L2.65845 4.84419L3.09911 3.94712L3.91453 3.41919ZM3.13607 3.97996L2.77611 4.71274L3.49897 4.25235L3.80753 3.54524L3.13607 3.97996Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.23047 10.6467L1.27786 10.6418L1.36849 11.3288L1.45909 12.5685L1.41171 12.5734L1.32107 11.8912L1.23047 10.6467Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.80103 19.8843L5.55461 20.323L5.67843 20.8775L4.94105 20.4444L4.80103 19.8843ZM4.87465 19.9823L4.98243 20.4134L5.60803 20.7809L5.51263 20.3537L4.87465 19.9823Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9661 3.26479L12.9872 3.22217L13.756 3.55589L14.7312 3.96549L14.7142 4.01L14.5608 3.95727L13.7372 3.59972L13.0435 3.30835L12.9661 3.26479Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5575 14.6116L19.4389 14.92L19.0845 15.0286L19.1977 14.7487L19.5575 14.6116ZM19.2341 14.7858L19.1661 14.9538L19.4027 14.8813L19.4747 14.6941L19.2341 14.7858Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0647 8.51392L10.0996 9.68013L9.71523 9.95624L9.66016 8.72969L10.0647 8.51392ZM9.70911 8.75759L9.75889 9.8662L10.0512 9.65621L10.0194 8.59211L9.70911 8.75759Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53547 16.041L9.69534 16.7338L9.21681 17.6703L9.07812 16.9608L9.53547 16.041ZM9.128 16.9676L9.23712 17.5258L9.645 16.7276L9.51894 16.1813L9.128 16.9676Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4565 10.105L16.3378 10.4575L16.3224 11.1542L15.4412 10.8672L15.4565 10.105ZM15.5028 10.1748L15.4895 10.8328L16.2762 11.089L16.2894 10.4895L15.5028 10.1748Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5162 11.917L20.8906 12.4707L20.7367 13.0283L20.3623 12.5327L20.5162 11.917ZM20.5366 12.0321L20.4141 12.5221L20.7164 12.9224L20.8389 12.4792L20.5366 12.0321Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4036 6.95068L19.6229 7.7902L19.7733 8.38668L18.537 7.58582L18.4036 6.95068ZM18.4747 7.05753L18.5796 7.55661L19.6975 8.28075L19.581 7.81927L18.4747 7.05753Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3129 14.5681L18.6462 14.732L18.5464 14.9669L18.2129 14.7974L18.3129 14.5681ZM18.3366 14.6329L18.2745 14.7753L18.5225 14.9013L18.5847 14.7549L18.3366 14.6329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.91895 17.5725L3.5684 18.5939L3.76115 19.2778L3.12418 18.2753L2.91895 17.5725ZM3.05873 17.8812L3.16803 18.2554L3.62716 18.9781L3.52443 18.6135L3.05873 17.8812Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.637 3.92041L16.2893 4.75417L16.6032 5.05308L14.8445 4.19706L14.637 3.92041ZM14.7972 4.05465L14.8757 4.15925L16.348 4.87589L16.2616 4.79355L14.7972 4.05465Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8567 14.3984L19.7358 14.755L19.3796 14.9536L19.5013 14.6373L19.8567 14.3984ZM19.5403 14.6685L19.4713 14.8479L19.6966 14.7223L19.7663 14.5166L19.5403 14.6685Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.405 6.5022L12.4925 6.7616L12.4474 7.69967L11.405 7.51016V6.5022ZM11.4527 6.56256V7.47038L12.4024 7.64306L12.443 6.79878L11.4527 6.56256Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6176 7.38428L10.6574 8.48273L10.0198 8.58043L9.94507 7.41508L10.6176 7.38428ZM9.99573 7.46047L10.064 8.52544L10.6082 8.44205L10.5718 7.43409L9.99573 7.46047Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.80005 2.18042L11.4036 2.64434L11.4169 2.82409L9.96598 2.38783L9.80005 2.18042ZM9.9311 2.26794L9.99392 2.34647L11.3643 2.7585L11.3585 2.68091L9.9311 2.26794Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3547 2.63257L13.0159 3.23256L12.9052 3.38228L11.3681 2.81317L11.3547 2.63257ZM11.4077 2.70237L11.4134 2.77911L12.8882 3.32516L12.9395 3.25566L11.4077 2.70237Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4455 6.74829L13.6181 7.11102L13.5324 7.97287L12.4001 7.68484L12.4455 6.74829ZM12.4901 6.81198L12.4496 7.64825L13.4905 7.91303L13.5668 7.14504L12.4901 6.81198Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0431 14.3374L18.3538 14.5936L18.253 14.8248L17.9482 14.5745L18.0431 14.3374ZM18.0631 14.4157L18.0054 14.5598L18.2345 14.748L18.2957 14.6075L18.0631 14.4157Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.44449 8.23795L1.34494 9.06087L1.4452 8.67238L1.54108 7.859L1.44449 8.23795ZM1.58379 7.49854L1.63054 7.50721L1.49215 8.68116L1.30112 9.4214L1.25439 9.41258L1.39755 8.22917L1.58379 7.49854Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.49658 20.2981L6.25967 20.5439L6.36546 21.0835L5.61824 20.8429L5.49658 20.2981ZM5.56124 20.369L5.65881 20.8059L6.30315 21.0133L6.21831 20.5807L5.56124 20.369Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5217 6.36108L11.4524 6.51201L11.4574 7.51154L10.5767 7.43102L10.5217 6.36108ZM10.5724 6.41757L10.6222 7.38733L11.4095 7.45931L11.405 6.55259L10.5724 6.41757Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1309 14.0999L19.9991 14.5139L19.6599 14.8168L19.7861 14.4447L20.1309 14.0999ZM19.8276 14.4705L19.7631 14.6607L19.9577 14.4871L20.0261 14.2721L19.8276 14.4705Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2732 11.0859L16.913 11.4213L16.9546 12.055L16.3095 11.7763L16.2732 11.0859ZM16.3252 11.167L16.3556 11.7443L16.9019 11.9803L16.8672 11.4511L16.3252 11.167Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9678 12.498L17.3165 12.8002L17.4651 13.3506L17.117 13.0896L16.9678 12.498ZM17.051 12.6333L17.1591 13.0616L17.383 13.2295L17.2742 12.8267L17.051 12.6333Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7976 8.85669L20.6304 9.6235L20.689 10.2676L19.8506 9.55118L19.7976 8.85669ZM19.8543 8.9737L19.8966 9.52781L20.6309 10.1553L20.5846 9.64611L19.8543 8.9737Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35432 1.7998L8.7164 1.93489L8.28871 1.99067L7.02319 1.87606L7.02328 1.82859L7.35432 1.7998ZM7.29485 1.85281L8.28776 1.94274L8.29851 1.94133L7.35403 1.84767L7.29485 1.85281Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.2019 19.4722L8.30004 20.1178L7.61262 20.4844L7.50415 19.844L8.2019 19.4722ZM7.55687 19.8699L7.64856 20.4113L8.24786 20.0916L8.1649 19.5459L7.55687 19.8699Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8024 14.0295L18.0805 14.3676L17.9843 14.608L17.7065 14.2812L17.8024 14.0295ZM17.8177 14.1232L17.7611 14.2718L17.9694 14.5169L18.0258 14.3761L17.8177 14.1232Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.10743 1.82837L6.72394 2.09976L5.48755 2.18359L5.99865 1.89572L7.10743 1.82837ZM6.01248 1.94263L5.69436 2.1218L6.70735 2.05312L6.94339 1.88608L6.01248 1.94263Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5919 8.26147L15.6582 8.69996L15.5565 9.45235L14.5056 9.08876L14.5919 8.26147ZM14.6327 8.32978L14.5569 9.05609L15.5171 9.38828L15.6061 8.73004L14.6327 8.32978Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9696 3.20972L14.6235 3.9277L14.4131 4.04245L12.8596 3.35853L12.9696 3.20972ZM12.9854 3.26853L12.9334 3.33893L14.4108 3.98939L14.515 3.93257L12.9854 3.26853Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5718 7.09473L14.7483 7.54148L14.6318 8.33177L13.4858 7.95971L13.5718 7.09473ZM13.6131 7.16137L13.5371 7.92624L14.5929 8.26903L14.6956 7.57243L13.6131 7.16137Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6034 11.3254L20.9804 11.9412L20.8783 12.5375L20.5015 11.9802L20.6034 11.3254ZM20.6306 11.461L20.5514 11.9691L20.8513 12.4126L20.9304 11.9509L20.6306 11.461Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.47998 16.7232L2.52428 16.7061L3.01123 17.7134L3.16906 18.2538L3.12475 18.2709L2.64733 17.2874L2.47998 16.7232ZM2.60917 16.9912L2.69191 17.2701L3.04388 17.9952L2.9666 17.7306L2.60917 16.9912Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.39868 1.89087L9.87891 2.2025L10.0406 2.40458L8.72166 2.11764L8.39868 1.89087ZM8.61619 1.98536L8.74118 2.07312L9.91945 2.32946L9.85238 2.24561L8.61619 1.98536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4106 4.89722L17.8884 5.78993L18.1697 6.18373L16.6343 5.29615L16.4106 4.89722ZM16.5405 5.03131L16.6695 5.26144L18.003 6.0323L17.8554 5.82563L16.5405 5.03131Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.24406 17.5352L9.32815 18.2815L8.76233 19.0428L8.67822 18.3016L9.24406 17.5352ZM8.72769 18.3148L8.79608 18.9175L9.27869 18.2682L9.21029 17.6611L8.72769 18.3148Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.405 5.59985L12.55 5.9334L12.4896 6.80984L11.405 6.55113V5.59985ZM11.4527 5.66337V6.51351L12.4459 6.75043L12.4999 5.96842L11.4527 5.66337Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.393 13.7163L20.2477 14.2114L19.9221 14.6137L20.0617 14.175L20.393 13.7163ZM20.1048 14.1967L20.0442 14.3871L20.2047 14.1889L20.2691 13.9692L20.1048 14.1967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5157 9.38867L16.4007 9.80606L16.3342 10.5121L15.4543 10.1601L15.5157 9.38867ZM15.5577 9.46119L15.5046 10.1289L16.2927 10.4442L16.3501 9.8349L15.5577 9.46119Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5583 3.95629L14.577 3.9126L14.7311 3.96555L16.2909 4.75261L16.2734 4.79673L16.0615 4.73414L14.5583 3.95629Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2319 6.45093L19.4404 7.33208L19.6469 7.86802L18.414 7.01909L18.2319 6.45093ZM18.3219 6.5755L18.4544 6.98906L19.5471 7.74139L19.401 7.36232L18.3219 6.5755Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3657 2.75659L12.9261 3.33431L12.8309 3.6421L11.3769 3.09868L11.3657 2.75659ZM11.4157 2.82591L11.4235 3.06522L12.8003 3.57978L12.8672 3.36334L11.4157 2.82591Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.77627 4.65942L2.42398 5.74339L1.77661 6.53463L2.17189 5.42461L2.77627 4.65942ZM2.21415 5.44799L1.91364 6.29189L2.38143 5.72015L2.64867 4.89786L2.21415 5.44799Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5033 5.91797L13.7235 6.34951L13.6122 7.15906L12.4426 6.7973L12.5033 5.91797ZM12.5465 5.98381L12.4928 6.76292L13.5726 7.09694L13.671 6.38149L12.5465 5.98381Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.94141 2.33545L11.4136 2.77811L11.4304 3.11461L10.0761 2.70584L9.94141 2.33545ZM10.0187 2.40844L10.1127 2.66712L11.3795 3.04944L11.3677 2.81407L10.0187 2.40844Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.66906 2.10596L5.23252 2.59776L4.18457 2.90846L4.72073 2.38889L5.66906 2.10596ZM4.74552 2.43122L4.35714 2.80759L5.20592 2.55594L5.52234 2.19946L4.74552 2.43122Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5898 13.656L17.8315 14.079L17.7335 14.3364L17.498 13.9312L17.5898 13.656ZM17.6013 13.7722L17.5501 13.9259L17.7245 14.2261L17.779 14.0831L17.6013 13.7722Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.83447 6.33569L10.5651 6.36635L10.6149 7.43657L9.94414 7.46729L9.83447 6.33569ZM9.88719 6.3856L9.98721 7.41761L10.5651 7.39114L10.5195 6.41214L9.88719 6.3856Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4543 5.39673L11.4525 5.61241V6.56035L10.5246 6.40987L10.4543 5.39673ZM10.5063 5.45671L10.5695 6.36888L11.4048 6.50434V5.65087L10.5063 5.45671Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.2146 20.5369L6.9646 20.5777L7.05889 21.112L6.31937 21.0712L6.2146 20.5369ZM6.27315 20.5878L6.359 21.0256L7.00152 21.0611L6.92424 20.6232L6.27315 20.5878Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99235 7.40967L10.0668 8.5667L9.66428 8.7814L9.54956 7.56438L9.99235 7.40967ZM9.60051 7.59706L9.70501 8.70567L10.0173 8.53911L9.94882 7.47536L9.60051 7.59706Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8943 11.9487L17.2319 12.292L17.3298 12.8794L16.9872 12.5825L16.8943 11.9487ZM16.9626 12.0862L17.0318 12.5581L17.261 12.7567L17.1873 12.3147L16.9626 12.0862Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.41162 12.5741L1.4583 12.5647L1.62054 13.2375L1.82083 14.4488L1.77419 14.4584L1.61193 13.7998L1.41162 12.5741ZM1.5616 13.1964L1.65866 13.7903L1.6738 13.8517L1.5738 13.247L1.5616 13.1964Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8818 3.32104L14.4482 4.01066L14.263 4.27443L12.7864 3.62412L12.8818 3.32104ZM12.9113 3.3861L12.8447 3.59771L14.2465 4.21506L14.3758 4.03086L12.9113 3.3861Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.69931 8.64673L9.75623 9.91456L9.60102 10.3543L9.52222 9.0199L9.69931 8.64673ZM9.57051 9.02929L9.63467 10.1158L9.70821 9.90744L9.66029 8.84011L9.57051 9.02929Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2907 10.4312L16.9287 10.8273L16.9131 11.475L16.2751 11.1406L16.2907 10.4312ZM16.3365 10.5157L16.3234 11.1121L16.8673 11.3972L16.8804 10.8534L16.3365 10.5157Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3999 4.78369L12.612 5.18111L12.5461 5.9819L11.405 5.64947L11.3999 4.78369ZM11.4479 4.8496L11.4524 5.61366L12.5034 5.91983L12.5615 5.21468L11.4479 4.8496Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6205 13.24L20.4627 13.8424L20.1497 14.3711L20.3087 13.8289L20.6205 13.24ZM20.3531 13.847L20.3025 14.0196L20.4183 13.8239L20.4702 13.6257L20.3531 13.847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59399 10.2352L9.64122 10.2307L9.7179 10.6285V11.846L9.67061 11.8502L9.59399 11.4192V10.2352ZM9.64165 10.4847V11.415L9.67024 11.5758V10.633L9.64165 10.4847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6778 6.3313L14.892 6.84672L14.7395 7.58903L13.5662 7.14345L13.6778 6.3313ZM13.7166 6.39951L13.6185 7.11236L14.7041 7.5246L14.8375 6.87534L13.7166 6.39951Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3757 3.04736L12.8431 3.59577L12.7525 4.04322L11.3864 3.54196L11.3757 3.04736ZM11.4249 3.11661L11.4333 3.50842L12.7168 3.97936L12.7883 3.62618L11.4249 3.11661Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67724 11.7483L9.9377 12.3089L9.82345 13.5916L9.58423 12.9936L9.67724 11.7483ZM9.71113 11.9343L9.63257 12.9861L9.79367 13.3888L9.8891 12.3174L9.71113 11.9343Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5658 5.16406L13.8433 5.66415L13.716 6.39737L12.4998 5.96723L12.5658 5.16406ZM12.6081 5.23178L12.5503 5.93455L13.6787 6.33363L13.7897 5.69434L12.6081 5.23178Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6953 8.26074L20.5246 9.07978L20.6446 9.70139L19.8037 8.92717L19.6953 8.26074ZM19.7658 8.39735L19.8481 8.90327L20.5703 9.56827L20.4806 9.10331L19.7658 8.39735Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6466 10.7212L21.0273 11.4022L20.978 12.0209L20.5974 11.3991L20.6466 10.7212ZM20.6826 10.8833L20.6461 11.3873L20.9422 11.871L20.9787 11.4128L20.6826 10.8833Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7031 7.51709L15.7926 8.0295L15.6448 8.74801L14.5811 8.31057L14.7031 7.51709ZM14.7405 7.58735L14.6339 8.28075L15.6098 8.68206L15.7384 8.05666L14.7405 7.58735Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.79742 13.4241L10.1099 14.0059L9.86499 15.1946L9.57861 14.5894L9.79742 13.4241ZM9.81934 13.5656L9.62831 14.583L9.84708 15.0453L10.0597 14.0132L9.81934 13.5656Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1072 4.63818L17.6523 5.59776L17.887 5.78936L17.8596 5.82822L16.4609 4.98328L16.1072 4.63818ZM16.4532 4.90917L16.4902 4.94533L17.2652 5.41345L16.4532 4.90917Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5691 3.90332L16.1351 4.71364L15.8487 4.79456L14.3616 4.01652L14.5691 3.90332ZM14.5697 3.95728L14.4627 4.01564L15.854 4.74355L16.0061 4.70056L14.5697 3.95728Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0571 2.65186L11.4237 3.06432L11.429 3.55743L10.179 3.18193L10.0571 2.65186ZM10.122 2.72121L10.2193 3.14426L11.3807 3.49314L11.3765 3.09983L10.122 2.72121Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.6792 2.06445L9.99802 2.35137L10.1314 2.72375L8.94087 2.45974L8.6792 2.06445ZM8.78368 2.13596L8.96998 2.41738L10.0574 2.65853L9.96206 2.39232L8.78368 2.13596Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3904 4.07275L12.6841 4.52879L12.6023 5.22956L11.3955 4.83388L11.3904 4.07275ZM11.4385 4.14024L11.4429 4.79928L12.5617 5.1661L12.6324 4.56108L11.4385 4.14024Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.24945 9.41035L1.2969 9.40771L1.38768 10.1005L1.36382 11.3271L1.31637 11.3297L1.22559 10.6417L1.24945 9.41035ZM1.29094 9.72903L1.2733 10.639L1.32231 11.0104L1.33996 10.1032L1.29094 9.72903Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3853 3.49072L12.7606 3.99535L12.6828 4.5819L11.3956 4.12819L11.3853 3.49072ZM11.434 3.55938L11.4427 4.09426L12.6432 4.51744L12.7084 4.02694L11.434 3.55938Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.07495 15.632L2.12014 15.6169L2.5207 16.7137L2.68771 17.2768L2.64246 17.2917L2.25146 16.2141L2.07495 15.632Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4206 13.2134L17.6209 13.7085L17.5268 13.9908L17.3271 13.5249L17.4206 13.2134ZM17.4273 13.357L17.3778 13.522L17.5212 13.8568L17.5701 13.7101L17.4273 13.357Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.798 3.57715L14.2859 4.2324L14.1147 4.63351L12.7073 4.02541L12.798 3.57715ZM12.833 3.64462L12.7616 3.99699L14.0897 4.57077L14.2235 4.25703L12.833 3.64462Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3726 4.52319L11.4477 4.79824L11.4528 5.66126L10.4583 5.44637L10.3726 4.52319ZM10.4263 4.58612L10.5025 5.40717L11.4048 5.60213L11.4003 4.8353L10.4263 4.58612Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6382 4.51074L13.9825 5.07414L13.8334 5.71149L12.5613 5.21348L12.6382 4.51074ZM12.6787 4.57936L12.6126 5.1824L13.7995 5.64705L13.9269 5.10252L12.6787 4.57936Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.613 8.68213L16.5065 9.16905L16.3938 9.85549L15.5107 9.43903L15.613 8.68213ZM15.651 8.75709L15.5626 9.41082L16.357 9.78545L16.454 9.19471L15.651 8.75709Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.35511 2.77295L3.87804 3.49965L3.02222 4.05374L3.57724 3.29335L4.35511 2.77295ZM3.61076 3.32827L3.21266 3.87367L3.84371 3.4651L4.18633 2.9432L3.61076 3.32827Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.22144 1.7915L8.51594 1.91988L8.8203 2.13359L7.68585 2.0139L7.22144 1.7915ZM7.48672 1.8657L7.69902 1.96737L8.64239 2.0669L8.49879 1.96607L7.48672 1.8657Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0227 6.02954L19.2248 6.94516L19.4954 7.42618L18.2582 6.5241L18.0227 6.02954ZM18.1503 6.18663L18.296 6.49271L19.3464 7.2586L19.1881 6.97711L18.1503 6.18663Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7153 3.98169L14.1312 4.59344L13.9697 5.12514L12.6321 4.56454L12.7153 3.98169ZM12.7537 4.05018L12.6845 4.53483L13.9394 5.06079L14.0733 4.6203L12.7537 4.05018Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.86368 15.0308L10.043 15.7262L9.67058 16.8176L9.50732 16.1102L9.86368 15.0308ZM9.55676 16.1125L9.67944 16.6441L9.99328 15.7243L9.85805 15.1998L9.55676 16.1125Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1746 3.12915L11.4334 3.50731L11.4386 4.13808L10.283 3.81073L10.1746 3.12915ZM10.2336 3.19663L10.3253 3.77318L11.3904 4.0749L11.3861 3.54285L10.2336 3.19663Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2808 3.76416L11.4428 4.09333L11.453 4.8523L10.3825 4.57845L10.2808 3.76416ZM10.337 3.82961L10.4258 4.54033L11.4045 4.7907L11.3957 4.1295L10.337 3.82961Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.803 5.64453L15.0597 6.22836L14.8857 6.89383L13.6753 6.38004L13.803 5.64453ZM13.8393 5.71395L13.7287 6.35095L14.8535 6.82841L15.0035 6.25481L13.8393 5.71395Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70459 5.31372L10.5028 5.40524L10.5778 6.40949L9.84962 6.37894L9.70459 5.31372ZM9.76008 5.36805L9.89146 6.333L10.5263 6.35963L10.4582 5.4481L9.76008 5.36805Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5681 13.4356L20.5462 13.5193L20.5942 13.4063L20.6184 13.3085L20.5681 13.4356ZM20.7043 12.9619L20.7496 12.9764L20.6395 13.4215L20.4625 13.8378L20.4175 13.8225L20.5227 13.4208L20.7043 12.9619Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4046 3.98999L15.8933 4.76884L15.6355 4.99304L14.2197 4.25332L14.4046 3.98999ZM14.4196 4.05161L14.2899 4.23624L15.6287 4.93575L15.8093 4.7787L14.4196 4.05161Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8623 11.366L17.1947 11.7603L17.2325 12.3656L16.9003 12.0277L16.8623 11.366ZM16.9181 11.5061L16.9468 12.0071L17.177 12.2412L17.1481 11.779L16.9181 11.5061Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5223 14.9043L18.8905 14.9934L18.3412 15.2598L17.9856 15.1591L18.5223 14.9043ZM18.5275 14.9546L18.1205 15.1478L18.3368 15.209L18.7502 15.0085L18.5275 14.9546Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.44242 1.85724L7.35266 1.80005L7.35656 1.84754L7.02725 1.88095L6.00693 1.94293L6.00342 1.8954L6.44242 1.85724Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2321 14.9641L18.6399 15.2631L18.2434 15.2566L18.8192 14.9772L19.2321 14.9641ZM18.8309 15.0245L18.4441 15.2122L18.6289 15.2152L19.0185 15.0186L18.8309 15.0245Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.79649 18.9326L8.89033 19.5947L8.25703 20.1565L8.15796 19.5047L8.79649 18.9326ZM8.20895 19.523L8.29097 20.0627L8.83955 19.576L8.76183 19.0277L8.20895 19.523Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2442 14.7551L18.5833 14.9303L18.0571 15.1801L17.7239 14.9994L18.2442 14.7551ZM17.8295 15.0024L18.0586 15.1267L18.476 14.9285L18.2432 14.8082L17.8295 15.0024Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.848 6.82544L15.9604 7.40646L15.7813 8.07675L14.6948 7.56576L14.848 6.82544ZM14.8819 6.8969L14.7491 7.53862L15.75 8.00935L15.9045 7.43104L14.8819 6.8969Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3509 9.7771L16.9917 10.2333L16.9192 10.8805L16.2837 10.4858L16.3509 9.7771ZM16.3906 9.86383L16.3339 10.4609L16.8803 10.8002L16.9412 10.2559L16.3906 9.86383Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8967 4.62354L16.2856 4.74727L17.8069 5.69209L17.3663 5.55338L15.8967 4.62354ZM16.235 4.78118L17.3865 5.50979L17.4606 5.5331L16.2655 4.79089L16.235 4.78118Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.91504 2.40039L10.1141 2.6663L10.2355 3.19396L9.15751 2.96439L8.91504 2.40039ZM8.99558 2.46706L9.19155 2.92291L10.1723 3.13178L10.0744 2.70631L8.99558 2.46706Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9383 5.05371L15.2472 5.70075L15.0405 6.27425L13.7888 5.69276L13.9383 5.05371ZM13.971 5.12305L13.844 5.66587L15.0133 6.20908L15.1879 5.72461L13.971 5.12305Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.65047 7.12708L1.8268 6.40747L1.87318 6.41842L1.62523 7.51036L1.43936 8.2395L1.39307 8.22819L1.65047 7.12708Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.64567 20.4192L7.72405 20.9574L7.00617 21.1152L6.91211 20.5822L7.64567 20.4192ZM6.96697 20.6188L7.04447 21.058L7.6705 20.9204L7.60591 20.4768L6.96697 20.6188Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.88698 6.33252L9.99616 7.45414L9.55561 7.60807L9.38623 6.4274L9.88698 6.33252ZM9.43987 6.46574L9.59456 7.54398L9.9451 7.4215L9.8446 6.38905L9.43987 6.46574Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.295 12.7222L17.4487 13.2856L17.352 13.6077L17.199 13.0806L17.295 12.7222ZM17.2954 12.9048L17.2484 13.0801L17.3528 13.4394L17.3991 13.285L17.2954 12.9048Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6411 14.8108L18.9337 15.1948L18.4487 15.3061L19.1164 14.969L19.6411 14.8108ZM19.1342 15.0134L18.8202 15.172L18.9167 15.1498L19.21 14.9906L19.1342 15.0134Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6353 10.1121L21.022 10.8584L21.0277 11.5005L20.6409 10.8086L20.6353 10.1121ZM20.6845 10.3107L20.6884 10.796L20.9784 11.3146L20.9745 10.8702L20.6845 10.3107Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2477 4.21167L15.6713 4.95549L15.4352 5.30976L14.0764 4.61322L14.2477 4.21167ZM14.2713 4.27777L14.1377 4.59107L15.4191 5.24797L15.6023 4.97317L14.2713 4.27777Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.551 7.72266L20.3856 8.58837L20.5555 9.17737L19.7139 8.34613L19.551 7.72266ZM19.6432 7.88693L19.7567 8.32142L20.4592 9.0152L20.3431 8.61294L19.6432 7.88693Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9801 14.5344L18.2879 14.7872L17.7728 15.029L17.4758 14.776L17.9801 14.5344ZM17.5626 14.7873L17.7803 14.9728L18.1993 14.7762L17.9733 14.5905L17.5626 14.7873Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0878 4.56812L15.4538 5.26838L15.2285 5.73998L13.9258 5.096L14.0878 4.56812ZM14.1167 4.63647L13.9832 5.07124L15.2063 5.67586L15.3908 5.28965L14.1167 4.63647Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53613 4.38745L10.4215 4.53417L10.5019 5.45377L9.70704 5.36264L9.53613 4.38745ZM9.59468 4.44546L9.74784 5.31934L10.4494 5.39977L10.3773 4.57515L9.59468 4.44546Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.26099 19.7883L4.98322 20.4111L5.08689 20.8662L4.38779 20.261L4.26099 19.7883ZM4.34714 19.9256L4.43003 20.2345L5.00811 20.7349L4.94023 20.437L4.34714 19.9256Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7487 8.00488L16.6553 8.56084L16.4961 9.21305L15.6052 8.72758L15.7487 8.00488ZM15.7822 8.08128L15.6588 8.7025L16.4645 9.14156L16.6008 8.5833L15.7822 8.08128Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6995 14.7134L19.6996 14.7134L19.7235 14.7546L19.7235 14.7546L19.4373 14.9216L19.4068 14.9381L19.213 15.0504L18.9361 15.1936L18.9138 15.1515L19.3835 14.8965L19.6995 14.7134Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7603 5.68604L18.9895 6.65337L19.3583 7.1034L18.0737 6.12492L17.7603 5.68604ZM17.9929 5.92978L18.1083 6.09136L19.0636 6.81898L18.9559 6.6876L17.9929 5.92978Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.13989 2.91211L10.2249 3.14318L10.3329 3.82208L9.3609 3.63169L9.13989 2.91211ZM9.20925 2.9756L9.39808 3.59041L10.2751 3.7622L10.183 3.18298L9.20925 2.9756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.64478 19.0259L4.32573 19.8409L4.45964 20.34L3.79649 19.5538L3.64478 19.0259ZM3.75291 19.2296L3.83948 19.5309L4.35821 20.1458L4.28242 19.8634L3.75291 19.2296Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7035 12.9643L20.8417 12.4639L20.8879 12.4757L20.7734 12.9624L20.6398 13.4205L20.5938 13.4081L20.7035 12.9643Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7445 14.2468L18.0196 14.5705L17.5177 14.811L17.2478 14.4926L17.7445 14.2468ZM17.3236 14.5083L17.5303 14.7522L17.9433 14.5542L17.7323 14.306L17.3236 14.5083Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34814 3.58057L10.3256 3.77203L10.427 4.58809L9.54578 4.44206L9.34814 3.58057ZM9.411 3.64144L9.58509 4.40027L10.3718 4.53064L10.2826 3.81216L9.411 3.64144Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.58809 7.51489L9.69926 8.74782L9.52758 9.10957L9.37378 7.81255L9.58809 7.51489ZM9.42329 7.82535L9.55543 8.93973L9.65063 8.73914L9.5521 7.64644L9.42329 7.82535Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.68559 16.6494L9.76614 17.412L9.28365 18.3511L9.19775 17.5888L9.68559 16.6494ZM9.24674 17.5978L9.31334 18.189L9.71725 17.4029L9.65484 16.812L9.24674 17.5978Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.20905 1.83057L5.60499 2.17474L4.7398 2.43286L4.72241 2.38868L5.23402 2.13527L6.20905 1.83057ZM5.25181 2.17963L4.99421 2.30723L5.58614 2.13063L5.80178 2.00777L5.25181 2.17963Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.63013 1.95532L8.74503 2.07295L9.00303 2.4627L8.01967 2.36132L7.63013 1.95532ZM7.75477 2.01639L8.04195 2.31571L8.90765 2.40496L8.71769 2.11799L7.75477 2.01639Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0167 6.20557L16.1618 6.85497L15.9455 7.45232L14.8369 6.87327L15.0167 6.20557ZM15.0467 6.27738L14.8929 6.84874L15.9193 7.38484L16.1033 6.87657L15.0467 6.27738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.074 4.68579L17.4452 5.5534L17.1188 5.61383L15.8 4.77101L16.074 4.68579ZM15.9121 4.78606L17.1286 5.56355L17.317 5.52867L16.0671 4.73784L15.9121 4.78606Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.92627 20.3804L5.66473 20.8142L5.74272 21.2431L5.02658 20.8206L4.92627 20.3804ZM4.99724 20.4773L5.06849 20.79L5.6772 21.1491L5.62175 20.8442L4.99724 20.4773Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.76489 14.4635L1.8107 14.4504L1.9967 15.0561L2.29706 16.2004L2.25121 16.2133L2.07477 15.6315L1.76489 14.4635Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.31616 11.3331L1.36312 11.3259L1.52558 12.014L1.61627 13.2407L1.56934 13.248L1.40685 12.5742L1.31616 11.3331ZM1.3855 11.6281L1.4541 12.5668L1.54721 12.9529L1.47834 12.0213L1.3855 11.6281Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8824 10.7649L17.2098 11.2143L17.1936 11.8283L16.8662 11.4399L16.8824 10.7649ZM16.9267 10.9066L16.9143 11.423L17.1493 11.7018L17.1617 11.2292L16.9267 10.9066Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.66593 4.61032L2.34461 5.21193L2.69696 4.76583L2.98183 4.18592L2.66593 4.61032ZM3.09843 3.94946L3.13893 3.9742L2.7375 4.7914L2.21647 5.45104L2.17676 5.42505L2.62557 4.58472L3.09843 3.94946Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9706 14.4802L19.9989 14.5184L19.7255 14.7582L19.2136 15.0548L19.1868 15.0156L19.45 14.805L19.9706 14.4802ZM19.4776 14.844L19.4649 14.8541L19.6976 14.7193L19.7472 14.6757L19.4776 14.844Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.06958 18.1016L3.72089 19.1178L3.88655 19.6943L3.26256 18.7138L3.06958 18.1016ZM3.2227 18.4288L3.30613 18.6935L3.74963 19.3904L3.67701 19.1377L3.2227 18.4288Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2134 12.178L17.3197 12.8099L17.2194 13.1843L17.1138 12.6032L17.2134 12.178ZM17.1624 12.6044L17.2283 12.9669L17.271 12.8076L17.2057 12.4196L17.1624 12.6044Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.52271 9.02627L9.56997 9.02075L9.63212 9.38408L9.72275 10.6291L9.67559 10.6353L9.59905 10.2383L9.52271 9.02627Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5328 13.8967L17.7676 14.2862L17.2719 14.5314L17.0476 14.1576L17.5328 13.8967ZM17.1142 14.1759L17.2902 14.4692L17.7 14.2665L17.5154 13.9602L17.1142 14.1759Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4635 9.13745L17.102 9.65847L16.988 10.2859L16.3496 9.83134L16.4635 9.13745ZM16.4972 9.22645L16.4014 9.80975L16.9545 10.2035L17.0501 9.67762L16.4972 9.22645Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2047 5.67725L16.3873 6.37987L16.1433 6.90415L15.0027 6.25731L15.2047 5.67725ZM15.2305 5.74802L15.0608 6.23546L16.1219 6.83722L16.3259 6.39883L15.2305 5.74802Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.74796 5.32031L9.89224 6.37998L9.39404 6.47438L9.17456 5.36202L9.74796 5.32031ZM9.23174 5.40564L9.43164 6.41875L9.83891 6.34158L9.70678 5.37109L9.23174 5.40564Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.854 4.73926L17.1691 5.57973L16.8614 5.76435L15.5972 4.96256L15.854 4.73926ZM15.8583 4.79861L15.6767 4.95656L16.862 5.70836L17.0786 5.57845L15.8583 4.79861Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5715 9.50952L20.9642 10.3285L21.0355 10.988L20.6365 10.2181L20.5715 9.50952ZM20.6432 9.76922L20.6831 10.2044L20.9608 10.7403L20.9177 10.3418L20.6432 9.76922Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3538 7.23389L20.1939 8.15379L20.4399 8.71337L19.5765 7.81782L19.3538 7.23389ZM19.4876 7.45114L19.6176 7.79174L20.2887 8.48791L20.1534 8.18014L19.4876 7.45114Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9181 7.38013L16.8424 8.00469L16.6413 8.60789L15.738 8.05392L15.9181 7.38013ZM15.9468 7.45708L15.7933 8.03188L16.6151 8.53589L16.7858 8.02393L15.9468 7.45708Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6187 5.63577L17.6485 5.59863L17.6486 5.59871L17.8868 5.78926L18.9827 6.65172L18.9542 6.68984L18.6867 6.4988L17.6187 5.63585L17.6187 5.63577Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67505 10.5229L9.94192 11.0683V12.4207L9.67505 11.8464V10.5229ZM9.72271 10.7288V11.8358L9.89427 12.2051V11.0793L9.72271 10.7288Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4123 5.24463L16.6375 6.00548L16.3637 6.42147L15.1865 5.71712L15.4123 5.24463ZM15.4323 5.31318L15.2482 5.69851L16.349 6.35713L16.5708 6.02015L15.4323 5.31318Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6301 4.93188L16.8938 5.73646L16.6083 6.04342L15.3887 5.28604L15.6301 4.93188ZM15.6435 4.99689L15.4561 5.27179L16.6002 5.98224L16.8199 5.74592L15.6435 4.99689Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.61279 20.7896L6.36079 21.0303L6.41534 21.4449L5.68918 21.2097L5.61279 20.7896ZM5.67391 20.8593L5.73097 21.1731L6.35825 21.3763L6.31748 21.0665L5.67391 20.8593Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35892 1.79956L7.78983 2.00305L6.89348 2.06707L6.32715 1.86438L7.35892 1.79956ZM6.56095 1.89745L6.90009 2.01882L7.605 1.96847L7.34966 1.84789L6.56095 1.89745Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8177 12.4749L20.9321 11.9412L20.9789 11.9502L20.8882 12.4799L20.7737 12.9667L20.7271 12.9568L20.8177 12.4749Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3433 14.0869L19.9993 14.5186L19.3394 14.9303L19.6861 14.535L20.3433 14.0869ZM19.718 14.571L19.5882 14.7189L19.9671 14.4825L20.1092 14.3042L19.718 14.571Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.98535 2.30981L8.97202 2.41153L9.20728 2.97092L8.34581 2.89491L7.98535 2.30981ZM8.07664 2.36713L8.37383 2.84954L9.13269 2.9165L8.93904 2.45604L8.07664 2.36713Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.89626 12.2368L10.2286 12.8034L10.1009 14.085L9.78418 13.4952L9.89626 12.2368ZM9.93052 12.3894L9.83291 13.4852L10.069 13.9248L10.1796 12.8141L9.93052 12.3894Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3638 13.4893L17.5546 13.9345L17.0694 14.1953L16.8838 13.771L17.3638 13.4893ZM16.9444 13.7907L17.0924 14.1289L17.4936 13.9131L17.3413 13.5577L16.9444 13.7907Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.42008 8.04736L1.53545 8.93396L1.36932 10.2963L1.25391 9.4155L1.42008 8.04736ZM1.42171 8.42919L1.30194 9.41528L1.36762 9.91653L1.48742 8.93416L1.42171 8.42919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.14741 2.85765L3.89139 3.07999L4.25109 2.83934L4.47872 2.621L4.14741 2.85765ZM4.71926 2.39062L4.7496 2.42721L4.28106 2.87663L3.6026 3.33053L3.57373 3.29273L4.11787 2.82019L4.71926 2.39062Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29369 20.0674L8.35669 20.6187L7.67862 20.9755L7.59985 20.4347L8.29369 20.0674ZM7.65186 20.4611L7.71606 20.902L8.30564 20.5917L8.25427 20.1422L7.65186 20.4611Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.64697 17.291L2.69106 17.2732L3.16839 18.2565L3.30712 18.6966L3.26295 18.7142L2.79997 17.7596L2.64697 17.291ZM2.80062 17.608L2.84427 17.7417L3.16386 18.4006L3.12399 18.2742L2.80062 17.608Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.92456 4.39233L9.58537 4.3975L9.75538 5.36756L9.185 5.40904L8.92456 4.39233ZM8.98609 4.44047L9.22128 5.35862L9.69934 5.32385L9.54528 4.44484L8.98609 4.44047Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9498 10.1746L17.2723 10.6774L17.2024 11.2899L16.8799 10.847L16.9498 10.1746ZM16.9831 10.3148L16.9292 10.8338L17.169 11.1629L17.223 10.6888L16.9831 10.3148Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1876 11.5974L17.2382 12.3055L17.1308 12.7639L17.0806 12.1114L17.1876 11.5974ZM17.1286 12.1145L17.1547 12.4532L17.1902 12.3016L17.1645 11.9422L17.1286 12.1145Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.32642 2.84497L9.1928 2.92142L9.41241 3.64156L8.65837 3.59571L8.32642 2.84497ZM8.40265 2.89954L8.69023 3.5499L9.34681 3.58983L9.15659 2.96606L8.40265 2.89954Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1256 6.82788L17.0676 7.51564L16.829 8.05488L15.9084 7.43286L16.1256 6.82788ZM16.149 6.90393L15.9658 7.41411L16.8085 7.98349L17.0084 7.53148L16.149 6.90393Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.43021 6.39917L9.59989 7.58694L9.39002 7.87843L9.16309 6.63788L9.43021 6.39917ZM9.21477 6.6556L9.41675 7.75974L9.55 7.57467L9.39562 6.494L9.21477 6.6556Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2786 5.48364L17.6498 5.59572L18.8164 6.53837L18.4133 6.41539L17.2786 5.48364ZM17.4932 5.59822L18.4363 6.37257L18.5955 6.42116L17.6271 5.63865L17.4932 5.59822Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6144 8.52734L17.2603 9.11729L17.0888 9.70458L16.4536 9.18624L16.6144 8.52734ZM16.6417 8.61676L16.507 9.16833L17.0632 9.62219L17.2063 9.13244L16.6417 8.61676Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0816 13.9033L10.2763 14.6044L10.0157 15.8292L9.83179 15.1157L10.0816 13.9033ZM10.0884 14.1061L9.88069 15.1145L10.0113 15.6211L10.2273 14.6059L10.0884 14.1061Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.64038 3.54224L9.39876 3.58835L9.60045 4.44052L8.94314 4.43539L8.64038 3.54224ZM8.70829 3.59411L8.9774 4.388L9.54009 4.39239L9.36054 3.63377L8.70829 3.59411Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.31932 18.2136L9.40926 18.8961L8.84939 19.6408L8.75415 18.9689L9.31932 18.2136ZM8.8041 18.9817L8.88043 19.5202L9.35946 18.883L9.28736 18.3359L8.8041 18.9817Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0847 6.78076L19.9738 7.79525L20.1941 8.1544L20.1562 8.18292L19.4013 7.35637L19.0847 6.78076ZM19.3375 7.14154L19.4403 7.32843L20.0227 7.96608L19.9353 7.82362L19.3375 7.14154Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5213 13.7439L20.244 14.2122L19.6204 14.6374L19.8891 14.2124L20.5213 13.7439ZM19.9247 14.2454L19.7836 14.4684L20.2084 14.1788L20.36 13.9227L19.9247 14.2454Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.4482 8.91333L20.8638 9.82749L21.0016 10.5168L20.5846 9.64692L20.4482 8.91333ZM20.5664 9.28855L20.6303 9.632L20.8804 10.1537L20.8181 9.84221L20.5664 9.28855Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2378 13.043L17.3806 13.5346L16.9001 13.8166L16.7573 13.3511L17.2378 13.043ZM16.8135 13.3717L16.928 13.745L17.3245 13.5123L17.2099 13.1175L16.8135 13.3717Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.31177 21.0229L7.05227 21.0638L7.08465 21.474L6.36575 21.4332L6.31177 21.0229ZM6.36651 21.0737L6.40785 21.3878L7.03285 21.4233L7.00804 21.1091L6.36651 21.0737Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9748 11.3982L21.0219 11.4047L20.9789 11.9496L20.8642 12.4849L20.8171 12.4778L20.8601 11.9761L20.9748 11.3982ZM20.9074 11.9828L20.897 12.1043L20.9316 11.9427L20.9424 11.8065L20.9074 11.9828Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.63398 1.84058L5.78642 2.13326L5.02466 2.20067L6.00076 1.89564L6.63398 1.84058ZM6.01004 1.94267L5.46113 2.1142L5.7764 2.0863L6.25369 1.92148L6.01004 1.94267Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0529 15.1284L18.4284 15.2331L17.4954 15.5214L17.1399 15.4049L18.0529 15.1284ZM18.0534 15.178L17.2984 15.4067L17.4958 15.4714L18.2593 15.2354L18.0534 15.178Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3476 6.35718L17.3172 7.09807L17.0402 7.55803L16.1025 6.87338L16.3476 6.35718ZM16.3654 6.43077L16.1625 6.85816L17.0263 7.48883L17.2544 7.11004L16.3654 6.43077Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7793 14.9751L18.1139 15.1597L17.2159 15.4317L16.8931 15.2415L17.7793 14.9751ZM17.0111 15.2557L17.2221 15.38L17.992 15.1469L17.7738 15.0265L17.0111 15.2557Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3878 5.51587L18.4805 6.41311L18.1215 6.44254L17.0632 5.56898L17.3878 5.51587ZM17.1745 5.59905L18.1369 6.39346L18.3593 6.37523L17.3742 5.56638L17.1745 5.59905Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.57373 13.2412L1.62012 13.2307L1.80639 13.8659L2.00202 15.054L1.95573 15.0649L1.76938 14.4581L1.57373 13.2412ZM1.68083 13.6071L1.81591 14.4472L1.89762 14.7133L1.75984 13.8766L1.68083 13.6071Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17042 5.42822L2.21613 5.4414L2.05851 6.14829L1.691 7.14105L1.64551 7.12711L1.82212 6.40632L2.17042 5.42822ZM1.86783 6.42002L1.79096 6.73376L2.0127 6.13477L2.0852 5.80961L1.86783 6.42002Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3404 15.2104L18.7788 15.2176L17.7797 15.5396L17.366 15.5115L18.3404 15.2104ZM18.3472 15.2582L17.6251 15.4813L17.7738 15.4914L18.4902 15.2606L18.3472 15.2582Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5249 14.7598L17.8254 15.0157L16.9462 15.28L16.657 15.0186L17.5249 14.7598ZM16.7518 15.0401L16.9582 15.2266L17.7279 14.9953L17.5137 14.8128L16.7518 15.0401Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70195 1.96118L8.08237 2.35768L7.33729 2.42494L6.82886 2.02355L7.70195 1.96118ZM6.95488 2.06232L7.35192 2.37577L7.97934 2.31913L7.68304 2.01031L6.95488 2.06232Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37964 7.82112L9.42703 7.81616L9.52796 8.63989L9.63664 9.38351L9.58958 9.39097L9.52753 9.02823L9.48072 8.64623L9.40829 8.15065L9.37964 7.82112Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5986 5.97778L17.5912 6.77186L17.2944 7.13232L16.3293 6.39492L16.5986 5.97778ZM16.6101 6.04795L16.3933 6.38379L17.2868 7.06655L17.5237 6.77888L16.6101 6.04795Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.126 5.55005L18.1795 6.41969L17.8395 6.5646L16.8203 5.73343L17.126 5.55005ZM16.903 5.73939L17.8473 6.50947L18.0887 6.40657L17.1216 5.60824L16.903 5.73939Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1381 11.615L17.1315 11.8449L17.1477 11.767L17.154 11.5291L17.1381 11.615ZM17.1623 11.2222L17.2096 11.2271L17.1952 11.7726L17.1233 12.1178L17.0762 12.1122L17.0905 11.61L17.1623 11.2222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0695 9.60034L17.3872 10.155L17.2638 10.7451L16.946 10.2496L17.0695 9.60034ZM17.0922 9.73592L16.9964 10.2399L17.241 10.6213L17.3368 10.163L17.0922 9.73592Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8035 7.97046L17.4567 8.61855L17.2429 9.16082L16.6006 8.57921L16.8035 7.97046ZM16.8244 8.05836L16.6556 8.56477L17.2238 9.07926L17.4008 8.63027L16.8244 8.05836Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9198 15.1492L18.9326 15.195L18.059 15.4862L17.781 15.5389L17.7693 15.4928L18.6281 15.2161L18.9198 15.1492Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6757 13.3367L20.46 13.8438L19.8477 14.2976L20.0591 13.8278L20.6757 13.3367ZM20.0978 13.8579L19.9661 14.1505L20.4212 13.8132L20.5582 13.4912L20.0978 13.8579Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.294 14.4778L17.5607 14.7989L16.6959 15.0568L16.4397 14.7406L17.294 14.4778ZM16.5211 14.7654L16.7129 15.0021L17.4779 14.7739L17.2776 14.5327L16.5211 14.7654Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.2561 16.2187L2.30114 16.2031L2.69206 17.2758L2.84468 17.7432L2.79965 17.7588L2.41826 16.7147L2.2561 16.2187Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8548 5.70874L17.8763 6.5418L17.5529 6.80597L16.5645 6.01525L16.8548 5.70874ZM16.859 5.77363L16.6347 6.01041L17.5525 6.74469L17.8009 6.54183L16.859 5.77363Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9451 6.68111L18.9815 6.65039L18.9816 6.65054L19.3589 7.09436L19.9678 7.78911L19.9323 7.8209L19.6746 7.5394L19.3228 7.1255L19.1881 6.97182L18.9451 6.68111Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0258 15.6145L10.1091 16.4142L9.72934 17.5218L9.646 16.7328L10.0258 15.6145ZM9.69449 16.7382L9.75411 17.3026L10.0606 16.4087L10.0009 15.8359L9.69449 16.7382Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33825 9.91479L1.54978 10.8093L1.52217 12.2063L1.31616 11.3338L1.33825 9.91479ZM1.37994 10.2982L1.3639 11.3287L1.48197 11.8287L1.50201 10.8143L1.37994 10.2982Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.2204 5.34717L9.43407 6.45528L9.17061 6.69071L8.88013 5.53377L9.2204 5.34717ZM8.93531 5.55786L9.19764 6.60264L9.38215 6.43776L9.18598 5.42039L8.93531 5.55786Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1548 12.5625L17.2501 13.0918L16.7683 13.4008L16.6782 12.9029L17.1548 12.5625ZM16.7305 12.9242L16.8025 13.3222L17.1975 13.0689L17.1212 12.645L16.7305 12.9242Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3416 8.60706L20.3863 8.59106L20.7156 9.35938L20.8637 9.82273L20.8193 9.83985L20.485 9.10435L20.3416 8.60706ZM20.4937 8.96248L20.5298 9.0878L20.6948 9.45069L20.6709 9.37606L20.4937 8.96248Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.09363 3.94928L3.57011 3.30127L3.60896 3.32885L3.1939 3.93475L2.65965 4.6121L2.62183 4.58311L3.09354 3.9494L3.09363 3.94928Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1982 15.0166L19.2167 15.0604L18.9341 15.1993L18.0605 15.4906L18.0437 15.446L18.316 15.3314L19.1982 15.0166Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0915 14.1384L17.322 14.5191L16.4695 14.7814L16.2493 14.4108L17.0915 14.1384ZM16.3207 14.4377L16.4913 14.7248L17.2496 14.4915L17.0703 14.1954L16.3207 14.4377Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.97 10.855L21.0172 10.8593L21.022 11.405L20.8641 12.2008L20.8503 11.477L20.97 10.855ZM20.8981 11.4811L20.9034 11.758L20.9743 11.4005L20.9716 11.0987L20.8981 11.4811Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.57544 9.26416L9.83657 9.80464L9.9522 11.2027L9.67538 10.637L9.57544 9.26416ZM9.64109 9.50959L9.72224 10.6244L9.88386 10.9546L9.7898 9.81739L9.64109 9.50959Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.04345 2.31348L8.3993 2.89109L7.77288 2.97392L7.29834 2.38074L8.04345 2.31348ZM7.39096 2.42023L7.7933 2.92315L8.32016 2.85348L8.01835 2.36359L7.39096 2.42023Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0307 7.47607L17.6912 8.1819L17.4396 8.66398L16.7903 8.01965L17.0307 7.47607ZM17.0451 7.56125L16.8471 8.00893L17.4273 8.5846L17.6333 8.1898L17.0451 7.56125Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5662 6.34937L18.9843 6.64801L19.8255 7.6377L19.3951 7.35077L18.5662 6.34937ZM18.8361 6.60076L19.4273 7.31499L19.5688 7.40931L18.9518 6.68337L18.8361 6.60076Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.88418 19.5317L8.93732 20.1003L8.31255 20.6483L8.24878 20.0903L8.88418 19.5317ZM8.29896 20.1096L8.34956 20.5524L8.88761 20.0805L8.84543 19.6293L8.29896 20.1096Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.72042 20.9094L7.7312 21.3249L7.03238 21.4779L7 21.0678L7.72042 20.9094ZM7.05078 21.1054L7.07559 21.4197L7.68254 21.2868L7.67427 20.9684L7.05078 21.1054Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7961 12.8953L20.6377 13.4326L20.0327 13.9145L20.1864 13.4194L20.7961 12.8953ZM20.2278 13.4466L20.1241 13.7807L20.5962 13.4047L20.7047 13.0366L20.2278 13.4466Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5963 14.749L19.2134 15.0553L18.1799 15.424L18.5562 15.1418L19.5963 14.749ZM18.5794 15.184L18.4601 15.2735L19.1899 15.0131L19.3312 14.9001L18.5794 15.184Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2271 9.05566L17.5401 9.66592L17.3633 10.2127L17.0503 9.66631L17.2271 9.05566ZM17.2395 9.18423L17.1017 9.66015L17.3511 10.0955L17.4887 9.67007L17.2395 9.18423Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.97197 4.3855L9.23572 5.39573L8.89893 5.58042L8.54883 4.53012L8.97197 4.3855ZM8.609 4.55992L8.92608 5.51118L9.18022 5.37181L8.93882 4.44719L8.609 4.55992Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2241 10.6792L17.2713 10.6858L17.2093 11.2251L17.1376 11.6119L17.0906 11.6046L17.1526 11.1133L17.2241 10.6792Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.43801 2.03418L4.74076 2.58294L4.14253 2.86052L4.11865 2.81951L4.72068 2.38949L5.43801 2.03418ZM4.74527 2.4305L4.35446 2.70965L4.71566 2.54205L5.04779 2.28065L4.74527 2.4305Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.37646 20.1785L5.07006 20.7886L5.10714 21.0976L4.4443 20.5176L4.37646 20.1785ZM4.45085 20.3074L4.48785 20.4924L5.04502 20.9799L5.02491 20.8124L4.45085 20.3074Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9272 13.7561L17.1133 14.1815L16.2719 14.4536L16.0908 14.0383L16.9272 13.7561ZM16.1553 14.0668L16.2983 14.3949L17.0485 14.1523L16.9011 13.8152L16.1553 14.0668Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.76294 19.4458L4.43124 20.2371L4.50313 20.5965L3.86796 19.8396L3.76294 19.4458ZM3.8638 19.6391L3.91128 19.8171L4.41986 20.4232L4.38691 20.2584L3.8638 19.6391Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1202 12.0667L17.1627 12.62L16.6839 12.962L16.6414 12.4402L17.1202 12.0667ZM16.6909 12.462L16.7246 12.8743L17.1132 12.5968L17.0795 12.1589L16.6909 12.462Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.8894 10.9883L10.2278 11.5485V12.8962L9.8894 12.3194V10.9883ZM9.93706 11.1594V12.3064L10.1801 12.7208V11.5618L9.93706 11.1594Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.37422 2.84644L8.7032 3.58538L8.18566 3.69516L7.74463 2.92969L8.37422 2.84644ZM7.82147 2.9676L8.20966 3.64135L8.63572 3.55097L8.34516 2.89835L7.82147 2.9676Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.44423 1.85571L6.96367 2.04709L6.34038 2.29214L5.7019 2.11206L6.44423 1.85571ZM5.86119 2.10747L6.33788 2.24192L6.82974 2.04853L6.44371 1.90631L5.86119 2.10747Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1565 8.17589L20.2004 8.15747L20.7151 9.35841L20.6716 9.37785L20.4808 8.9675L20.1565 8.17589Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.68517 3.54028L8.98554 4.42637L8.56581 4.56982L8.16406 3.65082L8.68517 3.54028ZM8.23115 3.68531L8.5919 4.51054L8.92514 4.39664L8.65363 3.59569L8.23115 3.68531Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.01831 20.7556L5.73368 21.1776L5.73963 21.4576L5.05405 21.0534L5.01831 20.7556ZM5.07711 20.8456L5.09856 21.0244L5.69017 21.3732L5.6866 21.2052L5.07711 20.8456Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2818 7.06396L17.9547 7.81724L17.6648 8.22836L17.0083 7.52676L17.2818 7.06396ZM17.2896 7.14431L17.0675 7.52027L17.6596 8.15305L17.8939 7.82073L17.2896 7.14431Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.68041 6.99365L1.78835 7.83448L1.50009 9.06225L1.39209 8.23234L1.68041 6.99365ZM1.44046 8.23475L1.51355 8.79641L1.73999 7.83199L1.66683 7.26218L1.44046 8.23475Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3564 6.34351L18.7198 6.45435L19.4865 7.38064L19.1066 7.25808L18.3564 6.34351ZM18.4932 6.43504L19.1346 7.21703L19.3456 7.2851L18.6922 6.49576L18.4932 6.43504Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9526 10.1587L21.0222 10.8606L20.8722 11.6403L20.8025 10.9873L20.9526 10.1587ZM20.8506 10.9891L20.8857 11.3178L20.9741 10.8584L20.9388 10.5024L20.8506 10.9891Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.17986 6.46045L9.42231 7.81597L9.45099 8.14587L9.40398 8.15301L9.14111 6.94862L9.17986 6.46045ZM9.19809 6.83307L9.18918 6.94536L9.34983 7.68145L9.19809 6.83307Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.21216 18.5403L3.84025 19.5329L3.95734 19.972L3.35784 19.0283L3.21216 18.5403ZM3.35667 18.8578L3.40161 19.0083L3.83149 19.685L3.79608 19.5522L3.35667 18.8578Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7902 14.4939L19.4824 14.8448L18.4866 15.2209L18.7873 14.895L19.7902 14.4939ZM18.8151 14.9353L18.6583 15.1051L19.4544 14.8044L19.6232 14.612L18.8151 14.9353Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.95068 15.0641L1.99642 15.0508L2.16819 15.5852L2.45906 16.6962L2.41336 16.7096L2.2511 16.2133L1.95068 15.0641Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7609 17.3328L9.84741 18.041L9.37072 18.9633L9.27881 18.2658L9.7609 17.3328ZM9.32801 18.2744L9.39813 18.8065L9.79832 18.0322L9.73233 17.4919L9.32801 18.2744Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8056 13.3306L16.9484 13.7961L16.1122 14.0782L15.9744 13.6328L16.8056 13.3306ZM16.0333 13.6621L16.1433 14.0174L16.8893 13.7657L16.7747 13.3925L16.0333 13.6621Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5522 6.73364L18.238 7.53456L17.9184 7.84885L17.2495 7.1001L17.5522 6.73364ZM17.5529 6.80768L17.3123 7.09888L17.9206 7.77982L18.1729 7.53177L17.5529 6.80768Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1926 12.6829L10.3998 13.4023L10.2625 14.7381L10.0608 14.0118L10.1926 12.6829ZM10.2155 12.9346L10.1091 14.0076L10.2409 14.4822L10.3515 13.4066L10.2155 12.9346Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4354 6.36475L19.1709 7.26144H18.8043L18.0862 6.38765L18.4354 6.36475ZM18.182 6.42913L18.8268 7.21378H19.0702L18.4141 6.4139L18.182 6.42913Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.46143 11.792L1.71621 12.671L1.81798 14.0958L1.56896 13.2466L1.46143 11.792ZM1.54256 12.2431L1.61612 13.238L1.73882 13.6565L1.66903 12.6794L1.54256 12.2431Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8776 12.4136L20.7722 12.9738L20.1707 13.4909L20.2709 12.9728L20.8776 12.4136ZM20.3147 12.9972L20.2435 13.3655L20.7285 12.9485L20.8042 12.5461L20.3147 12.9972Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4266 8.56445L17.7409 9.22458L17.516 9.72795L17.2073 9.12614L17.4266 8.56445ZM17.4309 8.68439L17.2595 9.12348L17.5131 9.61777L17.6885 9.22521L17.4309 8.68439Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8419 6.50854L18.5414 7.3479L18.2011 7.56931L17.5188 6.77241L17.8419 6.50854ZM17.5867 6.77847L18.2102 7.50658L18.4704 7.33721L17.8355 6.5753L17.5867 6.77847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1411 6.38403L18.8562 7.25415L18.5027 7.37014L17.8049 6.53282L18.1411 6.38403ZM17.882 6.55083L18.5186 7.31476L18.7751 7.23059L18.1272 6.44227L17.882 6.55083Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3388 10.1541L17.3856 10.1627L17.3092 10.6447L17.2043 11.1219L17.1575 11.1129L17.229 10.6788L17.3388 10.1541Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.68555 21.1633L6.41219 21.3936L6.38889 21.6616L5.69138 21.4376L5.68555 21.1633ZM5.73461 21.2289L5.7383 21.4026L6.34658 21.598L6.36141 21.4275L5.73461 21.2289Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9558 15.2231L17.2814 15.415L16.0757 15.7126L15.7678 15.5151L16.9558 15.2231ZM15.8869 15.5349L16.0842 15.6614L17.1565 15.3967L16.9483 15.2741L15.8869 15.5349Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9275 7.81814L19.9699 7.79688L20.1949 8.16074L20.5198 8.95366L20.4779 8.97591L20.2477 8.63067L19.9275 7.81814ZM20.0695 8.0485L20.2902 8.60846L20.3853 8.75109L20.1523 8.18245L20.0695 8.0485Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1395 11.5544L17.1234 12.1199L16.6414 12.496L16.6521 11.973L17.1395 11.5544ZM16.6993 11.9952L16.6911 12.3968L17.0764 12.0961L17.0888 11.6608L16.6993 11.9952Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.22 15.3811L17.5811 15.4994L16.3407 15.8121L16.0005 15.6822L17.22 15.3811ZM16.1597 15.6919L16.3437 15.7622L17.4098 15.4934L17.2182 15.4306L16.1597 15.6919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7107 15.0034L17.0019 15.261L15.8205 15.5514L15.5408 15.2884L16.7107 15.0034ZM15.6375 15.3139L15.8343 15.4989L16.9022 15.2364L16.6978 15.0556L15.6375 15.3139Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.498 15.4724L17.9288 15.5016L16.6153 15.8447L16.218 15.7951L17.498 15.4724ZM17.5023 15.5205L16.4757 15.7792L16.6122 15.7963L17.6339 15.5294L17.5023 15.5205Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.70073 4.43848L2.53026 5.29673L1.98071 6.30048L2.17528 5.4279L2.70073 4.43848ZM2.22039 5.44456L2.10042 5.9826L2.48494 5.28026L2.59087 4.74694L2.22039 5.44456Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4894 14.7214L16.7466 15.0388L15.5799 15.323L15.3335 15.0054L16.4894 14.7214ZM15.4161 15.0342L15.5986 15.2694L16.6622 15.0103L16.4714 14.7749L15.4161 15.0342Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.967 14.1724L19.7179 14.5721L18.7354 14.9651L18.9757 14.5957L19.967 14.1724ZM19.0078 14.6339L18.8574 14.865L19.6857 14.5336L19.8465 14.2757L19.0078 14.6339Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.79932 17.7577L2.84351 17.7402L3.30662 18.6951L3.40248 19.0162L3.35823 19.0335L2.90947 18.1121L2.79932 17.7577ZM2.93881 18.0459L2.9539 18.0945L3.27345 18.7506L3.26206 18.7124L2.93881 18.0459Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7227 12.8809L16.8125 13.3771L15.9806 13.6796L15.896 13.2035L16.7227 12.8809ZM15.9498 13.2337L16.0176 13.6154L16.7584 13.346L16.6861 12.9463L15.9498 13.2337Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8339 9.70337L20.9652 10.3301L20.8241 11.1086L20.6926 10.5169L20.8339 9.70337ZM20.8382 9.9567L20.7412 10.5157L20.8194 10.8675L20.9166 10.3308L20.8382 9.9567Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.89595 2.01587L7.38929 2.40534L6.89031 2.64696L6.28223 2.25716L6.89595 2.01587ZM6.38648 2.26738L6.89356 2.59243L7.30005 2.39561L6.88791 2.07024L6.38648 2.26738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6644 8.13135L17.975 8.84062L17.7122 9.27505L17.4021 8.6238L17.6644 8.13135ZM17.4555 8.625L17.7172 9.17471L17.9214 8.8371L17.6602 8.24058L17.4555 8.625Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.35598 20.5652L8.34506 20.9962L7.684 21.3447L7.6731 20.9246L8.35598 20.5652ZM7.7215 20.953L7.72965 21.2668L8.29813 20.9671L8.30628 20.6452L7.7215 20.953Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0536 15.4443L18.0639 15.4908L16.8852 15.8106L16.6166 15.8441L16.6077 15.7974L17.7762 15.4922L18.0536 15.4443Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37891 7.97705L9.62666 8.5462L9.85842 9.96912L9.58055 9.39399L9.37891 7.97705ZM9.47567 8.31875L9.62668 9.37994L9.75866 9.65309L9.58058 8.55975L9.47567 8.31875Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2963 14.3967L16.5172 14.7684L15.3628 15.052L15.1526 14.6802L16.2963 14.3967ZM15.2249 14.7113L15.3865 14.9971L16.4433 14.7375L16.2734 14.4515L15.2249 14.7113Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.91474 5.45801L9.2177 6.64931L9.1836 7.07898L8.80176 5.82207L8.91474 5.45801ZM8.85161 5.82222L9.15602 6.82422L9.16957 6.65341L8.91019 5.63346L8.85161 5.82222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.50463 8.79468L1.6978 9.65257L1.54318 10.9481L1.34448 10.1079L1.50463 8.79468ZM1.51861 9.07372L1.39282 10.1052L1.52771 10.6756L1.64951 9.65505L1.51861 9.07372Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.913 11.9177L20.8639 12.4911L20.2642 13.0439L20.308 12.5071L20.913 11.9177ZM20.354 12.5288L20.3216 12.9261L20.818 12.4686L20.8546 12.0412L20.354 12.5288Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23187 2.72119L3.79813 3.43627L3.06079 4.04008L3.57132 3.29483L4.23187 2.72119ZM3.60718 3.3268L3.28848 3.79202L3.76162 3.40458L4.03319 2.95684L3.60718 3.3268Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6252 7.41479L19.9704 7.79192L20.3353 8.71779L19.9865 8.35633L19.6252 7.41479ZM19.7598 7.63237L20.0276 8.33021L20.203 8.51203L19.9293 7.81759L19.7598 7.63237Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2598 14.4578L10.3472 15.3025L10.0767 16.5632L9.98926 15.724L10.2598 14.4578ZM10.0374 15.7266L10.093 16.2599L10.299 15.2998L10.2434 14.7625L10.0374 15.7266Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4966 9.66553L17.5428 9.67644L17.4615 10.2027L17.3083 10.6527L17.2622 10.6413L17.3387 10.1581L17.4966 9.66553ZM17.3852 10.1692L17.3528 10.3741L17.415 10.1913L17.4494 9.96906L17.3852 10.1692Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.84994 2.07983L5.36773 2.51869L4.64868 2.59495L5.23393 2.13435L5.84994 2.07983ZM5.25225 2.18057L4.80811 2.53012L5.34719 2.47295L5.71328 2.13977L5.25225 2.18057Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2079 11.0544L17.1367 11.6245L16.6438 12.0477L16.7152 11.515L17.2079 11.0544ZM16.7602 11.5382L16.7076 11.9301L17.0917 11.6003L17.1443 11.1791L16.7602 11.5382Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.36034 21.3855L7.08802 21.4265L7.0301 21.6929L6.33716 21.6522L6.36034 21.3855ZM6.40382 21.4357L6.38888 21.6075L6.99219 21.6429L7.02959 21.4709L6.40382 21.4357Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3134 15.321L18.3295 15.3657L18.061 15.4856L16.8821 15.8054L16.8667 15.7604L17.1206 15.655L18.3134 15.321Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.40349 18.8242L9.4469 19.421L8.8946 20.1592L8.84033 19.5785L9.40349 18.8242ZM8.88949 19.5924L8.93054 20.0316L9.39808 19.4067L9.36523 18.9551L8.88949 19.5924Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1266 13.7993L19.9248 14.2422L18.9431 14.6615L19.141 14.2487L20.1266 13.7993ZM19.1765 14.2849L19.0408 14.5679L19.8891 14.2056L20.0304 13.8956L19.1765 14.2849Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1414 14.0244L16.3228 14.4405L15.1796 14.7238L15.0034 14.3126L16.1414 14.0244ZM15.0692 14.3451L15.2075 14.6678L16.2566 14.4079L16.1139 14.0805L15.0692 14.3451Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.925 7.77026L18.2377 8.51879L17.9371 8.88388L17.6301 8.18306L17.925 7.77026ZM17.6846 8.18873L17.9496 8.79369L18.1827 8.51066L17.914 7.86762L17.6846 8.18873Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6872 12.4165L16.7295 12.9292L15.9018 13.2522L15.8594 12.7648L16.6872 12.4165ZM15.9099 12.7953L15.9437 13.1847L16.6791 12.8977L16.6451 12.4859L15.9099 12.7953Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6859 9.27148L20.8654 9.83319L20.7293 10.6171L20.5496 10.0895L20.6859 9.27148ZM20.7005 9.47373L20.5985 10.0855L20.7143 10.4252L20.8164 9.83656L20.7005 9.47373Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3672 7.271L19.7168 7.50802L20.0608 8.40458L19.7062 8.16819L19.3672 7.271ZM19.4649 7.39484L19.7454 8.13703L19.9627 8.28189L19.6777 7.53907L19.4649 7.39484Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35129 2.37549L7.81811 2.95901L7.43171 3.20779L6.85156 2.61746L7.35129 2.37549ZM6.93209 2.63142L7.43852 3.14672L7.7479 2.94753L7.33784 2.43495L6.93209 2.63142Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.75952 13.8801L1.80579 13.8691L1.9826 14.4378L2.1735 15.5927L2.1273 15.6039L1.9552 15.0685L1.75952 13.8801ZM1.86246 14.2119L2.00165 15.0572L2.07377 15.2816L1.93612 14.4488L1.86246 14.2119Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.77637 9.71143L10.113 10.2705L10.237 11.6559L9.89011 11.0815L9.77637 9.71143ZM9.84073 9.91074L9.93668 11.0664L10.1712 11.4547L10.0665 10.2857L9.84073 9.91074Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5857 4.49023L8.93747 5.54556L8.8295 5.89348L8.39014 4.79508L8.5857 4.49023ZM8.4435 4.80016L8.82382 5.75098L8.88741 5.54608L8.572 4.59985L8.4435 4.80016Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.41333 16.7094L2.45846 16.6941L2.83991 17.7431L2.94968 18.0963L2.90454 18.1115L2.5374 17.1054L2.41333 16.7094Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2191 7.49536L18.5294 8.2787L18.2058 8.55916L17.8962 7.81824L18.2191 7.49536ZM17.9525 7.82935L18.2244 8.48L18.4726 8.26483L18.2015 7.58035L17.9525 7.82935Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6871 15.103L18.3307 15.3704L17.0042 15.7418L17.3515 15.4937L18.6871 15.103ZM17.3726 15.5372L17.253 15.6226L18.3092 15.3269L18.4484 15.2225L17.3726 15.5372Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9018 11.4211L20.9126 11.994L20.3131 12.578L20.3022 12.0414L20.9018 11.4211ZM20.3503 12.0603L20.3585 12.4672L20.8646 11.9742L20.8563 11.5368L20.3503 12.0603Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0198 13.6184L16.1577 14.0693L15.02 14.3575L14.8872 13.9165L16.0198 13.6184ZM14.9471 13.95L15.0525 14.3001L16.0975 14.0354L15.9876 13.6762L14.9471 13.95Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7576 9.05737L17.6519 9.80385L17.3862 10.3416L17.4914 9.66131L17.7576 9.05737ZM17.5375 9.67479L17.4806 10.0429L17.6058 9.7896L17.662 9.39232L17.5375 9.67479Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0808 7.20459L19.4342 7.31859L19.7661 8.19676L19.4071 8.07142L19.0808 7.20459ZM19.1602 7.28028L19.4439 8.03377L19.6854 8.11811L19.3978 7.3569L19.1602 7.28028Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.79106 2.91504L8.22767 3.66781L7.94129 3.93798L7.40137 3.16594L7.79106 2.91504ZM7.4688 3.1792L7.94872 3.86545L8.1675 3.65906L7.77489 2.98213L7.4688 3.1792Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.20604 3.62744L8.60097 4.54727L8.40997 4.84499L7.91553 3.90151L8.20604 3.62744ZM7.97451 3.91139L8.41413 4.75025L8.5472 4.54282L8.18915 3.70889L7.97451 3.91139Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3231 10.564L17.193 11.1293L16.6914 11.5982L16.8162 11.0763L17.3231 10.564ZM16.8595 11.1003L16.7746 11.4552L17.1499 11.1044L17.239 10.7166L16.8595 11.1003Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5175 7.3064L18.8311 8.12082L18.484 8.30526L18.1763 7.52846L18.5175 7.3064ZM18.2349 7.54715L18.5086 8.23818L18.7715 8.09854L18.4942 7.37844L18.2349 7.54715Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.04697 6.04126L2.15065 6.8543L1.75287 7.93774L1.64917 7.12999L2.04697 6.04126ZM1.69792 7.13544L1.77532 7.73832L2.10191 6.84879L2.02449 6.24167L1.69792 7.13544Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2457 13.3787L20.098 13.8601L19.1206 14.3058L19.258 13.8497L20.2457 13.3787ZM19.2975 13.8837L19.1966 14.2188L20.0587 13.8257L20.1684 13.4684L19.2975 13.8837Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.781 7.21851H19.137L19.4605 8.07796L19.0989 8.06127L18.781 7.21851ZM18.8499 7.26616L19.1325 8.01511L19.3904 8.02702L19.104 7.26616H18.8499Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8297 7.21265L19.1476 8.05535L18.7912 8.13761L18.4792 7.32765L18.8297 7.21265ZM18.5417 7.3573L18.8208 8.08187L19.0838 8.02117L18.8012 7.27215L18.5417 7.3573Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7051 11.947L16.6945 12.4672L15.8652 12.8161L15.8706 12.3213L16.7051 11.947ZM15.9179 12.3523L15.9137 12.744L16.6475 12.4352L16.656 12.0213L15.9179 12.3523Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.832 15.4995L16.1406 15.6975L14.7121 16.0271L14.4165 15.8185L15.832 15.4995ZM14.5316 15.8414L14.7221 15.9759L16.0196 15.6765L15.823 15.5504L14.5316 15.8414Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5963 15.2751L15.8765 15.5385L14.4663 15.8563L14.2031 15.5875L15.5963 15.2751ZM14.297 15.6153L14.4818 15.804L15.7785 15.5118L15.5821 15.3272L14.297 15.6153Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.51001 10.6438L1.74515 11.4903L1.7181 12.8321L1.48291 12.0207L1.51001 10.6438ZM1.55125 10.9703L1.5307 12.0144L1.67676 12.5183L1.69737 11.4963L1.55125 10.9703Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.4914 8.88232L20.7179 9.36929L20.5854 10.1643L20.3586 9.70521L20.4914 8.88232ZM20.5137 9.04329L20.4081 9.69776L20.5626 10.0107L20.6684 9.37601L20.5137 9.04329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.4375 20.4578L5.10334 21.0359L5.05972 21.1813L4.4375 20.64V20.4578ZM4.48516 20.5623V20.6183L5.03529 21.0969L5.04885 21.0517L4.48516 20.5623Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1017 16.3044L10.1855 17.0528L9.80689 18.1414L9.71753 17.4097L10.1017 16.3044ZM9.76618 17.4149L9.82931 17.9318L10.1369 17.0474L10.0778 16.5186L9.76618 17.4149Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1856 11.3899L10.4042 12.1429L10.3988 13.571L10.1802 12.8119L10.1856 11.3899ZM10.232 11.7206L10.2279 12.8053L10.3524 13.2377L10.3565 12.1496L10.232 11.7206Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0817 15.667L16.4227 15.7972L14.9624 16.1458L14.6438 15.9988L16.0817 15.667ZM14.7903 16.0139L14.9673 16.0957L16.2615 15.7867L16.0783 15.7167L14.7903 16.0139Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.84937 19.7434L4.48524 20.5012V20.6945L3.89586 19.9914L3.84937 19.7434ZM3.9297 19.9133L3.9404 19.9703L4.43758 20.5635V20.5185L3.9297 19.9133Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.14185 6.95209L9.18764 6.93921L9.32142 7.32621L9.62667 8.54245L9.58171 8.55776L9.40445 8.15054L9.14185 6.95209ZM9.2754 7.33896L9.45001 8.13579L9.51015 8.27396L9.2757 7.33982L9.2754 7.33896Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3844 14.998L15.6306 15.3209L14.2397 15.6327L14.0049 15.2997L15.3844 14.998ZM14.0852 15.3309L14.2603 15.5792L15.5476 15.2906L15.3649 15.0511L14.0852 15.3309Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8721 14.8669L18.5857 15.1838L17.2898 15.5628L17.5683 15.2658L18.8721 14.8669ZM17.5944 15.3076L17.4439 15.4681L18.5594 15.1418L18.721 14.963L17.5944 15.3076Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74208 21.2737L7.65386 21.5501L6.9751 21.6992L7.0339 21.4288L7.74208 21.2737ZM7.07397 21.4688L7.03745 21.6367L7.61681 21.5095L7.67156 21.3379L7.07397 21.4688Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9415 13.1887L16.0314 13.6648L14.8985 13.963L14.8137 13.4967L15.9415 13.1887ZM14.8684 13.5312L14.9362 13.9038L15.9764 13.63L15.9043 13.2483L14.8684 13.5312Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.062 21.0076L5.74972 21.413L5.6638 21.5276L5.01929 21.1499L5.062 21.0076ZM5.09017 21.0795L5.0757 21.1278L5.65119 21.465L5.67964 21.427L5.09017 21.0795Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9879 8.72729L17.8853 9.46228L17.5955 9.89711L17.6919 9.21668L17.9879 8.72729ZM17.7377 9.23301L17.672 9.69633L17.8396 9.44491L17.9087 8.95036L17.7377 9.23301Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3408 15.7629L16.7408 15.8129L15.2183 16.1832L14.8457 16.1199L16.3408 15.7629ZM16.3434 15.8113L15.0839 16.112L15.2165 16.1345L16.4771 15.828L16.3434 15.8113Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.77527 2.08325L6.3773 2.24933L6.01423 2.66501L5.30737 2.50909L5.77527 2.08325ZM5.78792 2.13618L5.40752 2.48238L5.99692 2.61239L6.29151 2.2751L5.78792 2.13618Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2009 14.6689L15.4113 15.0412L14.0325 15.3426L13.833 14.9652L15.2009 14.6689ZM13.9045 14.9985L14.0577 15.2883L15.3381 15.0084L15.1767 14.723L13.9045 14.9985Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.93993 20.0393L8.912 20.4974L8.30127 21.0356L8.31245 20.5942L8.93993 20.0393ZM8.35956 20.6161L8.35167 20.9277L8.86564 20.4747L8.88536 20.1512L8.35956 20.6161Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8396 10.9338L20.9041 11.4875L20.3088 12.1033L20.2441 11.5806L20.8396 10.9338ZM20.2941 11.5967L20.3439 11.9985L20.8542 11.4706L20.8043 11.0426L20.2941 11.5967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.31445 18.8713L3.91684 19.8195L3.96906 20.098L3.40997 19.2187L3.31445 18.8713ZM3.4447 19.1652L3.45403 19.1991L3.87667 19.8639L3.87169 19.8373L3.4447 19.1652Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.21352 3.82812L3.08272 4.60751L2.46045 5.3787L2.61862 4.58238L3.21352 3.82812ZM2.66313 4.6029L2.5449 5.19815L3.03779 4.5873L3.13573 4.00371L2.66313 4.6029Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4973 10.0986L17.3065 10.6531L16.7847 11.1805L16.9647 10.6705L17.4973 10.0986ZM17.0063 10.6957L16.9004 10.9958L17.265 10.6273L17.3792 10.2953L17.0063 10.6957Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2595 8.55518L20.5276 8.95729L20.3975 9.76428L20.1345 9.37825L20.2595 8.55518ZM20.2882 8.68404L20.1844 9.3669L20.3695 9.63853L20.4776 8.96817L20.2882 8.68404Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3253 12.9419L20.2281 13.4493L19.2454 13.918L19.3375 13.4408L20.3253 12.9419ZM19.3799 13.4728L19.3101 13.8344L20.1859 13.4167L20.2603 13.0281L19.3799 13.4728Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.78122 2.51172L4.45988 3.13835L3.71973 3.47381L4.11609 2.82034L4.78122 2.51172ZM4.14931 2.85746L3.84037 3.36681L4.42506 3.1018L4.67559 2.61327L4.14931 2.85746Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8682 15.7634L16.8769 15.8102L15.4694 16.1585L15.2198 16.1825L15.2119 16.1356L16.6048 15.797L16.8682 15.7634Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.762 11.4861L16.6977 12.0004L15.8601 12.3761L15.9246 11.8921L16.762 11.4861ZM15.9685 11.9238L15.9187 12.2976L16.6537 11.9679L16.7038 11.5673L15.9685 11.9238Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.046 14.3013L15.2171 14.713L13.8492 15.0093L13.6887 14.5974L15.046 14.3013ZM13.7534 14.6321L13.8788 14.9541L15.1511 14.6785L15.0172 14.3563L13.7534 14.6321Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.12231 15.5979L2.16809 15.5847L2.30648 16.0237L2.58304 17.0919L2.53724 17.1049L2.41316 16.7089L2.12231 15.5979Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0484 14.5718L18.8118 14.9353L17.5249 15.3289L17.752 14.9854L19.0484 14.5718ZM17.7825 15.0257L17.6378 15.2446L18.7813 14.8948L18.9356 14.6578L17.7825 15.0257Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.70584 21.3923L6.41297 21.6211L6.28171 21.7232L5.61841 21.5089L5.70584 21.3923ZM5.72354 21.4482L5.69659 21.4841L6.27236 21.6701L6.31267 21.6388L5.72354 21.4482Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9059 12.7493L15.9482 13.2363L14.8198 13.5445L14.7773 13.0724L15.9059 12.7493ZM14.8283 13.1074L14.8621 13.4836L15.8973 13.2008L15.8634 12.811L14.8283 13.1074Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2454 8.43433L18.1422 9.17829L17.8281 9.5215L17.9254 8.82414L18.2454 8.43433ZM17.9708 8.84401L17.8965 9.37619L18.0971 9.15705L18.175 8.59523L17.9708 8.84401Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.75969 7.7251L1.94566 8.56743L1.66823 9.75568L1.48218 8.92941L1.75969 7.7251ZM1.53105 8.92953L1.66907 9.54247L1.89679 8.56713L1.7587 7.94164L1.53105 8.92953Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3757 13.1716L10.4711 14.1001L10.3232 15.5277L10.2278 14.6052L10.3757 13.1716ZM10.2757 14.6052L10.3232 15.0648L10.4232 14.1001L10.3756 13.637L10.2757 14.6052Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1227 15.6592L17.1377 15.7043L16.8835 15.8098L15.4759 16.1581L15.4617 16.1127L15.7012 16.0217L17.1227 15.6592Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9905 8.29199L20.2943 8.60688L20.1711 9.41809L19.8672 9.11416L19.9905 8.29199ZM20.0233 8.39461L19.9179 9.09745L20.1382 9.31774L20.2437 8.62301L20.0233 8.39461Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.73 10.4702L20.8479 10.9951L20.2563 11.6377L20.1382 11.1437L20.73 10.4702ZM20.1903 11.1566L20.2818 11.5396L20.7959 10.9812L20.704 10.572L20.1903 11.1566Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.55811 8.42432L9.88844 9.00691L10.1409 10.4062L9.79053 9.82415L9.55811 8.42432ZM9.6491 8.68142L9.83605 9.80737L10.048 10.1594L9.84299 9.02336L9.6491 8.68142Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.91357 18.1103L2.95776 18.0928L3.40192 19.0097L3.45492 19.2025L3.41049 19.2192L2.98558 18.3407L2.91357 18.1103Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.65234 12.4573L1.89733 13.2761L1.99863 14.6492L1.75966 13.8806L1.65234 12.4573ZM1.73299 12.8931L1.80677 13.8716L1.92046 14.2373L1.85019 13.2847L1.73299 12.8931Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9337 13.9006L15.0667 14.342L13.7092 14.6381L13.5815 14.1967L14.9337 13.9006ZM13.6415 14.2324L13.7426 14.5821L15.0062 14.3064L14.9008 13.9566L13.6415 14.2324Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.83707 17.947L9.87064 18.5849L9.39999 19.5L9.35522 18.8845L9.83707 17.947ZM9.40373 18.8944L9.43521 19.3273L9.82237 18.5745L9.79876 18.1258L9.40373 18.8944Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.79858 5.8222L8.84474 5.81055L9.18773 6.93957L9.32098 7.32504L9.32102 7.32516L9.27602 7.34085L9.27598 7.34073L8.87516 6.2003L8.79858 5.8222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5331 8.21313L18.4279 8.97118L18.093 9.22088L18.1926 8.50255L18.5331 8.21313ZM18.2373 8.5271L18.1559 9.11456L18.3834 8.94488L18.4687 8.33042L18.2373 8.5271Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7291 9.64551L17.4533 10.2106L16.8765 10.8299L17.1515 10.3073L17.7291 9.64551ZM17.1911 10.3345L17.0849 10.5362L17.4136 10.1833L17.5285 9.94783L17.1911 10.3345Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.32979 2.2312L6.92484 2.61264L6.66157 3.01809L5.96777 2.64568L6.32979 2.2312ZM6.33866 2.29349L6.04301 2.63198L6.64547 2.95536L6.85876 2.6269L6.33866 2.29349Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3632 12.4812L20.3204 13.0003L19.3367 13.4972L19.3743 13.0083L20.3632 12.4812ZM19.4198 13.038L19.3907 13.4165L20.2751 12.9698L20.3085 12.5643L19.4198 13.038Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7074 8.11182L20.0374 8.33179L19.9152 9.14668L19.5903 8.92646L19.7074 8.11182ZM19.7439 8.19337L19.6417 8.90374L19.8793 9.06476L19.9858 8.35465L19.7439 8.19337Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.8777 11.0432L16.7581 11.5431L15.9167 11.9511L16.0259 11.4819L16.8777 11.0432ZM16.0673 11.5142L15.9858 11.8646L16.717 11.5101L16.8072 11.1331L16.0673 11.5142Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1988 14.2268L19.0091 14.6342L17.7227 15.0447L17.9023 14.6573L19.1988 14.2268ZM17.9369 14.696L17.8115 14.9663L18.9747 14.5952L19.109 14.3069L17.9369 14.696Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9187 12.3047L15.9081 12.7982L14.7783 13.1217L14.789 12.6431L15.9187 12.3047ZM14.8358 12.6788L14.8274 13.0581L15.8612 12.762L15.8697 12.3691L14.8358 12.6788Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3662 15.6943L14.6435 15.9377L13.0666 16.3183L12.7844 16.07L14.3662 15.6943ZM12.8851 16.0951L13.0795 16.2662L14.543 15.9129L14.3533 15.7464L12.8851 16.0951Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1588 15.4414L14.4073 15.7396L12.8304 16.1141L12.5823 15.8109L14.1588 15.4414ZM12.6675 15.8399L12.8484 16.0609L14.3214 15.711L14.141 15.4945L12.6675 15.8399Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8361 8.06665L18.7277 8.84086L18.3762 8.99708L18.4791 8.25631L18.8361 8.06665ZM18.523 8.28698L18.4352 8.91871L18.6842 8.80805L18.776 8.15257L18.523 8.28698Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.36775 20.9304L8.24512 21.237L7.59497 21.5778L7.68707 21.2892L8.36775 20.9304ZM7.72654 21.3223L7.67567 21.4817L8.2074 21.203L8.2754 21.033L7.72654 21.3223Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4885 15.4592L17.1385 15.7042L15.5881 16.0996L15.9221 15.8651L17.4885 15.4592ZM15.9424 15.9091L15.8284 15.9892L17.1183 15.6602L17.2449 15.5716L15.9424 15.9091Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.597 15.8999L14.9046 16.0774L13.3221 16.4606L13.0088 16.2833L14.597 15.8999ZM13.1373 16.3013L13.3292 16.4098L14.7776 16.0591L14.5896 15.9507L13.1373 16.3013Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.36016 21.6104L7.09214 21.6483L6.90261 21.7589L6.21704 21.7217L6.36016 21.6104ZM6.37541 21.6589L6.34696 21.681L6.89093 21.7105L6.93015 21.6876L6.37541 21.6589Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4061 8.01562L19.7521 8.13646L19.6355 8.94786L19.2896 8.81608L19.4061 8.01562ZM19.4449 8.07967L19.3422 8.78515L19.5968 8.88212L19.6994 8.16852L19.4449 8.07967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8599 13.4854L14.9448 13.9469L13.5924 14.243L13.5076 13.7815L14.8599 13.4854ZM13.5628 13.8182L13.6304 14.1859L14.8896 13.9102L14.822 13.5424L13.5628 13.8182Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9659 15.1262L14.1828 15.4787L12.609 15.8476L12.387 15.4902L13.9659 15.1262ZM12.4626 15.5217L12.6314 15.7934L14.1076 15.4474L13.9432 15.1803L12.4626 15.5217Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1442 8.00195L19.032 8.79274L18.6777 8.85363L18.7846 8.09049L19.1442 8.00195ZM18.8273 8.12905L18.734 8.7956L18.9897 8.75165L19.0872 8.06509L18.8273 8.12905Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.583 10.0398L20.7488 10.5265L20.1599 11.1966L19.999 10.7354L20.583 10.0398ZM20.0529 10.7453L20.178 11.1038L20.6948 10.5158L20.5651 10.1352L20.0529 10.7453Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0953 8.01343L19.453 8.02994L19.3363 8.83134L18.9839 8.7983L19.0953 8.01343ZM19.1364 8.06303L19.0381 8.75551L19.2957 8.77966L19.3982 8.07512L19.1364 8.06303Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8417 16.0437L15.1839 16.147L13.5773 16.5411L13.2375 16.4321L14.8417 16.0437ZM13.4136 16.4385L13.579 16.4916L15.0033 16.1423L14.8403 16.0931L13.4136 16.4385Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.844 9.43359L17.8822 9.46204L17.6526 9.80644L17.194 10.3318L17.1567 10.3023L17.3764 9.99658L17.844 9.43359Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.38477 4.66528L8.84481 5.81539L8.96208 6.39442L8.39787 5.1701L8.38477 4.66528ZM8.43931 4.92997L8.44526 5.15906L8.83419 6.00303L8.79896 5.82908L8.43931 4.92997Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8061 14.7732L13.9884 15.17L12.4113 15.5336L12.2239 15.1319L13.8061 14.7732ZM12.2921 15.1653L12.4382 15.4785L13.9207 15.1367L13.7789 14.8282L12.2921 15.1653Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.51704 5.21265L2.6178 6.00812L2.11176 6.92724L2.01099 6.13696L2.51704 5.21265ZM2.06022 6.14628L2.14054 6.77616L2.56857 5.99872L2.48823 5.36449L2.06022 6.14628Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0708 10.2801L10.1175 10.2715L10.2849 10.8598L10.4042 12.1439L10.3576 12.1528L10.1854 11.5596L10.0708 10.2801ZM10.1408 10.5277L10.2324 11.5508L10.3336 11.8992L10.2378 10.8686L10.1408 10.5277Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.88811 2.58643L7.45832 3.17178L7.29211 3.57928L6.62231 2.99574L6.88811 2.58643ZM6.89559 2.66241L6.68482 2.98699L7.27316 3.49956L7.40241 3.18268L6.89559 2.66241Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.65975 9.52344L1.88361 10.3573L1.73034 11.6152L1.50635 10.8088L1.65975 9.52344ZM1.67779 9.77444L1.55478 10.8051L1.71205 11.3713L1.83518 10.3607L1.67779 9.77444Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.54663 17.1047L2.59181 17.0896L2.95417 18.0956L3.02581 18.3249L2.98067 18.3401L2.63258 17.3817L2.54663 17.1047Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3493 12.0107L20.3599 12.5321L19.374 13.0576L19.3633 12.5613L20.3493 12.0107ZM19.4115 12.5889L19.4199 12.9791L20.3117 12.5038L20.3032 12.091L19.4115 12.5889Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0911 16.1206L15.5166 16.1367L13.8379 16.5497L13.4067 16.5338L15.0911 16.1206ZM15.096 16.1685L13.7495 16.4988L13.833 16.5019L15.1757 16.1715L15.096 16.1685Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.39415 2.46802L5.15972 3.05148L4.40039 3.14963L4.71288 2.54027L5.39415 2.46802ZM4.74353 2.58495L4.48414 3.09075L5.12591 3.0078L5.32039 2.52376L4.74353 2.58495Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0296 10.6318L16.857 11.1051L15.9976 11.5477L16.1657 11.1049L17.0296 10.6318ZM16.204 11.1383L16.0862 11.4484L16.8186 11.0713L16.9414 10.7344L16.204 11.1383Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3147 13.8335L19.1728 14.2808L17.8833 14.709L18.0146 14.2768L19.3147 13.8335ZM18.0531 14.314L17.9557 14.6348L19.1347 14.2433L19.2407 13.9091L18.0531 14.314Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6556 15.2422L17.3794 15.5368L15.8572 15.9313L16.1246 15.6515L17.6556 15.2422ZM16.1498 15.6941L16.0073 15.8432L17.354 15.4942L17.5067 15.3313L16.1498 15.6941Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.45406 19.3325L9.40755 19.8325L8.85547 20.5634L8.88454 20.0866L9.45406 19.3325ZM8.93123 20.1039L8.91265 20.4086L9.36135 19.8146L9.39109 19.495L8.93123 20.1039Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3354 15.1499L10.4235 15.9551L10.1586 17.1987L10.0703 16.4102L10.3354 15.1499ZM10.1185 16.4126L10.1732 16.9012L10.3753 15.9527L10.3205 15.4521L10.1185 16.4126Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9749 11.874L15.9161 12.3552L14.7847 12.6941L14.8383 12.2329L15.9749 11.874ZM14.8821 12.2691L14.8404 12.6277L15.8726 12.3185L15.9186 11.9418L14.8821 12.2691Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.94067 14.4471L1.98704 14.4365L2.13044 14.9193L2.31178 16.0264L2.26554 16.0374L2.12681 15.5973L1.94067 14.4471ZM2.04698 14.8057L2.1733 15.5863L2.21097 15.7058L2.08389 14.93L2.04698 14.8057Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41724 20.628L4.44471 20.5894L4.48015 20.6096L5.06336 21.1211L5.03312 21.1579L4.97059 21.1098L4.41724 20.628Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8242 13.0608L14.8665 13.5376L13.5138 13.8338L13.4714 13.357L14.8242 13.0608ZM13.5226 13.3946L13.5565 13.7756L14.8153 13.5L14.7815 13.1189L13.5226 13.3946Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.88965 19.9158L4.56681 20.7166L4.41264 20.6285L3.88965 20.0038V19.9158Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6798 14.3867L13.8235 14.8181L12.2425 15.1765L12.0935 14.7403L13.6798 14.3867ZM12.1558 14.7753L12.2737 15.1206L13.7617 14.7833L13.6481 14.4426L12.1558 14.7753Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3885 9.65771L20.6025 10.091L20.0211 10.7785L19.812 10.3657L20.3885 9.65771ZM19.8685 10.3718L20.0311 10.6928L20.5459 10.0841L20.3786 9.74533L19.8685 10.3718Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.93413 3.83423L8.43578 4.79144L8.44778 5.25363L7.85327 4.27321L7.93413 3.83423ZM7.956 3.97861L7.90342 4.26401L8.39548 5.07547L8.38842 4.80375L7.956 3.97861Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.42953 3.12793L7.9751 3.90082L7.89724 4.32349L7.26001 3.54352L7.42953 3.12793ZM7.44079 3.22651L7.31483 3.5353L7.86902 4.21362L7.92459 3.91191L7.44079 3.22651Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.81166 3.36353L3.7117 4.10008L3.02637 4.65561L3.15265 3.90319L3.81166 3.36353ZM3.19669 3.92872L3.09422 4.53927L3.66702 4.07495L3.74815 3.47713L3.19669 3.92872Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.107 9.15625L18.143 9.18746L17.6656 9.77939L17.4121 10.0329L17.377 10.0008L17.8444 9.43792L18.107 9.15625Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.97119 21.1102L4.99778 21.0708L5.57524 21.4096L5.67134 21.4769L5.64563 21.517L5.03433 21.1588L4.97119 21.1102Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.22241 7.1272L9.54969 7.78175L9.94033 9.19027L9.58127 8.55701L9.22241 7.1272ZM9.37477 7.53848L9.62592 8.53914L9.79107 8.83041L9.505 7.79893L9.37477 7.53848Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5835 16.0738L15.5838 16.0737L15.5949 16.1201L15.5945 16.1202L14.0885 16.4919L13.8359 16.5491L13.825 16.5027L15.336 16.1309L15.5835 16.0738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.41016 19.2181L3.45232 19.1963L3.94207 19.9741V20.0774L3.43477 19.2772L3.41016 19.2181Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.81754 21.4602L7.50058 21.6238L6.75195 21.7915L7.00221 21.6455L7.81754 21.4602Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2882 11.55L20.3517 12.064L19.3682 12.6132L19.3044 12.1193L20.2882 11.55ZM19.3558 12.1447L19.4064 12.5372L20.3005 12.038L20.2497 11.6274L19.3558 12.1447Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5865 13.9812L13.6926 14.4374L12.1067 14.7909L12.0007 14.3298L13.5865 13.9812ZM12.058 14.366L12.1426 14.7341L13.6353 14.4013L13.5508 14.0379L12.058 14.366Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.156 9.33276L20.4132 9.7026L19.8387 10.4081L19.5864 10.0485L20.156 9.33276ZM19.6459 10.0503L19.8416 10.3292L20.3536 9.70035L20.1534 9.41253L19.6459 10.0503Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8218 14.9661L17.5961 15.3075L16.0835 15.7119L16.2985 15.3806L17.8218 14.9661ZM16.3286 15.4218L16.191 15.6338L17.5663 15.2661L17.7124 15.0452L16.3286 15.4218Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2294 10.26L17.0033 10.7006L16.1267 11.1806L16.3433 10.7708L17.2294 10.26ZM16.379 10.8052L16.243 11.0626L16.9676 10.6658L17.1133 10.382L16.379 10.8052Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8407 16.0613L13.1229 16.304L11.5036 16.7236L11.2117 16.471L12.8407 16.0613ZM11.3121 16.4949L11.5158 16.6712L13.0227 16.2807L12.8284 16.1135L11.3121 16.4949Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3948 13.4204L19.3032 13.8943L18.0076 14.3361L18.094 13.8821L19.3948 13.4204ZM18.1357 13.9179L18.0697 14.2646L19.2617 13.8581L19.3322 13.4932L18.1357 13.9179Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6242 15.7986L12.8773 16.1012L11.2533 16.5096L10.9902 16.1972L12.6242 15.7986ZM11.0762 16.2253L11.2706 16.4562L12.792 16.0735L12.6066 15.8519L11.0762 16.2253Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5348 8.83667L17.9359 9.6L17.5317 9.86946L18.1078 9.15514L18.5348 8.83667ZM18.1411 9.18975L17.764 9.65731L17.9031 9.56458L18.2764 9.08885L18.1411 9.18975Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8318 12.636L14.8265 13.1091L13.4729 13.4055L13.4782 12.9373L14.8318 12.636ZM13.5254 12.9756L13.5212 13.3462L14.7792 13.0707L14.7835 12.6956L13.5254 12.9756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0753 11.4668L15.9618 11.9313L14.8223 12.2912L14.9306 11.8417L16.0753 11.4668ZM14.9707 11.8787L14.8884 12.2203L15.9219 11.8939L16.0086 11.5388L14.9707 11.8787Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.41309 21.3528L6.15372 21.5936L6.46703 21.7839L5.64209 21.5131L5.41309 21.3528Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0764 16.2715L13.3828 16.4482L11.7637 16.8753L11.4473 16.6937L13.0764 16.2715ZM11.5723 16.7105L11.7705 16.8243L13.2587 16.4317L13.0694 16.3225L11.5723 16.7105Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.70047 11.3381L1.92606 12.1336L1.899 13.4326L1.67334 12.6785L1.70047 11.3381ZM1.74166 11.6581L1.72114 12.672L1.85767 13.1283L1.87826 12.1398L1.74166 11.6581Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4313 15.479L12.6531 15.836L11.0222 16.2339L10.7905 15.8622L12.4313 15.479ZM10.8662 15.8935L11.0444 16.1794L12.5779 15.8053L12.4089 15.5332L10.8662 15.8935Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0021 15.9094L15.5977 16.124L13.9131 16.5398L14.308 16.3222L16.0021 15.9094ZM14.3254 16.367L14.2533 16.4068L15.5806 16.0792L15.6432 16.0459L14.3254 16.367Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3566 12.1461L10.4041 12.1438L10.4757 12.8456L10.471 14.1002L10.4234 14.1026L10.3518 13.4055L10.3566 12.1461ZM10.4025 12.5977L10.3995 13.4032L10.425 13.6517L10.4281 12.8479L10.4025 12.5977Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.13737 6.76611L2.313 7.60167L1.92021 8.65599L1.73926 7.83637L2.13737 6.76611ZM1.78882 7.83982L1.93193 8.48802L2.26352 7.59796L2.12461 6.9371L1.78882 7.83982Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.95003 20.396L8.78924 20.7578L8.16724 21.3034L8.3016 20.9675L8.95003 20.396ZM8.34163 20.9957L8.28537 21.1364L8.74994 20.7289L8.81789 20.576L8.34163 20.9957Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8953 9.07422L20.1865 9.37083L19.6179 10.0853L19.3369 9.79353L19.8953 9.07422ZM19.8995 9.14652L19.3998 9.79018L19.6145 10.0131L20.1229 9.37414L19.8995 9.14652Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3212 16.4153L13.6659 16.5174L12.0337 16.9557L11.6799 16.8483L13.3212 16.4153ZM11.8546 16.8515L12.0345 16.9061L13.4905 16.5151L13.3205 16.4648L11.8546 16.8515Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1797 16.9226L10.2091 17.6206L9.83407 18.7027L9.79883 18.0331L10.1797 16.9226ZM9.8469 18.0398L9.86885 18.4568L10.1611 17.6136L10.1427 17.1774L9.8469 18.0398Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.2605 16.0315L2.30634 16.0186L2.40656 16.3478L2.67355 17.3681L2.62774 17.3812L2.54181 17.1043L2.2605 16.0315Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7821 8.76929L18.225 9.49254L17.8481 9.63462L18.3905 8.94334L18.7821 8.76929ZM18.4207 8.98207L17.9909 9.52986L18.1955 9.45272L18.6296 8.88925L18.4207 8.98207Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.98145 18.3418L3.02459 18.3215L3.44902 19.199L3.4731 19.2568L3.42965 19.2763L3.0291 18.4466L2.98145 18.3418Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2667 15.126L12.4541 15.5274L10.815 15.9101L10.6177 15.4941L12.2667 15.126ZM10.6863 15.5276L10.8416 15.855L12.386 15.4943L12.2398 15.1808L10.6863 15.5276Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5268 13.5615L13.5904 14.0281L12.0046 14.3767L11.9358 13.9003L13.5268 13.5615ZM11.9893 13.9377L12.0444 14.3191L13.5372 13.991L13.4865 13.6188L11.9893 13.9377Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.34262 2.46826L6.03012 2.61595L5.87982 3.1757L5.10962 3.04819L5.34262 2.46826ZM5.37187 2.52329L5.17597 3.01087L5.845 3.12163L5.9711 2.65201L5.37187 2.52329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1897 11.1128L20.3009 11.6053L19.3188 12.1736L19.2126 11.701L20.1897 11.1128ZM19.2667 11.7241L19.3511 12.0998L20.2467 11.5816L20.1578 11.1877L19.2667 11.7241Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6111 8.88745L19.9319 9.10493L19.3735 9.82428L19.0579 9.60662L19.6111 8.88745ZM19.6215 8.95208L19.1262 9.59589L19.3634 9.75948L19.863 9.11586L19.6215 8.95208Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4827 9.93115L17.1878 10.3391L16.2725 10.8667L16.5595 10.484L17.4827 9.93115ZM16.592 10.5201L16.4501 10.7093L17.1551 10.3029L17.3081 10.0912L16.592 10.5201Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0667 8.74316L18.5187 9.46188L18.1572 9.50269L18.6928 8.80743L19.0667 8.74316ZM18.7192 8.85125L18.2636 9.44272L18.4931 9.4168L18.9553 8.81067L18.7192 8.85125Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5755 16.4924L13.9939 16.5079L12.299 16.9645L11.875 16.949L13.5755 16.4924ZM13.5809 16.5403L12.1933 16.9129L12.2935 16.9166L13.6772 16.5439L13.5809 16.5403Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9624 14.6306L17.784 15.0209L16.2715 15.4325L16.4392 15.0524L17.9624 14.6306ZM16.4737 15.0923L16.3554 15.3603L17.75 14.9808L17.8765 14.7038L16.4737 15.0923Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3076 8.7771L19.6548 8.90388L19.0999 9.62534L18.7632 9.49287L19.3076 8.7771ZM19.3242 8.83392L18.8392 9.47158L19.084 9.56787L19.5774 8.92635L19.3242 8.83392Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0048 8.75195L19.3656 8.78579L18.8165 9.50782L18.467 9.4571L19.0048 8.75195ZM19.0266 8.80187L18.5541 9.42157L18.7956 9.45663L19.2758 8.82523L19.0266 8.80187Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4287 12.9832L19.386 13.4741L18.0884 13.9348L18.1258 13.4587L19.4287 12.9832ZM18.1709 13.493L18.1416 13.8653L19.3412 13.4395L19.3748 13.0536L18.1709 13.493Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8876 12.2156L14.8342 12.6805L13.4795 12.9821L13.5277 12.5271L14.8876 12.2156ZM13.5715 12.566L13.5339 12.9212L14.7907 12.6414L14.8326 12.2771L13.5715 12.566Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2246 11.0876L16.0652 11.5219L14.9165 11.8981L15.0656 11.484L16.2246 11.0876ZM15.1027 11.5217L14.9944 11.8225L16.0284 11.4838L16.1454 11.1651L15.1027 11.5217Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1318 15.7407L15.8386 15.9948L14.2314 16.3864L14.5246 16.1323L16.1318 15.7407ZM14.5471 16.1759L14.4114 16.2935L15.8161 15.9512L15.9518 15.8336L14.5471 16.1759Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1403 14.7349L12.2891 15.1708L10.6411 15.5387L10.4873 15.088L12.1403 14.7349ZM10.5497 15.1234L10.6724 15.4829L12.2268 15.1359L12.1088 14.7903L10.5497 15.1234Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.88062 6.20184L8.9252 6.18506L9.11122 6.64297L9.54966 7.78196L9.50611 7.80117L9.27667 7.34229L8.88062 6.20184ZM9.30653 7.283L9.32068 7.32374L9.32734 7.33707L9.30653 7.283Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.8374 9.01456L9.88387 9.00415L10.0414 9.5912L10.2798 10.8598L10.2335 10.8707L10.0664 10.2834L9.8374 9.01456ZM9.98549 9.56683L10.1128 10.2726L10.1368 10.3566L9.99487 9.60179L9.98549 9.56683Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.05444 21.5911L6.73609 21.6302L7.01347 21.7649L6.27474 21.7248L6.05444 21.5911ZM6.24268 21.6496L6.28926 21.6779L6.78006 21.7045L6.72384 21.6772L6.24268 21.6496Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.83252 13.0417L2.04499 13.7957L2.142 15.1367L1.93587 14.4427L1.83252 13.0417ZM1.91481 13.5094L1.98302 14.4341L2.06283 14.7028L1.99781 13.8039L1.91481 13.5094Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5199 13.137L13.5358 13.6094L11.9444 13.9483L11.9285 13.471L13.5199 13.137ZM11.9774 13.5094L11.9901 13.8898L13.4868 13.5711L13.4742 13.1953L11.9774 13.5094Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.078 16.4407L14.0896 16.4869L12.5593 16.9016L12.3014 16.959L12.29 16.9127L13.8298 16.498L14.078 16.4407Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.07211 4.5437L3.17097 5.32419L2.57664 6.06197L2.47778 5.28148L3.07211 4.5437ZM2.52761 5.2956L2.60984 5.9448L3.12114 5.31008L3.03891 4.66088L2.52761 5.2956Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0337 10.6997L20.198 11.1556L19.2224 11.743L19.063 11.302L20.0337 10.6997ZM19.1209 11.3222L19.2475 11.6723L20.1399 11.135L20.0088 10.7712L19.1209 11.3222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36816 20.3896L4.94424 20.8917L5.03613 21.1674L4.41092 20.6177L4.36816 20.3896ZM4.44029 20.5157L4.45473 20.5927L4.93514 21.0152L4.90313 20.9191L4.44029 20.5157Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.21995 21.1941L8.24221 21.2362L7.65583 21.5461L7.50825 21.6223L7.5081 21.6224L7.48608 21.5801L7.48624 21.58L8.21995 21.1941Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2679 16.4565L11.5585 16.708L9.98886 17.1671L9.67773 16.906L11.2679 16.4565ZM9.77733 16.9273L9.99996 17.1142L11.4612 16.6868L11.2562 16.5094L9.77733 16.9273Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0466 16.1748L11.3084 16.4912L9.72372 16.939L9.44727 16.6029L11.0466 16.1748ZM9.5311 16.6298L9.74073 16.8847L11.2251 16.4652L11.0294 16.2287L9.5311 16.6298Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.90176 19.8135L4.42661 20.4422L4.47314 20.6904L3.89404 19.9987L3.90176 19.8135ZM3.94424 19.9387L3.94243 19.9823L4.39272 20.5201L4.38206 20.4632L3.94424 19.9387Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8195 9.62378L17.4101 10.0255L16.3884 10.6373L16.7995 10.2591L17.8195 9.62378ZM16.8285 10.2972L16.763 10.3574L17.3807 9.98751L17.4765 9.8936L16.8285 10.2972Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46984 3.08154L4.40273 3.79392L3.65991 4.12691L3.758 3.40417L4.46984 3.08154ZM3.80168 3.4367L3.71867 4.04834L4.35789 3.7618L4.41468 3.15886L3.80168 3.4367Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5132 16.6716L11.8261 16.8571L10.2629 17.3285L9.9353 17.1331L11.5132 16.6716ZM10.0536 17.1481L10.2693 17.2768L11.7085 16.8428L11.5067 16.7232L10.0536 17.1481Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.89066 18.4512L9.822 19.0254L9.34912 19.944L9.39907 19.407L9.89066 18.4512ZM9.44566 19.4206L9.41936 19.7034L9.7757 19.0112L9.81189 18.7085L9.44566 19.4206Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.92513 8.46655L2.13645 9.30101L1.86569 10.457L1.64893 9.64953L1.92513 8.46655ZM1.92701 8.6681L1.69805 9.64872L1.86254 10.2614L2.0874 9.30145L1.92701 8.6681Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.63281 17.3822L2.67715 17.3647L2.74411 17.5226L3.07316 18.4287L3.02907 18.4466L2.98102 18.341L2.63281 17.3822Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0425 14.3198L12.1484 14.7807L10.4961 15.1337L10.3799 14.6582L12.0425 14.3198ZM10.4379 14.695L10.5314 15.0774L12.0911 14.7442L12.0064 14.3758L10.4379 14.695Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.844 15.8503L11.0751 16.2213L9.47809 16.6488L9.2373 16.2582L10.844 15.8503ZM9.31192 16.2884L9.50004 16.5936L11.0007 16.1919L10.822 15.9051L9.31192 16.2884Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87622 20.8523L5.47584 21.2078L5.61371 21.4901L4.96121 21.1073L4.87622 20.8523ZM4.96185 20.9585L5.00077 21.0752L5.50155 21.3691L5.43927 21.2415L4.96185 20.9585Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0697 14.2654L17.9386 14.6914L16.4219 15.1113L16.5423 14.6954L18.0697 14.2654ZM16.5807 14.7341L16.4914 15.0426L17.9007 14.6524L17.9984 14.335L16.5807 14.7341Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4152 12.541L19.4258 13.0349L18.1251 13.5097L18.1145 13.0306L19.4152 12.541ZM18.1629 13.0633L18.1713 13.4421L19.3774 13.0018L19.369 12.6093L18.1629 13.0633Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.99035 2.60352L6.68424 2.97832L6.61103 3.52222L5.83911 3.16673L5.99035 2.60352ZM6.02074 2.6741L5.89556 3.14026L6.5724 3.45196L6.63262 3.0046L6.02074 2.6741Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2878 15.5051L16.0509 15.8131L14.4707 16.1982L14.7129 15.8851L16.2878 15.5051ZM14.7403 15.9275L14.5917 16.1196L16.0234 15.7708L16.1677 15.5831L14.7403 15.9275Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.448 13.8679L10.5377 14.772L10.3904 16.1775L10.2944 15.301L10.448 13.8679ZM10.3424 15.3009L10.3894 15.7302L10.4898 14.7719L10.4462 14.3321L10.3424 15.3009Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7673 16.8203L12.1153 16.926L10.5471 17.4135L10.186 17.2972L11.7673 16.8203ZM10.3461 17.2987L10.5474 17.3635L11.953 16.9265L11.7672 16.8701L10.3461 17.2987Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.411 10.74L16.2015 11.1364L15.0342 11.5356L15.2341 11.1643L16.411 10.74ZM15.2674 11.2029L15.1338 11.4512L16.1682 11.0974L16.3113 10.8266L15.2674 11.2029Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9824 11.8284L14.8799 12.2709L13.5181 12.5829L13.6153 12.1454L14.9824 11.8284ZM13.6553 12.185L13.581 12.5196L14.8402 12.2311L14.9188 11.8921L13.6553 12.185Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.48597 19.1499L3.94699 19.8744L3.93876 20.0719L3.42871 19.2726L3.48597 19.1499ZM3.49242 19.2488L3.48297 19.269L3.89745 19.9186L3.89875 19.8873L3.49242 19.2488Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5146 16.262L14.092 16.4862L12.3535 16.9574L12.7877 16.7275L14.5146 16.262ZM12.8052 16.7721L12.7533 16.7996L14.0744 16.4416L14.1188 16.418L12.8052 16.7721Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6652 15.4832L10.8622 15.8985L9.25687 16.3059L9.04492 15.8661L10.6652 15.4832ZM9.1136 15.8989L9.2829 16.2502L10.7943 15.8665L10.6387 15.5384L9.1136 15.8989Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8498 10.3296L20.0571 10.7443L19.0872 11.3462L18.8848 10.9414L19.8498 10.3296ZM18.9466 10.9586L19.1064 11.2782L19.9951 10.7268L19.8307 10.3981L18.9466 10.9586Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.40356 5.16648L8.4481 5.1499L8.92489 6.18454L9.11096 6.64255L9.06769 6.66241L8.53764 5.63097L8.40356 5.16648ZM8.53683 5.45631L8.58215 5.61331L8.84395 6.12276L8.53683 5.45631Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1674 10.0908L17.0885 10.1411L16.8265 10.3031L16.8264 10.3032L16.8013 10.2627L16.8013 10.2626L17.142 10.0504L17.9067 9.56226L17.9327 9.60217L17.6608 9.78346L17.1674 10.0908Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5418 12.7219L13.5151 13.1857L11.9231 13.5198L11.9444 13.0512L13.5418 12.7219ZM11.9903 13.0904L11.9735 13.4606L13.4696 13.1466L13.4906 12.7811L11.9903 13.0904Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.07886 14.9284L2.12527 14.918L2.23527 15.3006L2.40706 16.3552L2.36075 16.3659L2.26021 16.0356L2.07886 14.9284Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.4209 21.1848L6.03065 21.381L6.20227 21.6629L5.54995 21.4491L5.4209 21.1848ZM5.51283 21.2645L5.58394 21.4101L6.09443 21.5774L5.99917 21.4209L5.51283 21.2645Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0356 16.907L12.4403 16.9217L10.8316 17.4267L10.4106 17.4122L12.0356 16.907ZM12.042 16.9549L10.6932 17.3742L10.8251 17.3788L12.1615 16.9592L12.042 16.9549Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9924 13.886L12.0558 14.362L10.3933 14.7003L10.3301 14.2047L11.9924 13.886ZM10.383 14.2431L10.4341 14.6434L12.0027 14.3241L11.9518 13.9423L10.383 14.2431Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.29397 21.5002L7.57585 21.6076L6.89358 21.755L6.65771 21.6404L7.29397 21.5002ZM6.80187 21.6575L6.8996 21.7049L7.40874 21.595L7.29031 21.5498L6.80187 21.6575Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5341 15.0779L10.6877 15.528L9.0685 15.9107L8.90503 15.4361L10.5341 15.0779ZM8.96751 15.4711L9.09951 15.8544L10.6256 15.4937L10.5028 15.1336L8.96751 15.4711Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6274 10.0166L19.8738 10.3754L18.9089 10.9872L18.6726 10.6382L19.6274 10.0166ZM18.7393 10.6517L18.9224 10.9222L19.8065 10.3617L19.6145 10.0819L18.7393 10.6517Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.636 2.9397L7.30649 3.52384L7.31185 4.06459L6.56152 3.49291L6.636 2.9397ZM6.67142 3.03376L6.61245 3.4718L7.26323 3.96763L7.25905 3.54572L6.67142 3.03376Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.50144 19.6223L9.26652 20.1173L8.6626 20.9205L8.86317 20.4693L9.50144 19.6223ZM8.90451 20.4936L8.87633 20.557L9.22554 20.0925L9.25749 20.0252L8.90451 20.4936Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4078 9.3728L17.3629 10.052L16.9045 10.2021L17.909 9.56089L18.4078 9.3728ZM17.9304 9.60373L17.2478 10.0395L17.3422 10.0086L18.013 9.57261L17.9304 9.60373Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.355 12.1033L19.4132 12.5907L18.1141 13.0798L18.0557 12.6072L19.355 12.1033ZM18.1075 12.6382L18.1539 13.0139L19.3615 12.5593L19.3149 12.1699L18.1075 12.6382Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.12032 18.3887L3.51087 19.1945L3.45149 19.3217L3.01758 18.4229L3.12032 18.3887ZM3.08405 18.451L3.45076 19.2106L3.4581 19.1949L3.09568 18.4471L3.08405 18.451Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1456 13.8665L18.0593 14.3192L16.5359 14.7481L16.6168 14.3004L18.1456 13.8665ZM16.6584 14.3382L16.5963 14.6816L18.018 14.2813L18.0843 13.9334L16.6584 14.3382Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.73763 16.8896L10.0409 17.1544L8.60235 17.6537L8.27905 17.3742L9.73763 16.8896ZM8.37366 17.393L8.61271 17.5996L9.94773 17.1363L9.72681 16.9435L8.37366 17.393Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49248 16.5938L9.76817 16.9235L8.31366 17.4067L8.01831 17.0523L9.49248 16.5938ZM8.10061 17.0766L8.32947 17.3512L9.68669 16.9003L9.47629 16.6487L8.10061 17.0766Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.85092 10.2375L2.05999 11.0343L1.91171 12.2523L1.69702 11.4953L1.85092 10.2375ZM1.868 10.4904L1.74549 11.4915L1.893 12.0117L2.01158 11.0376L1.868 10.4904Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6427 10.4568L16.3807 10.8081L15.1902 11.2373L15.4359 10.9017L16.6427 10.4568ZM15.4659 10.9414L15.3208 11.1395L16.3511 10.7681L16.5085 10.5571L15.4659 10.9414Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.84155 4.15479L8.44839 5.1502L8.62391 5.75828L7.93185 4.7447L7.84155 4.15479ZM7.92464 4.38263L7.97731 4.72678L8.49572 5.48602L8.40437 5.16956L7.92464 4.38263Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4314 15.2083L16.239 15.5647L14.676 15.9418L14.868 15.5805L16.4314 15.2083ZM14.8999 15.6219L14.7678 15.8706L16.2072 15.5233L16.3389 15.2793L14.8999 15.6219Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2375 10.8713L10.285 10.8665L10.3612 11.5721L10.4804 12.8491L10.4804 12.8493L10.433 12.8539L10.4329 12.8537L10.3615 12.1534L10.3615 12.1533L10.2375 10.8713Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99682 17.1145L10.3265 17.3078L8.90062 17.8245L8.55078 17.6214L9.99682 17.1145ZM8.6642 17.6321L8.9053 17.7721L10.2149 17.2976L9.99176 17.1668L8.6642 17.6321Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6266 16.1055L14.3333 16.3662L12.7129 16.803L13.0089 16.5328L14.6266 16.1055ZM13.0324 16.5759L12.8899 16.7059L14.31 16.3231L14.4456 16.2026L13.0324 16.5759Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1219 11.4692L14.9684 11.8805L13.5981 12.1982L13.741 11.7971L15.1219 11.4692ZM13.7772 11.8375L13.6723 12.1321L14.9327 11.8398L15.046 11.5362L13.7772 11.8375Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.2583 3.4668L7.90108 4.25871L7.98536 4.80934L7.26383 4.02552L7.2583 3.4668ZM7.30731 3.60279L7.3113 4.00673L7.91462 4.66213L7.85593 4.2787L7.30731 3.60279Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5479 16.8601L12.5606 16.906L11.1063 17.3637L10.8291 17.4307L10.8164 17.3848L12.2898 16.9222L12.5479 16.8601Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27593 16.2454L9.52123 16.6346L8.04924 17.0925L7.78418 16.6737L9.27593 16.2454ZM7.85846 16.7019L8.06997 17.0361L9.44783 16.6076L9.25469 16.301L7.85846 16.7019Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.367 9.76123L19.6537 10.0533L18.6976 10.6758L18.4214 10.3833L19.367 9.76123ZM18.495 10.3919L18.7049 10.6141L19.5789 10.0452L19.3604 9.82259L18.495 10.3919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.60376 5.93481L2.77083 6.76492L2.27336 7.66753L2.10107 6.84786L2.60376 5.93481ZM2.15135 6.85536L2.29359 7.53209L2.72066 6.7572L2.58273 6.07183L2.15135 6.85536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6064 9.39966L17.6462 10.0346L17.27 10.0537L18.2068 9.44477L18.6064 9.39966ZM18.2234 9.49086L17.4445 9.9971L17.6307 9.98763L18.4147 9.46926L18.2234 9.49086Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.49951 7.79614L9.54557 7.78394L9.88877 9.00896L10.046 9.59513L10.0001 9.60792L9.64735 8.3875L9.49951 7.79614Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.3606 16.3604L2.40594 16.3459L2.48739 16.5616L2.74029 17.5254L2.69531 17.5408L2.62791 17.3819L2.3606 16.3604Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.4092 20.1331L4.93292 20.5894L4.94448 20.9594L4.37451 20.4626L4.4092 20.1331ZM4.447 20.2292L4.4245 20.4429L4.89344 20.8517L4.88594 20.6116L4.447 20.2292Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2654 17.2776L10.6212 17.3861L9.20399 17.9188L8.82373 17.8L10.2654 17.2776ZM10.2667 17.3278L8.97282 17.7967L9.20257 17.8684L10.4732 17.3908L10.2667 17.3278Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6168 12.321L13.5416 12.7668L11.9429 13.0964L12.0179 12.6408L13.6168 12.321ZM12.0596 12.6811L12.0012 13.0357L13.5001 12.7267L13.5583 12.3813L12.0596 12.6811Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4122 15.7573L10.4376 16.5469L10.1691 17.8058L10.1372 17.0479L10.4122 15.7573ZM10.1851 17.0519L10.2009 17.4282L10.3898 16.5426L10.3772 16.1503L10.1851 17.0519Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.97385 19.5972L4.45765 20.1736L4.4221 20.5113L3.89673 19.8819L3.97385 19.5972ZM3.996 19.6977L3.94921 19.8704L4.3865 20.3943L4.40814 20.1887L3.996 19.6977Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9708 13.4619L11.992 13.9386L10.3294 14.2574L10.303 13.7611L11.9708 13.4619ZM10.3528 13.8006L10.3741 14.2003L11.9426 13.8996L11.9256 13.5184L10.3528 13.8006Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4267 14.644L10.5428 15.1188L8.91413 15.4769L8.78809 14.9727L10.4267 14.644ZM8.84643 15.0096L8.94913 15.4205L10.4849 15.0828L10.3913 14.6998L8.84643 15.0096Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.097 9.57251L19.4106 9.79263L18.4621 10.4167L18.1589 10.1962L19.097 9.57251ZM18.2424 10.1979L18.4634 10.3587L19.3258 9.79136L19.0962 9.63022L18.2424 10.1979Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5001 9.41455L18.8662 9.46769L17.9247 10.0937L17.571 10.0288L18.5001 9.41455ZM18.5112 9.46432L17.6961 10.0033L17.9143 10.0433L18.7361 9.49696L18.5112 9.46432Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.96655 21.3796L6.57049 21.4112L6.78084 21.6799L6.13002 21.6482L5.96655 21.3796ZM6.05417 21.4319L6.15758 21.6018L6.6791 21.6273L6.54633 21.4576L6.05417 21.4319Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8033 9.45068L19.1472 9.59165L18.2028 10.2196L17.8757 10.0674L18.8033 9.45068ZM18.8086 9.50437L17.9729 10.06L18.1986 10.1651L19.0461 9.60169L18.8086 9.50437Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.09712 15.8542L9.30332 16.2931L7.81315 16.721L7.58691 16.2527L9.09712 15.8542ZM7.65498 16.284L7.83859 16.6641L9.2364 16.2627L9.07088 15.9105L7.65498 16.284Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.88892 20.5627L5.4364 20.886L5.49292 21.2704L4.90021 20.9242L4.88892 20.5627ZM4.93925 20.6478L4.94702 20.8964L5.43135 21.1792L5.39256 20.9155L4.93925 20.6478Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.1681 3.0022L5.13224 3.69365L4.35376 3.80559L4.42034 3.09885L5.1681 3.0022ZM4.46421 3.14123L4.40688 3.7498L5.08668 3.65205L5.11755 3.05678L4.46421 3.14123Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.95261 20.6113L8.23982 21.2303L7.88794 21.416L8.55564 20.8287L8.95261 20.6113ZM8.58315 20.868L8.48752 20.9521L8.58649 20.8662L8.58315 20.868Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2518 11.6755L19.3629 12.1467L18.0646 12.6503L17.9585 12.1889L19.2518 11.6755ZM18.0141 12.2181L18.0987 12.5859L19.307 12.1173L19.218 11.7402L18.0141 12.2181Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9183 10.2175L16.5884 10.5276L15.342 10.9871L15.667 10.6959L16.9183 10.2175ZM15.6923 10.7373L15.5598 10.856L16.5628 10.4863L16.7095 10.3484L15.6923 10.7373Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.57727 18.9653L4.01064 19.6434L3.93182 19.9345L3.46191 19.1961L3.57727 18.9653ZM3.58234 19.0618L3.51661 19.1932L3.91405 19.8178L3.95913 19.6513L3.58234 19.0618Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1749 13.4482L18.1375 13.9184L16.6116 14.3515L16.6436 13.8864L18.1749 13.4482ZM16.6889 13.9231L16.6638 14.2872L18.0926 13.8816L18.1219 13.513L16.6889 13.9231Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5435 17.3643L10.9427 17.3846L9.50742 17.9318L9.08691 17.9118L10.5435 17.3643ZM10.551 17.4124L9.31978 17.8752L9.49974 17.8837L10.7142 17.4207L10.551 17.4124Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.87735 11.9583L2.06921 12.7009L2.04185 13.9647L1.84985 13.2834L1.87735 11.9583ZM1.91781 12.3054L1.89765 13.2773L2.00112 13.6445L2.02142 12.7065L1.91781 12.3054Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3666 17.2247L11.329 17.2453L12.549 16.8613L12.5644 16.8531L11.3666 17.2247ZM12.7942 16.7319L12.8124 16.7758L12.5674 16.9054L11.1119 17.3636L11.0933 17.32L11.3479 17.1806L12.7942 16.7319Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.32622 17.3513L8.64663 17.6337L7.40677 18.1693L7.06177 17.8621L8.32622 17.3513ZM7.15077 17.8775L7.41577 18.1135L8.55873 17.6198L8.31679 17.4065L7.15077 17.8775Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5518 14.8674L16.403 15.2642L14.8462 15.6349L14.9947 15.2332L16.5518 14.8674ZM15.0306 15.2738L14.9218 15.5679L16.3673 15.2237L16.4759 14.9342L15.0306 15.2738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.783 15.8657L14.5451 16.175L12.9536 16.5954L13.2021 16.276L14.783 15.8657ZM13.2297 16.318L13.0778 16.5133L14.5173 16.1331L14.6606 15.9467L13.2297 16.318Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.07171 17.041L8.36557 17.3936L7.09911 17.9032L6.78052 17.5209L8.07171 17.041ZM6.86023 17.5421L7.11351 17.846L8.28706 17.3739L8.0567 17.0974L6.86023 17.5421Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.6748 17.4851L2.81729 17.5522L3.13883 18.4327L3.03648 18.4668L2.6748 17.4851ZM2.75972 17.5777L3.06523 18.407L3.07725 18.403L2.77926 17.5869L2.75972 17.5777Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2968 11.1526L15.1001 11.5235L13.7144 11.8525L13.9004 11.4917L15.2968 11.1526ZM13.9328 11.5329L13.8042 11.7822L15.0681 11.4821L15.2051 11.2239L13.9328 11.5329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.71263 4.04199L3.80506 4.81743L3.13249 5.35753L3.03491 4.58722L3.71263 4.04199ZM3.08554 4.60766L3.16906 5.26704L3.75461 4.79682L3.67549 4.13304L3.08554 4.60766Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.60905 17.6047L8.95491 17.8056L7.734 18.3587L7.36328 18.1429L8.60905 17.6047ZM7.46885 18.1492L7.73673 18.3051L8.85098 17.8003L8.6059 17.658L7.46885 18.1492Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.94681 15.4207L9.11516 15.8942L7.60578 16.2924L7.42773 15.7844L8.94681 15.4207ZM7.49017 15.8185L7.63619 16.2351L9.0529 15.8613L8.91626 15.477L7.49017 15.8185Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3855 20.8665L5.94439 21.044L6.044 21.437L5.44086 21.2429L5.3855 20.8665ZM5.44374 20.935L5.48369 21.2066L5.97665 21.3652L5.9047 21.0814L5.44374 20.935Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.83623 16.6641L8.09508 17.0814L6.80562 17.5606L6.5271 17.1087L7.83623 16.6641ZM6.59904 17.1346L6.82568 17.5023L8.02388 17.057L7.8157 16.7214L6.59904 17.1346Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.372 14.2009L10.4405 14.691L8.80198 15.0196L8.72852 14.5002L10.372 14.2009ZM8.78212 14.5389L8.8421 14.963L10.3871 14.6531L10.3316 14.2567L8.78212 14.5389Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.82283 21.2231L8.12043 21.2975L7.49394 21.632L7.23438 21.5331L7.82283 21.2231ZM7.82894 21.2738L7.34989 21.5261L7.49064 21.5798L7.98885 21.3138L7.82894 21.2738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9976 13.0422L11.971 13.5103L10.3025 13.8096L10.329 13.3269L11.9976 13.0422ZM10.3745 13.3675L10.3534 13.7521L11.9255 13.47L11.9466 13.0993L10.3745 13.3675Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7262 11.958L13.6066 12.3764L12.0054 12.6966L12.1248 12.2734L13.7262 11.958ZM12.1627 12.3145L12.0724 12.6346L13.5688 12.3354L13.6589 12.0198L12.1627 12.3145Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.106 11.281L19.2653 11.7215L17.9722 12.2348L17.8181 11.7991L19.106 11.281ZM17.8783 11.8263L18.0006 12.1722L19.2047 11.6943L19.078 11.3437L17.8783 11.8263Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.90156 17.7727L9.26828 17.8891L8.06601 18.4576L7.66919 18.331L8.90156 17.7727ZM8.90457 17.8237L7.80177 18.3233L8.06261 18.4065L9.13831 17.8979L8.90457 17.8237Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5757 10.3437L16.8036 10.2566L16.8167 10.2487L16.5757 10.3437ZM17.0674 10.0986L17.0884 10.1412L16.8245 10.2996L15.6883 10.734L15.6682 10.6909L15.9174 10.552L17.0674 10.0986Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2395 17.387L10.1463 18.0898L9.74902 19.2348L9.82797 18.5745L10.2395 17.387ZM9.87468 18.5853L9.84878 18.8019L10.0997 18.0788L10.1303 17.8475L9.87468 18.5853Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0899 17.3176L11.1038 17.3631L9.80113 17.8641L9.50397 17.9312L9.49023 17.8857L10.8166 17.38L11.0899 17.3176Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.55565 19.7734L5.03604 20.1918L4.92293 20.6443L4.40479 20.1883L4.55565 19.7734ZM4.5767 19.855L4.46069 20.174L4.89567 20.5568L4.98272 20.2086L4.5767 19.855Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.22859 18.2522L3.60172 19.0136L3.48445 19.2481L3.07471 18.4184L3.22859 18.2522ZM3.13196 18.4267L3.48472 19.141L3.54855 19.0134L3.21636 18.3355L3.13196 18.4267Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99756 13.8064L2.04436 13.7986L2.15931 14.2344L2.25203 15.5267L2.07875 14.924L1.99756 13.8064ZM2.06367 14.0586L2.12593 14.9156L2.17187 15.0754L2.11209 14.2423L2.06367 14.0586Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.14221 19.2808L4.58864 19.8093L4.43653 20.2276L3.94678 19.6553L4.14221 19.2808ZM4.15162 19.3658L4.00393 19.6488L4.41965 20.1346L4.53442 19.819L4.15162 19.3658Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.29352 7.51733L2.4894 8.35909L2.10758 9.38586L1.90112 8.5706L2.29352 7.51733ZM1.95097 8.57331L2.11623 9.22588L2.43973 8.35592L2.28295 7.6822L1.95097 8.57331Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.63374 16.2397L7.85408 16.7067L6.54581 17.151L6.30566 16.6446L7.63374 16.2397ZM6.37239 16.6741L6.57068 17.0922L7.78819 16.6787L7.60822 16.2973L6.37239 16.6741Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.10488 17.8459L7.45266 18.1456L6.45055 18.704L6.06763 18.3795L7.10488 17.8459ZM6.15286 18.3893L6.45618 18.6463L7.36987 18.1371L7.09811 17.903L6.15286 18.3893Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1205 16.4988L12.8084 16.771L11.25 17.2545L11.5791 16.9715L13.1205 16.4988ZM11.6026 17.0141L11.4551 17.141L12.7847 16.7285L12.9206 16.6099L11.6026 17.0141Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.82707 17.4973L7.14382 17.8774L6.10904 18.4097L5.76758 17.9947L6.82707 17.4973ZM5.84334 18.0118L6.12142 18.3498L7.06914 17.8622L6.81401 17.5561L5.84334 18.0118Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.428 12.8467L10.4755 12.8442L10.5471 13.5318L10.5424 14.7721L10.4948 14.7744L10.428 14.1012V12.8467ZM10.4756 13.3054V14.0989L10.4965 14.3088L10.4995 13.5341L10.4756 13.3054Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99397 20.1636L5.49736 20.4594L5.43328 20.9347L4.88184 20.6121L4.99397 20.1636ZM5.02475 20.2369L4.93673 20.589L5.39561 20.8574L5.4459 20.4844L5.02475 20.2369Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.162 13.0203L18.1727 13.4985L16.6432 13.9362L16.6326 13.4629L18.162 13.0203ZM16.681 13.4985L16.6895 13.8734L18.1242 13.4628L18.1158 13.0833L16.681 13.4985Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.09017 21.2844L7.33923 21.5391L6.71547 21.6754L6.51147 21.4148L7.09017 21.2844ZM6.59542 21.4447L6.73455 21.6225L7.24501 21.5109L7.07469 21.3368L6.59542 21.4447Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.83967 14.9631L8.96023 15.4664L7.44153 15.8301L7.30591 15.2876L8.83967 14.9631ZM7.36413 15.324L7.47632 15.7727L8.90278 15.4311L8.80413 15.0194L7.36413 15.324Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.06079 6.65645L9.10631 6.64282L9.54528 7.78317L9.69336 8.37553L9.64814 8.39019L9.18989 7.24935L9.06079 6.65645ZM9.17347 6.94995L9.2356 7.2353L9.56288 8.05007L9.49975 7.79757L9.17347 6.94995Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4117 18.1091L7.78229 18.3271L6.80633 18.9077L6.40552 18.6748L7.4117 18.1091ZM6.50155 18.6755L6.80605 18.8524L7.68869 18.3274L7.41116 18.1641L6.50155 18.6755Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5114 10.8818L15.2648 11.2048L13.8577 11.5465L14.0884 11.2388L15.5114 10.8818ZM14.1164 11.2809L13.9755 11.4689L15.2371 11.1625L15.3908 10.9612L14.1164 11.2809Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9353 15.5649L14.7426 15.922L13.1746 16.3289L13.3664 15.9621L14.9353 15.5649ZM13.3988 16.0031L13.2666 16.2558L14.7105 15.8811L14.8418 15.6378L13.3988 16.0031Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6411 14.4875L16.5383 14.9205L14.9858 15.2852L15.0831 14.8475L16.6411 14.4875ZM15.1231 14.8871L15.0488 15.2214L16.4987 14.8808L16.577 14.5513L15.1231 14.8871Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.78121 18.6899L4.17911 19.3174L3.98196 19.6953L3.55103 19.0078L3.78121 18.6899ZM3.60847 19.0097L3.97817 19.5995L4.12415 19.3197L3.7786 18.7748L3.60847 19.0097Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.19838 17.8647L9.59759 17.8838L8.3931 18.4706L7.96973 18.4457L9.19838 17.8647ZM9.208 17.9129L8.15868 18.409L8.38344 18.4223L9.40926 17.9225L9.208 17.9129Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9268 10.9243L19.1296 11.3245L17.8411 11.8429L17.6487 11.4474L18.9268 10.9243ZM17.7138 11.4723L17.8645 11.7821L19.0636 11.2997L18.9042 10.9851L17.7138 11.4723Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.56765 17.0869L6.85007 17.5367L5.79166 18.0336L5.47925 17.5441L6.56765 17.0869ZM5.54985 17.5662L5.80931 17.9727L6.78107 17.5164L6.5488 17.1465L5.54985 17.5662Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3449 10.0076L17.3612 10.0523L16.1973 10.5198L15.9341 10.5964L15.9187 10.5513L17.0679 10.0983L17.3449 10.0076Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.19336 15.3048L2.23948 15.2935L2.33063 15.5669L2.49297 16.5648L2.44717 16.5771L2.36531 16.3604L2.19336 15.3048Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.89331 21.0322L6.4464 21.0635L6.59342 21.4556L5.99135 21.4189L5.89331 21.0322ZM5.95547 21.0835L6.02899 21.3735L6.52301 21.4036L6.41269 21.1094L5.95547 21.0835Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3553 13.7529L10.3764 14.2486L8.73281 14.5478L8.70654 14.0277L10.3553 13.7529ZM8.75628 14.0678L8.77767 14.4912L10.327 14.2091L10.31 13.8088L8.75628 14.0678Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.12058 3.00171L5.88644 3.12851V3.81009L5.08472 3.69339L5.12058 3.00171ZM5.16541 3.05744L5.13456 3.65248L5.83878 3.755V3.16892L5.16541 3.05744Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.86201 19.3401L5.30183 19.723L5.01606 20.2374L4.53467 19.8181L4.86201 19.3401ZM4.87109 19.4112L4.59796 19.81L5.00298 20.1628L5.24142 19.7336L4.87109 19.4112Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.11681 18.3496L6.49112 18.6765L5.74228 19.2444L5.33276 18.8874L6.11681 18.3496ZM5.41045 18.8919L5.74438 19.183L6.41569 18.6739L6.11329 18.4098L5.41045 18.8919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.49013 18.8857L4.89643 19.3743L4.56824 19.8536L4.12549 19.3233L4.49013 18.8857ZM4.18755 19.3233L4.56449 19.7747L4.83677 19.3771L4.49009 18.9602L4.18755 19.3233Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.47434 15.7727L7.65177 16.2841L6.32442 16.6887L6.12695 16.1379L7.47434 15.7727ZM6.18923 16.1704L6.35396 16.6299L7.59052 16.253L7.44389 15.8303L6.18923 16.1704Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.80584 17.9722L6.15031 18.3845L5.36743 18.9215L4.98779 18.469L5.80584 17.9722ZM5.05999 18.4809L5.37614 18.8577L6.08014 18.3748L5.79555 18.0342L5.05999 18.4809Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.72762 18.3013L8.11557 18.425L7.16682 19.0205L6.74341 18.8867L7.72762 18.3013ZM7.73356 18.3532L6.85918 18.8733L7.16015 18.9684L8.00328 18.4392L7.73356 18.3532Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.45183 20.4404L5.96803 20.6073L5.94681 21.0952L5.38818 20.9125L5.45183 20.4404ZM5.49144 20.5033L5.44072 20.8795L5.90193 21.0304L5.91884 20.6415L5.49144 20.5033Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99927 9.60387L10.0464 9.59692L10.2848 10.8655L10.3612 11.5717L10.3141 11.5787L10.0756 10.3149L9.99927 9.60387ZM10.1112 10.1996L10.1228 10.3079L10.2488 10.9759L10.2377 10.8725L10.1112 10.1996Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3489 17.1802L11.3686 17.2234L11.11 17.3623L9.80754 17.8633L9.7876 17.8201L10.0606 17.6716L11.3489 17.1802Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.77761 19.0073L9.82039 19.0283L9.67247 19.3432L9.27196 20.1204L9.22925 20.0993L9.36284 19.8178L9.77761 19.0073Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0677 12.637L11.9928 13.0866L10.323 13.3715L10.3977 12.9022L12.0677 12.637ZM10.4393 12.9439L10.3805 13.3133L11.9514 13.0454L12.0098 12.6944L10.4393 12.9439Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8754 11.6282L13.7094 12.01L12.104 12.3261L12.2697 11.9394L13.8754 11.6282ZM12.3036 11.9814L12.1834 12.2619L13.6756 11.968L13.7956 11.6922L12.3036 11.9814Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37094 18.8572L5.77753 19.2116L5.27353 19.7615L4.8313 19.3765L5.37094 18.8572ZM5.37253 18.9218L4.90186 19.3747L5.26979 19.695L5.70938 19.2155L5.37253 18.9218Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.45169 18.6465L6.84769 18.8766L6.13174 19.4665L5.70044 19.2162L6.45169 18.6465ZM6.45519 18.7036L5.78628 19.2109L6.12701 19.4086L6.76451 18.8834L6.45519 18.7036Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26253 19.6956L5.72623 19.9665L5.48248 20.5058L4.97729 20.209L5.26253 19.6956ZM5.28053 19.7613L5.04155 20.1915L5.4609 20.4379L5.66512 19.986L5.28053 19.7613Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.42358 16.4858L2.58632 16.6567L2.83413 17.6126L2.69702 17.548L2.42358 16.4858ZM2.5154 16.6514L2.73759 17.5145L2.76251 17.5262L2.54329 16.6806L2.5154 16.6514Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7097 10.6157L18.952 10.9657L17.6721 11.4895L17.4404 11.1392L18.7097 10.6157ZM17.5121 11.1612L17.6903 11.4305L18.879 10.944L18.6924 10.6744L17.5121 11.1612Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.03174 18.4414L5.40419 18.8915L4.86464 19.4106L4.45654 18.9199L5.03174 18.4414ZM4.52365 18.9261L4.86852 19.3408L5.33929 18.8878L5.02549 18.5086L4.52365 18.9261Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7834 9.97754L16.4662 10.5162L16.0608 10.5233L17.3471 10.0066L17.7834 9.97754ZM17.3579 10.0537L16.3186 10.4711L16.4565 10.4687L17.4935 10.0446L17.3579 10.0537Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35104 16.6294L6.59538 17.1337L5.50773 17.5906L5.23853 17.0367L6.35104 16.6294ZM5.30445 17.0633L5.53087 17.5292L6.53057 17.1092L6.32694 16.689L5.30445 17.0633Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.5278 17.5237L5.83362 18.0109L5.01613 18.5074L4.68018 17.9751L5.5278 17.5237ZM4.74786 17.993L5.03143 18.4424L5.76754 17.9953L5.51102 17.5866L4.74786 17.993Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.14681 18.3425L4.51482 18.9201L4.1492 19.3588L3.74463 18.7238L4.14681 18.3425ZM3.80594 18.7314L4.15434 19.2782L4.45591 18.9163L4.13767 18.4169L3.80594 18.7314Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.95446 17.4746L3.25438 18.2994L3.09872 18.4675L2.77319 17.5621L2.95446 17.4746ZM2.83256 17.5864L3.11703 18.3776L3.19965 18.2883L2.92769 17.5405L2.83256 17.5864Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.73879 19.1785L6.16511 19.4311L5.70121 20.0072L5.23413 19.729L5.73879 19.1785ZM5.74778 19.2392L5.30884 19.718L5.69005 19.945L6.09349 19.4441L5.74778 19.2392Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1066 12.5874L18.1649 13.0642L16.6364 13.5066L16.5781 13.0297L18.1066 12.5874ZM16.6304 13.0642L16.6769 13.4452L18.1127 13.0297L18.0661 12.6487L16.6304 13.0642Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.46291 18.0347L3.80776 18.7396L3.57603 19.0596L3.19824 18.2938L3.46291 18.0347ZM3.25626 18.3037L3.58379 18.9676L3.75237 18.7348L3.4491 18.1149L3.25626 18.3037Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2791 16.2534L13.0349 16.575L11.5276 17.0373L11.7815 16.7007L13.2791 16.2534ZM11.8101 16.7419L11.6542 16.9486L13.0062 16.534L13.1529 16.3409L11.8101 16.7419Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99543 17.7717L8.71571 18.3976L8.19409 18.5162L9.50041 17.8853L9.99543 17.7717ZM9.5163 17.9306L8.5923 18.3768L8.69974 18.3524L9.60268 17.9108L9.5163 17.9306Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.10688 9.19849L2.30339 10.0063L2.037 11.1294L1.83496 10.3594L2.10688 9.19849ZM2.10776 9.40372L1.88406 10.3587L2.03467 10.9327L2.25438 10.0065L2.10776 9.40372Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77534 14.4915L8.84865 15.0099L7.31501 15.3343L7.23682 14.7765L8.77534 14.4915ZM7.29034 14.8151L7.35512 15.2771L8.79523 14.9725L8.73511 14.5474L7.29034 14.8151Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7634 10.6692L15.4577 10.943L14.0093 11.3064L14.3052 11.0427L15.7634 10.6692ZM14.3282 11.086L14.1857 11.213L15.4346 10.8997L15.5864 10.7637L14.3282 11.086Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.464 10.3594L18.7422 10.6539L17.4699 11.1786L17.2024 10.8838L18.464 10.3594ZM17.2831 10.9019L17.4827 11.1218L18.6599 10.6362L18.452 10.416L17.2831 10.9019Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72091 17.9502L5.05517 18.4799L4.47979 18.9585L4.10986 18.3779L4.72091 17.9502ZM4.17457 18.3908L4.4909 18.8873L4.99256 18.47L4.70728 18.0179L4.17457 18.3908Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6359 9.99121L18.0084 10.0595L16.7351 10.5841L16.3826 10.5037L17.6359 9.99121ZM17.641 10.0406L16.5401 10.4908L16.7309 10.5343L17.8404 10.0772L17.641 10.0406Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.80106 18.8582L7.21575 18.9891L6.53055 19.5931L6.08057 19.4467L6.80106 18.8582ZM6.81127 18.9114L6.17832 19.4284L6.51943 19.5394L7.12095 19.0091L6.81127 18.9114Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.05307 18.408L8.46331 18.4258L7.52745 19.0377L7.08716 19.0142L8.05307 18.408ZM8.06583 18.4562L7.23986 18.9746L7.51442 18.9893L8.31325 18.467L8.06583 18.4562Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34323 20.0146L8.7856 20.7619L8.48462 20.9267L9.0057 20.2301L9.34323 20.0146ZM9.03866 20.2656L8.65427 20.7795L8.75361 20.7251L9.1491 20.1951L9.03866 20.2656Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.15968 5.25708L3.31921 6.09076L2.73232 6.81545L2.56763 5.99715L3.15968 5.25708ZM2.61874 6.00955L2.75905 6.70672L3.26825 6.07797L3.13231 5.36759L2.61874 6.00955Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6847 14.0835L16.6257 14.5389L15.0703 14.8982L15.1292 14.4379L16.6847 14.0835ZM15.1722 14.477L15.1263 14.8364L16.5827 14.4999L16.6286 14.1451L15.1722 14.477Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.68266 19.947L6.1602 20.0992L5.95797 20.654L5.43896 20.4863L5.68266 19.947ZM5.70862 20.0053L5.50435 20.4573L5.92893 20.5946L6.09842 20.1295L5.70862 20.0053Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2041 10.1604L18.5107 10.3889L17.2438 10.9155L16.9485 10.6815L18.2041 10.1604ZM17.0416 10.6945L17.2514 10.8608L18.415 10.3771L18.1974 10.2148L17.0416 10.6945Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0504 15.2197L14.9018 15.6214L13.3398 16.0169L13.4933 15.6003L15.0504 15.2197ZM13.5293 15.6406L13.4158 15.9485L14.8658 15.5814L14.9745 15.2873L13.5293 15.6406Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9248 10.0425L18.261 10.1932L16.9941 10.7189L16.676 10.557L17.9248 10.0425ZM16.79 10.5616L16.9962 10.6665L18.1407 10.1915L17.924 10.0944L16.79 10.5616Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35256 15.282L7.48792 15.8182L6.14099 16.1833L5.99072 15.5978L7.35256 15.282ZM6.04901 15.6332L6.17514 16.1247L7.43025 15.7845L7.3178 15.339L6.04901 15.6332Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.12094 19.4102L6.5634 19.5488L6.13861 20.1424L5.65527 19.9884L6.12094 19.4102ZM6.13769 19.4653L5.73602 19.9641L6.12003 20.0865L6.48634 19.5746L6.13769 19.4653Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.28762 17.0188L5.55069 17.5656L4.70315 18.0169L4.41504 17.415L5.28762 17.0188ZM4.47902 17.4383L4.72466 17.9515L5.48791 17.545L5.26488 17.0815L4.47902 17.4383Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50244 5.46729L9.10673 6.6432L9.27247 7.40436L8.64197 6.22561L8.50244 5.46729ZM8.60781 5.77659L8.6875 6.20968L9.1531 7.08015L9.06149 6.65943L8.60781 5.77659Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3772 13.3145L10.3507 13.8011L8.70142 14.076L8.72775 13.5599L10.3772 13.3145ZM8.77335 13.6013L8.75203 14.0193L10.3052 13.7604L10.3264 13.3702L8.77335 13.6013Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.92079 20.604L6.43345 20.6302L6.45459 21.1163L5.89966 21.0901L5.92079 20.604ZM5.96632 20.654L5.94933 21.0448L6.40471 21.0663L6.38773 20.6755L5.96632 20.654Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.17256 16.1245L6.36939 16.6736L5.25703 17.0808L5.04541 16.482L6.17256 16.1245ZM5.10675 16.5126L5.28591 17.0194L6.30856 16.6451L6.1432 16.1838L5.10675 16.5126Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4186 15.9548L10.4262 16.1912L10.5091 15.3858L10.5035 15.1451L10.4186 15.9548ZM10.4949 14.7695L10.5424 14.7715L10.5568 15.3877L10.4375 16.5473L10.39 16.5456L10.3708 15.953L10.4949 14.7695Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.86017 17.7427L4.1759 18.3945L3.77261 18.7768L3.43066 18.0674L3.86017 17.7427ZM3.49054 18.0819L3.78719 18.6973L4.11781 18.3839L3.8426 17.8157L3.49054 18.0819Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.3092 20.7947L8.62945 20.8362L8.06586 21.3319L7.78101 21.2607L8.3092 20.7947ZM8.32451 20.8447L7.88051 21.2365L8.05316 21.2796L8.51895 20.8699L8.32451 20.8447Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.40014 3.73779L4.49182 4.51196L3.75525 4.84214L3.66357 4.07306L4.40014 3.73779ZM3.71502 4.102L3.7949 4.77214L4.44037 4.48279L4.36049 3.8082L3.71502 4.102Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7009 16.926L11.3699 17.2233L9.95703 17.7622L10.3106 17.4487L11.7009 16.926ZM10.3356 17.4902L10.184 17.6246L11.3446 17.1819L11.4807 17.0597L10.3356 17.4902Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.84351 3.11157L6.61485 3.46679L6.64585 4.13862L5.84351 3.79839V3.11157ZM5.89116 3.18599V3.76683L6.59476 4.06519L6.56858 3.49795L5.89116 3.18599Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.93858 20.9543L7.12195 21.3319L6.54818 21.4623L6.40259 21.074L6.93858 20.9543ZM6.46645 21.1086L6.57819 21.4066L7.05286 21.2987L6.91218 21.0091L6.46645 21.1086Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0506 11.3455L13.8455 11.6815L12.2339 11.9939L12.4384 11.653L14.0506 11.3455ZM12.4683 11.6958L12.3297 11.9268L13.8158 11.6387L13.9539 11.4124L12.4683 11.6958Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.45752 17.3928L4.74429 17.992L4.1328 18.42L3.82056 17.7648L4.45752 17.3928ZM3.88243 17.7839L4.15159 18.3487L4.68386 17.9761L4.43687 17.4601L3.88243 17.7839Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5858 21.0249L7.8693 21.2528L7.28772 21.5592L7.04321 21.3091L7.5858 21.0249ZM7.12208 21.3216L7.29695 21.5005L7.78287 21.2445L7.58027 21.0816L7.12208 21.3216Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1765 12.2649L12.0575 12.6868L10.3857 12.9523L10.5043 12.5157L12.1765 12.2649ZM10.5422 12.5582L10.451 12.8937L12.0199 12.6445L12.1106 12.323L10.5422 12.5582Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0085 12.1782L18.1146 12.6394L16.5863 13.0816L16.4856 12.6204L18.0085 12.1782ZM16.5417 12.6537L16.622 13.0217L18.058 12.6061L17.9734 12.2381L16.5417 12.6537Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.02846 10.8989L2.20294 11.6492L2.0585 12.8315L1.87817 12.1334L2.02846 10.8989ZM2.04316 11.1725L1.92657 12.1302L2.04171 12.5759L2.15462 11.6518L2.04316 11.1725Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.15976 18.9702L7.58269 18.9928L6.92444 19.6099L6.46582 19.5819L7.15976 18.9702ZM7.17667 19.0188L6.58389 19.5414L6.90683 19.5611L7.46859 19.0344L7.17667 19.0188Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.094 10.5002L15.6887 10.7341L14.1458 11.1293L14.546 10.9088L16.094 10.5002ZM14.5639 10.9533L14.4971 10.9901L15.6706 10.6895L15.7608 10.6375L14.5639 10.9533Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99268 18.1915L10.0602 17.6672L10.0819 17.7096L9.80514 17.8576L8.71342 18.3915L8.69189 18.349L8.99268 18.1915Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.20117 17.3047L3.48355 18.0762L3.21748 18.3367L2.90747 17.4931L3.20117 17.3047ZM2.96539 17.5126L3.23679 18.2511L3.42822 18.0637L3.17685 17.3769L2.96539 17.5126Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82882 18.323L7.87845 18.9548L7.39893 19.068L8.38358 18.4242L8.82882 18.323ZM8.40249 18.4688L7.65245 18.9592L7.85918 18.9104L8.58631 18.427L8.40249 18.4688Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.74919 14.02L8.77539 14.5439L7.23661 14.8291L7.20532 14.2659L8.74919 14.02ZM7.25529 14.3062L7.28119 14.7723L8.72571 14.5047L8.70425 14.0754L7.25529 14.3062Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.10824 20.0896L6.58444 20.1161L6.42832 20.6729L5.90527 20.6465L6.10824 20.0896ZM6.14091 20.1391L5.97216 20.6021L6.39271 20.6234L6.52253 20.1603L6.14091 20.1391Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4253 15.9441L13.2276 16.317L11.7434 16.7602L11.951 16.3674L13.4253 15.9441ZM11.9837 16.4077L11.8386 16.6821L13.1948 16.277L13.3307 16.0209L11.9837 16.4077Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.50826 19.542L6.95332 19.5637L6.56603 20.1627L6.07959 20.1411L6.50826 19.542ZM6.53189 19.5909L6.16946 20.0974L6.54084 20.1139L6.8684 19.6073L6.53189 19.5909Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.09032 16.4663L5.30623 17.0626L4.43349 17.4589L4.19751 16.8074L5.09032 16.4663ZM4.25827 16.8352L4.46073 17.3942L5.24643 17.0374L5.06203 16.5281L4.25827 16.8352Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91187 4.63721L8.57764 5.61752L8.70754 6.3235L8.01288 5.34993L7.91187 4.63721ZM7.98788 4.83397L8.05843 5.33172L8.6219 6.12143L8.53258 5.636L7.98788 4.83397Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6907 13.6604L16.6801 14.1335L15.1262 14.4875L15.1368 14.0046L16.6907 13.6604ZM15.1837 14.043L15.1752 14.4275L16.6333 14.0953L16.6417 13.7201L15.1837 14.043Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.73553 16.6396L2.9645 17.5157L2.78264 17.6035L2.5293 16.6459L2.73553 16.6396ZM2.59071 16.6917L2.81394 17.5355L2.90848 17.4899L2.69902 16.6884L2.59071 16.6917Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.03016 12.5159L2.18819 13.1875L2.16045 14.4077L2.0022 13.8076L2.03016 12.5159ZM2.06968 12.8919L2.04999 13.8019L2.12048 14.0692L2.1404 13.1925L2.06968 12.8919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.56079 3.43237L7.31025 4.00338L7.3733 4.67593L6.59227 4.11441L6.56079 3.43237ZM6.6131 3.53214L6.63881 4.08917L7.31607 4.57609L7.26475 4.02863L6.6131 3.53214Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1352 14.8354L15.0325 15.2731L13.4797 15.6527L13.5821 15.2003L15.1352 14.8354ZM13.622 15.2399L13.5432 15.5881L14.9928 15.2338L15.0712 14.8994L13.622 15.2399Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.28356 14.7725L7.36676 15.3289L6.0048 15.6447L5.91675 15.0438L7.28356 14.7725ZM5.97047 15.0818L6.04445 15.5866L7.31312 15.2924L7.24382 14.8289L5.97047 15.0818Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.04194 15.5864L6.18657 16.1701L5.05949 16.5276L4.89478 15.8894L6.04194 15.5864ZM4.95276 15.9233L5.09305 16.467L6.12956 16.1382L6.00732 15.6449L4.95276 15.9233Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.868 11.7881L18.0168 12.224L16.4934 12.6663L16.3499 12.2303L17.868 11.7881ZM16.4105 12.2622L16.5243 12.6077L17.9556 12.1922L17.8376 11.8466L16.4105 12.2622Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.26562 15.4644L2.43496 15.7261L2.6007 16.7412L2.4471 16.5799L2.26562 15.4644ZM2.34919 15.6812L2.49178 16.5577L2.5288 16.5966L2.38954 15.7436L2.34919 15.6812Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.61639 17.0728L3.87508 17.7829L3.44457 18.1084L3.15967 17.33L3.61639 17.0728ZM3.21834 17.3516L3.46719 18.0315L3.81825 17.7661L3.59084 17.1418L3.21834 17.3516Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.0957 14.0063L2.25472 14.5629L2.33979 15.7595L2.18851 15.3057L2.0957 14.0063ZM2.17593 14.4606L2.23562 15.2963L2.26544 15.3858L2.20754 14.5712L2.17593 14.4606Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25708 3.94824L7.97233 4.7201L8.06941 5.405L7.32161 4.63657L7.25708 3.94824ZM7.31765 4.08371L7.36749 4.61539L8.00173 5.26712L7.92724 4.74155L7.31765 4.08371Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23668 16.7839L4.47145 17.4372L3.834 17.8095L3.57373 17.095L4.23668 16.7839ZM3.63342 17.1196L3.85925 17.7396L4.4132 17.416L4.20954 16.8493L3.63342 17.1196Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5489 10.9128L16.1835 10.4744L16.1963 10.5203L15.9336 10.5967L14.5612 10.9588L14.5611 10.9589L14.5488 10.9128L14.5489 10.9128Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4471 12.895L10.3726 13.3633L8.72217 13.6089L8.79635 13.1161L10.4471 12.895ZM8.83815 13.1586L8.77889 13.5522L10.331 13.3213L10.3899 12.9508L8.83815 13.1586Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2719 11.1184L14.0194 11.4009L12.3936 11.711L12.646 11.4286L14.2719 11.1184ZM12.6708 11.4724L12.5229 11.6379L13.9947 11.3571L14.1425 11.1916L12.6708 11.4724Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8619 16.6719L11.6057 17.0173L10.2568 17.5245L10.5274 17.1539L11.8619 16.6719ZM10.5573 17.1938L10.3894 17.4237L11.5758 16.9777L11.7295 16.7703L10.5573 17.1938Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3904 16.5398L10.4373 16.5479L10.3705 17.0493L10.1463 18.0891L10.0994 18.0809L10.1614 17.613L10.3904 16.5398Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.88362 20.519L6.94708 21.0003L6.40714 21.1156L6.38599 20.629L6.88362 20.519ZM6.43534 20.6669L6.4523 21.0572L6.89408 20.9629L6.84317 20.5768L6.43534 20.6669Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.94668 18.8948L7.30416 19.5214L6.82935 19.6341L7.50936 18.9966L7.94668 18.8948ZM7.53263 19.0401L6.99264 19.5464L7.28033 19.478L7.79109 18.9799L7.53263 19.0401Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3091 11.5766L10.3565 11.572L10.428 12.2728L10.5424 13.5306L10.5424 13.531L10.495 13.5356L10.4949 13.5353L10.4235 12.849L10.3091 11.5766Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.64648 8.3839L9.69307 8.37476L10.0463 9.59676L10.1228 10.3089L10.0762 10.3181L9.72296 9.10084L9.64648 8.3839ZM9.72266 8.64874L9.7699 9.0916L10.0465 10.0446L9.99937 9.60599L9.72266 8.64874Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3254 11.9307L12.1604 12.3155L10.4846 12.5669L10.6546 12.1722L12.3254 11.9307ZM10.6878 12.2155L10.5623 12.507L12.1271 12.2723L12.2481 11.99L10.6878 12.2155Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75531 6.69873L2.94249 7.54624L2.45534 8.42311L2.26294 7.59628L2.75531 6.69873ZM2.31349 7.60323L2.47373 8.29187L2.89206 7.53889L2.73612 6.83281L2.31349 7.60323Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.468 17.3682L10.084 17.7087L8.85303 18.3132L9.26624 17.9498L10.468 17.3682ZM9.29284 17.9899L9.15324 18.1127L10.0572 17.6687L10.1784 17.5613L9.29284 17.9899Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.694 11.4268L17.8865 11.8277L16.367 12.2703L16.1853 11.8641L17.694 11.4268ZM16.2512 11.8947L16.3935 12.2129L17.8192 11.7977L17.6685 11.4838L16.2512 11.8947Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99178 18.1968L9.01637 18.2375L8.20518 18.7815L7.88003 18.9537L7.85571 18.9127L8.6906 18.3593L8.99178 18.1968Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6472 10.4653L15.0585 10.8976L14.6289 10.8897L16.1816 10.4735L16.6472 10.4653ZM16.1883 10.5211L14.9674 10.8483L15.0525 10.8499L16.266 10.5197L16.1883 10.5211Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.3455 19.4595L6.98808 20.0639L6.50684 20.169L6.90094 19.5595L7.3455 19.4595ZM6.93039 19.6017L6.60964 20.0978L6.95762 20.0218L7.24832 19.5302L6.93039 19.6017Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00412 20.0112L6.88332 20.568L6.38159 20.68L6.53914 20.1181L7.00412 20.0112ZM6.57739 20.1582L6.449 20.6162L6.84321 20.5281L6.94163 20.0745L6.57739 20.1582Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77068 13.5532L8.74966 14.0682L7.20532 14.3141L7.22625 13.7647L8.77068 13.5532ZM7.27235 13.8065L7.25515 14.2579L8.70364 14.0273L8.72074 13.6082L7.27235 13.8065Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6546 13.2322L16.6917 13.709L15.139 14.0529L15.0967 13.5713L16.6546 13.2322ZM15.1478 13.6089L15.1817 13.9946L16.641 13.6714L16.6113 13.2904L15.1478 13.6089Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.9407 15.8762L5.09965 16.512L4.2067 16.8532L4.02759 16.1572L4.9407 15.8762ZM4.08508 16.1893L4.23956 16.7896L5.04317 16.4826L4.90666 15.9366L4.08508 16.1893Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.549 15.5854L13.401 16.0021L11.9292 16.4233L12.0872 15.9819L13.549 15.5854ZM12.1237 16.0214L12.0054 16.3519L13.3643 15.963L13.4737 15.6553L12.1237 16.0214Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.39103 20.7026L7.61688 21.0575L7.07176 21.3431L6.88892 20.9666L7.39103 20.7026ZM6.95195 20.9873L7.09317 21.2781L7.54884 21.0394L7.37437 20.7652L6.95195 20.9873Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.01017 16.5376L3.22116 17.3514L2.92643 17.5405L2.69336 16.6488L3.01017 16.5376ZM2.75057 16.6792L2.95594 17.465L3.16637 17.33L2.97704 16.5997L2.75057 16.6792Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1782 14.427L15.1248 14.8915L13.5745 15.2557L13.6277 14.7716L15.1782 14.427ZM13.6713 14.8107L13.6292 15.1939L15.0812 14.8528L15.1232 14.488L13.6713 14.8107Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1014 18.0742L10.1455 18.0921L9.82103 19.0273L9.67276 19.343L9.62866 19.3251L9.93883 18.4232L10.1014 18.0742Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4867 11.1226L17.7191 11.4739L16.2076 11.9121L15.9861 11.5555L17.4867 11.1226ZM16.0598 11.5839L16.229 11.8563L17.6436 11.4462L17.4662 11.1781L16.0598 11.5839Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25267 14.2583L7.2839 14.8153L5.91694 15.0867L5.87549 14.4804L7.25267 14.2583ZM5.926 14.5206L5.96079 15.0294L7.234 14.7766L7.20805 14.3138L5.926 14.5206Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4561 10.4631L16.8219 10.553L15.3123 10.9749L14.9707 10.8673L16.4561 10.4631ZM16.4567 10.5124L15.14 10.8707L15.3131 10.9252L16.6342 10.5559L16.4567 10.5124Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.95839 15.0342L6.05133 15.6331L4.9042 15.9361L4.80127 15.2825L5.95839 15.0342ZM4.85536 15.3196L4.94306 15.8765L5.99765 15.598L5.91903 15.0914L4.85536 15.3196Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5315 10.9451L14.2169 11.1726L12.5586 11.489L12.8775 11.2565L14.5315 10.9451ZM12.8969 11.3013L12.7583 11.4024L14.1976 11.1278L14.331 11.0313L12.8969 11.3013Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.13037 3.65063L5.22176 4.42235L4.44539 4.52891L4.354 3.75719L5.13037 3.65063ZM4.40683 3.79805L4.48701 4.47509L5.16893 4.38149L5.08875 3.70445L4.40683 3.79805Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.03293 20.6233L8.35207 20.8159L7.82681 21.2845L7.54614 21.0588L8.03293 20.6233ZM8.03847 20.6823L7.61979 21.0569L7.82521 21.222L8.27215 20.8233L8.03847 20.6823Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.07774 16.1372L4.25598 16.8298L3.59249 17.1412L3.39893 16.3822L4.07774 16.1372ZM3.45579 16.4123L3.62442 17.0735L4.19999 16.8035L4.04464 16.1998L3.45579 16.4123Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.43888 16.365L3.63117 17.119L3.17367 17.3767L2.96045 16.5543L3.43888 16.365ZM3.01715 16.5831L3.20425 17.3047L3.57597 17.0954L3.40607 16.4292L3.01715 16.5831Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2556 10.866L17.5194 11.1627L16.0145 11.5969L15.7622 11.2898L17.2556 10.866ZM15.8452 11.3158L16.0314 11.5425L17.4335 11.1379L17.2398 10.92L15.8452 11.3158Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5556 12.5093L10.4374 12.9446L8.78516 13.1658L8.90802 12.7011L10.5556 12.5093ZM8.94579 12.7447L8.84944 13.1091L10.3997 12.9015L10.4912 12.5648L8.94579 12.7447Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7355 10.5337L17.0601 10.6989L15.5622 11.1233L15.2566 10.947L16.7355 10.5337ZM15.3786 10.9624L15.5686 11.072L16.9301 10.6862L16.7305 10.5846L15.3786 10.9624Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.71399 19.2544L9.26439 20.1215L8.96045 20.3155L9.38487 19.4932L9.71399 19.2544ZM9.42202 19.5251L9.08394 20.1801L9.22797 20.0882L9.579 19.4112L9.42202 19.5251Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0002 10.6665L17.2988 10.9031L15.7977 11.3292L15.5161 11.087L17.0002 10.6665ZM15.6144 11.1087L15.8094 11.2763L17.1963 10.8827L16.9897 10.719L15.6144 11.1087Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0204 16.3411L11.8175 16.7358L10.4988 17.2121L10.7114 16.7925L12.0204 16.3411ZM10.7452 16.8312L10.5956 17.1264L11.7836 16.6973L11.9238 16.4248L10.7452 16.8312Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72912 20.2239H9.07485L8.58013 20.8784L8.26855 20.838L8.72912 20.2239ZM8.75295 20.2715L8.35569 20.8012L8.55881 20.8275L8.97909 20.2715H8.75295Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.79154 4.76855L3.94429 5.59849L3.27796 6.12748L3.12012 5.30264L3.79154 4.76855ZM3.17234 5.32199L3.30997 6.04122L3.89224 5.57896L3.75905 4.8553L3.17234 5.32199Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.32421 18.6624L7.66414 19.3282L7.1604 19.6005L7.8546 18.9179L8.32421 18.6624ZM7.88324 18.9565L7.43369 19.3986L7.63527 19.2896L8.06176 18.8594L7.88324 18.9565Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5049 11.6392L12.2963 11.9775L10.6199 12.2198L10.8328 11.8667L12.5049 11.6392ZM10.8619 11.9109L10.7127 12.1582L12.2674 11.9335L12.4114 11.7L10.8619 11.9109Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.46567 8.26831L2.64662 9.09324L2.27391 10.0923L2.08228 9.3045L2.46567 8.26831ZM2.13202 9.30738L2.28352 9.9302L2.59706 9.08974L2.45395 8.43731L2.13202 9.30738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.38208 15.698L2.60026 15.779L2.74991 16.6869L2.54455 16.6931L2.38208 15.698ZM2.44232 15.7712L2.58485 16.6442L2.69403 16.6409L2.55774 15.8141L2.44232 15.7712Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5807 12.8086L16.6601 13.2797L15.1028 13.6187L15.0234 13.1427L16.5807 12.8086ZM15.0779 13.1797L15.1416 13.5615L16.6056 13.2428L16.542 12.8656L15.0779 13.1797Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.30377 20.2834L7.41017 20.7463L6.90149 21.019L6.83765 20.5348L7.30377 20.2834ZM6.88919 20.5612L6.93972 20.9444L7.35557 20.7215L7.27134 20.3551L6.88919 20.5612Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6175 17.1128L10.3388 17.4884L9.20337 18.0379L9.50221 17.6315L10.6175 17.1128ZM9.53337 17.6696L9.35565 17.9113L10.3075 17.4506L10.4673 17.2352L9.53337 17.6696Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50772 18.5128L8.45895 18.5569L8.99312 18.1987L9.02088 18.1738L8.50772 18.5128ZM9.26628 17.9546L9.2953 17.9922L9.02242 18.2364L8.21088 18.7806L8.18164 18.7431L8.47844 18.475L9.26628 17.9546Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6698 10.8743L14.6829 10.9199L14.4378 11.016L12.8915 11.3071L12.8784 11.2615L13.1236 11.1654L14.6698 10.8743ZM13.1368 11.2114L13.1316 11.2134L14.4246 10.97L14.4298 10.968L13.1368 11.2114Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8453 13.1104L8.77131 13.6019L7.22607 13.8135L7.29966 13.2826L8.8453 13.1104ZM7.34177 13.3259L7.28191 13.7577L8.7295 13.5595L8.78895 13.1646L7.34177 13.3259Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6336 15.1873L13.5313 15.6395L12.0735 16.0349L12.1807 15.5579L13.6336 15.1873ZM12.2208 15.5969L12.1373 15.9682L13.4912 15.601L13.57 15.2527L12.2208 15.5969Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70921 19.2502L7.37653 19.8556L6.9043 20.109L7.27522 19.4817L7.70921 19.2502ZM7.30944 19.5175L7.03223 19.9863L7.34156 19.8203L7.5903 19.3677L7.30944 19.5175Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.85261 15.2673L4.95507 15.9231L4.04169 16.2041L3.9292 15.4831L4.85261 15.2673ZM3.9831 15.5195L4.08029 16.1424L4.90161 15.8897L4.81345 15.3254L3.9831 15.5195Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4994 13.5329L10.5471 13.5325L10.5614 14.1765L10.5566 15.3869L10.5089 15.3874L10.4946 14.772L10.4994 13.5329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.18 13.9944L15.1694 14.4721L13.6204 14.8163L13.631 14.3239L15.18 13.9944ZM13.6778 14.3626L13.6693 14.7566L15.1226 14.4337L15.1311 14.0535L13.6778 14.3626Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.38928 19.7898L7.30915 20.3346L6.82422 20.5933L6.94707 20.0271L7.38928 19.7898ZM6.98898 20.0587L6.89262 20.5028L7.2655 20.3039L7.32835 19.8766L6.98898 20.0587Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.19849 14.4438L2.3642 14.7897L2.43781 15.8255L2.27929 15.5805L2.19849 14.4438ZM2.26379 14.6904L2.32596 15.5649L2.37713 15.644L2.3173 14.8021L2.26379 14.6904Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.17114 7.07446L9.69373 8.3755L9.78846 9.26353L9.2658 7.96776L9.17114 7.07446ZM9.25464 7.41019L9.31248 7.95612L9.70483 8.92879L9.64705 8.38713L9.25464 7.41019Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.28057 9.90039L2.43659 10.6693L2.17841 11.7547L2.01123 11.0358L2.28057 9.90039ZM2.06018 11.0359L2.17894 11.5466L2.3878 10.6685L2.27694 10.1222L2.06018 11.0359Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.279 13.7583L7.25813 14.3063L5.88062 14.5285L5.9014 13.9361L7.279 13.7583ZM5.94761 13.9781L5.93028 14.4722L7.21199 14.2655L7.22924 13.8128L5.94761 13.9781Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.92762 14.4727L5.96379 15.0771L4.80633 15.3255L4.76514 14.6664L5.92762 14.4727ZM4.81538 14.7063L4.85044 15.2673L5.91377 15.0391L5.88321 14.5284L4.81538 14.7063Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4642 12.4089L16.5862 12.8599L15.029 13.194L14.9019 12.7332L16.4642 12.4089ZM14.9613 12.7695L15.0629 13.138L16.5271 12.8239L16.4299 12.4647L14.9613 12.7695Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.88478 15.7422L3.02019 16.5847L2.70282 16.696L2.55615 15.7756L2.88478 15.7422ZM2.61115 15.8179L2.7409 16.6322L2.96681 16.5529L2.84487 15.7941L2.61115 15.8179Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7034 12.1611L10.5399 12.5589L8.88965 12.751L9.05772 12.3336L10.7034 12.1611ZM9.09121 12.378L8.96381 12.6944L10.5065 12.5148L10.629 12.2168L9.09121 12.378Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7238 11.4138L12.4701 11.6969L10.7874 11.926L11.0452 11.633L12.7238 11.4138ZM11.0691 11.6779L10.9076 11.8615L12.4463 11.6521L12.6026 11.4777L11.0691 11.6779Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.13985 13.0134L2.27876 13.5829L2.25108 14.7289L2.11182 14.2415L2.13985 13.0134ZM2.17924 13.376L2.15963 14.2353L2.21099 14.4151L2.23096 13.5881L2.17924 13.376Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17846 11.4954L2.31245 12.1836L2.17177 13.3252L2.02588 12.7051L2.17846 11.4954ZM2.18853 11.7964L2.07424 12.7026L2.15709 13.0547L2.26423 12.1852L2.18853 11.7964Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.97102 15.4766L4.08296 16.1889L3.40362 16.4341L3.27637 15.6502L3.97102 15.4766ZM3.33043 15.6858L3.44146 16.3698L4.02977 16.1575L3.93203 15.5354L3.33043 15.6858Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9241 10.8406L14.9312 10.8877L13.391 11.1785L13.1328 11.2072L13.1257 11.1601L14.6707 10.8693L14.9241 10.8406Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.80971 20.3279L8.07341 20.6508L7.57786 21.0896L7.35181 20.7343L7.80971 20.3279ZM7.41382 20.743L7.58808 21.0168L8.00753 20.6455L7.8042 20.3965L7.41382 20.743Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1436 15.9644L11.9908 16.4063L10.6899 16.8549L10.8525 16.3781L12.1436 15.9644ZM10.8899 16.4162L10.7665 16.7781L11.9533 16.3689L12.0674 16.0388L10.8899 16.4162Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.32755 15.6343L3.45387 16.4124L2.97479 16.6019L2.83789 15.7501L3.32755 15.6343ZM2.89197 15.7863L3.01242 16.5358L3.40068 16.3822L3.28872 15.6924L2.89197 15.7863Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47715 18.4717L8.51013 18.5061L7.99491 19.0308L7.67029 19.322L7.63745 19.2875L8.1812 18.739L8.47715 18.4717Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.08228 3.64478L5.88408 3.76149L5.97566 4.53487L5.17386 4.41816L5.08228 3.64478ZM5.13691 3.70089L5.21689 4.37626L5.92103 4.47876L5.84105 3.80339L5.13691 3.70089Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0754 10.3138L10.1226 10.3071L10.3563 11.571L10.4279 12.2725L10.3807 12.2793L10.147 11.0345L10.0754 10.3138ZM10.179 10.8745L10.1942 11.0277L10.3228 11.7125L10.3091 11.5777L10.179 10.8745Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3188 12.033L16.4842 12.4544L14.9215 12.7787L14.751 12.3524L16.3188 12.033ZM14.8164 12.3877L14.9509 12.7239L16.4192 12.4192L16.2891 12.0877L14.8164 12.3877Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1486 13.5615L15.1856 14.0428L13.6376 14.372L13.5955 13.8712L15.1486 13.5615ZM13.6465 13.9096L13.6806 14.3142L15.1349 14.0049L15.1052 13.6188L13.6465 13.9096Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7807 16.7625L10.5597 17.1874L9.46436 17.6969L9.70054 17.2414L10.7807 16.7625ZM9.73515 17.2782L9.57101 17.5947L10.5249 17.151L10.6756 16.8612L9.73515 17.2782Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.611 17.5591L9.2914 17.9873L8.38623 18.5853L8.7341 18.1236L9.611 17.5591ZM8.76705 18.1591L8.5907 18.3931L9.25819 17.9522L9.41515 17.7418L8.76705 18.1591Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.677 14.7603L13.6238 15.2391L12.1736 15.609L12.2265 15.1007L13.677 14.7603ZM12.2704 15.1393L12.2281 15.546L13.5801 15.2011L13.6222 14.8221L12.2704 15.1393Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9264 10.8401L15.3202 10.9057L13.645 11.2221L13.2512 11.1565L14.9264 10.8401ZM14.9269 10.8885L13.5235 11.1535L13.6445 11.1737L15.0479 10.9086L14.9269 10.8885Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.95875 12.7009L8.83612 13.1594L7.28882 13.3319L7.42135 12.8389L8.95875 12.7009ZM7.45873 12.8834L7.35298 13.2768L8.7985 13.1157L8.89509 12.7545L7.45873 12.8834Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5092 15.3838L10.5565 15.3894L10.4373 16.543L10.3706 17.0438L10.3232 17.0382L10.4377 15.9179L10.5092 15.3838Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.31206 6.03003L3.48136 6.88168L2.90466 7.59107L2.71997 6.75483L3.31206 6.03003ZM2.77149 6.7671L2.92992 7.48445L3.43025 6.869L3.28502 6.13845L2.77149 6.7671Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.81248 14.6631L4.85351 15.3196L3.92962 15.5355L3.88354 14.8187L4.81248 14.6631ZM3.93386 14.8586L3.97357 15.4763L4.80343 15.2823L4.76821 14.7188L3.93386 14.8586Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.05397 18.9143L7.73549 19.5336L7.271 19.9485L7.63067 19.294L8.05397 18.9143ZM7.66853 19.3241L7.4468 19.7276L7.69715 19.5039L7.89336 19.1224L7.66853 19.3241Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.43313 20.085L8.77801 20.2382L8.32306 20.8465L8.00562 20.655L8.43313 20.085ZM8.44848 20.1439L8.07536 20.6414L8.31074 20.7834L8.70407 20.2575L8.44848 20.1439Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1355 11.6904L16.3401 12.0726L14.7712 12.3923L14.5615 12.0052L16.1355 11.6904ZM14.6342 12.0393L14.7963 12.3385L16.2678 12.0387L16.1102 11.7441L14.6342 12.0393Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9761 11.248L12.6674 11.4731L10.9617 11.6958L11.2815 11.4656L12.9761 11.248ZM11.2996 11.5113L11.1428 11.6241L12.6492 11.4275L12.798 11.319L11.2996 11.5113Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.68932 19.9294L7.8344 20.3647L7.36846 20.7783L7.26099 20.3108L7.68932 19.9294ZM7.31375 20.3276L7.39691 20.6893L7.77925 20.3499L7.66698 20.0131L7.31375 20.3276Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.62817 6.09961L9.23592 7.23054L9.32545 8.07555L8.71771 6.94462L8.62817 6.09961ZM8.70115 6.33609L8.76412 6.9303L9.25247 7.83907L9.18951 7.24486L8.70115 6.33609Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.31812 14.7456L2.54015 14.9121L2.60549 15.832L2.38962 15.7518L2.31812 14.7456ZM2.37305 14.8464L2.43499 15.7178L2.55271 15.7616L2.49415 14.9372L2.37305 14.8464Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1918 10.8823L15.5221 11.0257L13.9044 11.3322L13.5681 11.189L15.1918 10.8823ZM13.7313 11.2066L13.9097 11.2827L15.361 11.0077L15.1862 10.9319L13.7313 11.2066Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8926 11.8655L10.6805 12.2116L9.0293 12.3846L9.25102 12.0188L10.8926 11.8655ZM9.27934 12.064L9.11981 12.3272L10.6521 12.1666L10.8022 11.9218L9.27934 12.064Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.34857 13.2729L7.27522 13.8021L5.89673 13.98L5.97491 13.4014L7.34857 13.2729ZM6.01707 13.4453L5.95228 13.9248L7.23301 13.7595L7.2931 13.326L6.01707 13.4453Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74512 19.4609L7.70124 19.9875L7.25439 20.3853L7.33667 19.8258L7.74512 19.4609ZM7.38131 19.8499L7.32062 20.2625L7.65533 19.9646L7.6877 19.5761L7.38131 19.8499Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.94936 13.9255L5.92866 14.5208L4.76562 14.7147L4.78626 14.0647L5.94936 13.9255ZM4.83259 14.1072L4.8151 14.6581L5.88238 14.4802L5.8998 13.9795L4.83259 14.1072Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9238 11.4001L16.1587 11.7334L14.5825 12.0487L14.3479 11.7104L15.9238 11.4001ZM14.4286 11.7431L14.6038 11.9958L16.0776 11.701L15.9027 11.4529L14.4286 11.7431Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.447 10.9915L15.7429 11.2061L14.149 11.5139L13.8484 11.2943L15.447 10.9915ZM13.9652 11.3207L14.1603 11.4632L15.6259 11.1801L15.4357 11.0421L13.9652 11.3207Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.48444 4.46313L4.62617 5.29322L3.90014 5.61816L3.74829 4.79313L4.48444 4.46313ZM3.80193 4.82131L3.93603 5.54988L4.57297 5.26482L4.44782 4.53178L3.80193 4.82131Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6927 11.1672L15.9606 11.4463L14.3801 11.7575L14.1072 11.4735L15.6927 11.1672ZM14.2023 11.5036L14.3964 11.7057L15.8658 11.4164L15.6763 11.2189L14.2023 11.5036Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4197 16.8208L10.1459 18.0967L9.89355 18.6384L10.1528 17.4288L10.4197 16.8208ZM10.1983 17.4435L10.0286 18.2355L10.1004 18.0814L10.2745 17.2699L10.1983 17.4435Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0698 13.1335L15.1544 13.6091L13.6018 13.9186L13.5122 13.4235L15.0698 13.1335ZM13.5675 13.4616L13.64 13.8624L15.0993 13.5715L15.0313 13.1892L13.5675 13.4616Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.233 15.5432L12.1258 16.0202L10.8391 16.4325L10.951 15.9208L12.233 15.5432ZM10.9916 15.9586L10.9033 16.3619L12.0853 15.9831L12.1687 15.6118L10.9916 15.9586Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.45026 19.4792L9.04635 20.2717H8.70679L9.08769 19.5302L9.45026 19.4792ZM9.11879 19.574L8.78484 20.2241H9.01715L9.36622 19.5392L9.11879 19.574Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83398 3.74463L6.64232 4.08525L6.72942 4.86401L5.92621 4.5234L5.83398 3.74463ZM5.89094 3.82034L5.97028 4.49033L6.673 4.78832L6.59807 4.11832L5.89094 3.82034Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.93068 14.8066L3.97649 15.5244L3.28139 15.6982L3.23047 14.914L3.93068 14.8066ZM3.28085 14.9545L3.32525 15.6381L3.9264 15.4878L3.88643 14.8616L3.28085 14.9545Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0119 18.3574L9.67133 19.3476L9.36108 19.5727L9.68326 18.6374L10.0119 18.3574ZM9.72408 18.6652L9.45532 19.4455L9.63116 19.3179L9.91015 18.5067L9.72408 18.6652Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.28241 18.6478L8.20174 18.7567L8.47492 18.4785L8.542 18.3882L8.28241 18.6478ZM8.73833 18.1245L8.7743 18.1556L8.51121 18.5095L7.99538 19.0349L7.95923 19.0041L8.24622 18.6166L8.73833 18.1245Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6833 14.3135L13.678 14.81L12.2295 15.15V14.6289L13.6833 14.3135ZM12.2771 14.6673V15.0898L13.6308 14.7721L13.635 14.3727L12.2771 14.6673Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.49121 14.8965L2.82543 14.9466L2.88561 15.7941L2.55699 15.8275L2.49121 14.8965ZM2.54294 14.9524L2.60106 15.7751L2.8348 15.7514L2.7806 14.9881L2.54294 14.9524Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3043 11.1328L12.8933 11.302L11.1213 11.5295L11.5322 11.3556L13.3043 11.1328ZM11.5447 11.4021L11.46 11.438L12.881 11.2556L12.9561 11.2246L11.5447 11.4021Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00195 5.25293L8.69261 6.21362L8.77903 7.02917L8.09378 6.07398L8.00195 5.25293ZM8.06955 5.4286L8.13976 6.05631L8.71264 6.85487L8.64655 6.2312L8.06955 5.4286Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.27795 14.9036L3.32847 15.6866L2.83824 15.8026L2.78247 14.9457L3.27795 14.9036ZM2.83306 14.9893L2.88214 15.7432L3.27832 15.6495L3.23352 14.9552L2.83306 14.9893Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.23883 13.4578L2.39325 13.8673L2.36675 14.8954L2.21191 14.5723L2.23883 13.4578ZM2.28057 13.7035L2.25983 14.562L2.32421 14.6964L2.34537 13.8754L2.28057 13.7035Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9108 16.3552L10.7473 16.8294L9.67798 17.3036L9.85125 16.7891L10.9108 16.3552ZM9.88947 16.825L9.75774 17.2161L10.709 16.7943L10.8315 16.4392L9.88947 16.825Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.1113 12.3342L8.93845 12.7501L7.39819 12.8884L7.58069 12.4429L9.1113 12.3342ZM7.61357 12.4883L7.47201 12.8339L8.9055 12.7052L9.03766 12.3872L7.61357 12.4883Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.92834 7.47388L3.09017 8.30912L2.61694 9.163L2.43945 8.35388L2.92834 7.47388ZM2.48986 8.36127L2.63644 9.02949L3.04009 8.30118L2.90645 7.61142L2.48986 8.36127Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.77277 17.1956L9.53122 17.6671L8.68896 18.2093L8.9515 17.6957L9.77277 17.1956ZM8.9878 17.7294L8.8119 18.0735L9.49465 17.634L9.65342 17.324L8.9878 17.7294Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.58594 4.05127L7.36723 4.61298L7.45538 5.40118L6.67409 4.83947L6.58594 4.05127ZM6.64522 4.15259L6.7191 4.81314L7.3961 5.29987L7.32222 4.63932L6.64522 4.15259Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1141 11.6265L10.859 11.9164L9.20728 12.0706L9.47761 11.7657L11.1141 11.6265ZM9.50063 11.8116L9.323 12.0119L10.8357 11.8707L11 11.684L9.50063 11.8116Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9487 12.7241L15.0758 13.1798L13.5181 13.4698L13.386 12.9993L14.9487 12.7241ZM13.4461 13.0371L13.5522 13.4149L15.0159 13.1425L14.9144 12.7785L13.4461 13.0371Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.1757 19.8259L8.47246 20.1119L8.04109 20.6819L7.77661 20.358L8.1757 19.8259ZM8.18147 19.8977L7.83712 20.3568L8.03967 20.6048L8.40893 20.1169L8.18147 19.8977Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.31323 4.55933L8.05371 5.32552L8.1434 6.12745L7.40292 5.36125L7.31323 4.55933ZM7.37614 4.69299L7.44848 5.33981L8.0805 5.99378L8.00816 5.34696L7.37614 4.69299Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3804 12.2756L10.4279 12.2727L10.5471 13.5317L10.5614 14.1759L10.5139 14.1786L10.3995 12.9483L10.3804 12.2756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.83407 14.0596L4.81353 14.7067L3.88403 14.8624L3.90453 14.15L4.83407 14.0596ZM3.95096 14.1934L3.93334 14.8058L4.76713 14.6661L4.78471 14.1123L3.95096 14.1934Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.395 11.1787L11.8127 11.3789L11.5458 11.4075L11.5403 11.3601L13.132 11.16L13.1323 11.1599L13.3893 11.1314L13.3897 11.1313L13.3953 11.1787L13.395 11.1787Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.4664 12.8398L7.33962 13.3311L5.96436 13.4598L6.10101 12.9289L7.4664 12.8398ZM6.13856 12.9742L6.0274 13.406L7.30184 13.2868L7.40381 12.8917L6.13856 12.9742Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.24528 18.6133L8.28574 18.6381L8.0513 19.107L7.74031 19.528L7.69995 19.503L7.95818 19.0009L8.24528 18.6133ZM7.99879 19.0261L7.87726 19.2624L8.01052 19.082L8.12087 18.8613L7.99879 19.0261Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.7229 9.0987L9.76949 9.0896L10.1228 10.3069L10.1945 11.0287L10.1479 11.0377L9.79937 9.83473L9.7229 9.0987ZM9.79775 9.35801L9.84633 9.82557L10.1214 10.775L10.0758 10.316L9.79775 9.35801Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.27788 12.0359L2.39826 12.6315L2.26251 13.7121L2.1355 13.1914L2.27788 12.0359ZM2.28929 12.3329L2.18386 13.1885L2.24748 13.4494L2.35001 12.6333L2.28929 12.3329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02361 13.4023L5.94567 13.9739L4.78174 14.1132L4.86456 13.4869L6.02361 13.4023ZM4.90672 13.5316L4.83703 14.0586L5.90342 13.931L5.96845 13.4542L4.90672 13.5316Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.63013 9.00659L2.77051 9.78947L2.40537 10.7545L2.25415 10.0092L2.63013 9.00659ZM2.30357 10.0131L2.41923 10.5832L2.72128 9.78492L2.61384 9.18576L2.30357 10.0131Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2758 15.0879L12.2229 15.5957L10.9438 15.9725L11.0018 15.4299L12.2758 15.0879ZM11.0457 15.4675L10.9988 15.9066L12.1788 15.559L12.2212 15.1519L11.0457 15.4675Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6426 13.8618L13.6847 14.3619L12.232 14.6771L12.1848 14.1475L13.6426 13.8618ZM12.2361 14.186L12.2747 14.619L13.6337 14.3242L13.5996 13.9188L12.2361 14.186Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.41658 10.5474L2.53179 11.2559L2.2811 12.3013L2.1543 11.65L2.41658 10.5474ZM2.20304 11.651L2.28593 12.0768L2.48321 11.2541L2.40779 10.7903L2.20304 11.651Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7989 12.3435L14.9694 12.7697L13.4061 13.045L13.2256 12.5992L14.7989 12.3435ZM13.2922 12.6366L13.4358 12.9914L14.9034 12.733L14.7688 12.3967L13.2922 12.6366Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.02641 19.4673L8.20607 19.8647L7.79861 20.408L7.65161 19.967L8.02641 19.4673ZM7.70472 19.9756L7.81506 20.3067L8.15104 19.8587L8.01618 19.5604L7.70472 19.9756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.0565 19.0208L8.0451 19.522L7.64771 20.0519L7.69328 19.505L8.0565 19.0208ZM7.73963 19.5227L7.70895 19.8908L7.99781 19.5057L8.00548 19.1682L7.73963 19.5227Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3879 11.1309L13.8151 11.2008L12.0765 11.422L11.636 11.3525L13.3879 11.1309ZM11.9745 11.3577L12.0773 11.3739L13.484 11.1949L13.387 11.179L11.9745 11.3577Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.34211 13.8096L2.56026 14.058L2.53997 14.9715L2.32153 14.8076L2.34211 13.8096ZM2.38723 13.9332L2.36968 14.7842L2.49438 14.8777L2.51221 14.0755L2.38723 13.9332Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.30367 12.0198L9.08878 12.3834L7.5542 12.4923L7.77848 12.104L9.30367 12.0198ZM7.80685 12.1501L7.64035 12.4385L9.06047 12.3377L9.2173 12.0723L7.80685 12.1501Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3674 11.4583L11.0609 11.6834L9.40308 11.8245L9.71865 11.5893L11.3674 11.4583ZM9.73611 11.6358L9.56559 11.7628L11.0435 11.637L11.2041 11.519L9.73611 11.6358Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.95229 14.1465L3.93191 14.8498L3.2312 14.9572L3.25159 14.1823L3.95229 14.1465ZM3.29807 14.2276L3.28034 14.9015L3.88542 14.8087L3.90316 14.1967L3.29807 14.2276Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.05905 17.5994L8.77471 18.1557L8.17603 18.7544L8.48322 18.1461L9.05905 17.5994ZM8.52203 18.175L8.35267 18.5103L8.7357 18.1273L8.88981 17.8258L8.52203 18.175Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75719 19.4285L9.13315 19.532L8.74701 20.2738L8.40332 20.121L8.75719 19.4285ZM8.78197 19.4847L8.46865 20.0979L8.72543 20.2121L9.06366 19.5623L8.78197 19.4847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0038 15.9021L10.8918 16.4144L9.8374 16.8462L9.95952 16.2887L11.0038 15.9021ZM10.0005 16.3244L9.90339 16.7677L10.8506 16.3798L10.9387 15.977L10.0005 16.3244Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6158 12.001L14.8256 12.3883L13.2508 12.6442L13.031 12.2421L14.6158 12.001ZM13.1055 12.279L13.2765 12.5918L14.7517 12.352L14.5898 12.0531L13.1055 12.279Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21497 4.36548L5.35136 5.19898L4.57992 5.30556L4.43848 4.47713L5.21497 4.36548ZM4.49369 4.51734L4.61913 5.25204L5.29644 5.15846L5.17549 4.4193L4.49369 4.51734Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.9295 5.54028L4.08704 6.39914L3.43528 6.91851L3.26758 6.07491L3.9295 5.54028ZM3.31994 6.09388L3.46677 6.83248L4.03501 6.37966L3.89708 5.62772L3.31994 6.09388Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.653 11.1733L13.9966 11.3197L12.3404 11.5368L11.9871 11.3854L13.653 11.1733ZM12.1668 11.4105L12.3472 11.4879L13.8173 11.2951L13.6462 11.2222L12.1668 11.4105Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.91835 16.7556L9.73772 17.2756L8.93115 17.7668L9.12173 17.2005L9.91835 16.7556ZM9.16103 17.2331L9.01802 17.6581L9.69832 17.2438L9.83223 16.8583L9.16103 17.2331Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5139 14.1733L10.5614 14.1764L10.5566 15.3884L10.4849 15.9237L10.4375 15.9204L10.4423 14.7466L10.5139 14.1733ZM10.4899 14.7497L10.4867 15.5519L10.509 15.3851L10.5122 14.5715L10.4899 14.7497Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3992 11.7012L14.6397 12.0401L13.0524 12.2816L12.8071 11.9328L14.3992 11.7012ZM12.8907 11.9688L13.0744 12.2301L14.5561 12.0046L14.3772 11.7525L12.8907 11.9688Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9132 11.2874L14.2175 11.5039L12.595 11.7279L12.2812 11.5013L13.9132 11.2874ZM12.4065 11.5329L12.6074 11.6781L14.0921 11.4731L13.9008 11.337L12.4065 11.5329Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.51784 14.0325L2.85108 14.1602L2.83096 14.9951L2.49756 14.9451L2.51784 14.0325ZM2.56398 14.1012L2.54614 14.9042L2.78461 14.94L2.80262 14.1927L2.56398 14.1012Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.8035 14.1511L3.29912 14.1828L3.2789 14.9514L2.7832 14.9936L2.8035 14.1511ZM2.84995 14.2018L2.83213 14.9416L3.23238 14.9075L3.25027 14.2274L2.84995 14.2018Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5592 13.4148L13.6487 13.9092L12.1912 14.1948L12.0969 13.6708L13.5592 13.4148ZM12.1523 13.7095L12.2296 14.1387L13.5934 13.8715L13.5208 13.4699L12.1523 13.7095Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1682 11.4585L14.4423 11.7437L12.8451 11.9761L12.5557 11.6811L14.1682 11.4585ZM12.656 11.7153L12.8622 11.9255L14.3437 11.7099L14.1507 11.509L12.656 11.7153Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.63339 12.4404L7.45171 12.8839L6.08398 12.9731L6.27512 12.49L7.63339 12.4404ZM6.30798 12.5365L6.15599 12.9207L7.4189 12.8383L7.56128 12.4907L6.30798 12.5365Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2775 14.6179L12.2722 15.143L11 15.4846V14.92L12.2775 14.6179ZM11.0477 14.9577V15.4224L12.225 15.1064L12.2292 14.6783L11.0477 14.9577Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.91288 13.489L4.83039 14.1077L3.90015 14.1982L3.98761 13.5242L4.91288 13.489ZM4.02968 13.5703L3.95511 14.1449L4.78815 14.0639L4.85817 13.5388L4.02968 13.5703Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.74097 18.6162L9.42596 19.5308L9.06812 19.5811L9.36109 18.7224L9.74097 18.6162ZM9.39809 18.7615L9.13825 19.5231L9.39041 19.4876L9.66634 18.6865L9.39809 18.7615Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6969 11.3484L11.296 11.5072L9.58228 11.6435L9.99356 11.4746L11.6969 11.3484ZM10.0046 11.5215L9.88217 11.5718L11.2851 11.4603L11.3894 11.419L10.0046 11.5215Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.1508 12.9172L6.01484 13.4506L4.85449 13.5353L5.00029 12.9521L6.1508 12.9172ZM5.03777 12.9986L4.9167 13.483L5.97714 13.4056L6.08899 12.9668L5.03777 12.9986Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.48132 18.1546L8.48136 18.1545L8.52394 18.1759L8.5239 18.176L8.31426 18.5953L8.31422 18.5954L8.05215 19.1148L8.05211 19.1148L8.00952 19.0935L8.00956 19.0934L8.24304 18.6264L8.24308 18.6263L8.48132 18.1546Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5021 15.7573L10.3704 17.0466L10.1365 17.5794L10.2618 16.3483L10.5021 15.7573ZM10.3085 16.36L10.2145 17.283L10.3237 17.0343L10.4208 16.0836L10.3085 16.36Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.35954 12.5154L2.49855 12.9829L2.37647 13.9702L2.23071 13.5836L2.35954 12.5154ZM2.37934 12.7492L2.27942 13.5777L2.35289 13.7726L2.45003 12.987L2.37934 12.7492Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.24683 7.7937L9.76987 9.09042L9.8639 9.99546L9.3467 8.71606L9.24683 7.7937ZM9.33121 8.13029L9.39337 8.70434L9.78164 9.6648L9.72317 9.10202L9.33121 8.13029Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53874 11.7673L9.27665 12.0693L7.74292 12.154L8.01963 11.8321L9.53874 11.7673ZM8.0423 11.8788L7.85197 12.1002L9.25388 12.0228L9.43022 11.8197L8.0423 11.8788Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47984 19.2209L8.80494 19.4539L8.44739 20.1537L8.14941 19.8666L8.47984 19.2209ZM8.49706 19.2919L8.20795 19.8568L8.43419 20.0748L8.74384 19.4688L8.49706 19.2919Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2156 17.3574L9.98255 18.445L9.66553 18.7151L9.88671 17.6863L10.2156 17.3574ZM9.93034 17.7101L9.74168 18.5876L9.93934 18.4192L10.1352 17.5052L9.93034 17.7101Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4334 12.9915L13.5652 13.4661L12.1027 13.7221L11.9609 13.218L13.4334 12.9915ZM12.0214 13.2569L12.1369 13.6678L13.5052 13.4282L13.3988 13.045L12.0214 13.2569Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0514 15.4148L10.9934 15.957L9.95264 16.3423L10.0103 15.7551L11.0514 15.4148ZM10.0547 15.7908L10.0075 16.2711L10.9492 15.9225L10.9961 15.483L10.0547 15.7908Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1467 11.0318L10.194 11.0266L10.4278 12.2723L10.4469 12.9461L10.3997 12.9512L10.1706 11.7294L10.1467 11.0318ZM10.2051 11.3443L10.2181 11.7242L10.3907 12.6447L10.3803 12.2774L10.2051 11.3443Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.47057 6.81958L3.61462 7.66848L3.05134 8.35466L2.89185 7.53146L3.47057 6.81958ZM2.94287 7.54424L3.07884 8.24605L3.564 7.65503L3.44118 6.93127L2.94287 7.54424Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.30861 18.4922L8.32667 18.9615L7.99048 19.6227L8.00251 19.0933L8.30861 18.4922ZM8.04991 19.1052L8.04288 19.4145L8.27857 18.951L8.26803 18.6769L8.04991 19.1052Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2784 11.4506L11.8082 11.3362L11.8125 11.3836L11.5452 11.4123L10.0008 11.5267L9.99658 11.4792L10.2784 11.4506Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.30546 18.9072L8.51556 19.2555L8.17731 19.9216L7.99487 19.518L8.30546 18.9072ZM8.30901 19.0054L8.04772 19.5193L8.17981 19.8115L8.4611 19.2575L8.30901 19.0054Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.18767 17.1399L8.99053 17.7257L8.44409 18.2445L8.66339 17.6035L9.18767 17.1399ZM8.7044 17.6308L8.55199 18.0763L8.94914 17.6992L9.0856 17.2938L8.7044 17.6308Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33955 13.4893L4.03556 13.52L3.94869 14.1945L3.24756 14.2303L3.33955 13.4893ZM3.38142 13.5388L3.30185 14.1798L3.90651 14.1489L3.98167 13.5653L3.38142 13.5388Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.1665 4.3689L5.96916 4.48574L6.09577 5.32138L5.30324 5.20453L5.1665 4.3689ZM5.22405 4.42543L5.34465 5.16246L6.039 5.26483L5.92733 4.52781L5.22405 4.42543Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2318 14.1382L12.2789 14.6666L11.0026 14.9684L10.9504 14.4004L12.2318 14.1382ZM11.0018 14.4385L11.045 14.9094L12.2277 14.6298L12.1891 14.1956L11.0018 14.4385Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0134 16.2632L9.89618 16.8224L9.10718 17.2631L9.23458 16.6526L10.0134 16.2632ZM9.27652 16.6849L9.17518 17.1705L9.85399 16.7914L9.9465 16.3499L9.27652 16.6849Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.51751 11.1262L2.6128 11.7516L2.37697 12.7431L2.26392 12.1838L2.51751 11.1262ZM2.31271 12.1847L2.38075 12.5213L2.56429 11.7496L2.50708 11.3741L2.31271 12.1847Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2741 12.5918L13.4491 13.0373L11.9759 13.2639L11.791 12.7938L13.2741 12.5918ZM11.8576 12.8328L12.0063 13.211L13.3829 12.9992L13.2434 12.6441L11.8576 12.8328Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.83956 12.1028L7.61114 12.4889L6.24854 12.5387L6.49705 12.1227L7.83956 12.1028ZM6.52433 12.1699L6.33441 12.4878L7.58338 12.4423L7.75526 12.1517L6.52433 12.1699Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.4633 12.9163L2.67034 13.2416L2.5577 14.1222L2.34424 13.8791L2.4633 12.9163ZM2.49432 13.0538L2.39416 13.8637L2.52383 14.0114L2.62088 13.2526L2.49432 13.0538Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.80861 11.5813L9.49254 11.8123L7.95337 11.8779L8.28952 11.6318L9.80861 11.5813ZM8.3058 11.6789L8.10821 11.8236L9.47608 11.7653L9.65563 11.6341L8.3058 11.6789Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8111 11.3311L12.2846 11.4058L10.5701 11.541L10.0791 11.4605L11.8111 11.3311ZM10.4816 11.4782L10.5721 11.4931L11.8789 11.39L11.8092 11.379L10.4816 11.4782Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.0835 8.22754L3.19956 9.0294L2.73896 9.85225L2.60181 9.08733L3.0835 8.22754ZM2.65173 9.09573L2.76238 9.71285L3.15008 9.02023L3.05645 8.37333L2.65173 9.09573Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.14274 12.9272L5.05411 12.9524L4.90914 13.5323L3.98267 13.5675L4.14274 12.9272ZM4.17969 12.9759L4.0443 13.5175L4.87158 13.486L4.99349 12.9984L4.17969 12.9759Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.90177 13.3899L3.39185 13.4953L3.29555 14.2302L2.80005 14.1986L2.90177 13.3899ZM2.94256 13.4474L2.85366 14.1542L3.25409 14.1798L3.33889 13.5326L2.94256 13.4474Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75729 9.6897L2.85125 10.4193L2.49827 11.3465L2.3877 10.6665L2.75729 9.6897ZM2.43678 10.6714L2.51684 11.1638L2.80245 10.4135L2.73438 9.88491L2.43678 10.6714Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.18856 12.4797L6.32629 12.4897L6.13619 12.9703L4.98364 13.0052L5.18856 12.4797ZM5.22102 12.5277L5.05422 12.9554L6.1034 12.9236L6.25643 12.5368L5.22102 12.5277Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.70264 6.82129L9.31697 7.95462L9.41156 8.82823L8.80839 7.71183L8.70264 6.82129ZM8.7793 7.06272L8.85464 7.69718L9.33803 8.59188L9.2706 7.96908L8.7793 7.06272Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0815 12.2351L13.2959 12.6371L11.8111 12.8393L11.5818 12.4127L13.0815 12.2351ZM11.6569 12.4518L11.8374 12.7877L13.2216 12.5991L13.0547 12.2863L11.6569 12.4518Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.02005 18.678L9.41161 18.7215L9.11837 19.581L8.74341 19.4777L9.02005 18.678ZM9.05264 18.7295L8.80506 19.4453L9.08776 19.5231L9.34733 18.7623L9.05264 18.7295Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.61817 5.24927L4.75959 6.11291L4.04792 6.42808L3.89136 5.57455L4.61817 5.24927ZM3.94498 5.60277L4.08389 6.36003L4.7066 6.08426L4.58115 5.31805L3.94498 5.60277Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.62682 13.2073L2.9477 13.4064L2.84669 14.2095L2.51489 14.0823L2.62682 13.2073ZM2.66468 13.2869L2.56691 14.0512L2.80699 14.1432L2.89661 13.4308L2.66468 13.2869Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.081 11.3738L12.4414 11.5282L10.8436 11.6605L10.4673 11.501L12.081 11.3738ZM10.6654 11.5332L10.8514 11.612L12.2465 11.4965L12.0731 11.4222L10.6654 11.5332Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0475 14.9075V15.4661L10.0085 15.8057V15.1972L11.0475 14.9075ZM10.0562 15.2334V15.74L10.9998 15.4315V14.9702L10.0562 15.2334Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1437 13.6628L12.2379 14.1808L10.957 14.4429L10.8528 13.8854L12.1437 13.6628ZM10.9085 13.9242L10.9949 14.3865L12.1826 14.1435L12.1053 13.7178L10.9085 13.9242Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.51503 17.9982L8.43072 18.2413L8.4758 18.1511L8.55437 17.9214L8.51503 17.9982ZM8.66248 17.6062L8.70623 17.6248L8.51986 18.1696L8.30945 18.5904L8.26562 18.5719L8.47109 17.9794L8.66248 17.6062Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8556 11.9258L13.1061 12.2796L11.6035 12.4576L11.3381 12.084L12.8556 11.9258ZM11.4242 12.1229L11.6259 12.4069L13.0209 12.2417L12.8328 11.9761L11.4242 12.1229Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3506 11.4927L12.6665 11.7208L11.117 11.8658L10.7812 11.6226L12.3506 11.4927ZM10.9134 11.6595L11.1305 11.8167L12.5357 11.6852L12.3369 11.5416L10.9134 11.6595Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.91846 4.47314L6.72311 4.81436L6.84042 5.65593L6.04597 5.31472L5.91846 4.47314ZM5.97835 4.55031L6.08912 5.28139L6.78155 5.57878L6.67964 4.84769L5.97835 4.55031Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6104 11.6733L12.8952 11.9694L11.3721 12.1281L11.0723 11.8172L12.6104 11.6733ZM11.1753 11.8554L11.3902 12.0783L12.7932 11.9321L12.5919 11.7229L11.1753 11.8554Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4265 12.7146L10.5569 14.1768L10.4578 14.9692L10.3279 13.5565L10.4265 12.7146ZM10.3758 13.5571L10.4651 14.5273L10.509 14.1759L10.4201 13.179L10.3758 13.5571Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1283 11.4707L9.73725 11.6313L8.16821 11.6834L8.57858 11.5124L10.1283 11.4707ZM8.58872 11.5598L8.42721 11.6271L9.72709 11.584L9.86981 11.5253L8.58872 11.5598Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75227 11.822L8.08261 11.827L7.80843 12.146L6.45825 12.166L6.75227 11.822ZM6.77416 11.8698L6.56302 12.1168L7.78628 12.0986L7.97914 11.8743L6.77416 11.8698Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0601 15.7351L10.0025 16.3221L9.22778 16.7095L9.29052 16.0664L10.0601 15.7351ZM9.33522 16.099L9.28359 16.6283L9.95763 16.2913L10.0048 15.8108L9.33522 16.099Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.29245 16.6096L9.16311 17.2294L8.65088 17.6823L8.791 17.0032L9.29245 16.6096ZM8.83415 17.0299L8.72646 17.5518L9.11969 17.2041L9.21911 16.7278L8.83415 17.0299Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.08154 5.97485L8.76397 6.93337L8.86619 7.7942L8.18913 6.84631L8.08154 5.97485ZM8.15191 6.15585L8.23494 6.82846L8.79695 7.61527L8.71808 6.95109L8.15191 6.15585Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.71297 18.5176L9.06102 18.6916L8.78658 19.4946L8.46021 19.2607L8.71297 18.5176ZM8.74051 18.5846L8.51671 19.2426L8.76222 19.4185L9.00246 18.7156L8.74051 18.5846Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.94151 17.6584L9.72393 18.6704L9.34814 18.7754L9.54949 17.8241L9.94151 17.6584ZM9.59096 17.8583L9.41105 18.7084L9.68339 18.6323L9.87566 17.738L9.59096 17.8583Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50549 17.8774L8.55078 18.3109L8.28847 19.0864L8.26904 18.5812L8.50549 17.8774ZM8.317 18.5881L8.32616 18.8262L8.5023 18.3055L8.48086 18.1004L8.317 18.5881Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.50445 12.844L4.18576 12.9362L4.03188 13.5671L3.33521 13.5364L3.50445 12.844ZM3.54056 12.897L3.39528 13.4913L3.99486 13.5178L4.12692 12.9763L3.54056 12.897Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66504 4.77856L7.44303 5.34671L7.55666 6.18855L6.78382 5.63067L6.66504 4.77856ZM6.72777 4.88338L6.82821 5.60394L7.49459 6.08497L7.39852 5.37321L6.72777 4.88338Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.58968 11.6504L2.70351 12.1656L2.48115 13.0763L2.34937 12.633L2.58968 11.6504ZM2.39872 12.6318L2.47663 12.8938L2.65458 12.1651L2.58732 11.8606L2.39872 12.6318Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0085 13.2109L12.15 13.7091L10.8589 13.9317L10.7075 13.394L12.0085 13.2109ZM10.7682 13.4335L10.8932 13.8775L12.0897 13.6712L11.974 13.2639L10.7682 13.4335Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.79102 9.63257L10.1895 11.0273L10.22 11.918L9.82755 10.5583L9.79102 9.63257ZM9.8543 10.0275L9.87495 10.5506L10.1592 11.5355L10.1421 11.0347L9.8543 10.0275Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.39233 5.28711L8.13923 6.04946L8.24421 6.89982L7.50783 6.14284L7.39233 5.28711ZM7.45877 5.42301L7.55296 6.12088L8.17955 6.765L8.09391 6.07129L7.45877 5.42301Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.51789 18.2546L8.75353 18.5464L8.49452 19.3079L8.28125 18.9543L8.51789 18.2546ZM8.53527 18.352L8.33348 18.9486L8.4824 19.1955L8.69995 18.5559L8.53527 18.352Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4899 14.614V15.9253L10.2659 16.4762V15.2254L10.4899 14.614ZM10.3135 15.2339V16.2325L10.4422 15.916V14.8826L10.3135 15.2339Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.4298 12.0742L6.55109 12.1193L6.30999 12.5372L5.1687 12.5272L5.4298 12.0742ZM5.45669 12.123L5.25077 12.4802L6.28261 12.4893L6.47042 12.1637L5.45669 12.123Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9972 14.3901L11.0492 14.9565L10.0113 15.2459L9.95435 14.6297L10.9972 14.3901ZM10.0056 14.6668L10.0535 15.1847L10.9981 14.9213L10.9548 14.4488L10.0056 14.6668Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3168 16.2812L10.1935 17.4422L9.87329 17.7624L9.98572 16.6637L10.3168 16.2812ZM10.0316 16.6835L9.93434 17.634L10.1479 17.4204L10.2533 16.4273L10.0316 16.6835Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2798 11.4409L10.2831 11.4884L10.0009 11.5219L8.58441 11.56L8.58081 11.5126L8.887 11.4743L10.2798 11.4409Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.34061 12.407L5.23855 12.4827L5.03501 13.0046L4.12207 12.9793L4.34061 12.407ZM4.37234 12.4575L4.19056 12.9336L5.00279 12.956L5.17097 12.5248L4.37234 12.4575Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.08833 6.34985L4.21048 7.20491L3.57244 7.70513L3.42993 6.86534L4.08833 6.34985ZM3.48167 6.88536L3.60603 7.61824L4.15941 7.18439L4.0528 6.43819L3.48167 6.88536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.07673 12.6868L3.55077 12.8553L3.38289 13.5421L2.89307 13.4367L3.07673 12.6868ZM3.11047 12.7493L2.95102 13.4005L3.3476 13.4858L3.49426 12.8858L3.11047 12.7493Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8396 12.7876L12.0294 13.2568L10.7274 13.44L10.5227 12.9361L11.8396 12.7876ZM10.5905 12.9764L10.7576 13.3876L11.9623 13.2181L11.809 12.839L10.5905 12.9764Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.04631 11.6074L8.3688 11.6328L8.03865 11.8745L6.69116 11.8694L7.04631 11.6074ZM7.06157 11.6554L6.83531 11.8223L8.02315 11.8268L8.22674 11.6777L7.06157 11.6554Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.67179 12.0947L2.85674 12.482L2.65564 13.307L2.45337 12.9892L2.67179 12.0947ZM2.68638 12.2359L2.50449 12.9808L2.63581 13.1871L2.80642 12.4872L2.68638 12.2359Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.34892 5.15674L5.47493 6.02368L4.71803 6.12528L4.5769 5.2634L5.34892 5.15674ZM4.63183 5.30392L4.75758 6.07189L5.42084 5.98286L5.30857 5.21042L4.63183 5.30392Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86522 16.8843L8.71134 17.63L8.44067 18.1578L8.60889 17.3227L8.86522 16.8843ZM8.65404 17.3399L8.55538 17.8297L8.66597 17.614L8.7599 17.1589L8.65404 17.3399Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.84491 10.3096L2.91307 10.9627L2.57865 11.8439L2.48779 11.2477L2.84491 10.3096ZM2.5368 11.2529L2.59844 11.6574L2.86449 10.9564L2.81827 10.5135L2.5368 11.2529Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2772 11.4456L10.5653 11.4936L10.5621 11.5409L9.18722 11.5791L8.87988 11.5263L8.88335 11.479L10.2772 11.4456ZM9.12908 11.5208L9.19062 11.5313L10.3146 11.5001L10.2738 11.4933L9.12908 11.5208Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0563 15.1814V15.7885L9.28906 16.1188V15.4558L10.0563 15.1814ZM9.33672 15.4894V16.0464L10.0087 15.7571V15.2491L9.33672 15.4894Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.81284 12.4407L3.12036 12.7098L2.93754 13.4563L2.61865 13.2583L2.81284 12.4407ZM2.84095 12.5286L2.67295 13.2359L2.90687 13.3811L3.06718 12.7265L2.84095 12.5286Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6331 12.407L11.8621 12.8331L10.5435 12.9818L10.2944 12.526L11.6331 12.407ZM10.3712 12.567L10.57 12.9309L11.7868 12.7936L11.606 12.4572L10.3712 12.567Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34138 16.0403L9.27839 16.686L8.78345 17.0745L8.85178 16.3596L9.34138 16.0403ZM8.89704 16.387L8.84147 16.9683L9.23293 16.6611L9.28432 16.1344L8.89704 16.387Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.60665 7.60303L3.69995 8.42202L3.15408 9.08118L3.04004 8.29326L3.60665 7.60303ZM3.09022 8.30725L3.18586 8.96808L3.65031 8.40725L3.57205 7.72029L3.09022 8.30725Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.6 17.8157L9.39847 18.7679L9.00781 18.7245L9.19326 17.8374L9.6 17.8157ZM9.2324 17.883L9.06519 18.6829L9.36079 18.7158L9.54051 17.8666L9.2324 17.883Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9046 13.8818L11.0085 14.4375L9.9662 14.6769L9.84717 14.0714L10.9046 13.8818ZM9.90328 14.1097L10.0035 14.6194L10.9532 14.4013L10.8665 13.9371L9.90328 14.1097Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5709 11.4932L10.9585 11.6611L9.49903 11.7127L9.08521 11.5344L10.5709 11.4932ZM9.30233 11.5761L9.50805 11.6647L10.7459 11.621L10.5616 11.5411L9.30233 11.5761Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.18661 8.95093L3.25634 9.70184L2.81239 10.4905L2.72119 9.78241L3.18661 8.95093ZM2.77047 9.79195L2.84131 10.342L3.20751 9.69142L3.15333 9.10795L2.77047 9.79195Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3976 12.0786L11.6629 12.4522L10.3212 12.5714L10.0361 12.1681L11.3976 12.0786ZM10.1242 12.21L10.3443 12.5215L11.576 12.412L11.3742 12.1279L10.1242 12.21Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.70906 11.7451L6.81187 11.8257L6.52116 12.1658L5.39355 12.1205L5.70906 11.7451ZM5.72987 11.7944L5.49256 12.0767L6.49996 12.1172L6.71441 11.8664L5.72987 11.7944Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8595 11.6169L11.1974 11.8616L9.79653 11.9321L9.43384 11.6673L10.8595 11.6169ZM9.57322 11.7101L9.811 11.8837L11.0598 11.8208L10.8449 11.6651L9.57322 11.7101Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.73171 12.2759L4.39363 12.4196L4.1717 12.9873L3.48926 12.8949L3.73171 12.2759ZM3.76127 12.3311L3.55575 12.8558L4.14096 12.935L4.32889 12.4543L3.76127 12.3311Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1334 11.812L11.4339 12.1236L10.0659 12.2135L9.74536 11.8819L11.1334 11.812ZM9.85255 11.9242L10.0848 12.1645L11.3284 12.0828L11.1142 11.8607L9.85255 11.9242Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35432 11.4741L8.68974 11.5159L8.29749 11.6793L6.93848 11.6533L7.35432 11.4741ZM7.36343 11.5221L7.15975 11.6098L8.2884 11.6314L8.46803 11.5566L7.36343 11.5221Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34375 8.56982L9.85114 9.82495L9.8853 10.6902L9.38929 9.46352L9.34375 8.56982ZM9.40577 8.85039L9.43648 9.45309L9.82688 10.4186L9.80385 9.83511L9.40577 8.85039Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1865 11.5588L10.452 12.9459L10.362 13.7141L10.1084 12.376L10.1865 11.5588ZM10.2023 11.8947L10.1565 12.3738L10.3509 13.3995L10.4038 12.9477L10.2023 11.8947Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.63399 17.2024L8.69841 17.5889L8.51877 18.4639L8.46899 17.9875L8.63399 17.2024ZM8.51717 17.99L8.53412 18.1522L8.64995 17.588L8.62858 17.4598L8.51717 17.99Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.606 11.968L5.4815 12.0844L5.22265 12.5334L4.32178 12.4575L4.606 11.968ZM4.63125 12.0194L4.40079 12.4164L5.19648 12.4834L5.40466 12.1223L4.63125 12.0194Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8753 17.7446L9.2406 17.8482L9.0534 18.7437L8.70435 18.5692L8.8753 17.7446ZM8.91157 17.8044L8.75845 18.543L9.0194 18.6735L9.18486 17.882L8.91157 17.8044Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.65932 17.5393L8.91495 17.7609L8.73478 18.6051L8.49609 18.3095L8.65932 17.5393ZM8.68919 17.6283L8.54742 18.2972L8.70904 18.4973L8.86247 17.7784L8.68919 17.6283Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7597 13.3875L10.9158 13.9234L9.85841 14.113L9.68726 13.5321L10.7597 13.3875ZM9.74867 13.5719L9.89205 14.0585L10.8553 13.8859L10.7254 13.4402L9.74867 13.5719Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.001 14.6177L10.0578 15.2315L9.29205 15.5053L9.2251 14.8307L10.001 14.6177ZM9.27649 14.866L9.33345 15.4399L10.0069 15.1991L9.95883 14.6787L9.27649 14.866Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.89851 10.8716L2.98475 11.4293L2.67299 12.2483L2.56372 11.7537L2.89851 10.8716ZM2.61332 11.7573L2.68515 12.0824L2.93573 11.4241L2.879 11.0573L2.61332 11.7573Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0368 16.6294L9.93082 17.7145L9.54248 17.8786L9.64292 16.8592L10.0368 16.6294ZM9.68796 16.8881L9.59777 17.8035L9.88615 17.6816L9.98032 16.7175L9.68796 16.8881Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.2959 5.15527L6.0889 5.27727L6.20006 6.14128L5.42222 6.02435L5.2959 5.15527ZM5.35233 5.21217L5.46429 5.98248L6.14474 6.08477L6.04621 5.31892L5.35233 5.21217Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.68699 11.4456L8.88476 11.4837L8.88664 11.5312L8.58472 11.5648L7.36302 11.5266L7.36133 11.4791L7.68699 11.4456Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.31907 12.0557L3.77168 12.2872L3.53648 12.9008L3.06274 12.7324L3.31907 12.0557ZM3.34478 12.1223L3.12454 12.7038L3.50862 12.8403L3.71186 12.3102L3.34478 12.1223Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.90669 16.2871L8.83577 17.0291L8.59766 17.4364L8.67505 16.6127L8.90669 16.2871ZM8.7213 16.6299L8.66526 17.2263L8.78933 17.0141L8.84231 16.4598L8.7213 16.6299Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.753 6.05884L4.85413 6.92354L4.16115 7.22927L4.03979 6.37978L4.753 6.05884ZM4.09204 6.40853L4.19944 7.16029L4.80271 6.89414L4.71322 6.129L4.09204 6.40853Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3648 13.4392L10.4856 14.7514L10.271 15.3372L10.156 14.0831L10.3648 13.4392ZM10.2044 14.0885L10.2991 15.1219L10.4371 14.7451L10.3386 13.6745L10.2044 14.0885Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.3318 15.4285V16.0954L8.8457 16.4125V15.6821L9.3318 15.4285ZM8.89336 15.711V16.3245L9.28414 16.0696V15.5071L8.89336 15.711Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02716 11.5117L7.11276 11.6137L6.77033 11.8705L5.65381 11.7889L6.02716 11.5117ZM6.0409 11.5609L5.78519 11.7507L6.75601 11.8216L6.98545 11.6496L6.0409 11.5609Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3089 15.1577V16.3633L9.98486 16.7376V15.5935L10.3089 15.1577ZM10.0325 15.6093V16.6097L10.2613 16.3455V15.3017L10.0325 15.6093Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5673 12.9309L10.7714 13.4333L9.69792 13.5781L9.479 13.0308L10.5673 12.9309ZM9.54698 13.0724L9.72837 13.5259L10.7041 13.3942L10.5365 12.9816L9.54698 13.0724Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.96095 11.3574L3.11899 11.8033L2.83384 12.5447L2.65308 12.1662L2.96095 11.3574ZM2.70488 12.1641L2.82911 12.4242L3.06819 11.8026L2.95935 11.4956L2.70488 12.1641Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.80103 7.59814L9.39832 8.70367L9.44208 9.56243L8.85572 8.4787L8.80103 7.59814ZM8.86201 7.81128L8.90263 8.46526L9.38377 9.35451L9.35128 8.71685L8.86201 7.81128Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.08613 11.7522L3.36406 12.0846L3.10367 12.7586L2.79858 12.4916L3.08613 11.7522ZM3.10131 11.8447L2.85511 12.4777L3.08377 12.6778L3.30948 12.0936L3.10131 11.8447Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.68848 11.4882L7.69321 11.4409L8.891 11.4791L9.20237 11.5318L9.19771 11.5791L8.02847 11.5457L7.68848 11.4882Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.01166 11.7747L4.64944 11.9701L4.36853 12.4591L3.70508 12.3151L4.01166 11.7747ZM4.03425 11.8314L3.77849 12.2823L4.34457 12.4051L4.57836 11.9982L4.03425 11.8314Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.90952 11.6057L5.76463 11.7584L5.45284 12.124L4.57251 12.0069L4.90952 11.6057ZM4.92828 11.6575L4.66482 11.9711L5.43341 12.0733L5.67443 11.7907L4.92828 11.6575Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.2456 9.61426L3.2894 10.2931L2.86798 11.0411L2.80225 10.4112L3.2456 9.61426ZM2.85121 10.4213L2.89985 10.8875L3.24093 10.2821L3.20848 9.77903L2.85121 10.4213Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.89404 14.0576L10.0074 14.6605L9.23213 14.8733L9.10864 14.2147L9.89404 14.0576ZM9.16416 14.2522L9.26943 14.8136L9.95245 14.6261L9.85611 14.1138L9.16416 14.2522Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03857 5.25952L6.83311 5.60077L6.92976 6.46548L6.15048 6.12932L6.03857 5.25952ZM6.0965 5.33626L6.19428 6.09631L6.87328 6.38922L6.78883 5.63361L6.0965 5.33626Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3464 12.522L10.595 12.9768L9.50447 13.0769L9.23071 12.582L10.3464 12.522ZM9.30924 12.6255L9.53111 13.0266L10.5184 12.936L10.319 12.5712L9.30924 12.6255Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.68683 16.4871L8.77472 16.8146L8.68037 17.7699L8.60742 17.3322L8.68683 16.4871ZM8.70948 16.7554L8.65545 17.3305L8.66828 17.4074L8.72644 16.8186L8.70948 16.7554Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.69274 16.843L9.58715 17.8687L9.18164 17.8904L9.27665 16.9403L9.69274 16.843ZM9.32068 16.9789L9.23459 17.8398L9.54392 17.8233L9.63849 16.9046L9.32068 16.9789Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.2053 7.14868L4.27183 7.97769L3.65632 8.45984L3.56421 7.6513L4.2053 7.14868ZM3.61457 7.67237L3.69402 8.36977L4.22227 7.95597L4.16489 7.24092L3.61457 7.67237Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.90161 11.4946L9.19891 11.5317L9.61824 11.7152L8.35448 11.6887L7.90161 11.4946ZM8.15042 11.5494L8.36474 11.6413L9.37896 11.6625L9.18829 11.5791L8.15042 11.5494Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.84238 10.4207L10.2186 11.7294L10.1443 12.5065L9.77954 11.2432L9.84238 10.4207ZM9.86973 10.6883L9.82771 11.2383L10.1206 12.2529L10.1703 11.7338L9.86973 10.6883Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.1814 6.74878L8.85478 7.69152L8.90802 8.54867L8.25061 7.62726L8.1814 6.74878ZM8.24239 6.91616L8.29708 7.61034L8.85014 8.38549L8.80807 7.70811L8.24239 6.91616Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.36899 11.3689L7.45357 11.4894L7.05293 11.6558L5.93774 11.5511L6.36899 11.3689ZM6.37608 11.4176L6.13061 11.5214L7.04559 11.6073L7.26448 11.5163L6.37608 11.4176Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0923 12.1697L10.3775 12.5679L9.2583 12.6281L8.948 12.1948L10.0923 12.1697ZM9.03931 12.2404L9.28183 12.5791L10.2881 12.525L10.0682 12.2179L9.03931 12.2404Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.69552 8.35742L3.73748 9.12329L3.21346 9.75627L3.14526 9.02188L3.69552 8.35742ZM3.19454 9.0371L3.25025 9.63709L3.68888 9.10727L3.65459 8.48156L3.19454 9.0371Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.28369 11.6394L9.50668 11.665L9.86802 11.9273L8.67606 11.9221L8.28369 11.6394ZM8.43577 11.6903L8.69153 11.8745L9.72036 11.879L9.49076 11.7123L8.43577 11.6903Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.63208 11.8743L9.80904 11.8793L10.1358 12.2117L8.98374 12.2369L8.63208 11.8743ZM8.74515 11.9224L9.00348 12.1888L10.0245 12.1664L9.78898 11.9269L8.74515 11.9224Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.78125 5.56689L7.55317 6.119L7.63541 6.98254L6.87891 6.44073L6.78125 5.56689ZM6.84049 5.66785L6.92392 6.41434L7.57805 6.88284L7.50779 6.14513L6.84049 5.66785Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27158 14.8152L9.33821 15.4815L8.85441 15.7339L8.77734 14.9992L9.27158 14.8152ZM8.82859 15.031L8.8945 15.6592L9.28758 15.4542L9.2303 14.8814L8.82859 15.031Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50024 6.06689L8.23495 6.82215L8.30276 7.68288L7.58371 6.94328L7.50024 6.06689ZM7.56053 6.19721L7.62956 6.92208L8.24488 7.55498L8.18879 6.84305L7.56053 6.19721Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73037 16.7739L8.99991 16.9173L8.90783 17.8176L8.64941 17.5936L8.73037 16.7739ZM8.7708 16.8494L8.69926 17.5738L8.86975 17.7215L8.94924 16.9443L8.7708 16.8494Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8983 15.637V16.3763L8.67432 16.6911V15.8731L8.8983 15.637ZM8.72197 15.8921V16.5419L8.85064 16.361V15.7564L8.72197 15.8921Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.9482 16.9102L9.32466 16.9374L9.22884 17.8956L8.86304 17.7919L8.9482 16.9102ZM8.99116 16.9611L8.9143 17.7569L9.18708 17.8342L9.27236 16.9814L8.99116 16.9611Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.73499 13.5259L9.90523 14.1088L9.11978 14.2659L8.9292 13.6272L9.73499 13.5259ZM8.99095 13.6675L9.15302 14.2107L9.84491 14.0723L9.70063 13.5782L8.99095 13.6675Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.62877 11.5261L4.05475 11.8049L3.75113 12.3349L3.29883 12.1035L3.62877 11.5261ZM3.64503 11.5937L3.36497 12.0838L3.73237 12.2718L3.99108 11.8201L3.64503 11.5937Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.46455 5.97632L5.54523 6.84357L4.81227 6.94028L4.71143 6.07809L5.46455 5.97632ZM4.7642 6.11905L4.85398 6.88671L5.49353 6.80232L5.4217 6.0302L4.7642 6.11905Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28667 10.2043L3.34214 10.7979L2.94727 11.4982L2.86401 10.9598L3.28667 10.2043ZM2.91362 10.9688L2.97334 11.3549L3.29331 10.7875L3.25347 10.3613L2.91362 10.9688Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25623 11.3391L6.08973 11.5192L5.72553 11.7951L4.86133 11.6408L5.25623 11.3391ZM5.26768 11.3903L4.97633 11.6129L5.71338 11.7445L5.97825 11.5439L5.26768 11.3903Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0373 15.5532V16.6874L9.64648 16.9153V15.8517L10.0373 15.5532ZM9.69414 15.8753V16.8324L9.98961 16.66V15.6496L9.69414 15.8753Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73467 11.3264L7.89646 11.4651L7.3638 11.5269L6.16968 11.3942L6.73467 11.3264ZM6.73469 11.3744L6.58483 11.3924L7.36369 11.4789L7.48868 11.4644L6.73469 11.3744Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.34928 11.3889L4.95889 11.6214L4.62181 12.0164L3.9812 11.8201L4.34928 11.3889ZM4.3637 11.4454L4.06459 11.7958L4.60587 11.9617L4.87878 11.6419L4.3637 11.4454Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1375 12.2717L10.3762 13.5585L10.174 14.1819L9.94116 12.9505L10.1375 12.2717ZM9.9901 12.9528L10.1862 13.9899L10.3271 13.5553L10.1274 12.4783L9.9901 12.9528Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.41624 11.1709L3.66478 11.5545L3.33571 12.1355L3.06006 11.8058L3.41624 11.1709ZM3.41918 11.2631L3.1177 11.8005L3.32815 12.0522L3.60907 11.5562L3.41918 11.2631Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33122 10.7292L3.45264 11.2204L3.08857 11.8601L2.93408 11.4243L3.33122 10.7292ZM2.9863 11.4289L3.09868 11.746L3.40181 11.2134L3.31353 10.8563L2.9863 11.4289Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1973 14.0142L10.3144 15.2411L9.99558 15.6698L9.88403 14.5088L10.1973 14.0142ZM9.93303 14.5205L10.0312 15.5421L10.2652 15.2274L10.1631 14.1573L9.93303 14.5205Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.52869 13.0317L9.75183 13.5766L8.94505 13.6781L8.70166 13.0774L9.52869 13.0317ZM8.77087 13.1213L8.97545 13.6262L9.68414 13.5371L9.49744 13.0812L8.77087 13.1213Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.15038 14.2048L9.27806 14.8636L8.78471 15.0473L8.64648 14.3203L9.15038 14.2048ZM8.70188 14.3565L8.82099 14.983L9.22359 14.833L9.11298 14.2623L8.70188 14.3565Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66943 15.7649L8.76951 16.0014V16.9972L8.66943 16.6242V15.7649ZM8.71709 15.9998V16.6179L8.72186 16.6357V16.0111L8.71709 15.9998Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.3955 9.35059L9.87556 10.5508L9.81496 11.344L9.34595 10.1819L9.3955 9.35059ZM9.43039 9.56614L9.39416 10.174L9.78296 11.1374L9.8272 10.5582L9.43039 9.56614Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73047 11.3738L6.73756 11.3267L7.69605 11.4411L8.03024 11.4984L8.02353 11.5456L7.09821 11.4406L6.73047 11.3738Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.73607 9.04517L3.74672 9.74801L3.25012 10.3492L3.20752 9.68885L3.73607 9.04517ZM3.25629 9.70456L3.28994 10.2261L3.69881 9.73118L3.69039 9.17589L3.25629 9.70456Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82273 14.9736L8.89435 15.7052L8.67451 15.937L8.59668 15.1223L8.82273 14.9736ZM8.64685 15.1464L8.71199 15.8282L8.8448 15.6882L8.78299 15.0568L8.64685 15.1464Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.85526 6.87305L4.89587 7.71046L4.22589 8.00198L4.15991 7.17982L4.85526 6.87305ZM4.21012 7.20976L4.26805 7.93166L4.84667 7.6799L4.81102 6.94465L4.21012 7.20976Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4292 12.5798H9.28409L9.55184 13.0734L8.72242 13.1191L8.4292 12.5798ZM8.50935 12.6275L8.74989 13.0699L9.47406 13.0299L9.25572 12.6275H8.50935Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.63661 11.187L6.45331 11.3872L6.03638 11.561L5.18286 11.3766L5.63661 11.187ZM5.6405 11.237L5.34128 11.362L6.03184 11.5112L6.30116 11.399L5.6405 11.237Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.68927 15.8281V16.8929L9.27466 16.9844V15.995L9.68927 15.8281ZM9.32231 16.0272V16.9251L9.64161 16.8546V15.8987L9.32231 16.0272Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.4165 5.9751L6.19476 6.09718L6.25032 6.96079L5.49731 6.84377L5.4165 5.9751ZM5.46963 6.03167L5.54132 6.80238L6.19895 6.90458L6.14966 6.13834L5.46963 6.03167Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.94922 11.3711L8.03251 11.4939L8.50635 11.7038L7.46245 11.5977L6.94922 11.3711ZM7.25412 11.4536L7.47479 11.5511L8.21325 11.6261L8.01989 11.5405L7.25412 11.4536Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.00576 11.0881L4.39516 11.4091L4.03243 11.8392L3.6062 11.5603L4.00576 11.0881ZM4.01175 11.1548L3.6774 11.55L4.02321 11.7762L4.32766 11.4152L4.01175 11.1548Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72168 15.9768L8.99339 16.0346L8.99848 16.9706L8.7267 16.826L8.72168 15.9768ZM8.76969 16.0357L8.77419 16.7973L8.95039 16.891L8.94594 16.0732L8.76969 16.0357Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.27017 7.91821L4.2805 8.69777L3.69193 9.15726L3.65063 8.40352L4.27017 7.91821ZM3.69958 8.42572L3.73453 9.06354L4.23253 8.67475L4.22379 8.01508L3.69958 8.42572Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.11621 12.1487L9.00617 12.1896L9.3163 12.6227H8.45709L8.11621 12.1487ZM8.2124 12.2008L8.48152 12.5751H9.22356L8.98088 12.2361L8.2124 12.2008Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.38086 11.5415L8.3686 11.6419L8.76751 11.9293L7.81764 11.8502L7.38086 11.5415ZM7.55647 11.6072L7.83454 11.8037L8.60029 11.8676L8.35109 11.688L7.55647 11.6072Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.32229 15.9839V16.9802L8.9458 16.953V16.033L9.32229 15.9839ZM8.99346 16.0748V16.9086L9.27463 16.929V16.0382L8.99346 16.0748Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72989 11.094L5.30797 11.3596L4.92282 11.6538L4.30835 11.4195L4.72989 11.094ZM4.7362 11.1493L4.40476 11.4052L4.91499 11.5998L5.21608 11.3698L4.7362 11.1493Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.76514 11.8027L8.69464 11.8802L9.0477 12.2443L8.14892 12.203L7.76514 11.8027ZM7.88674 11.8607L8.17014 12.1562L8.9298 12.1912L8.67287 11.9262L7.88674 11.8607Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.97761 13.6218L9.16167 14.2558L8.65768 14.3713L8.45264 13.6691L8.97761 13.6218ZM8.51462 13.7113L8.69083 14.3149L9.10184 14.2207L8.94278 13.6728L8.51462 13.7113Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.85814 8.38306L9.43709 9.45308L9.38879 10.2634L8.8313 9.2258L8.85814 8.38306ZM8.90016 8.56086L8.87934 9.21452L9.35122 10.0928L9.38871 9.46381L8.90016 8.56086Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.74437 9.67603L3.76593 10.2959L3.29927 10.857L3.24536 10.2801L3.74437 9.67603ZM3.29464 10.2953L3.33604 10.7382L3.71767 10.2794L3.70111 9.80322L3.29464 10.2953Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.79696 11.1445L10.1574 12.3701L9.96593 13.0321L9.62183 11.856L9.79696 11.1445ZM9.80104 11.3273L9.67117 11.8549L9.96566 12.8615L10.1078 12.3702L9.80104 11.3273Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03583 11.1404L6.86096 11.3593L6.37581 11.4175L5.50586 11.2042L6.03583 11.1404ZM5.77133 11.2202L6.37873 11.3692L6.60843 11.3416L6.03245 11.1888L5.77133 11.2202Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.8182 10.7019L4.03233 11.1248L3.63937 11.5953L3.39307 11.2152L3.8182 10.7019ZM3.45209 11.2186L3.64422 11.5152L3.97564 11.1184L3.80852 10.7883L3.45209 11.2186Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.92762 14.4636L10.0384 15.6171L9.65075 15.9132L9.54541 14.8297L9.92762 14.4636ZM9.59508 14.8481L9.68989 15.8233L9.98846 15.5953L9.88959 14.566L9.59508 14.8481Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.146 6.07568L6.91983 6.4066L6.95537 7.26969L6.20184 6.94384L6.146 6.07568ZM6.19853 6.14998L6.24753 6.91167L6.90463 7.19583L6.87345 6.4386L6.19853 6.14998Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.59473 15.0474L8.69245 15.1777L8.78966 16.1555L8.67462 15.8836L8.59473 15.0474Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.69277 14.3088L8.8292 15.0264L8.60506 15.1739L8.45117 14.3737L8.69277 14.3088ZM8.50634 14.4082L8.6384 15.0949L8.77644 15.0041L8.65554 14.3682L8.50634 14.4082Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.75205 10.2322L3.83857 10.7567L3.40585 11.2728L3.28687 10.7915L3.75205 10.2322ZM3.33891 10.8034L3.42961 11.1703L3.78799 10.7428L3.722 10.3428L3.33891 10.8034Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.97674 12.8765L10.2054 14.086L9.89649 14.5738L9.67871 13.4292L9.97674 12.8765ZM9.72869 13.4369L9.92076 14.4464L10.1551 14.0763L9.95488 13.0174L9.72869 13.4369Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.19995 13.0652L8.75237 13.0757L8.99448 13.6681L8.46833 13.7155L8.19995 13.0652ZM8.27174 13.1142L8.49899 13.6649L8.92597 13.6264L8.72011 13.1228L8.27174 13.1142Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25101 7.55054L8.90324 8.46469L8.87692 9.29119L8.24048 8.40325L8.25101 7.55054ZM8.29686 7.69686L8.28832 8.3882L8.83376 9.14918L8.8551 8.47927L8.29686 7.69686Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.54773 6.79102L5.55784 7.63013L4.84879 7.72195L4.80835 6.8879L5.54773 6.79102ZM4.85808 6.92945L4.89389 7.66806L5.50968 7.58831L5.50073 6.84524L4.85808 6.92945Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.87573 6.37964L7.63047 6.9202L7.64583 7.7754L6.91158 7.25021L6.87573 6.37964ZM6.92737 6.47524L6.95824 7.22499L7.59648 7.68151L7.58325 6.945L6.92737 6.47524Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.02808 11.1923L6.0381 11.1458L6.43959 11.2127L7.10793 11.3941L7.09772 11.4406L6.72969 11.3784L6.02808 11.1923Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.13919 10.9285L5.68973 11.2143L5.26166 11.3954L4.67407 11.1255L5.13919 10.9285ZM4.79208 11.1272L5.26246 11.3434L5.57783 11.2099L5.13713 10.9811L4.79208 11.1272Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41576 10.7754L4.76915 11.1288L4.3559 11.4426L3.96558 11.1209L4.41576 10.7754ZM4.04212 11.1222L4.35711 11.3819L4.69683 11.1239L4.41162 10.8386L4.04212 11.1222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.58179 6.8728L8.3026 7.61406L8.29223 8.45364L7.59733 7.7383L7.58179 6.8728ZM7.6316 6.99239L7.64464 7.71861L8.246 8.33765L8.2547 7.63316L7.6316 6.99239Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.28693 8.64111L4.26084 9.35598L3.69965 9.79247L3.68921 9.1037L4.28693 8.64111ZM3.73722 9.1268L3.74585 9.69616L4.21403 9.33202L4.23559 8.74111L3.73722 9.1268Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.90531 15.1414L9.00159 16.089L8.7286 16.0309L8.64355 15.1704L8.90531 15.1414ZM8.69561 15.2126L8.7726 15.9915L8.94757 16.0288L8.86276 15.194L8.69561 15.2126Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.59045 14.7991L9.69087 15.8836L9.27782 16.0499L9.18286 15.0404L9.59045 14.7991ZM9.23314 15.066L9.31928 15.9818L9.64014 15.8527L9.54993 14.8784L9.23314 15.066Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.89771 12.5127L8.48449 12.5814L8.77658 13.1239L8.22151 13.1133L7.89771 12.5127ZM7.98311 12.5707L8.25025 13.0662L8.69596 13.0747L8.45429 12.6259L7.98311 12.5707Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.89602 7.65332L4.8756 8.44487L4.23311 8.72242L4.2229 7.95134L4.89602 7.65332ZM4.27097 7.98218L4.27982 8.65033L4.82874 8.41319L4.84644 7.72739L4.27097 7.98218Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42944 11.253L6.44541 11.2083L7.11434 11.3898L7.48294 11.5526L7.46737 11.5974L6.83636 11.4349L6.42944 11.253ZM6.72836 11.3344L6.85214 11.3898L7.198 11.4789L7.09838 11.4349L6.72836 11.3344Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.22883 15.0205L9.32439 16.0365L8.94833 16.0855L8.8584 15.1512L9.22883 15.0205ZM8.90941 15.1838L8.99104 16.0319L9.27264 15.9951L9.1871 15.0858L8.90941 15.1838Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.23682 13.6548L8.50191 13.6725L8.70411 14.3599L8.46266 14.4247L8.23682 13.6548ZM8.30176 13.7069L8.49529 14.3666L8.64462 14.3266L8.46557 13.7178L8.30176 13.7069Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.36276 10.0916L9.82393 11.2342L9.65204 11.9326L9.20703 10.838L9.36276 10.0916ZM9.37734 10.255L9.25665 10.8335L9.64087 11.7785L9.77398 11.2378L9.37734 10.255Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5481 12.0403L8.17328 12.1578L8.51539 12.6336L7.92227 12.5641L7.5481 12.0403ZM7.65576 12.109L7.94878 12.5192L8.41362 12.5737L8.14573 12.2012L7.65576 12.109Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.72266 11.3528L7.47882 11.5476L7.93625 11.8769L7.22021 11.7108L6.72266 11.3528ZM6.95628 11.4622L7.24029 11.6665L7.71566 11.7768L7.45825 11.5915L6.95628 11.4622Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.53023 14.343L8.70716 15.283L8.59957 15.1395L8.4519 14.3766L8.53023 14.343Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.16113 11.6519L7.84296 11.81L8.2329 12.2167L7.59488 12.0967L7.16113 11.6519ZM7.30879 11.735L7.61849 12.0527L8.09562 12.1424L7.81838 11.8533L7.30879 11.735Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.27236 10.3635L4.44239 10.8152L3.99574 11.158L3.7832 10.7382L4.27236 10.3635ZM3.84359 10.752L4.01231 11.0852L4.38534 10.7989L4.25038 10.4404L3.84359 10.752Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.58158 10.8777L6.10831 11.1795L5.63409 11.2366L5.06348 10.9403L5.58158 10.8777ZM5.22236 10.9691L5.643 11.1875L5.95987 11.1493L5.5716 10.9269L5.22236 10.9691Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.26248 9.29468L4.24666 9.9278L3.71959 10.3348L3.69849 9.72812L4.26248 9.29468ZM3.74697 9.75097L3.76399 10.2403L4.19958 9.90394L4.21235 9.39331L3.74697 9.75097Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.49536 6.79517L6.24904 6.91229L6.23387 7.74667L5.50548 7.63461L5.49536 6.79517ZM5.54369 6.85091L5.55264 7.59365L6.18722 7.69128L6.20064 6.953L5.54369 6.85091Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.71763 13.3796L9.93426 14.5182L9.55495 14.8815L9.34912 13.8122L9.71763 13.3796ZM9.40025 13.8257L9.5852 14.7866L9.88261 14.5017L9.6894 13.4863L9.40025 13.8257Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23829 9.87549L4.28613 10.4177L3.79623 10.7929L3.71118 10.2773L4.23829 9.87549ZM3.76287 10.2979L3.83032 10.7068L4.23636 10.3958L4.19842 9.96581L3.76287 10.2979Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.86884 10.5876L5.18193 10.9644L4.72763 11.1546L4.37207 10.799L4.86884 10.5876ZM4.45568 10.8152L4.73872 11.0983L5.10411 10.9453L4.85486 10.6454L4.45568 10.8152Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.65146 11.7876L9.9914 12.9495L9.69713 13.4952L9.37329 12.3921L9.65146 11.7876ZM9.42405 12.3958L9.71021 13.3705L9.94016 12.9441L9.6415 11.9233L9.42405 12.3958Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.95654 12.9763L8.25357 13.0654L8.52016 13.7165L8.25277 13.6987L7.95654 12.9763ZM8.03854 13.0507L8.28559 13.6531L8.44711 13.6639L8.21808 13.1045L8.03854 13.0507Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73021 14.252L8.90742 15.1836L8.64554 15.2127L8.48047 14.3623L8.73021 14.252ZM8.53449 14.3905L8.68395 15.1605L8.85098 15.1419L8.6946 14.3198L8.53449 14.3905Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.84358 9.14771L9.39522 10.1744L9.24191 10.9092L8.71143 9.92475L8.84358 9.14771ZM8.86729 9.29252L8.76116 9.91658L9.22192 10.7717L9.34502 10.1816L8.86729 9.29252Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.45117 10.8628L6.02817 10.9559L6.55517 11.2792L6.02591 11.1921L5.45117 10.8628ZM5.70187 10.9515L6.04214 11.1464L6.32304 11.1927L6.01128 11.0014L5.70187 10.9515Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.87935 8.40112L4.81756 9.12203L4.20752 9.38052L4.23327 8.67506L4.87935 8.40112ZM4.27979 8.7071L4.25788 9.30743L4.77253 9.08935L4.82511 8.47588L4.27979 8.7071Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.55946 7.58691L5.50362 8.3788L4.82764 8.46074L4.84794 7.67393L5.55946 7.58691ZM4.89452 7.71625L4.8767 8.40679L5.45885 8.33623L5.50785 7.64124L4.89452 7.71625Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.26839 13.4829L8.5411 14.3901L8.46354 14.4233L8.24487 13.6829L8.26839 13.4829Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.39096 13.7756L9.59198 14.846L9.18625 15.0863L8.99609 14.0905L9.39096 13.7756ZM9.0483 14.1098L9.22034 15.0107L9.53898 14.822L9.35874 13.8623L9.0483 14.1098Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.20151 6.89185L6.95471 7.21755L6.90901 8.04519L6.18628 7.72963L6.20151 6.89185ZM6.24787 6.96381L6.23451 7.69869L6.86521 7.97407L6.9053 7.24811L6.24787 6.96381Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.61816 12.366L7.95762 12.5236L8.2845 13.1246L7.9814 13.0337L7.61816 12.366ZM7.72899 12.47L8.01388 12.9937L8.18734 13.0457L7.92328 12.5602L7.72899 12.47Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.03911 14.0627L9.23538 15.0642L8.86586 15.1946L8.68579 14.2693L9.03911 14.0627ZM8.73903 14.2934L8.90208 15.1313L9.18069 15.033L9.00524 14.1377L8.73903 14.2934Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.76314 10.1694L4.89014 10.6351L4.40004 10.8384L4.23071 10.3887L4.76314 10.1694ZM4.2916 10.4151L4.42728 10.7755L4.83312 10.6072L4.73137 10.2341L4.2916 10.4151Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00732 10.9989L6.02931 10.9568L6.47896 11.1529L6.85698 11.3922L6.83473 11.4341L6.42812 11.2572L6.00732 10.9989ZM6.3238 11.1372L6.4502 11.2148L6.57004 11.267L6.45655 11.1951L6.3238 11.1372Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.34473 10.5339L5.6191 10.9213L5.13284 10.9801L4.81543 10.5981L5.34473 10.5339ZM4.90796 10.6349L5.1529 10.9296L5.53398 10.8836L5.32226 10.5847L4.90796 10.6349Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.24859 8.33252L8.88054 9.21418L8.75015 9.98084L8.14429 9.13569L8.24859 8.33252ZM8.28028 8.45854L8.19397 9.12319L8.72232 9.86023L8.83022 9.22579L8.28028 8.45854Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.21802 11.8279L7.61833 12.0566L8.01343 12.602L7.65786 12.4369L7.21802 11.8279ZM7.38561 11.9785L7.68921 12.3989L7.86739 12.4816L7.58577 12.0929L7.38561 11.9785Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.91343 7.18823L7.6461 7.71229L7.56943 8.52498L6.86743 8.02138L6.91343 7.18823ZM6.95624 7.27744L6.91646 7.9979L7.52978 8.43789L7.59608 7.73511L6.95624 7.27744Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.44897 11.1886L6.47567 11.1492L6.85275 11.3878L7.24411 11.6694L7.21764 11.709L6.87857 11.4989L6.44897 11.1886Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.82715 9.06323L4.76993 9.70823L4.19849 9.95239L4.21409 9.3282L4.82715 9.06323ZM4.26097 9.35985L4.24798 9.87942L4.72498 9.67561L4.77261 9.13872L4.26097 9.35985Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.23619 10.771L9.67236 11.8513L9.39717 12.4493L8.98218 11.4219L9.23619 10.771ZM9.23694 10.9002L9.03345 11.4216L9.40002 12.3291L9.62046 11.85L9.23694 10.9002Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73804 11.3604L7.24537 11.6748L7.73764 12.1796L7.28712 11.9222L6.73804 11.3604ZM7.04594 11.6072L7.31655 11.8841L7.4665 11.9698L7.21532 11.7122L7.04594 11.6072Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.60229 7.67456L8.2896 8.38733L8.18643 9.18179L7.5249 8.49483L7.60229 7.67456ZM7.63999 7.78232L7.5744 8.47753L8.15194 9.07728L8.23939 8.40392L7.63999 7.78232Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.40787 12.3401L9.72545 13.4339L9.35968 13.8633L9.05811 12.8329L9.40787 12.3401ZM9.11033 12.8417L9.38063 13.7652L9.67252 13.4226L9.38962 12.4481L9.11033 12.8417Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.77119 9.65601V10.2129L4.2404 10.4367L4.19312 9.90078L4.77119 9.65601ZM4.24364 9.93114L4.28213 10.3674L4.72353 10.1813V9.72794L4.24364 9.93114Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.94312 12.7092L8.30305 13.5916L8.28204 13.7702L7.97413 13.0194L7.94312 12.7092Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47667 13.3948L8.74596 14.2992L8.49702 14.4092L8.25635 13.5919L8.47667 13.3948ZM8.31057 13.6073L8.5274 14.3437L8.68831 14.2726L8.45244 13.4804L8.31057 13.6073Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.51184 7.58667L6.23668 7.69897L6.15046 8.48505L5.45605 8.37782L5.51184 7.58667ZM5.55573 7.6417L5.50668 8.33742L6.10852 8.43035L6.18434 7.73909L5.55573 7.6417Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.28101 10.531L5.83438 10.6232L6.06657 11.0102L5.5629 10.929L5.28101 10.531ZM5.38603 10.5968L5.59023 10.8851L5.97297 10.9468L5.80484 10.6666L5.38603 10.5968Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.50903 8.3252L5.41192 9.05097L4.7688 9.12814L4.83013 8.41263L5.50903 8.3252ZM4.87433 8.45499L4.82128 9.07385L5.36958 9.00805L5.45356 8.38039L4.87433 8.45499Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.10019 12.7957L9.40212 13.8323L9.0092 14.1456L8.72852 13.1833L9.10019 12.7957ZM8.78198 13.1964L9.03505 14.064L9.34744 13.815L9.0775 12.8882L8.78198 13.1964Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.2817 10.1086L5.36111 10.5798L4.8441 10.6425L4.71704 10.1766L5.2817 10.1086ZM4.77754 10.2173L4.87924 10.5902L5.30582 10.5385L5.24226 10.1614L4.77754 10.2173Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.76786 13.1426L9.05029 14.116L8.69815 14.3218L8.4375 13.4296L8.76786 13.1426ZM8.49181 13.4456L8.72678 14.2499L8.99417 14.0936L8.74286 13.2274L8.49181 13.4456Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.56763 12.0598L8.00172 12.8635L8.02823 13.1286L7.64614 12.4262L7.56763 12.0598ZM7.68403 12.3756L7.69129 12.4095L7.95733 12.8985L7.95525 12.8777L7.68403 12.3756Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73548 9.86011L9.26282 10.8387L9.01137 11.4831L8.51025 10.5567L8.73548 9.86011ZM8.74649 9.98099L8.56187 10.552L9.00436 11.3699L9.21041 10.8419L8.74649 9.98099Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.76123 10.5891L6.32332 10.8316L6.5134 11.2175L6.00335 10.9926L5.76123 10.5891ZM5.87823 10.6915L6.03643 10.9551L6.41278 11.1211L6.28833 10.8684L5.87823 10.6915Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.41427 9.00195L5.31629 9.64657L4.71631 9.71898L4.77303 9.07952L5.41427 9.00195ZM4.81709 9.1222L4.76897 9.66462L5.27462 9.60359L5.35773 9.0568L4.81709 9.1222Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.14621 12.5969L8.48818 13.4442L8.26936 13.64L7.9563 12.8674L8.14621 12.5969ZM8.01021 12.8736L8.28808 13.5593L8.43146 13.431L8.13483 12.6961L8.01021 12.8736Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.32131 9.59814L5.27957 10.1565L4.71875 10.224V9.67087L5.32131 9.59814ZM4.76641 9.71312V10.1703L5.23497 10.1139L5.26947 9.6524L4.76641 9.71312Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.01163 11.3726L9.4304 12.4018L9.08315 12.8911L8.69067 11.925L9.01163 11.3726ZM8.74363 11.9288L9.09459 12.7927L9.3764 12.3956L9.00408 11.4804L8.74363 11.9288Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.19021 7.68433L6.91828 7.99999L6.79623 8.77299L6.10376 8.47258L6.19021 7.68433ZM6.23054 7.75375L6.15496 8.44285L6.75875 8.70478L6.86545 8.02903L6.23054 7.75375Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.15137 11.5149L7.65289 12.203L7.7206 12.519L7.28033 11.9094L7.15137 11.5149ZM7.29026 11.7864L7.32332 11.8875L7.6265 12.3073L7.60842 12.2229L7.29026 11.7864Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.24121 10.7778L6.79214 11.1826L6.94627 11.5525L6.44417 11.1899L6.24121 10.7778ZM6.3701 10.9317L6.48168 11.1582L6.83738 11.4151L6.75324 11.2132L6.3701 10.9317Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.70728 11.0862L7.24176 11.6379L7.35667 11.9894L6.87649 11.4923L6.70728 11.0862ZM6.84794 11.2999L6.91701 11.4656L7.24699 11.8073L7.19988 11.6632L6.84794 11.2999Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.16372 9.07446L8.76738 9.91654L8.54421 10.6068L7.97168 9.80628L8.16372 9.07446ZM8.18407 9.18465L8.02345 9.79674L8.52796 10.5022L8.71463 9.92476L8.18407 9.18465Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.23291 10.1062L5.80844 10.2004L5.8457 10.6688L5.31276 10.58L5.23291 10.1062ZM5.29098 10.164L5.3541 10.5385L5.79335 10.6117L5.76389 10.2414L5.29098 10.164Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.46088 8.33032L6.15745 8.43788L6.0198 9.15671L5.36401 9.05425L5.46088 8.33032ZM5.50166 8.38484L5.41743 9.01436L5.98165 9.10252L6.10135 8.47744L5.50166 8.38484Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.87441 7.97241L7.58085 8.48064L7.42237 9.23218L6.75171 8.74951L6.87441 7.97241ZM6.90941 8.0563L6.80336 8.72796L7.39083 9.15076L7.52782 8.5012L6.90941 8.0563Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.41469 12.2866L8.78385 13.1918L8.45519 13.4774L8.11304 12.6421L8.41469 12.2866ZM8.16812 12.6509L8.47409 13.3978L8.72684 13.1782L8.40022 12.3773L8.16812 12.6509Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.53556 8.4375L8.19563 9.11788L8.00516 9.84374L7.37598 9.19426L7.53556 8.4375ZM7.56372 8.53496L7.42784 9.1793L7.98053 9.74982L8.14271 9.13177L7.56372 8.53496Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72345 11.8794L9.11674 12.8425L8.74703 13.228L8.38013 12.3283L8.72345 11.8794ZM8.43456 12.3356L8.76344 13.142L9.06092 12.8318L8.71077 11.9744L8.43456 12.3356Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.7526 11.8833L8.16844 12.6482L7.98068 12.9157L7.59912 12.2187L7.7526 11.8833ZM7.75626 11.9898L7.65241 12.2168L7.98569 12.8256L8.11246 12.645L7.75626 11.9898Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.53493 10.502L9.03517 11.4317L8.71647 11.9803L8.24243 11.1078L8.53493 10.502ZM8.53723 10.6068L8.29597 11.1066L8.71786 11.883L8.98058 11.4308L8.53723 10.6068Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.27826 9.59766L5.89429 9.69601L5.81092 10.2484L5.23657 10.1552L5.27826 9.59766ZM5.32192 9.65289L5.28736 10.1152L5.77099 10.1936L5.84011 9.73563L5.32192 9.65289Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.75928 10.1829L6.33939 10.4389L6.32863 10.8908L5.79693 10.6562L5.75928 10.1829ZM5.81312 10.2587L5.84218 10.6241L6.28268 10.8184L6.29098 10.4697L5.81312 10.2587Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.37065 9.0022L6.03424 9.10508L5.89027 9.74269L5.27295 9.64494L5.37065 9.0022ZM5.41058 9.05661L5.32718 9.60528L5.85362 9.68863L5.97652 9.14436L5.41058 9.05661Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.30144 11.2673L7.78198 11.9339L7.63049 12.2649L7.19482 11.6601L7.30144 11.2673ZM7.32124 11.3763L7.24676 11.6507L7.62126 12.1706L7.72711 11.9393L7.32124 11.3763Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.11151 8.42383L6.80549 8.72999L6.62676 9.42961L5.97363 9.14387L6.11151 8.42383ZM6.14706 8.4916L6.0276 9.11546L6.59447 9.36347L6.74933 8.7573L6.14706 8.4916Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.29264 10.4089L6.8557 10.8104L6.79575 11.2354L6.28174 10.8668L6.29264 10.4089ZM6.33814 10.4999L6.32998 10.8427L6.75956 11.1508L6.80446 10.8324L6.33814 10.4999Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.98935 11.5178L8.43605 12.3351L8.13609 12.6886L7.72681 11.9357L7.98935 11.5178ZM7.78199 11.9375L8.14475 12.6048L8.37853 12.3292L7.98649 11.612L7.78199 11.9375Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.81304 10.7698L7.33772 11.31L7.23265 11.6972L6.7522 11.2012L6.81304 10.7698ZM6.84664 10.8728L6.80264 11.1848L7.2086 11.6038L7.28462 11.3238L6.84664 10.8728Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.27029 11.0547L8.74541 11.9291L8.404 12.3756L7.96045 11.5641L8.27029 11.0547ZM8.01546 11.5654L8.41066 12.2884L8.68878 11.9247L8.26796 11.1502L8.01546 11.5654Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.98661 9.7522L8.56377 10.5521L8.27329 11.1538L7.73242 10.4006L7.98661 9.7522ZM7.99909 9.85094L7.78625 10.3939L8.26523 11.0609L8.5085 10.557L7.99909 9.85094Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.84857 9.68164L6.46723 9.94972L6.3314 10.4826L5.75977 10.2354L5.84857 9.68164ZM5.88591 9.74976L5.81269 10.2063L6.29903 10.4166L6.41101 9.9773L5.88591 9.74976Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75832 8.69897L7.42502 9.17737L7.20967 9.85419L6.57886 9.40143L6.75832 8.69897ZM6.78722 8.77837L6.63309 9.3817L7.18415 9.77722L7.36911 9.19591L6.78722 8.77837Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.98375 9.09131L6.63472 9.37835L6.44939 9.99614L5.8396 9.72968L5.98375 9.09131ZM6.01748 9.15827L5.89476 9.70178L6.41919 9.93094L6.57698 9.40498L6.01748 9.15827Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.39112 9.14624L8.02045 9.79588L7.76781 10.4404L7.17456 9.82685L7.39112 9.14624ZM7.41236 9.23666L7.2286 9.81418L7.75051 10.3539L7.9649 9.80702L7.41236 9.23666Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50727 10.8557L8.01671 11.5638L7.75584 11.9791L7.28369 11.3242L7.50727 10.8557ZM7.5156 10.9489L7.33886 11.3192L7.75312 11.8938L7.95929 11.5656L7.5156 10.9489Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.75539 10.3462L8.29782 11.1015L7.98976 11.608L7.48389 10.9049L7.75539 10.3462ZM7.76331 10.4389L7.53918 10.9001L7.98643 11.5218L8.24072 11.1037L7.76331 10.4389Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42466 9.92358L7.02031 10.3549L6.84171 10.8592L6.28809 10.4594L6.42466 9.92358ZM6.45351 10.0033L6.34227 10.4397L6.81803 10.7833L6.96349 10.3726L6.45351 10.0033Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.98229 10.3196L7.53997 10.8977L7.31787 11.3631L6.80249 10.8273L6.98229 10.3196ZM7.00149 10.4081L6.85724 10.8154L7.30451 11.2804L7.48273 10.907L7.00149 10.4081Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5974 9.35278L7.23039 9.8071L7.00304 10.4013L6.41138 9.97286L6.5974 9.35278ZM6.62386 9.43043L6.46675 9.95412L6.98071 10.3263L7.17275 9.82439L6.62386 9.43043Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.1928 9.77246L7.79284 10.3878L7.52287 10.9433L6.96436 10.3695L7.1928 9.77246ZM7.21069 9.85906L7.01975 10.3581L7.50967 10.8614L7.73534 10.3971L7.21069 9.85906Z"
        fill="black"
      />
    </>
  ),
}
