import React from 'react'
export const meveth = {
  path: (
    <>
      <g clipPath="url(#clip0_14480_4435)">
        <circle cx="16" cy="16" r="13" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0151 15.7484C10.9881 15.7909 10.9982 15.8471 11.0382 15.8774L15.9424 19.5885C15.9765 19.6144 16.0235 19.6144 16.0576 19.5885L20.9618 15.8774C21.0018 15.8471 21.0119 15.7909 20.9849 15.7484L16.0807 8.04443C16.043 7.98519 15.957 7.98519 15.9193 8.04443L11.0151 15.7484ZM15.6645 9.86651V18.4511C15.6645 18.4908 15.6194 18.5135 15.5878 18.4896L14.6483 17.781C14.6242 17.7628 14.61 17.7342 14.61 17.7038V14.2811L13.521 16.2891C13.4903 16.3456 13.4143 16.3566 13.369 16.3111L12.7886 15.7273V16.3016C12.7886 16.342 12.742 16.3645 12.7106 16.3391L12.1533 15.8881C12.1307 15.8698 12.1176 15.8422 12.1176 15.813V15.322C12.1176 15.3034 12.1229 15.2851 12.133 15.2695L12.6735 14.4332C12.7075 14.3805 12.7819 14.3742 12.8243 14.4204L13.3158 14.956L13.891 12.4974L15.5761 9.8406C15.6019 9.79996 15.6645 9.81833 15.6645 9.86651ZM16.3355 18.4612V9.86843C16.3355 9.82007 16.3984 9.80182 16.424 9.84275L18.0374 12.4213C18.0642 12.4641 18.0535 12.5206 18.0128 12.5504L17.39 13.0085V14.2329L18.5301 13.3784C18.5746 13.3451 18.6378 13.3566 18.6679 13.4034L19.0903 14.06C19.1179 14.1029 19.1074 14.16 19.0664 14.1902L17.39 15.4221V16.5227L19.5363 14.9444C19.581 14.9115 19.644 14.9235 19.6737 14.9704L20.0746 15.6041C20.1014 15.6465 20.0914 15.7024 20.0516 15.7327L16.4124 18.4996C16.3808 18.5236 16.3355 18.501 16.3355 18.4612ZM11.1568 17.3868C11.1243 17.342 11.1803 17.2866 11.2243 17.3199L16 20.9338L20.7757 17.3199C20.8197 17.2866 20.8757 17.342 20.8432 17.3868L16.0775 23.9604C16.0392 24.0132 15.9608 24.0132 15.9225 23.9604L11.1568 17.3868Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14480_4435">
          <rect width="26" height="26" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
