import React from 'react'
export const renbtc_circle_color = {
  path: (
    <>
      <path
        d="M16 29.3337C23.3638 29.3337 29.3334 23.3641 29.3334 16.0003C29.3334 8.63653 23.3638 2.66699 16 2.66699C8.63622 2.66699 2.66669 8.63653 2.66669 16.0003C2.66669 23.3641 8.63622 29.3337 16 29.3337Z"
        fill="url(#renbtc_paint0_linear)"
        key="0JE4IF2TC"
      />
      <path
        d="M21.3055 14.1316C21.5274 12.529 20.3978 11.6675 18.8533 11.0927L19.3543 8.92176L18.131 8.59242L17.6432 10.7062C17.3216 10.6196 16.9913 10.5379 16.6631 10.457L17.1543 8.32933L15.9317 8L15.4303 10.1702C15.1641 10.1047 14.9028 10.04 14.6492 9.97186L14.6506 9.96508L12.9635 9.51004L12.6381 10.9215C12.6381 10.9215 13.5457 11.1462 13.5266 11.1601C14.022 11.2937 14.1116 11.6479 14.0966 11.9287L13.5259 14.4019C13.56 14.4113 13.6043 14.4248 13.653 14.4459C13.6123 14.435 13.5687 14.4229 13.5238 14.4113L12.7238 17.8758C12.6632 18.0384 12.5095 18.2823 12.1632 18.1897C12.1754 18.2089 11.274 17.95 11.274 17.95L10.6667 19.4627L12.2586 19.8914C12.5548 19.9715 12.845 20.0555 13.1307 20.1345L12.6245 22.3303L13.8464 22.6596L14.3478 20.4872C14.6816 20.585 15.0056 20.6754 15.3227 20.7604L14.823 22.9227L16.0464 23.2521L16.5526 21.0604C18.6386 21.4868 20.2072 21.3148 20.8675 19.2767C21.3996 17.6357 20.841 16.6891 19.7435 16.0719C20.5428 15.8728 21.1449 15.3048 21.3055 14.1316ZM18.5104 18.3655C18.1324 20.0065 15.5746 19.1194 14.7453 18.897L15.4171 15.9879C16.2464 16.2115 18.9055 16.6541 18.5104 18.3655ZM18.8888 14.1079C18.5439 15.6006 16.415 14.8422 15.7244 14.6563L16.3335 12.0179C17.024 12.2038 19.248 12.5508 18.8888 14.1079Z"
        fill="white"
        key="_pboT__PjE"
      />
      <linearGradient
        id="renbtc_paint0_linear"
        key="renbtc_paint0_linear"
        x1="8.50555"
        y1="5.50032"
        x2="23.5056"
        y2="26.5003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#87888C" key="dB2c3hNvmI" />
        <stop offset="1" stopColor="#BFC0C4" key="3BEuuzM_sz" />
      </linearGradient>
    </>
  ),
  viewBox: '0 0 32 32',
}
