import React from 'react'
export const error = {
  path: (
    <>
      <circle cx="8" cy="8.5" r="8" fill="#D94A1E" key="V4siZa1Zm" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C7.45963 3.5 7.03275 3.95846 7.07125 4.49746L7.46438 10.0013C7.48445 10.2823 7.71828 10.5 8 10.5C8.28172 10.5 8.51555 10.2823 8.53562 10.0013L8.92875 4.49746C8.96725 3.95846 8.54037 3.5 8 3.5ZM8 13.5C8.55228 13.5 9 13.0523 9 12.5C9 11.9477 8.55228 11.5 8 11.5C7.44772 11.5 7 11.9477 7 12.5C7 13.0523 7.44772 13.5 8 13.5Z"
        fill="white"
        key="zya9BOnX0P"
      />
    </>
  ),
  viewBox: '0 0 16 17',
}
