import React from 'react'
export const tbtc_circle_color = {
  path: (
    <>
      <path
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="black"
        key="nETjJD06n"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7616 10.9106C22.5068 11.0641 23.8885 11.6014 24.042 13.2024C24.1561 14.3742 23.6669 15.0754 22.8831 15.4762C24.173 15.7879 24.9809 16.5542 24.8207 18.2726C24.6231 20.4061 23.0318 20.978 20.7591 21.1074V22.1153H19.4075V21.1374C19.0572 21.1379 18.6981 21.1346 18.3279 21.1267V22.1148H16.9774V21.1026C16.6616 21.1006 15.2046 21.0942 14.8771 21.0942L14.8871 19.9196H16.0145V12.1112L14.8868 12.1048L14.8908 10.9134C14.8908 10.9134 16.6881 10.915 16.9806 10.9094V9.88428H18.3311V10.8657C18.6921 10.8589 19.0552 10.8521 19.4103 10.8521V9.88507H20.7616V10.9106ZM18.6221 19.4943C19.7295 19.5101 22.1485 19.5447 22.1485 18.0044C22.1481 16.4288 19.8196 16.4835 18.6861 16.5101C18.5604 16.5131 18.4494 16.5157 18.3579 16.5157V19.4916C18.4336 19.4916 18.5225 19.4929 18.6221 19.4943ZM18.5831 15.1563C19.5073 15.1717 21.5214 15.2051 21.5214 13.8056C21.522 12.3741 19.586 12.4244 18.6386 12.449C18.5317 12.4518 18.4374 12.4543 18.3599 12.4542V15.1537C18.4237 15.1537 18.4989 15.1549 18.5831 15.1563ZM11.6679 12.7337H9.4911V14.9104H11.6679V12.7337ZM13.8451 14.9106H11.6683V17.0874H13.8451V14.9106ZM7.31421 14.9106H9.49105V17.0874H7.31421V14.9106ZM11.6679 17.0871H9.4911V19.2639H11.6679V17.0871Z"
        fill="white"
        key="OF-k5loOjS"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
