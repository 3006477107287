import React from 'react'
export const styeth_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_14152_2442)" />
      <path d="M16.071 10.071L14.875 8.87502L16 7L17.1782 8.96374L16.071 10.071Z" fill="white" />
      <path d="M18.9999 15.6274L20.3602 14.267L22 17L18.9999 18.5V15.6274Z" fill="white" />
      <path
        d="M15.9999 20L14.0001 19.0001L14 15.0711L9 10.0711L11.8284 7.24268L16.0711 11.4853L20.3137 7.24268L23.1421 10.0711L18 15.2132V19L15.9999 20Z"
        fill="white"
      />
      <path d="M12.9999 15.4852V18.5L10 17L11.693 14.1783L12.9999 15.4852Z" fill="white" />
      <path d="M16 21L10 18L16 26L22 18L16 21Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_14152_2442"
          x1="25"
          y1="26"
          x2="6"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5816F9" />
          <stop offset="1" stopColor="#7840FF" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
