import React from 'react'
export const ether = {
  path: (
    <g key="nvYdFhgTC">
      <path d="M12.0001 2V15.8138L5.50008 12.1856L12.0001 2Z" fill="currentColor" key="znaosvJkO" />
      <path
        d="M12.0001 2L18.5002 12.1856L12.0001 15.8138V2Z"
        fill="currentColor"
        key="FHhCiQEDfG"
      />
      <path d="M12 16.9765V22.0001L5.5 13.3501L12 16.9765Z" fill="currentColor" key="pO1Dgc-krn" />
      <path
        d="M12 22.0001V16.9765L18.5003 13.3501L12 22.0001Z"
        fill="currentColor"
        key="Cl6KDN-zcK"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
