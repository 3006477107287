import React from 'react'
export const ether_circle_color = {
  path: (
    <g key="E7vxnjmPr">
      <path
        key="ether_circle_color1"
        d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63616 2.6665 2.66663 8.63604 2.66663 15.9998C2.66663 23.3636 8.63616 29.3332 16 29.3332Z"
        fill="url(#eth_paint0_linear)"
      />
      <path
        key="ether_circle_color2"
        d="M15.9999 8V19.0509L10.6667 16.1484L15.9999 8Z"
        fill="white"
      />
      <path
        key="ether_circle_color3"
        d="M15.9999 8L21.3332 16.1484L15.9999 19.0509V8Z"
        fill="white"
      />
      <path
        key="ether_circle_color4"
        d="M15.9998 19.9811V23.9999L10.6666 17.08L15.9998 19.9811Z"
        fill="white"
      />
      <path
        key="ether_circle_color5"
        d="M15.9998 23.9999V19.9811L21.3333 17.08L15.9998 23.9999Z"
        fill="white"
      />
      <linearGradient
        id="eth_paint0_linear"
        key="ether_circle_color6"
        x1="3.61295"
        y1="16.0003"
        x2="29.1702"
        y2="36.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6580EB" key="L1PHx9CECk" />
        <stop offset="1" stopColor="#8EA2F2" key="g0811YJKu6" />
      </linearGradient>
    </g>
  ),
  viewBox: '0 0 32 32',
}
