import React from 'react'
export const ldo = {
  path: (
    <>
      <g clipPath="url(#clip0_14079_7236)">
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0046 4.92969L15.5124 10.4076L12.0044 12.4471L8.49683 10.4075L12.0046 4.92969ZM9.57062 10.1485L12.0046 6.34754L14.4385 10.1485L12.0044 11.5637L9.57062 10.1485Z"
          fill="white"
        />
        <path
          d="M11.9995 13.6511L7.93031 11.285L7.81921 11.4585C6.56593 13.4157 6.84581 15.9788 8.49214 17.621C10.4295 19.5533 13.5705 19.5533 15.5078 17.621C17.1542 15.9788 17.4341 13.4157 16.1808 11.4585L16.0696 11.2849L11.9996 13.6512L11.9995 13.6511Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14079_7236">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </>
  ),
}
