import React from 'react'

export const swbtc = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="#F69827"
      />
      <circle cx="16" cy="16" r="12" fill="#0B101C" />
      <path
        d="M19.3368 14.3406C19.3252 14.3839 19.3121 14.4253 19.2976 14.465C20.2744 14.7085 21.0537 15.1284 21.6473 15.5635C21.8568 15.266 22.0084 14.8868 22.086 14.4112C22.3684 12.7277 21.1001 11.7977 19.3543 11.1501L19.9606 8.88734L18.5762 8.51639L17.9818 10.7346C17.6174 10.6369 17.2451 10.5371 16.8719 10.4512L17.4697 8.21991L16.0932 7.85107L15.4834 10.1269L12.6779 9.3752L12.281 10.8566C12.281 10.8566 13.3084 11.1122 13.2878 11.1264C13.6824 11.1869 13.958 11.5491 13.9114 11.9454L13.5386 13.3367C14.1866 13.6389 14.9983 13.9358 15.9264 14.1056L16.4685 12.0826C16.5468 12.1036 16.6426 12.1266 16.7512 12.1527C17.7264 12.387 19.7313 12.8686 19.3368 14.3406Z"
        fill="#F7931A"
      />
      <path
        d="M20.8552 16.8444C20.9966 16.9532 21.1225 17.0618 21.2334 17.1657C21.67 17.6755 21.8545 18.3247 21.679 19.1768C21.5667 19.0855 21.4485 18.9929 21.325 18.9002C20.559 18.3258 19.5359 17.7123 18.3641 17.4218C17.6717 16.8155 16.4405 16.5146 15.6978 16.3331C15.5641 16.3004 15.4462 16.2716 15.3502 16.2456L15.1454 17.0099C14.4345 16.8372 13.7588 16.591 13.1731 16.3373C13.0367 16.2782 12.906 16.219 12.7819 16.1609L13.1452 14.805C14.2546 15.2927 15.7582 15.7503 17.5 15.7502C19.0573 15.7501 20.1504 16.3023 20.8552 16.8444Z"
        fill="#F7931A"
      />
      <path
        d="M12.3881 17.6304L12.2414 18.1778C12.1522 18.4436 11.8646 18.5866 11.5987 18.4977C11.5972 18.4973 11.5956 18.4968 11.5941 18.4962C11.6082 18.5168 10.5873 18.2264 10.5873 18.2264L9.86865 19.8071L12.6637 20.556L12.044 22.8685L13.4284 23.2395L14.0389 20.961L15.1454 21.2575L14.5377 23.5255L15.9247 23.8971L16.5373 21.6108C18.5657 22.0248 20.1634 21.9836 21.0799 20.6358C20.888 20.4654 20.6683 20.2827 20.4251 20.1003C19.9185 19.7204 19.336 19.3614 18.7113 19.1092C18.0698 20.2998 15.8517 19.6706 14.8003 19.3723C14.6995 19.3437 14.6094 19.3181 14.5324 19.2975L14.7571 18.4592C13.9455 18.2595 13.1984 17.9829 12.5768 17.7137C12.5127 17.6859 12.4498 17.6581 12.3881 17.6304Z"
        fill="#F7931A"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
