import React from 'react'

export const eurc = {
  path: (
    <>
      <path
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="url(#paint0_linear_15429_4440)"
      />
      <path
        d="M13.5556 25.2224C13.5556 25.5558 13.3333 25.6669 13 25.6669C8.88889 24.3335 6 20.5558 6 16.1113C6 11.6669 8.88889 7.88909 13 6.55576C13.3333 6.44465 13.5556 6.66687 13.5556 7.0002V7.77798C13.5556 8.0002 13.4444 8.22243 13.2222 8.33354C10 9.55576 7.77778 12.5558 7.77778 16.1113C7.77778 19.6669 10.1111 22.778 13.2222 23.8891C13.4444 24.0002 13.5556 24.2224 13.5556 24.4446V25.2224Z"
        fill="white"
      />
      <path
        d="M18.9999 25.6668C18.6666 25.7779 18.4443 25.5557 18.4443 25.2223V24.4446C18.4443 24.2223 18.5554 24.0001 18.7777 23.889C21.9999 22.6668 24.2221 19.6668 24.2221 16.1112C24.2221 12.5557 21.8888 9.44455 18.7777 8.33344C18.5554 8.22233 18.4443 8.00011 18.4443 7.77789V7.00011C18.4443 6.66678 18.6666 6.55566 18.9999 6.55566C22.9999 7.889 25.9999 11.6668 25.9999 16.1112C25.9999 20.5557 23.111 24.3334 18.9999 25.6668Z"
        fill="white"
      />
      <path
        d="M18.3828 14.3878L17.9105 15.4119H11.3729L11.7457 14.3878H18.3828ZM17.4929 16.4062L17.0107 17.4403H11.3729L11.7457 16.4062H17.4929ZM19.5959 11.6882L19.0092 12.946C18.8236 12.7869 18.6214 12.6428 18.4027 12.5135C18.1839 12.3842 17.9486 12.2815 17.6967 12.2053C17.4448 12.1257 17.1747 12.0859 16.8864 12.0859C16.3561 12.0859 15.8838 12.2285 15.4695 12.5135C15.0552 12.7985 14.7287 13.2261 14.4901 13.7962C14.2547 14.3629 14.1371 15.0689 14.1371 15.9141C14.1371 16.7592 14.2547 17.4652 14.4901 18.032C14.7287 18.5987 15.0552 19.0246 15.4695 19.3097C15.8838 19.5914 16.3561 19.7322 16.8864 19.7322C17.178 19.7322 17.4498 19.6941 17.7017 19.6179C17.9569 19.5417 18.1889 19.4422 18.3977 19.3196C18.6098 19.1937 18.8004 19.0611 18.9695 18.9219L19.571 20.1747C19.2197 20.4929 18.812 20.7332 18.348 20.8956C17.884 21.058 17.3968 21.1392 16.8864 21.1392C16.0545 21.1392 15.317 20.9287 14.674 20.5078C14.0343 20.0869 13.5322 19.487 13.1676 18.7081C12.803 17.9259 12.6207 16.9946 12.6207 15.9141C12.6207 14.8303 12.803 13.8973 13.1676 13.1151C13.5322 12.3329 14.0343 11.7313 14.674 11.3104C15.317 10.8894 16.0545 10.679 16.8864 10.679C17.4167 10.679 17.9105 10.7668 18.3679 10.9425C18.8253 11.1148 19.2346 11.3634 19.5959 11.6882Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15429_4440"
          x1="10.0938"
          y1="2.67479"
          x2="23.5878"
          y2="28.5416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0666CE" />
          <stop offset="1" stopColor="#61A9F8" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
