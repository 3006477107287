import React from 'react'
export const oseth_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="white" stroke="#E5E4E6" />
      <path
        d="M16.0035 24L9.38354 20.62L16.0035 17.3733V24Z"
        fill="url(#paint0_linear_14323_2458)"
      />
      <path
        d="M16.0034 17.3733L22.6234 20.64L16.0034 24V17.3733Z"
        fill="url(#paint1_linear_14323_2458)"
      />
      <path
        d="M19.9168 15.4666L22.6234 20.6332L16.0034 17.3666L19.9168 15.4666Z"
        fill="url(#paint2_linear_14323_2458)"
      />
      <path
        d="M12.097 15.4534L16.0036 17.3734L9.37695 20.6134L12.097 15.4534Z"
        fill="url(#paint3_linear_14323_2458)"
      />
      <path
        d="M16.0035 17.3733V8.00659L12.1035 15.4599L16.0168 17.3733H16.0035Z"
        fill="url(#paint4_linear_14323_2458)"
      />
      <path
        d="M16.0034 17.3733V8.00659L19.9168 15.4666L16.0034 17.3733Z"
        fill="url(#paint5_linear_14323_2458)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14323_2458"
          x1="10.8169"
          y1="18.3933"
          x2="17.5835"
          y2="21.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8175A5" />
          <stop offset="1" stopColor="#63577F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14323_2458"
          x1="15.9501"
          y1="24.66"
          x2="20.8701"
          y2="17.4533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#655981" />
          <stop offset="0.2" stopColor="#73628C" />
          <stop offset="1" stopColor="#B3A5DA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14323_2458"
          x1="18.8701"
          y1="18.8999"
          x2="24.0368"
          y2="13.1999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFA0D8" />
          <stop offset="1" stopColor="#CEC6E6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14323_2458"
          x1="12.7236"
          y1="18.64"
          x2="13.4503"
          y2="16.4534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#887BAE" />
          <stop offset="1" stopColor="#796D9B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14323_2458"
          x1="16.8968"
          y1="12.6866"
          x2="13.4635"
          y2="12.6866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFA0D8" />
          <stop offset="1" stopColor="#887BAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14323_2458"
          x1="17.1168"
          y1="13.7666"
          x2="19.5434"
          y2="16.0666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFA0D8" />
          <stop offset="1" stopColor="#CEC6E6" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
