import React from 'react'
export const deth = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="black" />
      <path
        d="M14.5192 7.61337C15.337 6.79554 16.663 6.79554 17.4808 7.61337L24.3865 14.5192C25.2044 15.337 25.2044 16.663 24.3865 17.4808L17.4808 24.3865C16.663 25.2044 15.337 25.2044 14.5192 24.3865L7.61337 17.4808C6.79554 16.663 6.79554 15.337 7.61337 14.5192L14.5192 7.61337Z"
        fill="#00ED76"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0566 8.03764L23.9623 14.9434C24.5459 15.5269 24.5459 16.473 23.9623 17.0565L17.0566 23.9623C16.473 24.5458 15.527 24.5458 14.9434 23.9623L8.03764 17.0565C7.45412 16.473 7.45412 15.5269 8.03764 14.9434L14.9434 8.03764C15.527 7.45412 16.473 7.45412 17.0566 8.03764ZM14.5192 7.61337C15.337 6.79554 16.663 6.79554 17.4808 7.61337L24.3866 14.5192C25.2045 15.337 25.2045 16.663 24.3866 17.4808L17.4808 24.3865C16.663 25.2044 15.337 25.2044 14.5192 24.3865L7.61338 17.4808C6.79554 16.663 6.79554 15.337 7.61338 14.5192L14.5192 7.61337ZM19.3 16.0676L15.9997 10.3L12.7 16.0672L15.9997 18.1179L19.3 16.0676ZM12.7 16.7446L15.9997 21.6999L19.3 16.7446L15.9997 18.8171L12.7 16.7446Z"
        fill="black"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
