import React from 'react'
export const arb_circle = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_14079_7174)" />
      <path
        d="M17.9557 15.1689L19.2303 13.006L22.666 18.3572L22.6676 19.3841L22.6564 12.3174C22.6483 12.1447 22.5566 11.9867 22.4102 11.8935L16.2247 8.33549C16.08 8.26439 15.8965 8.26514 15.7521 8.33769C15.7326 8.3475 15.7143 8.35811 15.6967 8.36969L15.6752 8.38326L9.67117 11.8626L9.64785 11.8731C9.6179 11.8869 9.58763 11.9044 9.55919 11.9248C9.44537 12.0064 9.36975 12.1271 9.3453 12.2626C9.34163 12.2831 9.33894 12.304 9.33765 12.325L9.34707 18.0837L12.5473 13.1236C12.9501 12.4659 13.828 12.254 14.6429 12.2656L15.5994 12.2908L9.96397 21.3284L10.6283 21.7109L16.3312 12.3L18.8519 12.2908L13.1637 21.9392L15.5341 23.3026L15.8173 23.4655C15.9371 23.5142 16.0783 23.5166 16.1991 23.473L22.4716 19.838L21.2723 20.5329L17.9557 15.1689ZM18.442 22.1732L16.0478 18.4156L17.5093 15.9356L20.6536 20.8915L18.442 22.1732Z"
        fill="#2D374B"
      />
      <path
        d="M16.0476 18.4158L18.4418 22.1734L20.6534 20.8917L17.5091 15.9358L16.0476 18.4158Z"
        fill="#28A0F0"
      />
      <path
        d="M22.6675 19.3845L22.6659 18.3576L19.2302 13.0063L17.9556 15.1692L21.2723 20.5333L22.4715 19.8384C22.5891 19.7429 22.6603 19.6027 22.6677 19.4514L22.6675 19.3845Z"
        fill="#28A0F0"
      />
      <path
        d="M8.27032 20.3529L9.96378 21.3286L15.5992 12.2911L14.6427 12.2658C13.8278 12.2543 12.95 12.4661 12.5471 13.1239L9.34688 18.084L8.27026 19.7382V20.3529H8.27032Z"
        fill="white"
      />
      <path
        d="M18.8517 12.291L16.3311 12.3002L10.6282 21.7111L12.6215 22.8588L13.1636 21.9394L18.8517 12.291Z"
        fill="white"
      />
      <path
        d="M23.7299 12.2779C23.7089 11.7508 23.4234 11.2681 22.9763 10.9872L16.7098 7.38347C16.2675 7.16075 15.715 7.16048 15.272 7.38331C15.2196 7.4097 9.17783 10.9137 9.17783 10.9137C9.09424 10.9538 9.01371 11.0016 8.93793 11.0558C8.5388 11.3418 8.2955 11.7863 8.27051 12.2747V19.738L9.34713 18.0838L9.3377 12.3252C9.33899 12.3041 9.34163 12.2834 9.34535 12.2629C9.36964 12.1274 9.44532 12.0066 9.55924 11.9249C9.58768 11.9045 15.7326 8.34763 15.7522 8.33782C15.8966 8.26527 16.0801 8.26451 16.2248 8.33561L22.4103 11.8936C22.5566 11.9868 22.6483 12.1448 22.6565 12.3176V19.4512C22.649 19.6025 22.5892 19.7426 22.4716 19.8382L21.2723 20.5331L20.6536 20.8916L18.442 22.1733L16.1991 23.4731C16.0783 23.5168 15.9371 23.5143 15.8173 23.4656L13.1637 21.9393L12.6216 22.8587L15.0063 24.2317C15.0852 24.2765 15.1555 24.3163 15.2131 24.3487C15.3024 24.3988 15.3632 24.4322 15.3847 24.4426C15.5542 24.5249 15.7981 24.5729 16.0178 24.5729C16.2193 24.5729 16.4158 24.5359 16.6017 24.463L23.1162 20.6903C23.4901 20.4006 23.7101 19.9637 23.7299 19.4904V12.2779Z"
        fill="#96BEDC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14079_7174"
          x1="3"
          y1="16"
          x2="29"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D374B" />
          <stop offset="1" stopColor="#585C65" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
