import React from 'react'
export const bal = {
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16937 6.41796C6.16937 7.75129 8.79458 8.83592 12.0312 8.83592C15.2678 8.83592 17.893 7.75129 17.893 6.41796C17.893 5.08463 15.2678 4 12.0312 4C8.79458 4 6.16937 5.08463 6.16937 6.41796ZM12.0584 13.4926C7.87191 13.4926 4.4764 12.18 4.4764 10.5599C4.4764 9.66183 5.52304 8.85699 7.1638 8.32158C8.33478 8.93298 10.0895 9.32331 12.0584 9.32331C14.0274 9.32331 15.7821 8.93298 16.9531 8.32158C18.5973 8.86044 19.6405 9.66183 19.6405 10.5599C19.6439 12.18 16.2484 13.4926 12.0584 13.4926ZM12 19.0573C6.47668 19.0573 2 17.4614 2 15.3233C2 14.2076 3.21934 13.2024 5.17098 12.4943C6.6943 13.4718 9.25043 13.9727 12.1485 13.9727C14.9775 13.9727 17.4784 13.3613 19.0155 12.4252C20.8566 13.1264 22 14.1039 22 15.1816C22 17.3233 17.5233 19.0573 12 19.0573Z"
        fill="black"
      />
    </>
  ),
}
