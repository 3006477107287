import React from 'react'
export const yieldbtc_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="#17438C" key="RnijqaxRF" />
      <circle cx="11" cy="11" r="2" fill="#3682FF" key="M9Xq7XVOMN" />
      <circle cx="11" cy="16" r="2" fill="#3682FF" key="qIimw4oDMO" />
      <circle cx="16" cy="11" r="2" fill="#3682FF" key="YbvN9fU7ii" />
      <circle cx="16" cy="16" r="2" fill="#3682FF" key="qTTv8X3RkH" />
      <circle cx="21" cy="11" r="2" fill="#3682FF" key="6SrjPEDvxX" />
      <circle cx="21" cy="16" r="2" fill="#3682FF" key="leMwzoYDqs" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7324 22C12.3866 22.5978 11.7403 23 11 23C9.89543 23 9 22.1046 9 21C9 19.8954 9.89543 19 11 19C11.7403 19 12.3866 19.4022 12.7324 20H14.2676C14.6134 19.4022 15.2597 19 16 19C16.7403 19 17.3866 19.4022 17.7324 20H19.2676C19.6134 19.4022 20.2597 19 21 19C22.1046 19 23 19.8954 23 21C23 22.1046 22.1046 23 21 23C20.2597 23 19.6134 22.5978 19.2676 22H17.7324C17.3866 22.5978 16.7403 23 16 23C15.2597 23 14.6134 22.5978 14.2676 22H12.7324Z"
        fill="#FFBF60"
        key="5LgLVqjRAK"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
