import React from 'react'
export const prime = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="#E2F063" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9621 15H21.9143L9.59756 27.3167C9.00425 26.9803 8.44 26.5986 7.90952 26.1763L21.0858 13H28.6521C28.8055 13.6492 28.9102 14.3172 28.9621 15ZM28.2001 11.5H20.4143L6.76484 25.1494C6.29398 24.6742 5.85954 24.1628 5.46606 23.6198L19.5858 9.5H27.2608C27.628 10.1347 27.943 10.8033 28.2001 11.5ZM13.2136 28.7007C12.4692 28.5381 11.7487 28.3118 11.0581 28.0277L22.5858 16.5H28.9905C28.9648 17.1814 28.8866 17.8494 28.7598 18.5H23.4143L13.2136 28.7007Z"
        fill="black"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
