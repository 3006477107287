import React from 'react'
export const bal_circle = {
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0001 2.66675C23.3627 2.66675 29.3334 8.63741 29.3334 16.0001C29.3334 23.3627 23.3627 29.3334 16.0001 29.3334C8.63741 29.3334 2.66675 23.3627 2.66675 16.0001C2.66675 8.63741 8.63741 2.66675 16.0001 2.66675Z"
        fill="url(#paint0_linear_12112_871)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.699 11.8159C11.699 12.8452 13.7256 13.6826 16.2243 13.6826C18.723 13.6826 20.7496 12.8452 20.7496 11.8159C20.7496 10.7866 18.723 9.94922 16.2243 9.94922C13.7256 9.94922 11.699 10.7866 11.699 11.8159ZM16.2453 17.2775C13.0133 17.2775 10.392 16.2641 10.392 15.0135C10.392 14.3201 11.2 13.6988 12.4667 13.2855C13.3707 13.7575 14.7253 14.0588 16.2453 14.0588C17.7653 14.0588 19.12 13.7575 20.024 13.2855C21.2933 13.7015 22.0987 14.3201 22.0987 15.0135C22.1013 16.2641 19.48 17.2775 16.2453 17.2775ZM16.2002 21.5735C11.9362 21.5735 8.48022 20.3415 8.48022 18.6908C8.48022 17.8295 9.42156 17.0535 10.9282 16.5068C12.1042 17.2615 14.0776 17.6481 16.3149 17.6481C18.4989 17.6481 20.4296 17.1761 21.6162 16.4535C23.0376 16.9948 23.9202 17.7495 23.9202 18.5815C23.9202 20.2348 20.4642 21.5735 16.2002 21.5735Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12112_871"
          x1="13.0056"
          y1="3.00012"
          x2="24.5056"
          y2="29.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222121" />
          <stop offset="1" stopColor="#8E8E8E" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
