import React from 'react'
export const op_circle = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_14079_7113)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.71211 18.967C10.2115 19.3314 10.8521 19.5136 11.6341 19.5136C12.5803 19.5136 13.3359 19.2956 13.901 18.8597C14.4661 18.4172 14.8636 17.7503 15.0936 16.8589C15.2316 16.3124 15.3498 15.7496 15.4484 15.1705C15.4813 14.9623 15.4977 14.7899 15.4977 14.6532C15.4977 14.1978 15.3827 13.8074 15.1527 13.4821C14.9227 13.1502 14.6073 12.903 14.2065 12.7403C13.8057 12.5712 13.3523 12.4866 12.8464 12.4866C10.9868 12.4866 9.83367 13.3812 9.38686 15.1705C9.22916 15.8277 9.1076 16.3905 9.02218 16.8589C8.98933 17.0671 8.9729 17.2428 8.9729 17.386C8.9729 18.0692 9.2193 18.5962 9.71211 18.967ZM12.7084 17.7861C12.4521 18.0008 12.14 18.1082 11.772 18.1082C11.1413 18.1082 10.8259 17.8089 10.8259 17.2103C10.8259 17.0671 10.839 16.9305 10.8653 16.8004C10.9836 16.1693 11.1018 15.6357 11.2201 15.1998C11.3318 14.7573 11.5158 14.4288 11.772 14.2141C12.0349 13.9993 12.3503 13.892 12.7182 13.892C13.3424 13.892 13.6546 14.188 13.6546 14.7801C13.6546 14.9233 13.6414 15.0631 13.6152 15.1998C13.5363 15.6552 13.4213 16.1888 13.2702 16.8004C13.1585 17.2428 12.9712 17.5714 12.7084 17.7861ZM16.3303 19.3477C16.3697 19.3932 16.4255 19.416 16.4978 19.416H17.8382C17.904 19.416 17.9664 19.3932 18.0255 19.3477C18.0846 19.3021 18.1208 19.2436 18.1339 19.172L18.5873 17.0346H19.9179C20.7786 17.0346 21.4554 16.8557 21.9483 16.4978C22.4476 16.14 22.7794 15.5869 22.9437 14.8387C22.9832 14.663 23.0029 14.4938 23.0029 14.3312C23.0029 13.7651 22.7794 13.3324 22.3326 13.0331C21.8924 12.7338 21.3076 12.5842 20.5782 12.5842H17.9565C17.8908 12.5842 17.8284 12.6069 17.7692 12.6525C17.7101 12.698 17.674 12.7566 17.6608 12.8282L16.3007 19.172C16.2875 19.2371 16.2974 19.2956 16.3303 19.3477ZM20.7064 15.4633C20.5027 15.6129 20.2629 15.6878 19.9869 15.6878H18.8534L19.228 13.9408H20.4107C20.6801 13.9408 20.8707 13.9928 20.9824 14.0969C21.094 14.1945 21.1499 14.3377 21.1499 14.5264C21.1499 14.6109 21.14 14.7085 21.1203 14.8192C21.0546 15.0989 20.9167 15.3136 20.7064 15.4633Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14079_7113"
          x1="3"
          y1="16"
          x2="29"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0420" />
          <stop offset="1" stopColor="#FF6C7D" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
