import React from 'react'
export const ena = {
  path: (
    <>
      <path
        d="M15.9997 3.5H16.0002C22.9038 3.5 28.5002 9.09643 28.5002 16V16.0005C28.5002 22.904 22.9038 28.5004 16.0002 28.5004H15.9997C9.09618 28.5004 3.49976 22.904 3.49976 16.0005V16C3.49976 9.09643 9.09618 3.5 15.9997 3.5Z"
        fill="#111111"
        stroke="#111111"
      />
      <path
        d="M16.0002 3H15.9997C8.82004 3 2.99976 8.82028 2.99976 16V16.0005C2.99976 23.1801 8.82004 29.0004 15.9997 29.0004H16.0002C23.1799 29.0004 29.0002 23.1801 29.0002 16.0005V16C29.0002 8.82028 23.1799 3 16.0002 3Z"
        fill="url(#paint0_radial_14937_4241)"
        fillOpacity="0.7"
      />
      <path
        d="M15.9997 3.2381H16.0002C23.0484 3.2381 28.7621 8.95178 28.7621 16V16.0005C28.7621 23.0486 23.0484 28.7623 16.0002 28.7623H15.9997C8.95154 28.7623 3.23785 23.0486 3.23785 16.0005V16C3.23785 8.95178 8.95154 3.2381 15.9997 3.2381Z"
        stroke="url(#paint1_linear_14937_4241)"
        strokeWidth="0.476194"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9842 21L19.1509 16L14.9842 11H21.5V14H22.5V10H13.5L8 16L13.5 22H22.5V18H21.5V21H14.9842ZM9.35657 16L13.8051 11.1471L17.8491 16L13.8051 20.8529L9.35657 16Z"
        fill="white"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
