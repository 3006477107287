import React from 'react'

export const usda = {
  path: (
    <>
      <g clipPath="url(#clip0_15058_4261)">
        <path
          d="M28.8459 15.9147C28.8459 23.0425 23.0678 28.8207 15.94 28.8207C8.81231 28.8207 3.03418 23.0425 3.03418 15.9148C3.03418 8.78706 8.81231 3.00879 15.94 3.00879C23.0678 3.00879 28.8459 8.78692 28.8459 15.9147Z"
          fill="url(#paint0_linear_15058_4261)"
        />
        <path
          d="M19.4645 17.5353L23 13.9998L15.929 6.92871L8.85791 13.9998L12.3934 17.5353L15.929 13.9998L19.4645 17.5353Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2276 22.5644H14.2227V22.8413C14.2227 23.1668 14.2979 23.4519 14.4531 23.6915C14.6068 23.9287 14.8197 24.1101 15.0884 24.2357C15.3568 24.3613 15.6632 24.4225 16.0051 24.4225C16.3457 24.4225 16.6467 24.3655 16.9032 24.248C17.1619 24.1273 17.3649 23.9612 17.5072 23.7479C17.6541 23.5297 17.7264 23.2791 17.7264 22.9997C17.7264 22.7224 17.6591 22.4849 17.516 22.2957C17.3773 22.1124 17.1852 21.9681 16.9447 21.8607C16.7127 21.7552 16.4409 21.6699 16.1308 21.6037L15.9907 21.5732C15.8244 21.5376 15.6919 21.4968 15.5911 21.452C15.4937 21.4088 15.4265 21.3579 15.3831 21.3025C15.3461 21.2508 15.3251 21.1845 15.3251 21.0981C15.3251 20.9688 15.3701 20.8768 15.4555 20.8097C15.546 20.7379 15.6983 20.6925 15.9319 20.6925C16.0841 20.6925 16.2099 20.7155 16.3117 20.7576L16.3147 20.7587C16.4117 20.7955 16.4815 20.8537 16.5293 20.9335L16.5321 20.9377C16.5819 21.0123 16.6119 21.1195 16.6119 21.2688V21.558H17.6168V21.2688C17.6168 20.9447 17.5443 20.6668 17.3919 20.4421C17.2457 20.2168 17.0423 20.0495 16.7849 19.9403C16.5336 19.8277 16.2485 19.7729 15.9319 19.7729C15.6156 19.7729 15.3343 19.8256 15.0905 19.9343C14.8503 20.0392 14.6589 20.1944 14.5208 20.3992C14.3853 20.6025 14.3203 20.8455 14.3203 21.1225C14.3203 21.3969 14.378 21.6309 14.5029 21.8159L14.504 21.8173C14.6296 21.9968 14.8051 22.1399 15.0257 22.248L15.028 22.2491C15.2477 22.3505 15.5077 22.434 15.8056 22.5003L15.9456 22.5307C16.1251 22.5705 16.2739 22.6139 16.3933 22.6601L16.3971 22.6615C16.5165 22.7025 16.5959 22.7533 16.6448 22.8084L16.6469 22.8107C16.6943 22.8608 16.7216 22.9285 16.7216 23.0241C16.7216 23.1611 16.6683 23.2711 16.5545 23.3615L16.5533 23.3624C16.4467 23.4497 16.2704 23.5029 16.0051 23.5029C15.7489 23.5029 15.5599 23.4439 15.4257 23.3376C15.2983 23.2327 15.2276 23.0737 15.2276 22.8413V22.5644Z"
          fill="#1F2333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.65718 23.6305C9.80171 23.8845 10.0078 24.0814 10.2732 24.2206L10.275 24.2216C10.5448 24.3565 10.8625 24.4217 11.2245 24.4217C11.5866 24.4217 11.9032 24.3561 12.1693 24.2209C12.4388 24.0818 12.6456 23.8845 12.7862 23.6296C12.9306 23.3713 13.0009 23.0705 13.0009 22.7308V19.8574H11.9837V22.7552C11.9837 22.9937 11.9154 23.1694 11.7893 23.2956L11.7884 23.2966C11.6681 23.4201 11.4862 23.49 11.2245 23.49C10.9634 23.49 10.7789 23.4204 10.6542 23.2961C10.532 23.1701 10.4654 22.9941 10.4654 22.7552V19.8574H9.44824V22.7308C9.44824 23.0701 9.51611 23.3706 9.65611 23.6286L9.65718 23.6305Z"
          fill="#1F2333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9482 23.4168V24.3364H20.8098C21.3997 24.3364 21.8652 24.1877 22.1882 23.8736C22.5161 23.5545 22.6714 23.09 22.6714 22.4992V21.6946C22.6714 21.1042 22.5164 20.6414 22.1882 20.3264C21.8653 20.0081 21.3997 19.8574 20.8098 19.8574H18.9482V20.777H19.509V23.4168H18.9482ZM19.0545 24.23L20.8098 24.2301C21.376 24.2301 21.8073 24.0892 22.1036 23.8074M22.114 23.7974C22.114 23.7974 22.1141 23.7973 22.114 23.7974C22.1106 23.8007 22.1071 23.8041 22.1036 23.8074C21.8073 24.0892 21.376 24.2301 20.8098 24.2301L19.0545 24.23M20.8221 20.8013C21.1301 20.8013 21.3326 20.882 21.4554 21.0213L21.4562 21.0222C21.583 21.1628 21.6544 21.3733 21.6544 21.6702V22.5236C21.6544 22.8158 21.5833 23.0272 21.4554 23.1725C21.3326 23.3117 21.1301 23.3924 20.8221 23.3924H20.5261V20.8013H20.8221Z"
          fill="#1F2333"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_15058_4261"
          x1="7.21938"
          y1="23.736"
          x2="21.6354"
          y2="10.9144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.073" stopColor="#C3EEE4" />
          <stop offset="1" stopColor="#2AC8A5" />
        </linearGradient>
        <clipPath id="clip0_15058_4261">
          <rect width="26" height="26" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
