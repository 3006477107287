import React from 'react'
export const crv_circle = {
  path: (
    <>
      <path
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="url(#paint0_linear_14077_831)"
      />
      <path
        d="M16.1351 17.7368L14.9459 18.0002L14.7991 17.4432L16.0636 17.2136L16.1351 17.7368Z"
        fill="#0000FF"
      />
      <path
        d="M14.9458 18.0005L13.9108 18.3505L13.6887 17.7671L14.799 17.4397L14.9458 18.0005Z"
        fill="#0000FF"
      />
      <path
        d="M16.0599 17.21L14.7953 17.4396L14.6636 16.7885L15.9959 16.5928L16.0599 17.21Z"
        fill="#0028FF"
      />
      <path
        d="M14.7954 17.4432L13.6852 17.7707L13.4819 17.0932L14.6599 16.7959L14.7954 17.4432Z"
        fill="#0018FF"
      />
      <path
        d="M16.2144 18.1623L15.1041 18.4521L14.946 18.0005L16.1353 17.7371L16.2144 18.1623Z"
        fill="#0000F1"
      />
      <path
        d="M15.1039 18.4484L14.1518 18.8209L13.9109 18.3505L14.9459 18.0005L15.1039 18.4484Z"
        fill="#0000DA"
      />
      <path
        d="M15.9961 16.5964L14.6638 16.7922L14.5471 16.0658L15.9359 15.9077L15.9961 16.5964Z"
        fill="#0058FF"
      />
      <path
        d="M14.6636 16.7924L13.4856 17.0897L13.3125 16.3295L14.5507 16.0623L14.6636 16.7924Z"
        fill="#0048FF"
      />
      <path
        d="M17.4375 17.564L16.1353 17.7371L16.0601 17.2102L17.43 17.0747L17.4375 17.564Z"
        fill="#0014FF"
      />
      <path
        d="M16.3005 18.4747L15.273 18.7833L15.1074 18.4521L16.2177 18.1624L16.3005 18.4747Z"
        fill="#0000DA"
      />
      <path
        d="M13.9112 18.3503L13.0644 18.7869L12.7671 18.1885L13.6892 17.7708L13.9112 18.3503Z"
        fill="#0000E8"
      />
      <path
        d="M15.2693 18.7833L14.3999 19.171L14.1477 18.8247L15.0999 18.4521L15.2693 18.7833Z"
        fill="#0000C4"
      />
      <path
        d="M17.4338 17.0749L16.0638 17.2103L15.9961 16.5969L17.43 16.499L17.4338 17.0749Z"
        fill="#003CFF"
      />
      <path
        d="M13.6854 17.7705L12.7633 18.1882L12.4961 17.4882L13.4859 17.093L13.6854 17.7705Z"
        fill="#0008FF"
      />
      <path
        d="M17.4449 17.9591L16.218 18.1624L16.1389 17.7371L17.4411 17.564L17.4449 17.9591Z"
        fill="#0000FF"
      />
      <path
        d="M14.1517 18.8205L13.3802 19.2684L13.0603 18.7867L13.9071 18.3501L14.1517 18.8205Z"
        fill="#0000C8"
      />
      <path
        d="M15.9394 15.9075L14.5506 16.0656L14.4565 15.279L15.8942 15.1624L15.9394 15.9075Z"
        fill="#0090FF"
      />
      <path
        d="M14.5509 16.0654L13.3127 16.3326L13.1697 15.5084L14.4568 15.2788L14.5509 16.0654Z"
        fill="#0084FF"
      />
      <path
        d="M17.4298 16.4988L15.9959 16.5966L15.9395 15.9079L17.4261 15.8552L17.4298 16.4988Z"
        fill="#0068FF"
      />
      <path
        d="M13.4856 17.0896L12.4958 17.4848L12.2625 16.7019L13.3125 16.3293L13.4856 17.0896Z"
        fill="#003CFF"
      />
      <path
        d="M17.4491 18.245L16.2974 18.4746L16.2146 18.1622L17.4415 17.959L17.4491 18.245Z"
        fill="#0000F6"
      />
      <path
        d="M16.3838 18.6666L15.4391 18.9902L15.2698 18.7832L16.2972 18.4746L16.3838 18.6666Z"
        fill="#0000D1"
      />
      <path
        d="M14.4003 19.1708L13.7153 19.6262L13.3804 19.2687L14.1481 18.8208L14.4003 19.1708Z"
        fill="#0000AD"
      />
      <path
        d="M15.4391 18.9901L14.6563 19.3852L14.4004 19.1669L15.2698 18.7793L15.4391 18.9901Z"
        fill="#0000BB"
      />
      <path
        d="M17.4261 15.8555L15.9395 15.9082L15.8943 15.163L17.4261 15.1555V15.8555Z"
        fill="#009CFF"
      />
      <path
        d="M13.3089 16.3333L12.2588 16.7059L12.0669 15.8553L13.1659 15.509L13.3089 16.3333Z"
        fill="#0078FF"
      />
      <path
        d="M17.4563 18.418L16.3837 18.6664L16.2971 18.4745L17.4488 18.2449L17.4563 18.418Z"
        fill="#0000ED"
      />
      <path
        d="M15.894 15.1587L14.4563 15.2753L14.3848 14.4473L15.8601 14.3721L15.894 15.1587Z"
        fill="#00CCFF"
      />
      <path
        d="M18.7922 17.4775L17.4374 17.5641L17.4336 17.0748L18.8487 17.0259L18.7922 17.4775Z"
        fill="#002CFF"
      />
      <path
        d="M14.4529 15.2795L13.1658 15.5091L13.0566 14.6435L14.3814 14.4478L14.4529 15.2795Z"
        fill="#00C4FF"
      />
      <path
        d="M18.7322 17.8347L17.445 17.9551L17.4375 17.56L18.7924 17.4734L18.7322 17.8347Z"
        fill="#0014FF"
      />
      <path
        d="M18.8486 17.0255L17.4335 17.0745L17.4297 16.4986L18.9012 16.4949L18.8486 17.0255Z"
        fill="#0050FF"
      />
      <path
        d="M13.0604 18.7871L12.413 19.2952L12.0442 18.6893L12.763 18.1887L13.0604 18.7871Z"
        fill="#0000DA"
      />
      <path
        d="M12.7629 18.1887L12.0441 18.6892L11.7129 17.9779L12.492 17.4924L12.7629 18.1887Z"
        fill="#0000FF"
      />
      <path
        d="M14.656 19.3854L14.0538 19.8446L13.7151 19.6263L14.4001 19.1709L14.656 19.3854Z"
        fill="#0000A4"
      />
      <path
        d="M13.3801 19.2687L12.808 19.7806L12.4128 19.2913L13.0602 18.7832L13.3801 19.2687Z"
        fill="#0000B6"
      />
      <path
        d="M16.4704 18.734L15.6086 19.0652L15.4392 18.9899L16.3839 18.6663L16.4704 18.734Z"
        fill="#0000D6"
      />
      <path
        d="M18.6681 18.0943L17.4487 18.2448L17.4412 17.9588L18.7283 17.8384L18.6681 18.0943Z"
        fill="#0004FF"
      />
      <path
        d="M18.8974 16.4951L17.4259 16.4989L17.4221 15.8516L18.9426 15.893L18.8974 16.4951Z"
        fill="#0078FF"
      />
      <path
        d="M15.6079 19.0653L14.9117 19.4642L14.6558 19.3852L15.4386 18.99L15.6079 19.0653Z"
        fill="#0000BF"
      />
      <path
        d="M17.426 15.1511L15.8942 15.1587L15.8604 14.3721L17.426 14.4097V15.1511Z"
        fill="#00D8FF"
      />
      <path
        d="M12.4956 17.4882L11.7166 17.9737L11.4268 17.1721L12.2623 16.7017L12.4956 17.4882Z"
        fill="#0034FF"
      />
      <path
        d="M17.4677 18.4747L16.4704 18.7344L16.3838 18.6666L17.4564 18.4182L17.4677 18.4747Z"
        fill="#0000F1"
      />
      <path
        d="M13.7154 19.6262L13.2186 20.1418L12.8083 19.7843L13.3804 19.2725L13.7154 19.6262Z"
        fill="#00009F"
      />
      <path
        d="M13.1657 15.5087L12.0667 15.8549L11.9199 14.9592L13.0528 14.6431L13.1657 15.5087Z"
        fill="#00BCFF"
      />
      <path
        d="M18.6077 18.2487L17.456 18.4218L17.4485 18.2487L18.6679 18.0981L18.6077 18.2487Z"
        fill="#0000FF"
      />
      <path
        d="M15.8603 14.3721L14.385 14.4473L14.3398 13.5968L15.8415 13.5667L15.8603 14.3721Z"
        fill="#23FFD4"
      />
      <path
        d="M18.9465 15.8969L17.426 15.8555V15.1555L18.9841 15.2458L18.9465 15.8969Z"
        fill="#00ACFF"
      />
      <path
        d="M14.3851 14.4472L13.0603 14.643L12.9888 13.7548L14.3399 13.5967L14.3851 14.4472Z"
        fill="#1CFFDB"
      />
      <path
        d="M14.912 19.4643L14.3963 19.9234L14.0576 19.8444L14.6598 19.3853L14.912 19.4643Z"
        fill="#0000AD"
      />
      <path
        d="M12.2626 16.7021L11.427 17.1726L11.1899 16.3032L12.0706 15.8516L12.2626 16.7021Z"
        fill="#0070FF"
      />
      <path
        d="M16.5529 18.6743L15.7701 19.0055L15.6045 19.0657L16.4664 18.7345L16.5529 18.6743Z"
        fill="#0000E8"
      />
      <path
        d="M14.0577 19.8444L13.6399 20.3562L13.2222 20.1379L13.719 19.6223L14.0577 19.8444Z"
        fill="#000096"
      />
      <path
        d="M17.4261 14.4135L15.8605 14.3758L15.8379 13.5667L17.4261 13.6532V14.4135Z"
        fill="#26FFD1"
      />
      <path
        d="M18.5476 18.29L17.4675 18.4745L17.46 18.418L18.6116 18.2449L18.5476 18.29Z"
        fill="#0000FF"
      />
      <path
        d="M20.1283 17.4662L18.7922 17.4775L18.8487 17.0259L20.2375 17.056L20.1283 17.4662Z"
        fill="#0044FF"
      />
      <path
        d="M20.0117 17.7896L18.7283 17.8348L18.7885 17.4735L20.1246 17.4622L20.0117 17.7896Z"
        fill="#002CFF"
      />
      <path
        d="M15.7742 19.0054L15.1607 19.4005L14.9124 19.4608L15.6086 19.0618L15.7742 19.0054Z"
        fill="#0000D1"
      />
      <path
        d="M18.9841 15.2456L17.426 15.1552V14.4138L19.018 14.5568L18.9841 15.2456Z"
        fill="#00E0FB"
      />
      <path
        d="M17.4748 18.4109L16.5528 18.6743L16.47 18.7346L17.4673 18.4749L17.4748 18.4109Z"
        fill="#0000FF"
      />
      <path
        d="M20.2374 17.0556L18.8486 17.0255L18.9013 16.4949L20.339 16.5701L20.2374 17.0556Z"
        fill="#0064FF"
      />
      <path
        d="M13.0569 14.6433L11.924 14.9595L11.8262 14.0374L12.9854 13.7551L13.0569 14.6433Z"
        fill="#19FFDE"
      />
      <path
        d="M19.8953 18.0189L18.6721 18.0942L18.7323 17.8345L20.0157 17.7893L19.8953 18.0189Z"
        fill="#001CFF"
      />
      <path
        d="M12.4133 19.2948L11.9654 19.8593L11.5364 19.2496L12.0445 18.6851L12.4133 19.2948Z"
        fill="#0000D1"
      />
      <path
        d="M12.8081 19.7805L12.4242 20.3413L11.9651 19.8558L12.413 19.2913L12.8081 19.7805Z"
        fill="#0000A8"
      />
      <path
        d="M15.8381 13.5666L14.3364 13.5967L14.3176 12.7461L15.8306 12.7574L15.8381 13.5666Z"
        fill="#5AFF9D"
      />
      <path
        d="M20.3354 16.5707L18.8977 16.4954L18.9429 15.897L20.422 16.0249L20.3354 16.5707Z"
        fill="#008CFF"
      />
      <path
        d="M12.0476 18.6854L11.5395 19.25L11.1519 18.5349L11.7164 17.9741L12.0476 18.6854Z"
        fill="#0000FF"
      />
      <path
        d="M12.0701 15.8554L11.1894 16.3071L11.0088 15.3888L11.9233 14.9597L12.0701 15.8554Z"
        fill="#00B8FF"
      />
      <path
        d="M14.3363 13.5968L12.9852 13.7549L12.9551 12.8667L14.3175 12.75L14.3363 13.5968Z"
        fill="#5AFF9D"
      />
      <path
        d="M19.7744 18.1471L18.6077 18.2488L18.6679 18.0982L19.891 18.0229L19.7744 18.1471Z"
        fill="#0018FF"
      />
      <path
        d="M15.1565 19.4038L14.7237 19.8554L14.3962 19.9232L14.9119 19.464L15.1565 19.4038Z"
        fill="#0000BF"
      />
      <path
        d="M14.3964 19.9235L14.0576 20.4278L13.6399 20.3563L14.0576 19.8445L14.3964 19.9235Z"
        fill="#00009B"
      />
      <path
        d="M13.2223 20.1382L12.9062 20.6952L12.4282 20.3452L12.8121 19.7844L13.2223 20.1382Z"
        fill="#000092"
      />
      <path
        d="M18.4912 18.2188L17.4751 18.4107L17.4675 18.4747L18.5477 18.2903L18.4912 18.2188Z"
        fill="#000CFF"
      />
      <path
        d="M11.7166 17.974L11.1521 18.5348L10.8171 17.7256L11.4306 17.1724L11.7166 17.974Z"
        fill="#002CFF"
      />
      <path
        d="M17.4258 13.6533L15.8376 13.5667L15.8301 12.7576L17.4296 12.8893L17.4258 13.6533Z"
        fill="#5DFF9A"
      />
      <path
        d="M19.0141 14.5532L17.4221 14.4101V13.6499L19.0329 13.8418L19.0141 14.5532Z"
        fill="#2CFFCA"
      />
      <path
        d="M20.422 16.0208L18.9429 15.8928L18.9805 15.2417L20.4935 15.4186L20.422 16.0208Z"
        fill="#00B8FF"
      />
      <path
        d="M16.6321 18.4858L15.9246 18.8133L15.7703 19.0052L16.5531 18.674L16.6321 18.4858Z"
        fill="#0000FF"
      />
      <path
        d="M17.4826 18.2261L16.632 18.4858L16.553 18.6739L17.4751 18.4105L17.4826 18.2261Z"
        fill="#000CFF"
      />
      <path
        d="M19.6541 18.1733L18.5476 18.29L18.6078 18.2486L19.7745 18.147L19.6541 18.1733Z"
        fill="#0018FF"
      />
      <path
        d="M15.9284 18.8132L15.394 19.2009L15.1606 19.4041L15.7741 19.0089L15.9284 18.8132Z"
        fill="#0000F1"
      />
      <path
        d="M12.985 13.7549L11.8258 14.0372L11.7844 13.1151L12.9549 12.8667L12.985 13.7549Z"
        fill="#56FFA0"
      />
      <path
        d="M13.6401 20.3562L13.3955 20.9019L12.9062 20.6949L13.2224 20.1379L13.6401 20.3562Z"
        fill="#000089"
      />
      <path
        d="M11.4269 17.1726L10.8134 17.7259L10.5349 16.8452L11.186 16.3032L11.4269 17.1726Z"
        fill="#006CFF"
      />
      <path
        d="M11.9237 14.9557L11.0091 15.3847L10.8887 14.4401L11.8258 14.0374L11.9237 14.9557Z"
        fill="#16FFE1"
      />
      <path
        d="M20.4973 15.4223L18.9844 15.2454L19.0145 14.5566L20.5538 14.7862L20.4973 15.4223Z"
        fill="#06ECF1"
      />
      <path
        d="M15.8306 12.7576L14.3176 12.7463L14.3252 11.9146L15.8343 11.9672L15.8306 12.7576Z"
        fill="#94FF63"
      />
      <path
        d="M18.4385 18.0342L17.4863 18.2261L17.4788 18.4105L18.4949 18.2186L18.4385 18.0342Z"
        fill="#0024FF"
      />
      <path
        d="M14.7236 19.8562L14.4602 20.3492L14.0537 20.4283L14.3924 19.9239L14.7236 19.8562Z"
        fill="#0000AD"
      />
      <path
        d="M21.2161 17.8085L20.0117 17.7897L20.1284 17.4661L21.3779 17.5225L21.2161 17.8085Z"
        fill="#0044FF"
      />
      <path
        d="M21.3779 17.5226L20.1284 17.4661L20.2376 17.0559L21.5285 17.1538L21.3779 17.5226Z"
        fill="#005CFF"
      />
      <path
        d="M19.0368 13.845L17.426 13.6531L17.4298 12.8928L19.0519 13.1337L19.0368 13.845Z"
        fill="#5DFF9A"
      />
      <path
        d="M14.3175 12.7463L12.9551 12.863L12.9626 11.9936L14.325 11.9146L14.3175 12.7463Z"
        fill="#94FF63"
      />
      <path
        d="M19.5413 18.0945L18.4912 18.2187L18.5477 18.2902L19.6542 18.1735L19.5413 18.0945Z"
        fill="#0028FF"
      />
      <path
        d="M15.39 19.2012L15.0325 19.6415L14.7239 19.856L15.1567 19.4044L15.39 19.2012Z"
        fill="#0000DF"
      />
      <path
        d="M21.0503 18.0076L19.8911 18.0189L20.0115 17.7893L21.2159 17.8081L21.0503 18.0076Z"
        fill="#0038FF"
      />
      <path
        d="M21.5287 17.1576L20.2378 17.0597L20.3356 16.5742L21.6642 16.721L21.5287 17.1576Z"
        fill="#0078FF"
      />
      <path
        d="M17.4296 12.8894L15.8301 12.7576L15.8376 11.9673L17.4371 12.1442L17.4296 12.8894Z"
        fill="#90FF66"
      />
      <path
        d="M14.0577 20.428L13.8808 20.9586L13.3953 20.9022L13.6399 20.3564L14.0577 20.428Z"
        fill="#00008D"
      />
      <path
        d="M16.707 18.1731L16.071 18.4892L15.928 18.8129L16.6355 18.4855L16.707 18.1731Z"
        fill="#001CFF"
      />
      <path
        d="M20.8849 18.1132L19.7747 18.1471L19.8951 18.0191L21.0543 18.0078L20.8849 18.1132Z"
        fill="#0034FF"
      />
      <path
        d="M17.4938 17.9253L16.7073 18.1737L16.6357 18.4861L17.4863 18.2264L17.4938 17.9253Z"
        fill="#0030FF"
      />
      <path
        d="M21.664 16.7173L20.3354 16.5705L20.422 16.021L21.7844 16.2167L21.664 16.7173Z"
        fill="#00A0FF"
      />
      <path
        d="M20.5535 14.7861L19.0142 14.5565L19.0367 13.8452L20.5911 14.1275L20.5535 14.7861Z"
        fill="#33FFC4"
      />
      <path
        d="M11.19 16.3028L10.5389 16.8448L10.3281 15.9114L11.0093 15.3845L11.19 16.3028Z"
        fill="#00B0FF"
      />
      <path
        d="M11.9651 19.8598L11.7016 20.462L11.2236 19.8636L11.536 19.2539L11.9651 19.8598Z"
        fill="#0000C8"
      />
      <path
        d="M12.4243 20.3449L12.2135 20.9358L11.7017 20.4615L11.9651 19.8594L12.4243 20.3449Z"
        fill="#0000A4"
      />
      <path
        d="M16.0673 18.4895L15.6044 18.8659L15.3899 19.2046L15.9243 18.8169L16.0673 18.4895Z"
        fill="#0008FF"
      />
      <path
        d="M11.5361 19.2497L11.2237 19.8594L10.7947 19.1519L11.1522 18.5347L11.5361 19.2497Z"
        fill="#0000FF"
      />
      <path
        d="M12.9549 12.8669L11.7844 13.1153L11.7957 12.2158L12.9624 11.9976L12.9549 12.8669Z"
        fill="#94FF63"
      />
      <path
        d="M20.7191 18.1206L19.6541 18.1733L19.7707 18.1469L20.881 18.113L20.7191 18.1206Z"
        fill="#0034FF"
      />
      <path
        d="M19.4357 17.9138L18.4346 18.038L18.4873 18.2224L19.5373 18.0982L19.4357 17.9138Z"
        fill="#003CFF"
      />
      <path
        d="M11.826 14.0371L10.8889 14.4398L10.8362 13.4913L11.7846 13.115L11.826 14.0371Z"
        fill="#56FFA0"
      />
      <path
        d="M12.9059 20.695L12.7478 21.2708L12.2134 20.9359L12.4241 20.345L12.9059 20.695Z"
        fill="#000089"
      />
      <path
        d="M18.3896 17.7444L17.4939 17.925L17.4863 18.2261L18.4385 18.0342L18.3896 17.7444Z"
        fill="#0044FF"
      />
      <path
        d="M21.7843 16.2168L20.4219 16.0211L20.4934 15.4189L21.8821 15.6673L21.7843 16.2168Z"
        fill="#00C8FF"
      />
      <path
        d="M19.0518 13.134L17.4297 12.8931L17.4335 12.1479L19.0518 12.4377V13.134Z"
        fill="#90FF66"
      />
      <path
        d="M15.8341 11.9673L14.325 11.9146L14.3588 11.1243L15.853 11.2146L15.8341 11.9673Z"
        fill="#CAFF2C"
      />
      <path
        d="M15.0325 19.6414L14.8443 20.1193L14.4604 20.3451L14.7239 19.8521L15.0325 19.6414Z"
        fill="#0000CD"
      />
      <path
        d="M11.1525 18.5349L10.795 19.1521L10.4224 18.3467L10.8175 17.7295L11.1525 18.5349Z"
        fill="#0028FF"
      />
      <path
        d="M14.3248 11.9146L12.9624 11.9937L13.0113 11.1694L14.3587 11.1243L14.3248 11.9146Z"
        fill="#CEFF29"
      />
      <path
        d="M17.4335 12.1483L15.834 11.9714L15.8528 11.2224L17.4373 11.4407L17.4335 12.1483Z"
        fill="#C7FF30"
      />
      <path
        d="M14.4601 20.3489L14.3472 20.8645L13.8767 20.9586L14.0536 20.4279L14.4601 20.3489Z"
        fill="#00009F"
      />
      <path
        d="M20.5949 14.1274L19.0405 13.8451L19.0518 13.1338L20.6175 13.465L20.5949 14.1274Z"
        fill="#60FF97"
      />
      <path
        d="M20.5649 18.0342L19.5449 18.0944L19.6578 18.1734L20.7229 18.1207L20.5649 18.0342Z"
        fill="#0040FF"
      />
      <path
        d="M15.6044 18.8621L15.3184 19.2873L15.0361 19.6411L15.3937 19.2008L15.6044 18.8621Z"
        fill="#0000FF"
      />
      <path
        d="M13.3951 20.9021L13.2935 21.4591L12.7478 21.2709L12.9059 20.6951L13.3951 20.9021Z"
        fill="#000080"
      />
      <path
        d="M11.0092 15.3845L10.328 15.9114L10.1887 14.9479L10.8887 14.436L11.0092 15.3845Z"
        fill="#16FFE1"
      />
      <path
        d="M21.8861 15.6669L20.4973 15.4185L20.5538 14.7825L21.9613 15.0836L21.8861 15.6669Z"
        fill="#0FF8E7"
      />
      <path
        d="M17.5011 17.5151L16.771 17.7447L16.707 18.1738L17.4936 17.9254L17.5011 17.5151Z"
        fill="#0058FF"
      />
      <path
        d="M16.7711 17.7444L16.1952 18.0417L16.071 18.4896L16.7071 18.1734L16.7711 17.7444Z"
        fill="#0048FF"
      />
      <path
        d="M19.3418 17.6316L18.3896 17.7445L18.4386 18.0343L19.4397 17.9101L19.3418 17.6316Z"
        fill="#0058FF"
      />
      <path
        d="M10.8134 17.7256L10.4182 18.3429L10.1096 17.4622L10.5387 16.845L10.8134 17.7256Z"
        fill="#0068FF"
      />
      <path
        d="M22.2696 17.8835L21.2158 17.8082L21.3739 17.5222L22.4616 17.6314L22.2696 17.8835Z"
        fill="#0060FF"
      />
      <path
        d="M22.0705 18.0496L21.0505 18.0044L21.2161 17.8049L22.2699 17.8802L22.0705 18.0496Z"
        fill="#0054FF"
      />
      <path
        d="M22.4654 17.6314L21.3777 17.5223L21.5282 17.1572L22.646 17.3078L22.4654 17.6314Z"
        fill="#0074FF"
      />
      <path
        d="M18.3482 17.3528L17.5014 17.5146L17.4939 17.9248L18.3896 17.7442L18.3482 17.3528Z"
        fill="#006CFF"
      />
      <path
        d="M16.1954 18.0378L15.7927 18.3916L15.6045 18.8583L16.0674 18.4819L16.1954 18.0378Z"
        fill="#0038FF"
      />
      <path
        d="M20.4146 17.8538L19.436 17.914L19.5414 18.0946L20.5613 18.0344L20.4146 17.8538Z"
        fill="#0054FF"
      />
      <path
        d="M19.0558 12.4344L17.4375 12.1446L17.445 11.437L19.0521 11.7682L19.0558 12.4344Z"
        fill="#C1FF36"
      />
      <path
        d="M13.8812 20.9585L13.8322 21.5004L13.2903 21.4628L13.3919 20.9058L13.8812 20.9585Z"
        fill="#000084"
      />
      <path
        d="M11.7846 13.1152L10.8362 13.4915L10.8512 12.5619L11.7959 12.2119L11.7846 13.1152Z"
        fill="#97FF60"
      />
      <path
        d="M21.8708 18.132L20.8848 18.1132L21.0504 18.0078L22.0703 18.053L21.8708 18.132Z"
        fill="#004CFF"
      />
      <path
        d="M12.9626 11.9976L11.7959 12.2159L11.8636 11.3578L13.0153 11.1733L12.9626 11.9976Z"
        fill="#D1FF26"
      />
      <path
        d="M22.6463 17.3079L21.5286 17.1574L21.6641 16.717L22.8082 16.9165L22.6463 17.3079Z"
        fill="#0090FF"
      />
      <path
        d="M21.965 15.0832L20.5574 14.7822L20.5988 14.1235L22.0214 14.4773L21.965 15.0832Z"
        fill="#36FFC1"
      />
      <path
        d="M20.6177 13.4652L19.052 13.134L19.0558 12.4377L20.6177 12.8179V13.4652Z"
        fill="#8DFF6A"
      />
      <path
        d="M15.8564 11.2188L14.3623 11.1285L14.4225 10.4021L15.8903 10.5301L15.8564 11.2188Z"
        fill="#FFEA00"
      />
      <path
        d="M15.3182 19.2874L15.194 19.7465L14.844 20.1191L15.0322 19.6411L15.3182 19.2874Z"
        fill="#0000FF"
      />
      <path
        d="M21.6717 18.1281L20.7195 18.1243L20.8851 18.113L21.8711 18.1319L21.6717 18.1281Z"
        fill="#0050FF"
      />
      <path
        d="M14.8441 20.1194L14.7952 20.6199L14.3511 20.8646L14.464 20.349L14.8441 20.1194Z"
        fill="#0000C4"
      />
      <path
        d="M22.8116 16.9166L21.6675 16.7171L21.7879 16.2166L22.9584 16.4649L22.8116 16.9166Z"
        fill="#00B0FF"
      />
      <path
        d="M17.4409 11.4403L15.8564 11.222L15.8903 10.5295L17.4484 10.7855L17.4409 11.4403Z"
        fill="#FBF100"
      />
      <path
        d="M19.2587 17.2588L18.3479 17.3529L18.3893 17.7443L19.3415 17.6314L19.2587 17.2588Z"
        fill="#0080FF"
      />
      <path
        d="M14.3586 11.1285L13.0112 11.1736L13.1016 10.4134L14.4188 10.4021L14.3586 11.1285Z"
        fill="#FFE200"
      />
      <path
        d="M10.8885 14.4404L10.1885 14.9522L10.1245 13.985L10.8358 13.4919L10.8885 14.4404Z"
        fill="#53FFA4"
      />
      <path
        d="M10.5385 16.8449L10.1132 17.4621L9.87988 16.525L10.3315 15.9153L10.5385 16.8449Z"
        fill="#00B0FF"
      />
      <path
        d="M15.7928 18.3955L15.567 18.7982L15.3186 19.2875L15.6046 18.8622L15.7928 18.3955Z"
        fill="#002CFF"
      />
      <path
        d="M20.2827 17.5828L19.3418 17.6317L19.4359 17.914L20.4144 17.8537L20.2827 17.5828Z"
        fill="#0070FF"
      />
      <path
        d="M12.2136 20.9359L12.1685 21.5079L11.634 21.0413L11.7018 20.4617L12.2136 20.9359Z"
        fill="#00009F"
      />
      <path
        d="M11.7015 20.4617L11.6338 21.0413L11.137 20.4504L11.2235 19.8633L11.7015 20.4617Z"
        fill="#0000C8"
      />
      <path
        d="M17.5088 17.0029L16.8238 17.2062L16.7673 17.7444L17.4975 17.5148L17.5088 17.0029Z"
        fill="#008CFF"
      />
      <path
        d="M21.4793 18.0303L20.561 18.034L20.7191 18.1206L21.6713 18.1244L21.4793 18.0303Z"
        fill="#005CFF"
      />
      <path
        d="M16.8239 17.2063L16.297 17.4773L16.1917 18.0381L16.7675 17.7407L16.8239 17.2063Z"
        fill="#007CFF"
      />
      <path
        d="M22.9551 16.4646L21.7847 16.2162L21.8863 15.6667L23.0756 15.9641L22.9551 16.4646Z"
        fill="#00D8FF"
      />
      <path
        d="M12.7478 21.2709L12.7252 21.8316L12.1682 21.5042L12.2134 20.9321L12.7478 21.2709Z"
        fill="#000089"
      />
      <path
        d="M14.3509 20.8645L14.3547 21.3839L13.8315 21.4968L13.8805 20.9548L14.3509 20.8645Z"
        fill="#00009B"
      />
      <path
        d="M22.0176 14.4815L20.595 14.1277L20.6176 13.4653L22.0477 13.868L22.0176 14.4815Z"
        fill="#63FF94"
      />
      <path
        d="M11.2239 19.8637L11.1373 20.4508L10.6895 19.7432L10.7948 19.1523L11.2239 19.8637Z"
        fill="#0000FA"
      />
      <path
        d="M18.3181 16.8677L17.5127 17.0032L17.5051 17.515L18.3519 17.3532L18.3181 16.8677Z"
        fill="#009CFF"
      />
      <path
        d="M19.0482 11.7717L17.4412 11.4405L17.4487 10.7856L19.0332 11.1545L19.0482 11.7717Z"
        fill="#F1FC06"
      />
      <path
        d="M20.6175 12.8143L19.0556 12.4342L19.0481 11.7681L20.5987 12.1934L20.6175 12.8143Z"
        fill="#BEFF39"
      />
      <path
        d="M16.3008 17.4771L15.9508 17.8082L15.7927 18.3954L16.1954 18.0416L16.3008 17.4771Z"
        fill="#0070FF"
      />
      <path
        d="M13.2899 21.4592V22.0124L12.7253 21.8318L12.7479 21.271L13.2899 21.4592Z"
        fill="#000080"
      />
      <path
        d="M21.3028 17.8538H20.4146L20.5613 18.0344L21.4796 18.0306L21.3028 17.8538Z"
        fill="#006CFF"
      />
      <path
        d="M11.7957 12.212L10.8511 12.562L10.9339 11.6776L11.8635 11.3577L11.7957 12.212Z"
        fill="#D4FF23"
      />
      <path
        d="M23.075 15.9678L21.8857 15.6705L21.9648 15.0872L23.1691 15.4372L23.075 15.9678Z"
        fill="#19FFDE"
      />
      <path
        d="M13.0152 11.1731L11.8635 11.3575L11.984 10.5671L13.1055 10.4128L13.0152 11.1731Z"
        fill="#FFDB00"
      />
      <path
        d="M10.7946 19.1521L10.6893 19.743L10.2979 18.9413L10.4183 18.3467L10.7946 19.1521Z"
        fill="#0024FF"
      />
      <path
        d="M20.1697 17.2251L19.2627 17.2552L19.3455 17.6278L20.2864 17.5789L20.1697 17.2251Z"
        fill="#0094FF"
      />
      <path
        d="M19.1948 16.7998L18.3179 16.8675L18.3517 17.3531L19.2625 17.259L19.1948 16.7998Z"
        fill="#00ACFF"
      />
      <path
        d="M15.887 10.5296L14.4192 10.4016L14.5058 9.75806L15.9321 9.91613L15.887 10.5296Z"
        fill="#FFB200"
      />
      <path
        d="M10.3281 15.9154L9.87648 16.5251L9.72217 15.5541L10.1889 14.9519L10.3281 15.9154Z"
        fill="#13FCE4"
      />
      <path
        d="M15.1941 19.7466L15.2016 20.2246L14.7952 20.6197L14.8441 20.1192L15.1941 19.7466Z"
        fill="#0000F1"
      />
      <path
        d="M17.4487 10.7854L15.8906 10.5295L15.9358 9.91602L17.4563 10.2058L17.4487 10.7854Z"
        fill="#FFBD00"
      />
      <path
        d="M15.5668 18.7981L15.499 19.2384L15.1904 19.7465L15.3146 19.2874L15.5668 18.7981Z"
        fill="#0020FF"
      />
      <path
        d="M22.0439 13.8683L20.6138 13.4656L20.6175 12.8145L22.0439 13.2661V13.8683Z"
        fill="#8DFF6A"
      />
      <path
        d="M22.8869 18.1398L22.074 18.0495L22.2734 17.8801L23.109 17.9968L22.8869 18.1398Z"
        fill="#006CFF"
      />
      <path
        d="M23.1092 18.0006L22.2737 17.8839L22.4656 17.6355L23.3237 17.7898L23.1092 18.0006Z"
        fill="#0078FF"
      />
      <path
        d="M10.8358 13.4918L10.1245 13.9848L10.1396 13.0364L10.8471 12.5659L10.8358 13.4918Z"
        fill="#97FF60"
      />
      <path
        d="M22.6612 18.1999L21.8708 18.1321L22.0741 18.0493L22.887 18.1396L22.6612 18.1999Z"
        fill="#0068FF"
      />
      <path
        d="M23.3234 17.7856L22.4653 17.6313L22.646 17.3076L23.5267 17.4996L23.3234 17.7856Z"
        fill="#008CFF"
      />
      <path
        d="M14.4227 10.4016L13.1055 10.4129L13.2334 9.73926L14.5093 9.75808L14.4227 10.4016Z"
        fill="#FFA700"
      />
      <path
        d="M17.5164 16.4048L16.869 16.5779L16.8276 17.2102L17.5126 17.007L17.5164 16.4048Z"
        fill="#00C4FF"
      />
      <path
        d="M13.8322 21.4966L13.8548 22.0386L13.2903 22.0122V21.459L13.8322 21.4966Z"
        fill="#000084"
      />
      <path
        d="M14.7956 20.6199L14.852 21.1204L14.359 21.3876L14.3552 20.8683L14.7956 20.6199Z"
        fill="#0000BB"
      />
      <path
        d="M21.1447 17.5903L20.2866 17.5828L20.4183 17.8537H21.3066L21.1447 17.5903Z"
        fill="#0084FF"
      />
      <path
        d="M15.9508 17.8083L15.7777 18.1885L15.5669 18.7982L15.7927 18.3955L15.9508 17.8083Z"
        fill="#0068FF"
      />
      <path
        d="M23.1692 15.4337L21.9648 15.0837L22.0175 14.4778L23.2332 14.8805L23.1692 15.4337Z"
        fill="#3CFFBA"
      />
      <path
        d="M18.2921 16.3032L17.5168 16.4048L17.5093 17.0033L18.3147 16.8678L18.2921 16.3032Z"
        fill="#00D0FF"
      />
      <path
        d="M10.4184 18.3428L10.2979 18.9375L9.97803 18.0568L10.1098 17.4622L10.4184 18.3428Z"
        fill="#0064FF"
      />
      <path
        d="M22.4392 18.1806L21.6714 18.1279L21.8709 18.1355L22.6612 18.2032L22.4392 18.1806Z"
        fill="#0068FF"
      />
      <path
        d="M16.8691 16.5742L16.3836 16.8113L16.3008 17.4737L16.8277 17.2027L16.8691 16.5742Z"
        fill="#00B8FF"
      />
      <path
        d="M23.5233 17.4996L22.6426 17.3077L22.8082 16.9163L23.7039 17.1534L23.5233 17.4996Z"
        fill="#00A4FF"
      />
      <path
        d="M20.6028 12.1967L19.0522 11.7714L19.0371 11.158L20.5689 11.6209L20.6028 12.1967Z"
        fill="#EBFF0C"
      />
      <path
        d="M19.033 11.1545L17.4485 10.7856L17.456 10.2061L19.0066 10.6088L19.033 11.1545Z"
        fill="#FFC800"
      />
      <path
        d="M20.0757 16.7959L19.1951 16.7997L19.2628 17.2588L20.1698 17.2287L20.0757 16.7959Z"
        fill="#00BCFF"
      />
      <path
        d="M22.2283 18.0793L21.4832 18.0342L21.6751 18.1283L22.4429 18.181L22.2283 18.0793Z"
        fill="#0074FF"
      />
      <path
        d="M23.7075 17.1497L22.8118 16.9126L22.9548 16.4609L23.8693 16.7432L23.7075 17.1497Z"
        fill="#00C4FF"
      />
      <path
        d="M19.1421 16.2688L18.2915 16.3027L18.3141 16.8672L19.191 16.7995L19.1421 16.2688Z"
        fill="#00DCFE"
      />
      <path
        d="M16.3833 16.8149L16.0746 17.116L15.9504 17.8085L16.3005 17.4773L16.3833 16.8149Z"
        fill="#00B0FF"
      />
      <path
        d="M22.048 13.2655L20.6216 12.8139L20.6028 12.1929L22.0217 12.6897L22.048 13.2655Z"
        fill="#B7FF40"
      />
      <path
        d="M21.0052 17.2514L20.1697 17.2251L20.2863 17.5826L21.1444 17.5902L21.0052 17.2514Z"
        fill="#00A4FF"
      />
      <path
        d="M23.2332 14.8806L22.0176 14.4779L22.0439 13.8682L23.2633 14.3198L23.2332 14.8806Z"
        fill="#63FF94"
      />
      <path
        d="M14.3547 21.3838L14.3998 21.9145L13.8541 22.0387L13.8315 21.4967L14.3547 21.3838Z"
        fill="#000096"
      />
      <path
        d="M10.189 14.9519L9.72229 15.554L9.65454 14.5755L10.1288 13.9846L10.189 14.9519Z"
        fill="#53FFA4"
      />
      <path
        d="M11.86 11.3576L10.9304 11.6775L11.0772 10.857L11.9805 10.5635L11.86 11.3576Z"
        fill="#FFD700"
      />
      <path
        d="M15.9318 9.91619L14.5054 9.75812L14.6145 9.2124L15.9882 9.39682L15.9318 9.91619Z"
        fill="#FF7A00"
      />
      <path
        d="M13.1054 10.4129L11.9839 10.5672L12.157 9.86346L13.2372 9.73926L13.1054 10.4129Z"
        fill="#FF9C00"
      />
      <path
        d="M17.4563 10.2057L15.9358 9.91586L15.9922 9.39648L17.4638 9.71263L17.4563 10.2057Z"
        fill="#FF8900"
      />
      <path
        d="M22.0291 17.899L21.3027 17.8501L21.4796 18.0307L22.2248 18.0759L22.0291 17.899Z"
        fill="#0084FF"
      />
      <path
        d="M12.1684 21.5039L12.2323 22.0271L11.7017 21.5642L11.6377 21.041L12.1684 21.5039Z"
        fill="#00009F"
      />
      <path
        d="M23.8694 16.7473L22.9548 16.4651L23.0753 15.9683L24.0011 16.2995L23.8694 16.7473Z"
        fill="#00E4F8"
      />
      <path
        d="M11.6339 21.0413L11.6978 21.5645L11.201 20.9736L11.1333 20.4504L11.6339 21.0413Z"
        fill="#0000C8"
      />
      <path
        d="M15.5031 19.2385L15.5633 19.6977L15.2058 20.2283L15.1982 19.7504L15.5031 19.2385Z"
        fill="#0018FF"
      />
      <path
        d="M10.1098 17.4625L9.97803 18.0572L9.7334 17.12L9.87642 16.5254L10.1098 17.4625Z"
        fill="#00ACFF"
      />
      <path
        d="M12.7252 21.8316L12.7892 22.3547L12.2322 22.0273L12.1682 21.5042L12.7252 21.8316Z"
        fill="#000089"
      />
      <path
        d="M15.7778 18.1887L15.759 18.6065L15.4993 19.2425L15.567 18.8022L15.7778 18.1887Z"
        fill="#005CFF"
      />
      <path
        d="M17.5238 15.7312L16.899 15.8629L16.8689 16.5742L17.5162 16.4011L17.5238 15.7312Z"
        fill="#16FFE1"
      />
      <path
        d="M15.2016 20.2283L15.3032 20.7062L14.8516 21.1202L14.7952 20.6197L15.2016 20.2283Z"
        fill="#0000ED"
      />
      <path
        d="M20.565 11.621L19.0332 11.1581L19.0068 10.6086L20.5123 11.1054L20.565 11.621Z"
        fill="#FFD700"
      />
      <path
        d="M18.2804 15.6707L17.5239 15.7309L17.5164 16.4046L18.2917 16.3029L18.2804 15.6707Z"
        fill="#1CFFDB"
      />
      <path
        d="M10.8511 12.5624L10.1436 13.0329L10.2376 12.1258L10.9301 11.678L10.8511 12.5624Z"
        fill="#D7FF1F"
      />
      <path
        d="M11.1373 20.4463L11.2051 20.9695L10.7572 20.2657L10.6895 19.7388L11.1373 20.4463Z"
        fill="#0000FA"
      />
      <path
        d="M20.0042 16.2955L19.1423 16.2654L19.1913 16.796L20.0719 16.7923L20.0042 16.2955Z"
        fill="#02E8F4"
      />
      <path
        d="M14.5093 9.75421L13.2334 9.73539L13.399 9.16333L14.6184 9.20849L14.5093 9.75421Z"
        fill="#FF6F00"
      />
      <path
        d="M19.0067 10.6086L17.4561 10.2059L17.4636 9.71289L18.969 10.1419L19.0067 10.6086Z"
        fill="#FF9800"
      />
      <path
        d="M16.8994 15.8625L16.4402 16.0658L16.3838 16.8147L16.8693 16.5776L16.8994 15.8625Z"
        fill="#13FCE4"
      />
      <path
        d="M21.8521 17.6468L21.1445 17.5903L21.3064 17.8538L22.0327 17.9027L21.8521 17.6468Z"
        fill="#009CFF"
      />
      <path
        d="M16.0753 17.116L15.9436 17.4697L15.7817 18.1886L15.9549 17.8085L16.0753 17.116Z"
        fill="#00A8FF"
      />
      <path
        d="M20.8887 16.8452L20.072 16.7925L20.1661 17.2253L21.0016 17.2516L20.8887 16.8452Z"
        fill="#00CCFF"
      />
      <path
        d="M24.001 16.2991L23.0752 15.9679L23.1693 15.4373L24.1064 15.8211L24.001 16.2991Z"
        fill="#1FFFD7"
      />
      <path
        d="M13.2899 22.0127L13.3539 22.5358L12.7893 22.3552L12.7253 21.832L13.2899 22.0127Z"
        fill="#000080"
      />
      <path d="M23.2636 14.3194L22.0442 13.8678V13.2656L23.2636 13.7662V14.3194Z" fill="#8DFF6A" />
      <path
        d="M22.0214 12.6898L20.6026 12.193L20.5649 11.6172L21.9688 12.1516L22.0214 12.6898Z"
        fill="#E1FF16"
      />
      <path
        d="M19.112 15.6782L18.2803 15.6707L18.2953 16.3029L19.1459 16.2691L19.112 15.6782Z"
        fill="#23FFD4"
      />
      <path
        d="M10.6894 19.7428L10.7572 20.2697L10.3695 19.4681L10.3018 18.9412L10.6894 19.7428Z"
        fill="#0024FF"
      />
      <path
        d="M23.4365 18.2639L22.8833 18.1397L23.1054 18.0005L23.6699 18.151L23.4365 18.2639Z"
        fill="#0084FF"
      />
      <path
        d="M14.852 21.1165L14.916 21.6358L14.4004 21.9106L14.3552 21.3799L14.852 21.1165Z"
        fill="#0000BB"
      />
      <path
        d="M23.1993 18.3055L22.6611 18.2001L22.8869 18.1399L23.4402 18.2641L23.1993 18.3055Z"
        fill="#0080FF"
      />
      <path
        d="M23.6739 18.1507L23.1094 18.0002L23.3239 17.7856L23.8997 17.9701L23.6739 18.1507Z"
        fill="#0090FF"
      />
      <path
        d="M22.9659 18.2713L22.439 18.1772L22.661 18.1961L23.1992 18.3014L22.9659 18.2713Z"
        fill="#0084FF"
      />
      <path
        d="M23.8996 17.97L23.3237 17.7855L23.527 17.4995L24.1141 17.7216L23.8996 17.97Z"
        fill="#00A0FF"
      />
      <path
        d="M16.44 16.0659L16.1578 16.3331L16.075 17.116L16.3836 16.8149L16.44 16.0659Z"
        fill="#0CF4EB"
      />
      <path
        d="M9.87663 16.5252L9.73361 17.1198L9.57178 16.1451L9.72232 15.5542L9.87663 16.5252Z"
        fill="#13FCE4"
      />
      <path
        d="M21.6979 17.3267L21.0054 17.2515L21.1446 17.5902L21.8522 17.6466L21.6979 17.3267Z"
        fill="#00B8FF"
      />
      <path
        d="M17.464 9.71294L15.9924 9.3968L16.0602 8.98657L17.4753 9.32153L17.464 9.71294Z"
        fill="#FF6000"
      />
      <path
        d="M24.1066 15.8175L23.1694 15.4336L23.2334 14.8804L24.1743 15.3132L24.1066 15.8175Z"
        fill="#43FFB4"
      />
      <path
        d="M10.1288 13.9847L9.65454 14.5718L9.67336 13.6083L10.1438 13.0325L10.1288 13.9847Z"
        fill="#97FF60"
      />
      <path
        d="M13.8548 22.0384L13.915 22.5615L13.3543 22.5314L13.2903 22.0083L13.8548 22.0384Z"
        fill="#000084"
      />
      <path
        d="M15.9921 9.39691L14.6184 9.2125L14.7501 8.78345L16.0599 8.99044L15.9921 9.39691Z"
        fill="#FF4E00"
      />
      <path
        d="M20.5085 11.1053L19.0031 10.6085L18.9692 10.1418L20.437 10.665L20.5085 11.1053Z"
        fill="#FFAB00"
      />
      <path
        d="M22.7441 18.1657L22.2285 18.0791L22.4393 18.1807L22.9662 18.2748L22.7441 18.1657Z"
        fill="#008CFF"
      />
      <path
        d="M19.9586 15.7458L19.1118 15.6743L19.1419 16.2652L20.0038 16.2953L19.9586 15.7458Z"
        fill="#2CFFCA"
      />
      <path
        d="M24.1101 17.722L23.5229 17.4999L23.7074 17.1499L24.3058 17.4096L24.1101 17.722Z"
        fill="#00B8FF"
      />
      <path
        d="M11.9804 10.5634L11.0771 10.857L11.2879 10.1268L12.1498 9.85962L11.9804 10.5634Z"
        fill="#FF9400"
      />
      <path
        d="M13.2335 9.7355L12.1533 9.85969L12.3716 9.26129L13.3991 9.15967L13.2335 9.7355Z"
        fill="#FF6400"
      />
      <path
        d="M20.8055 16.3817L20.0039 16.2952L20.0754 16.792L20.8921 16.8447L20.8055 16.3817Z"
        fill="#0CF4EB"
      />
      <path
        d="M18.2768 14.9858L17.5316 15.0009L17.5278 15.731L18.2843 15.6708L18.2768 14.9858Z"
        fill="#50FFA7"
      />
      <path
        d="M17.5314 15.001L16.9179 15.0913L16.9028 15.8628L17.5276 15.7311L17.5314 15.001Z"
        fill="#4DFFAA"
      />
      <path
        d="M23.2633 13.7663L22.0439 13.2658L22.0176 12.6899L23.2295 13.2319L23.2633 13.7663Z"
        fill="#B4FF43"
      />
      <path
        d="M15.759 18.6028L15.8606 19.0394L15.5595 19.6942L15.4993 19.2351L15.759 18.6028Z"
        fill="#0058FF"
      />
      <path
        d="M18.9693 10.1418L17.4639 9.7127L17.4714 9.32129L18.9241 9.76916L18.9693 10.1418Z"
        fill="#FF6F00"
      />
      <path
        d="M10.2979 18.9373L10.3657 19.4642L10.0458 18.5873L9.97803 18.0566L10.2979 18.9373Z"
        fill="#0068FF"
      />
      <path
        d="M21.9686 12.1553L20.5648 11.6208L20.5083 11.1052L21.8895 11.6735L21.9686 12.1553Z"
        fill="#FFE200"
      />
      <path
        d="M15.5635 19.6978L15.7027 20.1569L15.3038 20.7064L15.2021 20.2284L15.5635 19.6978Z"
        fill="#0014FF"
      />
      <path
        d="M15.9396 17.4661L15.9622 17.8575L15.7627 18.6027L15.7815 18.1849L15.9396 17.4661Z"
        fill="#00A0FF"
      />
      <path
        d="M22.5331 17.9893L22.0288 17.8989L22.2283 18.0796L22.7439 18.1661L22.5331 17.9893Z"
        fill="#009CFF"
      />
      <path
        d="M19.1008 15.0419L18.2766 14.9893L18.2804 15.6742L19.1121 15.6818L19.1008 15.0419Z"
        fill="#53FFA4"
      />
      <path
        d="M24.3059 17.4129L23.7075 17.1532L23.8694 16.7505L24.4753 17.0553L24.3059 17.4129Z"
        fill="#00D4FF"
      />
      <path
        d="M14.6183 9.20871L13.3989 9.16354L13.5946 8.71191L14.7501 8.77966L14.6183 9.20871Z"
        fill="#FF3F00"
      />
      <path
        d="M10.93 11.6776L10.2375 12.1255L10.4107 11.2825L11.0806 10.8572L10.93 11.6776Z"
        fill="#FFD300"
      />
      <path
        d="M16.9145 15.0911L16.4666 15.2529L16.4365 16.0658L16.8957 15.8626L16.9145 15.0911Z"
        fill="#49FFAD"
      />
      <path
        d="M21.57 16.9465L20.8926 16.8486L21.0055 17.2551L21.698 17.3304L21.57 16.9465Z"
        fill="#00DCFE"
      />
      <path
        d="M24.174 15.3129L23.2332 14.8801L23.267 14.3193L24.2117 14.7973L24.174 15.3129Z"
        fill="#66FF90"
      />
      <path
        d="M16.1577 16.3333L16.0524 16.6569L15.9395 17.4699L16.0712 17.1161L16.1577 16.3333Z"
        fill="#09F0EE"
      />
      <path
        d="M14.4002 21.9146L14.4604 22.4377L13.9147 22.5619L13.8545 22.0387L14.4002 21.9146Z"
        fill="#00009B"
      />
      <path
        d="M15.3034 20.7061L15.3862 21.2141L14.9158 21.6357L14.8518 21.1163L15.3034 20.7061Z"
        fill="#0000E8"
      />
      <path
        d="M22.3453 17.7443L21.8523 17.6465L22.0329 17.8986L22.5373 17.989L22.3453 17.7443Z"
        fill="#00B0FF"
      />
      <path
        d="M24.4756 17.0518L23.8696 16.7469L24.0014 16.2991L24.6148 16.6491L24.4756 17.0518Z"
        fill="#0CF4EB"
      />
      <path
        d="M20.7491 15.8703L19.9587 15.7461L20.0039 16.2956L20.8055 16.3821L20.7491 15.8703Z"
        fill="#33FFC4"
      />
      <path
        d="M19.9404 15.1589L19.1011 15.0422L19.1124 15.6783L19.9592 15.7498L19.9404 15.1589Z"
        fill="#56FFA0"
      />
      <path
        d="M17.4713 9.32153L16.0562 8.98657L16.1314 8.69678L17.4788 9.04303L17.4713 9.32153Z"
        fill="#FF3B00"
      />
      <path
        d="M12.2323 22.0275L12.3339 22.4942L11.807 22.035L11.7017 21.5608L12.2323 22.0275Z"
        fill="#0000A8"
      />
      <path
        d="M23.2333 13.2358L22.0214 12.6939L21.9688 12.1594L23.1693 12.739L23.2333 13.2358Z"
        fill="#D7FF1F"
      />
      <path
        d="M20.4367 10.6653L18.9689 10.1421L18.9275 9.76953L20.3501 10.3115L20.4367 10.6653Z"
        fill="#FF8600"
      />
      <path
        d="M11.7018 21.5644L11.8072 22.0386L11.3179 21.4553L11.2087 20.9773L11.7018 21.5644Z"
        fill="#0000CD"
      />
      <path
        d="M9.72205 15.5542L9.57151 16.1451L9.5 15.1628L9.65054 14.5757L9.72205 15.5542Z"
        fill="#53FFA4"
      />
      <path
        d="M16.4663 15.2529L16.1991 15.4825L16.1577 16.3331L16.44 16.0659L16.4663 15.2529Z"
        fill="#46FFB1"
      />
      <path
        d="M16.06 8.98611L14.7502 8.77911L14.897 8.47803L16.1352 8.69632L16.06 8.98611Z"
        fill="#FF2900"
      />
      <path
        d="M9.97789 18.0568L10.0456 18.5874L9.801 17.6541L9.72949 17.1196L9.97789 18.0568Z"
        fill="#00B0FF"
      />
      <path
        d="M18.2802 14.2707L17.535 14.2368L17.5312 15.0046L18.2764 14.9895L18.2802 14.2707Z"
        fill="#80FF77"
      />
      <path
        d="M12.7855 22.3547L12.8796 22.8214L12.3301 22.4977L12.2285 22.031L12.7855 22.3547Z"
        fill="#000092"
      />
      <path
        d="M21.8895 11.6737L20.5083 11.1054L20.4368 10.665L21.7879 11.2597L21.8895 11.6737Z"
        fill="#FFB900"
      />
      <path
        d="M17.5353 14.2368L16.9181 14.2782L16.9143 15.0911L17.5278 15.0008L17.5353 14.2368Z"
        fill="#80FF77"
      />
      <path
        d="M21.4719 16.5138L20.802 16.3821L20.8886 16.845L21.566 16.9429L21.4719 16.5138Z"
        fill="#16FFE1"
      />
      <path
        d="M18.9276 9.76934L17.4749 9.32147L17.4824 9.04297L18.8787 9.50213L18.9276 9.76934Z"
        fill="#FF4E00"
      />
      <path d="M24.2078 14.8012L23.2632 14.3233V13.77L24.2078 14.2932V14.8012Z" fill="#8AFF6D" />
      <path
        d="M10.1438 13.032L9.67334 13.6079L9.77872 12.682L10.2379 12.125L10.1438 13.032Z"
        fill="#D7FF1F"
      />
      <path
        d="M11.205 20.97L11.3142 21.448L10.8701 20.7517L10.7534 20.2625L11.205 20.97Z"
        fill="#0000FF"
      />
      <path
        d="M22.1833 17.4396L21.6978 17.3267L21.8521 17.6466L22.3451 17.7444L22.1833 17.4396Z"
        fill="#00C8FF"
      />
      <path
        d="M19.1082 14.3759L18.2802 14.2705L18.2727 14.9856L19.0969 15.0383L19.1082 14.3759Z"
        fill="#80FF77"
      />
      <path
        d="M24.6147 16.6491L24.0012 16.2991L24.1028 15.8174L24.7201 16.2126L24.6147 16.6491Z"
        fill="#29FFCE"
      />
      <path
        d="M13.399 9.16354L12.3716 9.26516L12.6313 8.79095L13.5947 8.71191L13.399 9.16354Z"
        fill="#FF3000"
      />
      <path
        d="M12.1534 9.85964L11.2915 10.1269L11.5625 9.50586L12.3717 9.26123L12.1534 9.85964Z"
        fill="#FF5900"
      />
      <path
        d="M23.448 18.4408L23.2034 18.3053L23.4405 18.2639L23.6926 18.4182L23.448 18.4408Z"
        fill="#0098FF"
      />
      <path
        d="M23.6884 18.4181L23.4363 18.2638L23.6696 18.1509L23.9255 18.3278L23.6884 18.4181Z"
        fill="#009CFF"
      />
      <path
        d="M13.3539 22.5355L13.4405 22.9984L12.8797 22.8178L12.7856 22.3511L13.3539 22.5355Z"
        fill="#000084"
      />
      <path
        d="M23.2106 18.3954L22.9697 18.2712L23.2031 18.3051L23.4477 18.4406L23.2106 18.3954Z"
        fill="#0098FF"
      />
      <path d="M16.9178 14.2783L16.47 14.395V15.2531L16.9178 15.0913V14.2783Z" fill="#80FF77" />
      <path
        d="M23.9258 18.3279L23.6699 18.151L23.8957 17.9666L24.1517 18.1735L23.9258 18.3279Z"
        fill="#00A4FF"
      />
      <path
        d="M15.9622 17.8574L16.0977 18.2714L15.8643 19.0392L15.7627 18.6026L15.9622 17.8574Z"
        fill="#009CFF"
      />
      <path
        d="M14.916 21.6399L14.9762 22.1668L14.4606 22.4415L14.4004 21.9184L14.916 21.6399Z"
        fill="#0000BB"
      />
      <path
        d="M15.8643 19.0427L16.0374 19.4831L15.7025 20.1567L15.5632 19.6976L15.8643 19.0427Z"
        fill="#0054FF"
      />
      <path
        d="M14.7504 8.77964L13.595 8.71189L13.817 8.39575L14.8972 8.47855L14.7504 8.77964Z"
        fill="#FF1600"
      />
      <path
        d="M20.7265 15.3281L19.9399 15.1587L19.9588 15.7496L20.7491 15.8738L20.7265 15.3281Z"
        fill="#5AFF9D"
      />
      <path
        d="M22.9812 18.2865L22.7441 18.166L22.97 18.2714L23.2108 18.3956L22.9812 18.2865Z"
        fill="#00A0FF"
      />
      <path
        d="M10.757 20.2661L10.8737 20.7554L10.4898 19.9651L10.3694 19.4683L10.757 20.2661Z"
        fill="#0028FF"
      />
      <path
        d="M16.0525 16.6528L16.0976 17.0179L15.9584 17.8572L15.9358 17.4658L16.0525 16.6528Z"
        fill="#06ECF1"
      />
      <path
        d="M23.1654 12.7352L21.9648 12.1556L21.8857 11.6738L23.0675 12.2873L23.1654 12.7352Z"
        fill="#FBF100"
      />
      <path
        d="M19.9516 14.5453L19.1086 14.376L19.1011 15.0421L19.9404 15.1588L19.9516 14.5453Z"
        fill="#80FF77"
      />
      <path
        d="M24.1553 18.1734L23.8994 17.9664L24.1102 17.718L24.3699 17.9551L24.1553 18.1734Z"
        fill="#00B4FF"
      />
      <path
        d="M22.0478 17.0819L21.5698 16.9426L21.6978 17.3227L22.1833 17.4357L22.0478 17.0819Z"
        fill="#02E8F4"
      />
      <path
        d="M20.3505 10.3114L18.9278 9.76941L18.8789 9.5022L20.2526 10.0554L20.3505 10.3114Z"
        fill="#FF6400"
      />
      <path
        d="M11.0806 10.8571L10.4106 11.2824L10.659 10.5297L11.2913 10.127L11.0806 10.8571Z"
        fill="#FF9100"
      />
      <path
        d="M24.7239 16.2127L24.1067 15.8176L24.1782 15.3132L24.7992 15.7536L24.7239 16.2127Z"
        fill="#46FFB1"
      />
      <path
        d="M21.4117 16.0434L20.7493 15.874L20.8057 16.3821L21.4756 16.5138L21.4117 16.0434Z"
        fill="#39FFBE"
      />
      <path
        d="M17.4826 9.04289L16.1353 8.69665L16.2181 8.53857L17.4901 8.88859L17.4826 9.04289Z"
        fill="#FF2200"
      />
      <path
        d="M24.2083 14.2932L23.2636 13.77L23.2297 13.2356L24.1669 13.8039L24.2083 14.2932Z"
        fill="#ADFF49"
      />
      <path
        d="M16.2029 15.4827L16.1126 15.7725L16.0562 16.6569L16.1615 16.3332L16.2029 15.4827Z"
        fill="#43FFB4"
      />
      <path
        d="M22.7708 18.1094L22.5337 17.989L22.7445 18.1659L22.9816 18.2863L22.7708 18.1094Z"
        fill="#00B0FF"
      />
      <path
        d="M21.788 11.2597L20.4369 10.6651L20.3503 10.3113L21.6638 10.9247L21.788 11.2597Z"
        fill="#FF9800"
      />
      <path
        d="M15.7022 20.1567L15.8 20.6573L15.386 21.2181L15.3032 20.71L15.7022 20.1567Z"
        fill="#0014FF"
      />
      <path
        d="M18.2992 13.5404L17.5389 13.4539L17.5352 14.2367L18.2803 14.2706L18.2992 13.5404Z"
        fill="#B4FF43"
      />
      <path
        d="M24.3735 17.959L24.1138 17.7219L24.3057 17.4133L24.5692 17.6881L24.3735 17.959Z"
        fill="#00C8FF"
      />
      <path
        d="M13.9148 22.5621L13.9976 23.025L13.4406 22.9949L13.354 22.532L13.9148 22.5621Z"
        fill="#00008D"
      />
      <path
        d="M18.8787 9.5059L17.4824 9.04298L17.4899 8.88867L18.8185 9.35159L18.8787 9.5059Z"
        fill="#FF3800"
      />
      <path
        d="M9.73326 17.1198L9.80477 17.6542L9.6467 16.6832L9.5752 16.145L9.73326 17.1198Z"
        fill="#13FCE4"
      />
      <path
        d="M19.1346 13.6989L18.2953 13.5371L18.2803 14.2672L19.1083 14.3726L19.1346 13.6989Z"
        fill="#ADFF49"
      />
      <path
        d="M17.5351 13.4503L16.9028 13.439L16.9141 14.2745L17.5314 14.2331L17.5351 13.4503Z"
        fill="#B7FF40"
      />
      <path
        d="M16.1349 8.69663L14.8967 8.47834L15.0586 8.31274L16.2177 8.53856L16.1349 8.69663Z"
        fill="#F60B00"
      />
      <path d="M16.4701 14.395L16.2029 14.587V15.4865L16.4701 15.2569V14.395Z" fill="#80FF77" />
      <path
        d="M9.65445 14.5756L9.50391 15.1628L9.52272 14.1918L9.67327 13.6084L9.65445 14.5756Z"
        fill="#97FF60"
      />
      <path
        d="M22.5781 17.8761L22.3447 17.7444L22.5329 17.989L22.77 18.1095L22.5781 17.8761Z"
        fill="#00C0FF"
      />
      <path
        d="M10.3692 19.4681L10.4897 19.9649L10.1735 19.0955L10.0493 18.5874L10.3692 19.4681Z"
        fill="#0068FF"
      />
      <path
        d="M21.9461 16.6833L21.4719 16.5139L21.566 16.9467L22.044 17.086L21.9461 16.6833Z"
        fill="#1FFFD7"
      />
      <path
        d="M24.5694 17.6883L24.3059 17.4135L24.4753 17.0522L24.7425 17.3646L24.5694 17.6883Z"
        fill="#00E0FB"
      />
      <path
        d="M20.7378 14.7675L19.9512 14.5454L19.9399 15.1589L20.7265 15.3282L20.7378 14.7675Z"
        fill="#80FF77"
      />
      <path
        d="M24.7948 15.7572L24.1738 15.3131L24.2077 14.8013L24.8287 15.2868L24.7948 15.7572Z"
        fill="#66FF90"
      />
      <path
        d="M23.0712 12.2872L21.8895 11.6738L21.7878 11.2598L22.947 11.8996L23.0712 12.2872Z"
        fill="#FFCC00"
      />
      <path
        d="M19.9887 13.9244L19.1382 13.7024L19.1118 14.3798L19.9549 14.5492L19.9887 13.9244Z"
        fill="#AAFF4D"
      />
      <path
        d="M16.9066 13.4429L16.4399 13.5144L16.4663 14.3951L16.9142 14.2784L16.9066 13.4429Z"
        fill="#BAFF3C"
      />
      <path
        d="M21.3852 15.5426L20.7266 15.3281L20.7491 15.8701L21.4115 16.0394L21.3852 15.5426Z"
        fill="#5AFF9D"
      />
      <path
        d="M20.2488 10.0552L18.8751 9.50196L18.8186 9.34766L20.1359 9.9009L20.2488 10.0552Z"
        fill="#FF4E00"
      />
      <path
        d="M10.2375 12.1253L9.77832 12.6823L9.97026 11.8204L10.4106 11.2822L10.2375 12.1253Z"
        fill="#FFD000"
      />
      <path
        d="M24.1705 13.8002L23.2334 13.2319L23.1694 12.7351L24.099 13.3373L24.1705 13.8002Z"
        fill="#D1FF26"
      />
      <path
        d="M13.5948 8.71189L12.6313 8.79093L12.9287 8.45597L13.8169 8.39575L13.5948 8.71189Z"
        fill="#F10800"
      />
      <path
        d="M22.409 17.5865L22.1794 17.4397L22.3413 17.7445L22.5746 17.8763L22.409 17.5865Z"
        fill="#00D8FF"
      />
      <path
        d="M15.386 21.2144L15.4462 21.745L14.9757 22.1665L14.9155 21.6396L15.386 21.2144Z"
        fill="#0000ED"
      />
      <path
        d="M21.6639 10.9248L20.3504 10.3113L20.2488 10.0554L21.5246 10.6764L21.6639 10.9248Z"
        fill="#FF7A00"
      />
      <path
        d="M14.8968 8.47858L13.8167 8.39578L14.0613 8.22266L15.0586 8.31298L14.8968 8.47858Z"
        fill="#DF0000"
      />
      <path
        d="M18.3259 12.8144L17.5393 12.6714V13.4504L18.2996 13.537L18.3259 12.8144Z"
        fill="#E4FF13"
      />
      <path
        d="M17.4899 8.88871L16.2178 8.53869L16.3043 8.51611L17.5012 8.86236L17.4899 8.88871Z"
        fill="#FA0F00"
      />
      <path
        d="M12.3714 9.26488L11.5623 9.50951L11.8859 9.01649L12.6349 8.79443L12.3714 9.26488Z"
        fill="#FF2500"
      />
      <path
        d="M24.7389 17.3645L24.4717 17.0521L24.6109 16.6494L24.8781 17.0032L24.7389 17.3645Z"
        fill="#16FFE1"
      />
      <path
        d="M14.4607 22.4414L14.5397 22.9081L14.0015 23.0285L13.9187 22.5656L14.4607 22.4414Z"
        fill="#00009F"
      />
      <path
        d="M18.8224 9.35206L17.4939 8.88914L17.5014 8.86279L18.7622 9.31819L18.8224 9.35206Z"
        fill="#FF2500"
      />
      <path
        d="M19.1835 13.0327L18.3254 12.8145L18.2991 13.5408L19.1384 13.7027L19.1835 13.0327Z"
        fill="#DBFF1C"
      />
      <path
        d="M17.539 12.6713L16.8804 12.6111L16.9067 13.4428L17.539 13.4541V12.6713Z"
        fill="#EBFF0C"
      />
      <path
        d="M16.1012 17.0217L16.2592 17.4169L16.0974 18.2788L15.9619 17.8648L16.1012 17.0217Z"
        fill="#02E8F4"
      />
      <path
        d="M21.8821 16.2467L21.4116 16.0435L21.4718 16.5139L21.946 16.6833L21.8821 16.2467Z"
        fill="#3CFFBA"
      />
      <path
        d="M16.0976 18.2749L16.2971 18.6964L16.0374 19.483L15.8643 19.0427L16.0976 18.2749Z"
        fill="#0098FF"
      />
      <path
        d="M16.1087 15.7725L16.1689 16.1112L16.0974 17.022L16.0522 16.6569L16.1087 15.7725Z"
        fill="#43FFB4"
      />
      <path
        d="M16.2178 8.53844L15.0586 8.31262L15.2317 8.29004L16.3043 8.51585L16.2178 8.53844Z"
        fill="#DF0000"
      />
      <path
        d="M12.334 22.4939L12.4319 22.8853L11.9163 22.4337L11.8071 22.031L12.334 22.4939Z"
        fill="#0000B2"
      />
      <path d="M24.8329 15.2868L24.2119 14.8013V14.2932L24.8329 14.8239V15.2868Z" fill="#8AFF6D" />
      <path
        d="M11.8072 22.0354L11.9164 22.4381L11.4309 21.8622L11.3142 21.4482L11.8072 22.0354Z"
        fill="#0000DA"
      />
      <path
        d="M20.7793 14.1993L19.9851 13.9246L19.9475 14.5456L20.7341 14.7676L20.7793 14.1993Z"
        fill="#A7FF50"
      />
      <path d="M16.2031 14.583L16.1128 14.8389V15.7723L16.2031 15.4825V14.583Z" fill="#80FF77" />
      <path
        d="M22.2737 17.2516L22.0479 17.0823L22.1833 17.4398L22.4129 17.5866L22.2737 17.2516Z"
        fill="#0CF4EB"
      />
      <path
        d="M23.3799 18.6026L23.4476 18.4408L23.6885 18.4182L23.617 18.5951L23.3799 18.6026Z"
        fill="#00A8FF"
      />
      <path
        d="M22.9474 11.8996L21.7883 11.2598L21.6641 10.9248L22.7969 11.5797L22.9474 11.8996Z"
        fill="#FFAB00"
      />
      <path
        d="M10.0459 18.5877L10.1701 19.0957L9.92923 18.1737L9.80127 17.6543L10.0459 18.5877Z"
        fill="#00B0FF"
      />
      <path
        d="M20.0528 13.3113L19.1834 13.0291L19.1345 13.699L19.9851 13.921L20.0528 13.3113Z"
        fill="#D4FF23"
      />
      <path
        d="M23.1431 18.5497L23.207 18.3992L23.4442 18.4443L23.3764 18.6062L23.1431 18.5497Z"
        fill="#00ACFF"
      />
      <path
        d="M11.2915 10.1308L10.6592 10.5335L10.9753 9.88989L11.5624 9.50977L11.2915 10.1308Z"
        fill="#FF5500"
      />
      <path
        d="M9.57151 16.1446L9.64302 16.6828L9.57151 15.7043L9.5 15.1624L9.57151 16.1446Z"
        fill="#53FFA4"
      />
      <path
        d="M12.883 22.8177L12.9695 23.2054L12.4313 22.8892L12.3335 22.4978L12.883 22.8177Z"
        fill="#00009B"
      />
      <path
        d="M20.1397 9.90523L18.8224 9.35199L18.7622 9.31812L20.0192 9.86383L20.1397 9.90523Z"
        fill="#FF3F00"
      />
      <path
        d="M23.6172 18.5953L23.6887 18.4185L23.9258 18.3281L23.8505 18.5238L23.6172 18.5953Z"
        fill="#00ACFF"
      />
      <path
        d="M16.44 13.5142L16.1577 13.6647L16.1991 14.5868L16.4663 14.3948L16.44 13.5142Z"
        fill="#BEFF39"
      />
      <path
        d="M16.0373 19.4792L16.1503 19.9685L15.8002 20.6535L15.7024 20.1529L16.0373 19.4792Z"
        fill="#0050FF"
      />
      <path
        d="M21.3965 15.0271L20.7379 14.7637L20.7266 15.3244L21.3852 15.539L21.3965 15.0271Z"
        fill="#80FF77"
      />
      <path
        d="M24.8817 17.0032L24.6145 16.6495L24.7236 16.2129L24.9909 16.6081L24.8817 17.0032Z"
        fill="#30FFC7"
      />
      <path
        d="M24.0952 13.3408L23.1656 12.7386L23.0715 12.2908L23.9898 12.9231L24.0952 13.3408Z"
        fill="#F1FC06"
      />
      <path
        d="M22.9209 18.4332L22.9849 18.2864L23.2145 18.3993L23.1505 18.5498L22.9209 18.4332Z"
        fill="#00B4FF"
      />
      <path
        d="M11.3139 21.4513L11.4305 21.8653L10.9977 21.1841L10.8735 20.7588L11.3139 21.4513Z"
        fill="#0000FF"
      />
      <path
        d="M21.5245 10.6763L20.2486 10.0553L20.1357 9.90479L21.3664 10.5258L21.5245 10.6763Z"
        fill="#FF6400"
      />
      <path
        d="M23.8542 18.5273L23.9295 18.3316L24.1591 18.1772L24.0838 18.3993L23.8542 18.5273Z"
        fill="#00B8FF"
      />
      <path
        d="M18.3598 12.1142L17.5393 11.9185V12.6749L18.3259 12.818L18.3598 12.1142Z"
        fill="#FFD700"
      />
      <path
        d="M16.8802 12.6111L16.3872 12.6337L16.4437 13.5143L16.9103 13.4428L16.8802 12.6111Z"
        fill="#F1FC06"
      />
      <path
        d="M17.5015 8.86236L16.3047 8.51611L16.395 8.62902L17.509 8.96398L17.5015 8.86236Z"
        fill="#ED0400"
      />
      <path
        d="M18.7623 9.31819L17.5015 8.86279L17.509 8.96441L18.6983 9.40475L18.7623 9.31819Z"
        fill="#FF1E00"
      />
      <path
        d="M19.2475 12.3851L18.3593 12.1104L18.3254 12.8104L19.1835 13.0287L19.2475 12.3851Z"
        fill="#FFE200"
      />
      <path
        d="M22.7102 18.2599L22.7704 18.1094L22.9849 18.2863L22.921 18.433L22.7102 18.2599Z"
        fill="#00C0FF"
      />
      <path
        d="M9.673 13.6082L9.52246 14.1916L9.63537 13.2582L9.78215 12.6824L9.673 13.6082Z"
        fill="#D7FF1F"
      />
      <path
        d="M13.4402 22.9988L13.5154 23.3827L12.966 23.2058L12.8794 22.8181L13.4402 22.9988Z"
        fill="#000092"
      />
      <path
        d="M17.5393 11.9149L16.843 11.802L16.8844 12.6074L17.543 12.6676L17.5393 11.9149Z"
        fill="#FFCC00"
      />
      <path
        d="M24.0798 18.3956L24.1551 18.1735L24.3696 17.959L24.2906 18.2074L24.0798 18.3956Z"
        fill="#00C4FF"
      />
      <path
        d="M21.8519 15.7876L21.3853 15.543L21.4116 16.0435L21.882 16.2468L21.8519 15.7876Z"
        fill="#5DFF9A"
      />
      <path
        d="M22.1721 16.8749L21.9463 16.6792L22.0479 17.0819L22.2737 17.2513L22.1721 16.8749Z"
        fill="#26FFD1"
      />
      <path
        d="M24.8293 14.8201L24.2083 14.2894L24.1707 13.7964L24.7879 14.3647L24.8293 14.8201Z"
        fill="#AAFF4D"
      />
      <path
        d="M15.0589 8.31286L14.0615 8.22253L14.3212 8.19995L15.232 8.29028L15.0589 8.31286Z"
        fill="#C80000"
      />
      <path
        d="M14.9722 22.1667L15.0437 22.6372L14.5356 22.9082L14.4565 22.4415L14.9722 22.1667Z"
        fill="#0000C4"
      />
      <path
        d="M22.5181 18.0345L22.5783 17.8765L22.7702 18.1098L22.71 18.2604L22.5181 18.0345Z"
        fill="#00D0FF"
      />
      <path
        d="M13.8169 8.39578L12.9287 8.456L13.2561 8.27158L14.0615 8.22266L13.8169 8.39578Z"
        fill="#CD0000"
      />
      <path
        d="M20.8586 13.642L20.0532 13.3108L19.9893 13.9243L20.7834 14.199L20.8586 13.642Z"
        fill="#CEFF29"
      />
      <path
        d="M20.019 9.86383L18.762 9.31812L18.698 9.40468L19.8948 9.93158L20.019 9.86383Z"
        fill="#FF3800"
      />
      <path
        d="M20.1395 12.7237L19.2476 12.385L19.1836 13.0286L20.053 13.3108L20.1395 12.7237Z"
        fill="#FBF100"
      />
      <path
        d="M24.9908 16.6078L24.7236 16.2126L24.7951 15.7534L25.0624 16.19L24.9908 16.6078Z"
        fill="#4DFFAA"
      />
      <path
        d="M10.8739 20.7551L10.9981 21.1803L10.6217 20.405L10.49 19.9609L10.8739 20.7551Z"
        fill="#0030FF"
      />
      <path
        d="M16.3046 8.51585L15.2319 8.29004L15.4088 8.41424L16.3949 8.62876L16.3046 8.51585Z"
        fill="#D10000"
      />
      <path
        d="M22.7967 11.5795L21.6639 10.9247L21.5247 10.6763L22.6274 11.3424L22.7967 11.5795Z"
        fill="#FF9100"
      />
      <path
        d="M15.8002 20.6572L15.8604 21.1917L15.4464 21.7487L15.3862 21.218L15.8002 20.6572Z"
        fill="#0014FF"
      />
      <path
        d="M18.4049 11.4553L17.5393 11.2031V11.9144L18.3598 12.1101L18.4049 11.4553Z"
        fill="#FFA700"
      />
      <path
        d="M24.2944 18.2076L24.3735 17.9592L24.5692 17.6882L24.4864 17.9705L24.2944 18.2076Z"
        fill="#00D8FF"
      />
      <path
        d="M21.4458 14.5116L20.7834 14.1992L20.7383 14.7675L21.3969 15.031L21.4458 14.5116Z"
        fill="#A4FF53"
      />
      <path
        d="M21.3704 10.5257L20.1397 9.90468L20.0193 9.86328L21.2048 10.4767L21.3704 10.5257Z"
        fill="#FF5500"
      />
      <path
        d="M23.9898 12.9201L23.0715 12.2878L22.9473 11.9001L23.8505 12.5588L23.9898 12.9201Z"
        fill="#FFDB00"
      />
      <path
        d="M18.6986 9.40421L17.5093 8.96387L17.5168 9.19345L18.6346 9.6112L18.6986 9.40421Z"
        fill="#FF1E00"
      />
      <path
        d="M10.4108 11.2823L9.97046 11.8204L10.2452 11.0489L10.6554 10.5295L10.4108 11.2823Z"
        fill="#FF8D00"
      />
      <path
        d="M19.3309 11.7867L18.405 11.4592L18.3599 12.1141L19.2481 12.3888L19.3309 11.7867Z"
        fill="#FFB600"
      />
      <path
        d="M17.5088 8.96386L16.3948 8.62891L16.4851 8.8773L17.5201 9.18968L17.5088 8.96386Z"
        fill="#F10800"
      />
      <path
        d="M12.6313 8.79077L11.8823 9.01282L12.2474 8.65904L12.9286 8.45581L12.6313 8.79077Z"
        fill="#E40000"
      />
      <path
        d="M22.3525 17.756L22.409 17.5867L22.5746 17.8765L22.5144 18.0345L22.3525 17.756Z"
        fill="#02E8F4"
      />
      <path
        d="M16.839 11.8021L16.3008 11.7795L16.3836 12.6339L16.8766 12.6113L16.839 11.8021Z"
        fill="#FFC100"
      />
      <path
        d="M17.5353 11.2072L16.7864 11.0454L16.8391 11.8057L17.5353 11.9186V11.2072Z"
        fill="#FF9800"
      />
      <path
        d="M13.9973 23.0284L14.0651 23.4123L13.5194 23.3822L13.4441 22.9983L13.9973 23.0284Z"
        fill="#000096"
      />
      <path
        d="M16.3873 12.6333L16.075 12.7424L16.1615 13.6608L16.4438 13.5102L16.3873 12.6333Z"
        fill="#F8F500"
      />
      <path
        d="M22.1044 16.4724L21.8823 16.2466L21.9463 16.6832L22.1721 16.8789L22.1044 16.4724Z"
        fill="#43FFB4"
      />
      <path
        d="M9.80504 17.654L9.93301 18.1734L9.77494 17.2174L9.64697 16.6868L9.80504 17.654Z"
        fill="#13FCE4"
      />
      <path
        d="M19.8948 9.93095L18.698 9.40405L18.634 9.60729L19.7706 10.1078L19.8948 9.93095Z"
        fill="#FF3800"
      />
      <path
        d="M16.1576 13.6609L16.0522 13.8829L16.1087 14.8389L16.199 14.583L16.1576 13.6609Z"
        fill="#C1FF36"
      />
      <path
        d="M21.8632 15.3171L21.3965 15.0273L21.3853 15.543L21.8519 15.7876L21.8632 15.3171Z"
        fill="#80FF77"
      />
      <path
        d="M18.4535 10.8646L17.5352 10.5635L17.5389 11.207L18.4045 11.4592L18.4535 10.8646Z"
        fill="#FF7E00"
      />
      <path
        d="M24.4868 17.9667L24.5696 17.6844L24.739 17.3645L24.6562 17.6806L24.4868 17.9667Z"
        fill="#09F0EE"
      />
      <path d="M16.1089 14.8391L16.1691 15.1515V16.1112L16.1089 15.7725V14.8391Z" fill="#83FF73" />
      <path
        d="M20.2527 12.178L19.3306 11.7866L19.2478 12.3888L20.1398 12.7275L20.2527 12.178Z"
        fill="#FFC400"
      />
      <path
        d="M18.6341 9.6077L17.5164 9.18994L17.5239 9.53996L18.5702 9.92384L18.6341 9.6077Z"
        fill="#FF2900"
      />
      <path
        d="M16.1691 16.1111L16.3422 16.4837L16.2594 17.4133L16.1013 17.0181L16.1691 16.1111Z"
        fill="#40FFB7"
      />
      <path
        d="M19.4247 11.2449L18.4537 10.8647L18.4048 11.4594L19.3306 11.7868L19.4247 11.2449Z"
        fill="#FF9100"
      />
      <path
        d="M24.7877 14.3684L24.1705 13.8001L24.0952 13.3372L24.7049 13.9393L24.7877 14.3684Z"
        fill="#CAFF2C"
      />
      <path
        d="M25.0626 16.1938L24.7954 15.7572L24.8293 15.2905L25.0965 15.7685L25.0626 16.1938Z"
        fill="#6AFF8D"
      />
      <path
        d="M20.9641 13.1113L20.1399 12.7236L20.0496 13.3108L20.855 13.6419L20.9641 13.1113Z"
        fill="#F1FC06"
      />
      <path
        d="M16.2592 17.4133L16.4775 17.8198L16.2969 18.6967L16.0974 18.2752L16.2592 17.4133Z"
        fill="#00E4F8"
      />
      <path
        d="M22.6274 11.3428L21.5247 10.6767L21.3704 10.5261L22.443 11.1923L22.6274 11.3428Z"
        fill="#FF7A00"
      />
      <path
        d="M21.2047 10.4767L20.0192 9.86328L19.895 9.93103L21.0316 10.5257L21.2047 10.4767Z"
        fill="#FF4E00"
      />
      <path
        d="M17.5164 9.19372L16.4814 8.88135L16.568 9.25771L17.5202 9.54374L17.5164 9.19372Z"
        fill="#FF1300"
      />
      <path
        d="M16.395 8.62907L15.4089 8.41455L15.5858 8.68176L16.4853 8.88123L16.395 8.62907Z"
        fill="#D60000"
      />
      <path
        d="M18.5101 10.3489L17.5278 10.0027L17.5316 10.5635L18.4499 10.8645L18.5101 10.3489Z"
        fill="#FF5900"
      />
      <path
        d="M9.5 15.1629L9.57151 15.7049L9.59032 14.7376L9.51882 14.1919L9.5 15.1629Z"
        fill="#97FF60"
      />
      <path
        d="M18.57 9.92344L17.5237 9.53955L17.5312 10.0025L18.5135 10.3487L18.57 9.92344Z"
        fill="#FF3B00"
      />
      <path
        d="M10.4858 19.9647L10.6175 20.4088L10.3089 19.5583L10.1697 19.0991L10.4858 19.9647Z"
        fill="#0070FF"
      />
      <path
        d="M22.2173 17.4396L22.2737 17.2515L22.4092 17.5864L22.3528 17.7558L22.2173 17.4396Z"
        fill="#16FFE1"
      />
      <path
        d="M19.7706 10.1116L18.634 9.61108L18.5701 9.92722L19.6502 10.3939L19.7706 10.1116Z"
        fill="#FF4300"
      />
      <path
        d="M17.535 10.5639L16.7258 10.3569L16.7898 11.0457L17.5388 11.2075L17.535 10.5639Z"
        fill="#FF6C00"
      />
      <path
        d="M19.5338 10.7743L18.5101 10.3452L18.4536 10.8608L19.4246 11.2409L19.5338 10.7743Z"
        fill="#FF6F00"
      />
      <path
        d="M21.5284 14.0069L20.8585 13.6418L20.7832 14.1989L21.4456 14.5112L21.5284 14.0069Z"
        fill="#C7FF30"
      />
      <path
        d="M11.5586 9.50949L10.9714 9.88585L11.3478 9.37024L11.8822 9.0127L11.5586 9.50949Z"
        fill="#FF1E00"
      />
      <path
        d="M15.2318 8.29028L14.321 8.19995L14.5883 8.32791L15.4087 8.41448L15.2318 8.29028Z"
        fill="#BB0000"
      />
      <path
        d="M23.8507 12.5546L22.9474 11.896L22.7969 11.5798L23.6813 12.2535L23.8507 12.5546Z"
        fill="#FFBD00"
      />
      <path
        d="M19.6501 10.3939L18.57 9.92725L18.5098 10.3488L19.5335 10.7778L19.6501 10.3939Z"
        fill="#FF5500"
      />
      <path
        d="M16.3009 18.6968L16.4251 19.1785L16.1542 19.9726L16.0413 19.4834L16.3009 18.6968Z"
        fill="#0098FF"
      />
      <path
        d="M17.5242 9.53969L16.572 9.25366L16.6511 9.75046L17.528 9.99885L17.5242 9.53969Z"
        fill="#FF2900"
      />
      <path
        d="M17.5275 10.003L16.6506 9.75464L16.7259 10.3568L17.5351 10.5638L17.5275 10.003Z"
        fill="#FF4700"
      />
      <path
        d="M20.384 11.689L19.4281 11.2449L19.334 11.7868L20.2561 12.1782L20.384 11.689Z"
        fill="#FFA300"
      />
      <path
        d="M16.7864 11.0455L16.1956 10.9778L16.3047 11.7794L16.8429 11.802L16.7864 11.0455Z"
        fill="#FF8900"
      />
      <path
        d="M24.6558 17.6804L24.7386 17.3642L24.8816 17.0029L24.795 17.3529L24.6558 17.6804Z"
        fill="#1CFFDB"
      />
      <path
        d="M21.0316 10.5256L19.8949 9.93091L19.7708 10.1078L20.8584 10.6761L21.0316 10.5256Z"
        fill="#FF5200"
      />
      <path
        d="M22.0743 16.0506L21.8523 15.7871L21.8786 16.2463L22.1007 16.4721L22.0743 16.0506Z"
        fill="#60FF97"
      />
      <path
        d="M22.7705 18.7267L23.1431 18.5498L23.3764 18.6063L22.9963 18.7907L22.7705 18.7267Z"
        fill="#00BCFF"
      />
      <path
        d="M14.0613 8.22253L13.2559 8.27146L13.6021 8.24135L14.321 8.19995L14.0613 8.22253Z"
        fill="#B20000"
      />
      <path
        d="M23 18.7869L23.3801 18.6025L23.6172 18.595L23.2296 18.7907L23 18.7869Z"
        fill="#00B8FF"
      />
      <path
        d="M15.4465 21.7449L15.5143 22.2228L15.0476 22.6368L14.9761 22.1664L15.4465 21.7449Z"
        fill="#0000F1"
      />
      <path
        d="M22.5522 18.6065L22.9211 18.4333L23.1469 18.55L22.7743 18.7269L22.5522 18.6065Z"
        fill="#00C4FF"
      />
      <path
        d="M21.0956 12.6183L20.2526 12.178L20.1396 12.7237L20.9639 13.1113L21.0956 12.6183Z"
        fill="#FFD700"
      />
      <path
        d="M21.9124 14.8503L21.442 14.5154L21.3931 15.031L21.8597 15.3208L21.9124 14.8503Z"
        fill="#A0FF56"
      />
      <path
        d="M22.4394 11.1922L21.3668 10.5261L21.2012 10.4734L22.2362 11.1283L22.4394 11.1922Z"
        fill="#FF6F00"
      />
      <path
        d="M16.4854 8.87745L15.5859 8.67798L15.7591 9.08068L16.572 9.25381L16.4854 8.87745Z"
        fill="#E40000"
      />
      <path
        d="M20.5311 11.267L19.5337 10.7739L19.4246 11.2406L20.3805 11.6847L20.5311 11.267Z"
        fill="#FF8200"
      />
      <path
        d="M9.77861 12.6827L9.63184 13.2585L9.83507 12.3891L9.97432 11.8208L9.77861 12.6827Z"
        fill="#FFD000"
      />
      <path
        d="M14.5359 22.9082L14.5923 23.2959L14.0616 23.4125L13.9939 23.0286L14.5359 22.9082Z"
        fill="#0000A8"
      />
      <path
        d="M16.3048 11.7827L15.9473 11.8505L16.0752 12.7424L16.3876 12.6333L16.3048 11.7827Z"
        fill="#FFB900"
      />
      <path
        d="M22.1157 17.0897L22.1722 16.8789L22.2738 17.2515L22.2173 17.4397L22.1157 17.0897Z"
        fill="#30FFC7"
      />
      <path
        d="M23.2297 18.7946L23.6174 18.5989L23.8545 18.5273L23.4593 18.7419L23.2297 18.7946Z"
        fill="#00BCFF"
      />
      <path
        d="M25.0998 15.7647L24.8326 15.2868L24.8289 14.8201L25.0961 15.3357L25.0998 15.7647Z"
        fill="#87FF70"
      />
      <path
        d="M20.8622 10.6801L19.7745 10.1118L19.6541 10.3941L20.6966 10.9285L20.8622 10.6801Z"
        fill="#FF5900"
      />
      <path
        d="M24.7086 13.9391L24.0989 13.3369L23.9897 12.9192L24.5957 13.5477L24.7086 13.9391Z"
        fill="#E7FF0F"
      />
      <path
        d="M22.3489 18.4331L22.7102 18.26L22.9209 18.4331L22.5521 18.6063L22.3489 18.4331Z"
        fill="#00D0FF"
      />
      <path
        d="M20.6929 10.9285L19.6504 10.394L19.5337 10.7742L20.531 11.2672L20.6929 10.9285Z"
        fill="#FF6C00"
      />
      <path
        d="M16.7223 10.3562L16.0637 10.2471L16.1917 10.9772L16.7826 11.0449L16.7223 10.3562Z"
        fill="#FF5D00"
      />
      <path
        d="M12.4319 22.8893L12.4996 23.1866L11.9991 22.75L11.9163 22.4414L12.4319 22.8893Z"
        fill="#0000C4"
      />
      <path
        d="M21.645 13.5288L20.9638 13.1111L20.8584 13.6417L21.5283 14.0068L21.645 13.5288Z"
        fill="#E7FF0F"
      />
      <path
        d="M23.4592 18.7418L23.8544 18.5272L24.0802 18.3955L23.6775 18.6288L23.4592 18.7418Z"
        fill="#00C4FF"
      />
      <path
        d="M23.6847 12.2536L22.8002 11.5799L22.6309 11.3428L23.4965 12.024L23.6847 12.2536Z"
        fill="#FFA700"
      />
      <path
        d="M16.5683 9.25735L15.7554 9.08423L15.9172 9.61113L16.6473 9.75415L16.5683 9.25735Z"
        fill="#FF1300"
      />
      <path
        d="M11.9125 22.4376L11.9953 22.7463L11.5287 22.1892L11.4346 21.8618L11.9125 22.4376Z"
        fill="#0000E8"
      />
      <path
        d="M24.7954 17.3531L24.882 17.0031L24.9911 16.6079L24.9008 16.9918L24.7954 17.3531Z"
        fill="#36FFC1"
      />
      <path
        d="M22.1646 18.2111L22.5221 18.0342L22.714 18.26L22.3527 18.4331L22.1646 18.2111Z"
        fill="#00E0FB"
      />
      <path
        d="M16.6508 9.7541L15.9207 9.61108L16.0674 10.2509L16.7261 10.36L16.6508 9.7541Z"
        fill="#FF3400"
      />
      <path
        d="M16.0713 12.7429L15.9358 12.9311L16.0487 13.8833L16.1541 13.6612L16.0713 12.7429Z"
        fill="#FBF100"
      />
      <path
        d="M16.1504 19.9724L16.2069 20.5068L15.8568 21.188L15.7966 20.6536L16.1504 19.9724Z"
        fill="#0054FF"
      />
      <path
        d="M12.9288 8.45956L12.2476 8.66279L12.6503 8.46333L13.2524 8.27515L12.9288 8.45956Z"
        fill="#BB0000"
      />
      <path
        d="M15.4088 8.41469L14.5884 8.32812L14.8556 8.60663L15.5857 8.6819L15.4088 8.41469Z"
        fill="#BF0000"
      />
      <path
        d="M21.2538 12.1778L20.3844 11.6848L20.2527 12.1741L21.0957 12.6144L21.2538 12.1778Z"
        fill="#FFB600"
      />
      <path
        d="M22.24 11.1322L21.205 10.4773L21.0356 10.53L22.0368 11.1698L22.24 11.1322Z"
        fill="#FF6800"
      />
      <path
        d="M12.9698 23.2055L13.0225 23.4953L12.4994 23.1867L12.4316 22.8894L12.9698 23.2055Z"
        fill="#0000AD"
      />
      <path
        d="M10.1699 19.0952L10.3092 19.5543L10.0721 18.6511L9.92529 18.1731L10.1699 19.0952Z"
        fill="#00B4FF"
      />
      <path
        d="M9.64329 16.6834L9.77125 17.214L9.69974 16.2468L9.57178 15.7048L9.64329 16.6834Z"
        fill="#53FFA4"
      />
      <path
        d="M22.0856 15.6217L21.8636 15.3169L21.8523 15.7873L22.0743 16.0508L22.0856 15.6217Z"
        fill="#7DFF7A"
      />
      <path
        d="M10.6593 10.5296L10.249 11.0489L10.6028 10.3865L10.9754 9.88599L10.6593 10.5296Z"
        fill="#FF4E00"
      />
      <path
        d="M23.6772 18.629L24.0799 18.3957L24.2945 18.2075L23.8842 18.4634L23.6772 18.629Z"
        fill="#00D4FF"
      />
      <path
        d="M11.4307 21.8621L11.5248 22.1895L11.1033 21.5272L10.9941 21.1809L11.4307 21.8621Z"
        fill="#0004FF"
      />
      <path
        d="M22.0479 16.713L22.1043 16.4722L22.172 16.8749L22.1156 17.0856L22.0479 16.713Z"
        fill="#49FFAD"
      />
      <path
        d="M16.0522 13.8828L16.1012 14.1688L16.1727 15.1511L16.1125 14.8388L16.0522 13.8828Z"
        fill="#C1FF36"
      />
      <path
        d="M22.0027 17.9443L22.3527 17.7561L22.5183 18.0308L22.1608 18.2077L22.0027 17.9443Z"
        fill="#0CF4EB"
      />
      <path
        d="M21.9988 14.391L21.5284 14.0071L21.4456 14.5114L21.916 14.8464L21.9988 14.391Z"
        fill="#C1FF36"
      />
      <path
        d="M21.4306 11.8058L20.5311 11.2676L20.3843 11.6891L21.2537 12.1821L21.4306 11.8058Z"
        fill="#FF9800"
      />
      <path
        d="M16.1957 10.9778L15.7817 11.0079L15.9473 11.8472L16.3049 11.7794L16.1957 10.9778Z"
        fill="#FF7E00"
      />
      <path
        d="M22.0326 11.1659L21.0315 10.5261L20.8584 10.6767L21.8256 11.2901L22.0326 11.1659Z"
        fill="#FF6C00"
      />
      <path
        d="M25.0962 15.3356L24.829 14.82L24.7876 14.3684L25.051 14.9179L25.0962 15.3356Z"
        fill="#A7FF50"
      />
      <path
        d="M24.5919 13.5517L23.9859 12.9232L23.8467 12.5581L24.4413 13.2092L24.5919 13.5517Z"
        fill="#FFEA00"
      />
      <path
        d="M21.7921 13.0891L21.0958 12.6187L20.9641 13.1117L21.6453 13.5294L21.7921 13.0891Z"
        fill="#FFE600"
      />
      <path
        d="M23.4928 12.0236L22.6271 11.3424L22.439 11.1919L23.282 11.8731L23.4928 12.0236Z"
        fill="#FF9400"
      />
      <path d="M16.1689 15.1514L16.3421 15.5051V16.4837L16.1689 16.1111V15.1514Z" fill="#83FF73" />
      <path
        d="M21.6225 11.5085L20.6892 10.929L20.5273 11.2677L21.4268 11.8059L21.6225 11.5085Z"
        fill="#FF8200"
      />
      <path
        d="M21.8256 11.2897L20.8583 10.6763L20.689 10.9247L21.6223 11.5043L21.8256 11.2897Z"
        fill="#FF7300"
      />
      <path
        d="M13.5195 23.3825L13.5571 23.6685L13.0227 23.4954L12.97 23.2056L13.5195 23.3825Z"
        fill="#0000A4"
      />
      <path
        d="M14.3209 8.19995L13.6021 8.24511L13.9596 8.37308L14.5919 8.32791L14.3209 8.19995Z"
        fill="#A40000"
      />
      <path
        d="M24.9004 16.9955L24.9907 16.6116L25.0622 16.1938L24.9719 16.6154L24.9004 16.9955Z"
        fill="#50FFA7"
      />
      <path
        d="M23.8845 18.467L24.2948 18.2111L24.4867 17.9702L24.0727 18.2525L23.8845 18.467Z"
        fill="#00E4F8"
      />
      <path
        d="M15.5856 8.68147L14.8555 8.6062L15.1152 9.02772L15.755 9.08418L15.5856 8.68147Z"
        fill="#CD0000"
      />
      <path
        d="M16.3423 16.4834L16.5719 16.8748L16.4778 17.8195L16.2595 17.413L16.3423 16.4834Z"
        fill="#40FFB7"
      />
      <path
        d="M21.8708 17.6429L22.2171 17.4397L22.3526 17.7558L22.0026 17.944L21.8708 17.6429Z"
        fill="#1FFFD7"
      />
      <path
        d="M9.52246 14.1916L9.59397 14.7373L9.70688 13.8114L9.63537 13.262L9.52246 14.1916Z"
        fill="#D7FF1F"
      />
      <path
        d="M11.8823 9.01296L11.3479 9.3705L11.7769 8.9979L12.2512 8.65918L11.8823 9.01296Z"
        fill="#DA0000"
      />
      <path
        d="M15.0475 22.6367L15.0927 23.0319L14.5921 23.2953L14.5356 22.9077L15.0475 22.6367Z"
        fill="#0000CD"
      />
      <path
        d="M10.9977 21.1806L11.1068 21.5268L10.7418 20.7741L10.6213 20.4053L10.9977 21.1806Z"
        fill="#003CFF"
      />
      <path
        d="M16.0677 10.2474L15.5859 10.2437L15.7816 11.0077L16.1956 10.9776L16.0677 10.2474Z"
        fill="#FF4E00"
      />
      <path
        d="M22.1343 15.1924L21.9122 14.8462L21.8633 15.3166L22.0853 15.6215L22.1343 15.1924Z"
        fill="#9DFF5A"
      />
      <path
        d="M22.0215 16.3218L22.0779 16.0508L22.108 16.4723L22.0516 16.7132L22.0215 16.3218Z"
        fill="#63FF94"
      />
      <path
        d="M15.755 9.08062L15.1152 9.02417L15.3599 9.57742L15.9169 9.61129L15.755 9.08062Z"
        fill="#E80000"
      />
      <path
        d="M21.9651 12.6974L21.2538 12.178L21.0957 12.6183L21.792 13.0888L21.9651 12.6974Z"
        fill="#FFC400"
      />
      <path
        d="M15.9472 11.8508L15.7703 12.0089L15.9396 12.9347L16.0751 12.7466L15.9472 11.8508Z"
        fill="#FFAE00"
      />
      <path
        d="M23.2823 11.873L22.4392 11.1918L22.2397 11.1316L23.0602 11.8053L23.2823 11.873Z"
        fill="#FF8600"
      />
      <path
        d="M22.1232 13.9621L21.6452 13.5293L21.5286 14.0073L21.999 14.3912L22.1232 13.9621Z"
        fill="#DEFF19"
      />
      <path
        d="M16.4778 17.8198L16.6095 18.294L16.4213 19.1785L16.2971 18.6967L16.4778 17.8198Z"
        fill="#00E4F8"
      />
      <path
        d="M15.9169 9.61126L15.3599 9.57739L15.5857 10.2435L16.0674 10.2473L15.9169 9.61126Z"
        fill="#FF2200"
      />
      <path
        d="M15.8605 21.188L15.9245 21.6735L15.5143 22.223L15.4465 21.745L15.8605 21.188Z"
        fill="#0018FF"
      />
      <path
        d="M24.4453 13.2093L23.8507 12.5582L23.6851 12.2571L24.2722 12.927L24.4453 13.2093Z"
        fill="#FFD000"
      />
      <path
        d="M24.0728 18.2526L24.4867 17.9704L24.6561 17.6843L24.2346 17.9967L24.0728 18.2526Z"
        fill="#0FF8E7"
      />
      <path
        d="M25.0512 14.9181L24.7878 14.3686L24.7087 13.9434L24.9722 14.523L25.0512 14.9181Z"
        fill="#C1FF36"
      />
      <path
        d="M21.769 17.3077L22.1153 17.0857L22.2169 17.4357L21.8707 17.6389L21.769 17.3077Z"
        fill="#33FFC4"
      />
      <path
        d="M14.065 23.4122L14.0876 23.6982L13.5569 23.6681L13.5193 23.3821L14.065 23.4122Z"
        fill="#0000A8"
      />
      <path
        d="M24.9717 16.6155L25.062 16.1939L25.0959 15.7649L25.0056 16.2203L24.9717 16.6155Z"
        fill="#6AFF8D"
      />
      <path
        d="M13.256 8.27132L12.6538 8.4595L13.0791 8.42186L13.6022 8.24121L13.256 8.27132Z"
        fill="#9F0000"
      />
      <path
        d="M22.1116 18.9226L22.7702 18.7231L22.9998 18.7871L22.3299 18.9941L22.1116 18.9226Z"
        fill="#00C8FF"
      />
      <path
        d="M22.1644 12.3662L21.4306 11.8054L21.2537 12.178L21.965 12.6974L22.1644 12.3662Z"
        fill="#FFAB00"
      />
      <path
        d="M21.9011 18.8017L22.5522 18.606L22.7705 18.7264L22.1119 18.9259L21.9011 18.8017Z"
        fill="#00D0FF"
      />
      <path
        d="M23.0602 11.8053L22.2397 11.1316L22.0327 11.1655L22.8306 11.8241L23.0602 11.8053Z"
        fill="#FF8200"
      />
      <path
        d="M9.92921 18.1733L10.076 18.6513L9.92168 17.7142L9.7749 17.2136L9.92921 18.1733Z"
        fill="#16FFE1"
      />
      <path
        d="M9.97031 11.8245L9.83105 12.3928L10.1209 11.61L10.245 11.053L9.97031 11.8245Z"
        fill="#FF8D00"
      />
      <path
        d="M22.3301 18.9939L23 18.7869L23.2296 18.7906L22.5521 19.0089L22.3301 18.9939Z"
        fill="#00C8FF"
      />
      <path
        d="M21.7053 18.6253L22.3489 18.4333L22.5521 18.6065L21.901 18.8022L21.7053 18.6253Z"
        fill="#00D8FF"
      />
      <path
        d="M14.5883 8.32812L13.9561 8.37329L14.3136 8.65556L14.8556 8.60663L14.5883 8.32812Z"
        fill="#A80000"
      />
      <path
        d="M15.9358 12.9309L15.9584 13.1906L16.1014 14.1691L16.0525 13.8831L15.9358 12.9309Z"
        fill="#FEED00"
      />
      <path
        d="M22.379 12.1066L21.6263 11.5044L21.4343 11.8017L22.1682 12.3625L22.379 12.1066Z"
        fill="#FF9800"
      />
      <path
        d="M22.8306 11.8242L22.0327 11.1655L21.8257 11.2897L22.601 11.922L22.8306 11.8242Z"
        fill="#FF8200"
      />
      <path
        d="M22.0289 15.9264L22.0853 15.6216L22.074 16.0506L22.0176 16.3216L22.0289 15.9264Z"
        fill="#7DFF7A"
      />
      <path
        d="M22.2207 14.7827L21.9987 14.395L21.9121 14.8504L22.1342 15.1967L22.2207 14.7827Z"
        fill="#BAFF3C"
      />
      <path
        d="M22.2773 13.5704L21.7918 13.0886L21.645 13.529L22.123 13.9618L22.2773 13.5704Z"
        fill="#FBF100"
      />
      <path
        d="M22.5522 19.0129L23.2297 18.7946L23.4593 18.7419L22.7705 18.9715L22.5522 19.0129Z"
        fill="#00C8FF"
      />
      <path
        d="M24.2344 17.9926L24.6559 17.6802L24.7951 17.3528L24.3699 17.6915L24.2344 17.9926Z"
        fill="#26FFD1"
      />
      <path
        d="M21.5286 18.4069L22.1646 18.2112L22.349 18.4332L21.7055 18.6252L21.5286 18.4069Z"
        fill="#02E8F4"
      />
      <path
        d="M10.6214 20.4054L10.7418 20.7742L10.4407 19.95L10.3127 19.5586L10.6214 20.4054Z"
        fill="#0078FF"
      />
      <path
        d="M22.6011 11.9262L21.8258 11.2939L21.6226 11.5085L22.3753 12.1106L22.6011 11.9262Z"
        fill="#FF8900"
      />
      <path
        d="M24.2683 12.9229L23.6812 12.253L23.4893 12.0234L24.0651 12.7009L24.2683 12.9229Z"
        fill="#FFB900"
      />
      <path
        d="M16.4211 19.1787L16.4776 19.7207L16.2066 20.511L16.1501 19.9766L16.4211 19.1787Z"
        fill="#0098FF"
      />
      <path
        d="M9.57178 15.7048L9.69974 16.2467L9.71856 15.2946L9.58683 14.7375L9.57178 15.7048Z"
        fill="#97FF60"
      />
      <path
        d="M15.7814 11.011L15.5481 11.1389L15.7664 12.0083L15.9433 11.8503L15.7814 11.011Z"
        fill="#FF7300"
      />
      <path
        d="M21.7053 16.9578L22.0478 16.7131L22.1156 17.0895L21.7693 17.3115L21.7053 16.9578Z"
        fill="#4DFFAA"
      />
      <path
        d="M24.9721 14.5228L24.7086 13.9432L24.5957 13.5518L24.8554 14.1577L24.9721 14.5228Z"
        fill="#DEFF19"
      />
      <path
        d="M10.9715 9.88587L10.5989 10.3864L11.0204 9.852L11.3478 9.37402L10.9715 9.88587Z"
        fill="#FF1A00"
      />
      <path
        d="M22.7705 18.9715L23.4592 18.742L23.6813 18.6328L22.985 18.8812L22.7705 18.9715Z"
        fill="#00D0FF"
      />
      <path
        d="M21.3743 18.1471L22.0028 17.9438L22.1646 18.2111L21.5286 18.4068L21.3743 18.1471Z"
        fill="#13FCE4"
      />
      <path
        d="M25.0061 16.2201L25.0964 15.7647L25.0926 15.3357L24.9985 15.825L25.0061 16.2201Z"
        fill="#87FF70"
      />
      <path
        d="M16.1013 14.1687L16.2632 14.5037L16.346 15.5048L16.1728 15.151L16.1013 14.1687Z"
        fill="#C4FF33"
      />
      <path
        d="M14.8554 8.6062L14.3135 8.65513L14.6597 9.08794L15.1151 9.02772L14.8554 8.6062Z"
        fill="#B60000"
      />
      <path
        d="M22.4618 13.2242L21.9688 12.6973L21.7957 13.0887L22.2812 13.5704L22.4618 13.2242Z"
        fill="#FFD700"
      />
      <path
        d="M24.0686 12.7013L23.4927 12.0238L23.282 11.8733L23.8428 12.5507L24.0686 12.7013Z"
        fill="#FFA700"
      />
      <path
        d="M15.5105 22.2229L15.5482 22.6256L15.0928 23.0283L15.0476 22.6331L15.5105 22.2229Z"
        fill="#0000FA"
      />
      <path
        d="M14.5922 23.292L14.5997 23.5818L14.0841 23.6947L14.0615 23.4087L14.5922 23.292Z"
        fill="#0000BB"
      />
      <path
        d="M24.3696 17.6916L24.7949 17.3529L24.9003 16.9954L24.4712 17.3642L24.3696 17.6916Z"
        fill="#3CFFBA"
      />
      <path
        d="M22.9812 18.8769L23.6775 18.6285L23.8845 18.4629L23.1769 18.7301L22.9812 18.8769Z"
        fill="#00DCFE"
      />
      <path
        d="M22.3455 14.395L22.1235 13.9622L21.9993 14.3912L22.2213 14.7789L22.3455 14.395Z"
        fill="#D4FF23"
      />
      <path
        d="M15.5857 10.2437L15.2883 10.3453L15.5518 11.1356L15.7851 11.0077L15.5857 10.2437Z"
        fill="#FF3F00"
      />
      <path
        d="M22.0777 15.5354L22.1342 15.1929L22.0853 15.6182L22.0288 15.923L22.0777 15.5354Z"
        fill="#9AFF5D"
      />
      <path
        d="M12.2516 8.66285L11.7773 9.00157L12.2478 8.78328L12.6543 8.46338L12.2516 8.66285Z"
        fill="#B20000"
      />
      <path
        d="M12.4962 23.1867L12.5187 23.3749L12.037 22.9533L11.9956 22.7463L12.4962 23.1867Z"
        fill="#0000D6"
      />
      <path
        d="M21.2463 17.8536L21.8711 17.6428L22.0028 17.9439L21.3743 18.1471L21.2463 17.8536Z"
        fill="#26FFD1"
      />
      <path
        d="M11.9953 22.7462L12.0367 22.9532L11.5851 22.415L11.5249 22.1892L11.9953 22.7462Z"
        fill="#0000FA"
      />
      <path
        d="M21.675 16.589L22.0175 16.3218L22.0476 16.7132L21.7052 16.9578L21.675 16.589Z"
        fill="#63FF94"
      />
      <path d="M16.342 15.5051L16.5716 15.8852V16.8751L16.342 16.4837V15.5051Z" fill="#83FF73" />
      <path
        d="M15.1153 9.02417L14.6599 9.08439L14.9873 9.65269L15.3599 9.57365L15.1153 9.02417Z"
        fill="#D60000"
      />
      <path
        d="M13.6025 8.24512L13.0793 8.42577L13.5197 8.54997L13.96 8.37684L13.6025 8.24512Z"
        fill="#960000"
      />
      <path
        d="M24.8513 14.1577L24.5916 13.5517L24.4448 13.2092L24.7007 13.834L24.8513 14.1577Z"
        fill="#F4F802"
      />
      <path
        d="M15.3599 9.57739L14.9873 9.65643L15.2884 10.3452L15.5857 10.2435L15.3599 9.57739Z"
        fill="#FF1300"
      />
      <path
        d="M13.0227 23.4954L13.0264 23.6723L12.5221 23.3749L12.4995 23.1868L13.0227 23.4954Z"
        fill="#0000BF"
      />
      <path
        d="M22.6649 12.9347L22.1606 12.3701L21.9648 12.7013L22.4579 13.2282L22.6649 12.9347Z"
        fill="#FFC100"
      />
      <path
        d="M9.63184 13.2582L9.70334 13.8076L9.90658 12.942L9.83507 12.385L9.63184 13.2582Z"
        fill="#FFD000"
      />
      <path
        d="M23.8468 12.5506L23.286 11.8732L23.064 11.8054L23.6134 12.4753L23.8468 12.5506Z"
        fill="#FF9C00"
      />
      <path
        d="M25.0023 15.8247L25.0964 15.3355L25.055 14.9177L24.9609 15.4371L25.0023 15.8247Z"
        fill="#A0FF56"
      />
      <path
        d="M15.7703 12.0049L15.7515 12.242L15.9585 13.1904L15.9359 12.9307L15.7703 12.0049Z"
        fill="#FFAB00"
      />
      <path
        d="M11.5285 22.1893L11.5887 22.4152L11.1822 21.7754L11.1069 21.5232L11.5285 22.1893Z"
        fill="#0014FF"
      />
      <path
        d="M23.1807 18.7303L23.8882 18.4631L24.0764 18.2485L23.3613 18.5346L23.1807 18.7303Z"
        fill="#06ECF1"
      />
      <path
        d="M10.3091 19.555L10.4371 19.9464L10.2075 19.0695L10.072 18.6479L10.3091 19.555Z"
        fill="#00BCFF"
      />
      <path
        d="M16.2104 20.5071L16.2706 21.0001L15.9243 21.67L15.8604 21.1845L16.2104 20.5071Z"
        fill="#0054FF"
      />
      <path
        d="M21.1482 17.5334L21.7692 17.3076L21.8708 17.6388L21.246 17.8496L21.1482 17.5334Z"
        fill="#39FFBE"
      />
      <path
        d="M22.8908 12.7089L22.3752 12.1067L22.1606 12.3664L22.665 12.9309L22.8908 12.7089Z"
        fill="#FFAE00"
      />
      <path
        d="M16.5716 16.875L16.7071 17.3454L16.6093 18.2976L16.4775 17.8234L16.5716 16.875Z"
        fill="#40FFB7"
      />
      <path
        d="M23.6133 12.4753L23.0638 11.8054L22.8342 11.8205L23.3724 12.4753H23.6133Z"
        fill="#FF9800"
      />
      <path
        d="M9.77121 17.2138L9.91799 17.7144L9.85025 16.766L9.69971 16.2466L9.77121 17.2138Z"
        fill="#56FFA0"
      />
      <path
        d="M24.4717 17.3644L24.9007 16.9956L24.9722 16.6155L24.5394 17.0144L24.4717 17.3644Z"
        fill="#53FFA4"
      />
      <path
        d="M22.5034 14.0445L22.2776 13.5703L22.1233 13.9655L22.3453 14.3983L22.5034 14.0445Z"
        fill="#EEFF09"
      />
      <path
        d="M23.128 12.5546L22.6011 11.9224L22.3752 12.1068L22.8909 12.709L23.128 12.5546Z"
        fill="#FF9F00"
      />
      <path
        d="M23.3691 12.4757L22.8309 11.8208L22.6013 11.9224L23.1282 12.5547L23.3691 12.4757Z"
        fill="#FF9800"
      />
      <path
        d="M22.1646 15.1589L22.2248 14.7788L22.1382 15.1928L22.0818 15.5353L22.1646 15.1589Z"
        fill="#B4FF43"
      />
      <path
        d="M13.5571 23.6647L13.5421 23.8378L13.0265 23.6722L13.0227 23.4954L13.5571 23.6647Z"
        fill="#0000B6"
      />
      <path
        d="M21.0127 19.0164L21.9009 18.7981L22.1117 18.9223L21.2122 19.1443L21.0127 19.0164Z"
        fill="#00D8FF"
      />
      <path
        d="M24.7009 13.8341L24.445 13.2094L24.2681 12.9233L24.5202 13.5631L24.7009 13.8341Z"
        fill="#FFDE00"
      />
      <path
        d="M21.6826 16.2243L22.0288 15.927L22.0175 16.3222L21.675 16.5894L21.6826 16.2243Z"
        fill="#7DFF7A"
      />
      <path
        d="M21.2122 19.1481L22.1117 18.926L22.3299 18.9975L21.4192 19.2271L21.2122 19.1481Z"
        fill="#00D0FF"
      />
      <path
        d="M20.8245 18.84L21.7051 18.6255L21.9008 18.7986L21.0126 19.0169L20.8245 18.84Z"
        fill="#00E0FB"
      />
      <path
        d="M21.4194 19.2271L22.3302 18.9976L22.5523 19.0126L21.6302 19.2535L21.4194 19.2271Z"
        fill="#00D0FF"
      />
      <path
        d="M10.2487 11.0487L10.1245 11.6057L10.4933 10.9321L10.6025 10.3826L10.2487 11.0487Z"
        fill="#FF4E00"
      />
      <path
        d="M20.655 18.6215L21.5282 18.407L21.7051 18.6253L20.8244 18.8398L20.655 18.6215Z"
        fill="#09F0EE"
      />
      <path
        d="M23.3577 18.5386L24.0727 18.2526L24.2346 17.9929L23.512 18.3015L23.3577 18.5386Z"
        fill="#19FFDE"
      />
      <path
        d="M11.107 21.5269L11.1823 21.779L10.8285 21.0527L10.7419 20.7742L11.107 21.5269Z"
        fill="#0048FF"
      />
      <path
        d="M21.0879 17.1986L21.7051 16.9578L21.7691 17.3115L21.1481 17.5374L21.0879 17.1986Z"
        fill="#50FFA7"
      />
      <path
        d="M24.957 15.4375L25.0511 14.9181L24.972 14.5229L24.8779 15.0649L24.957 15.4375Z"
        fill="#BEFF39"
      />
      <path
        d="M13.956 8.37329L13.5156 8.54642L13.956 8.82868L14.3135 8.65556L13.956 8.37329Z"
        fill="#960000"
      />
      <path
        d="M22.6878 13.7363L22.462 13.2207L22.2776 13.567L22.5034 14.0412L22.6878 13.7363Z"
        fill="#FFE600"
      />
      <path
        d="M15.0926 23.0283L15.0851 23.3256L14.5996 23.5816L14.592 23.2918L15.0926 23.0283Z"
        fill="#0000DA"
      />
      <path
        d="M21.6301 19.2495L22.5522 19.0086L22.7705 18.9709L21.8409 19.2231L21.6301 19.2495Z"
        fill="#00D0FF"
      />
      <path
        d="M15.9585 13.1904L16.0977 13.5103L16.2596 14.5039L16.0977 14.169L15.9585 13.1904Z"
        fill="#FFEA00"
      />
      <path
        d="M20.5083 18.3615L21.3739 18.147L21.5282 18.4067L20.6551 18.6212L20.5083 18.3615Z"
        fill="#16FFE1"
      />
      <path
        d="M15.548 11.1355L15.4802 11.3538L15.7512 12.2458L15.77 12.0086L15.548 11.1355Z"
        fill="#FF6C00"
      />
      <path
        d="M9.59033 14.7371L9.72206 15.2941L9.8312 14.3796L9.70324 13.8113L9.59033 14.7371Z"
        fill="#D7FF1F"
      />
      <path
        d="M24.5391 17.0107L24.9719 16.6117L25.0057 16.2166L24.5692 16.6418L24.5391 17.0107Z"
        fill="#6DFF8A"
      />
      <path
        d="M11.3477 9.3704L11.0203 9.84837L11.4982 9.45696L11.7767 8.9978L11.3477 9.3704Z"
        fill="#D10000"
      />
      <path
        d="M24.5203 13.563L24.2682 12.9232L24.0649 12.7012L24.3133 13.3485L24.5203 13.563Z"
        fill="#FFCC00"
      />
      <path
        d="M16.6093 18.2937L16.6658 18.8394L16.4776 19.7163L16.4211 19.1744L16.6093 18.2937Z"
        fill="#00E4F8"
      />
      <path
        d="M22.285 14.809L22.349 14.395L22.2248 14.7827L22.1646 15.1628L22.285 14.809Z"
        fill="#CEFF29"
      />
      <path
        d="M12.6499 8.46366L12.2434 8.78357L12.7402 8.73088L13.0752 8.42603L12.6499 8.46366Z"
        fill="#960000"
      />
      <path
        d="M21.7279 15.8627L22.0741 15.5391L22.0252 15.9305L21.679 16.2278L21.7279 15.8627Z"
        fill="#97FF60"
      />
      <path
        d="M14.0842 23.6949L14.0465 23.868L13.5347 23.8379L13.5497 23.6648L14.0842 23.6949Z"
        fill="#0000BB"
      />
      <path
        d="M21.8408 19.2232L22.7704 18.971L22.9812 18.877L22.0403 19.1404L21.8408 19.2232Z"
        fill="#00D8FF"
      />
      <path
        d="M15.9246 21.6736L15.9547 22.0876L15.552 22.6258L15.5144 22.2231L15.9246 21.6736Z"
        fill="#0020FF"
      />
      <path
        d="M23.5117 18.2976L24.2343 17.989L24.3698 17.6917L23.6434 18.0228L23.5117 18.2976Z"
        fill="#29FFCE"
      />
      <path
        d="M20.3877 18.072L21.2496 17.8538L21.3775 18.1473L20.5119 18.3618L20.3877 18.072Z"
        fill="#29FFCE"
      />
      <path
        d="M22.8946 13.4843L22.665 12.9348L22.458 13.2246L22.6838 13.7402L22.8946 13.4843Z"
        fill="#FFD000"
      />
      <path
        d="M21.0581 16.8488L21.6753 16.5891L21.7054 16.9542L21.0882 17.195L21.0581 16.8488Z"
        fill="#66FF90"
      />
      <path
        d="M24.8779 15.065L24.972 14.523L24.8553 14.158L24.7612 14.7225L24.8779 15.065Z"
        fill="#D4FF23"
      />
      <path
        d="M24.3171 13.3489L24.0687 12.7016L23.8467 12.551L24.0913 13.1984L24.3171 13.3489Z"
        fill="#FFB900"
      />
      <path
        d="M14.3136 8.65503L13.9561 8.82815L14.3851 9.26473L14.6598 9.08784L14.3136 8.65503Z"
        fill="#A80000"
      />
      <path
        d="M10.0762 18.6512L10.2117 19.0728L10.0611 18.162L9.92188 17.7141L10.0762 18.6512Z"
        fill="#19FFDE"
      />
      <path
        d="M16.2595 14.5042L16.4816 14.873L16.5719 15.8816L16.3423 15.5015L16.2595 14.5042Z"
        fill="#C4FF33"
      />
      <path
        d="M15.2886 10.3452L15.1606 10.5447L15.4843 11.3539L15.5521 11.1356L15.2886 10.3452Z"
        fill="#FF3400"
      />
      <path
        d="M10.7381 20.7743L10.8247 21.0528L10.5349 20.2587L10.437 19.9463L10.7381 20.7743Z"
        fill="#0080FF"
      />
      <path
        d="M23.1243 13.2883L22.8948 12.7087L22.6689 12.9346L22.8985 13.484L23.1243 13.2883Z"
        fill="#FFC100"
      />
      <path
        d="M22.04 19.1405L22.9809 18.877L23.1766 18.7302L22.2245 19.0087L22.04 19.1405Z"
        fill="#00E4F8"
      />
      <path
        d="M24.5731 16.6457L25.0097 16.2204L25.0059 15.8252L24.5693 16.2768L24.5731 16.6457Z"
        fill="#87FF70"
      />
      <path
        d="M20.2939 17.7598L21.152 17.5339L21.2499 17.8538L20.388 18.0721L20.2939 17.7598Z"
        fill="#3CFFBA"
      />
      <path
        d="M22.4353 14.4926L22.4993 14.041L22.345 14.391L22.281 14.805L22.4353 14.4926Z"
        fill="#E4FF13"
      />
      <path
        d="M24.0913 13.1982L23.8466 12.5509L23.6133 12.4756L23.8541 13.1154L24.0913 13.1982Z"
        fill="#FFB200"
      />
      <path
        d="M14.6595 9.08813L14.3848 9.26502L14.7875 9.84085L14.9869 9.65267L14.6595 9.08813Z"
        fill="#C80000"
      />
      <path
        d="M14.9873 9.65649L14.7878 9.84467L15.1567 10.5485L15.2846 10.349L14.9873 9.65649Z"
        fill="#F10800"
      />
      <path
        d="M21.8106 15.5161L22.1606 15.1624L22.074 15.5387L21.7278 15.8624L21.8106 15.5161Z"
        fill="#B1FF46"
      />
      <path
        d="M23.3616 13.164L23.1282 12.5581L22.8911 12.7124L23.1207 13.292L23.3616 13.164Z"
        fill="#FFB200"
      />
      <path
        d="M9.83496 12.3927L9.90647 12.9497L10.1925 12.1706L10.121 11.6099L9.83496 12.3927Z"
        fill="#FF8D00"
      />
      <path
        d="M23.6396 18.023L24.366 17.6918L24.4676 17.3606L23.7337 17.7106L23.6396 18.023Z"
        fill="#40FFB7"
      />
      <path
        d="M23.8543 13.1154L23.6134 12.4756H23.3726L23.6097 13.1041L23.8543 13.1154Z"
        fill="#FFAB00"
      />
      <path
        d="M23.6096 13.1041L23.3725 12.4756L23.1316 12.5546L23.3649 13.1606L23.6096 13.1041Z"
        fill="#FFAE00"
      />
      <path
        d="M21.0658 16.5021L21.6868 16.2236L21.6792 16.5925L21.062 16.8522L21.0658 16.5021Z"
        fill="#7DFF7A"
      />
      <path
        d="M19.9441 19.2611L21.0129 19.0203L21.2124 19.1482L20.136 19.3966L19.9441 19.2611Z"
        fill="#00DCFE"
      />
      <path
        d="M19.7671 19.0769L20.8247 18.8398L21.0128 19.0167L19.944 19.2576L19.7671 19.0769Z"
        fill="#00E4F8"
      />
      <path
        d="M9.70337 16.2465L9.85391 16.7659L9.87273 15.8325L9.72219 15.2905L9.70337 16.2465Z"
        fill="#97FF60"
      />
      <path
        d="M24.7577 14.7222L24.8518 14.1577L24.7013 13.834L24.6072 14.4173L24.7577 14.7222Z"
        fill="#EBFF0C"
      />
      <path
        d="M12.5221 23.3746L12.4995 23.4499L12.0366 23.0472V22.9531L12.5221 23.3746Z"
        fill="#0000ED"
      />
      <path
        d="M16.4813 19.7166L16.5377 20.2209L16.2668 20.9999L16.2065 20.5069L16.4813 19.7166Z"
        fill="#009CFF"
      />
      <path
        d="M16.5719 15.885L16.7074 16.3517L16.7036 17.3453L16.5681 16.8748L16.5719 15.885Z"
        fill="#83FF73"
      />
      <path
        d="M20.1357 19.3966L21.2121 19.1482L21.4191 19.2272L20.3314 19.4869L20.1357 19.3966Z"
        fill="#00D4FF"
      />
      <path
        d="M12.0368 22.9535V23.0476L11.6078 22.5357L11.5852 22.4153L12.0368 22.9535Z"
        fill="#0000FF"
      />
      <path
        d="M15.7515 12.2419L15.8568 12.5468L16.0977 13.5065L15.9585 13.1866L15.7515 12.2419Z"
        fill="#FFA300"
      />
      <path
        d="M19.6052 18.8509L20.6553 18.6213L20.8246 18.8434L19.7671 19.0805L19.6052 18.8509Z"
        fill="#0CF4EB"
      />
      <path
        d="M22.2285 19.0089L23.1807 18.7304L23.3576 18.5347L22.3979 18.8282L22.2285 19.0089Z"
        fill="#0CF4EB"
      />
      <path
        d="M14.5994 23.5815L14.5429 23.7584L14.0461 23.8676L14.0838 23.6945L14.5994 23.5815Z"
        fill="#0000CD"
      />
      <path
        d="M20.2336 17.4321L21.088 17.1987L21.152 17.5375L20.2939 17.7633L20.2336 17.4321Z"
        fill="#50FFA7"
      />
      <path
        d="M13.0264 23.6723L12.9813 23.7325L12.4995 23.4503L12.5221 23.375L13.0264 23.6723Z"
        fill="#0000D6"
      />
      <path
        d="M22.6196 14.2216L22.6874 13.7361L22.5029 14.0409L22.439 14.4926L22.6196 14.2216Z"
        fill="#F8F500"
      />
      <path
        d="M20.3315 19.4833L21.4192 19.2236L21.63 19.25L20.531 19.5172L20.3315 19.4833Z"
        fill="#00D4FF"
      />
      <path
        d="M13.0789 8.42212L12.7439 8.72697L13.2557 8.83988L13.5154 8.54255L13.0789 8.42212Z"
        fill="#890000"
      />
      <path
        d="M19.4661 18.591L20.5086 18.3652L20.6554 18.6249L19.6053 18.8545L19.4661 18.591Z"
        fill="#19FFDE"
      />
      <path
        d="M15.5479 22.626L15.5291 22.9346L15.085 23.326L15.0925 23.0287L15.5479 22.626Z"
        fill="#0000FF"
      />
      <path
        d="M24.5657 16.2765L25.0022 15.8249L24.9571 15.4373L24.5205 15.9115L24.5657 16.2765Z"
        fill="#A0FF56"
      />
      <path
        d="M11.5847 22.415L11.6073 22.5354L11.2196 21.9257L11.1782 21.7751L11.5847 22.415Z"
        fill="#0024FF"
      />
      <path
        d="M10.5991 10.3865L10.49 10.936L10.9303 10.3902L11.0207 9.85205L10.5991 10.3865Z"
        fill="#FF1600"
      />
      <path
        d="M21.9274 15.1966L22.2849 14.8127L22.1645 15.1628L21.8145 15.5165L21.9274 15.1966Z"
        fill="#C7FF30"
      />
      <path
        d="M24.6073 14.4178L24.7014 13.8345L24.5207 13.5635L24.4304 14.1581L24.6073 14.4178Z"
        fill="#FEED00"
      />
      <path
        d="M23.7378 17.7143L24.4717 17.3643L24.5394 17.0105L23.8018 17.3831L23.7378 17.7143Z"
        fill="#56FFA0"
      />
      <path
        d="M11.777 8.99783L11.4985 9.45699L12.0217 9.21988L12.2475 8.77954L11.777 8.99783Z"
        fill="#A80000"
      />
      <path
        d="M20.531 19.5172L21.63 19.25L21.837 19.2236L20.7267 19.4984L20.531 19.5172Z"
        fill="#00D4FF"
      />
      <path
        d="M21.1072 16.16L21.7282 15.8589L21.683 16.2202L21.062 16.4987L21.1072 16.16Z"
        fill="#97FF60"
      />
      <path
        d="M19.3494 18.2981L20.3843 18.0723L20.5048 18.3621L19.4623 18.5879L19.3494 18.2981Z"
        fill="#29FFCE"
      />
      <path
        d="M10.4373 19.9499L10.5352 20.2623L10.3169 19.4192L10.2078 19.073L10.4373 19.9499Z"
        fill="#00C4FF"
      />
      <path
        d="M22.3977 18.8283L23.3574 18.5347L23.5117 18.2976L22.5445 18.6062L22.3977 18.8283Z"
        fill="#1CFFDB"
      />
      <path
        d="M13.5385 23.8375L13.4707 23.8902L12.9814 23.7321L13.0266 23.6719L13.5385 23.8375Z"
        fill="#0000CD"
      />
      <path
        d="M20.2036 17.0933L21.0579 16.8486L21.0881 17.1949L20.2337 17.4282L20.2036 17.0933Z"
        fill="#66FF90"
      />
      <path
        d="M22.8231 13.9997L22.8946 13.4841L22.6876 13.7363L22.6199 14.2218L22.8231 13.9997Z"
        fill="#FFDE00"
      />
      <path
        d="M9.70337 13.8114L9.83133 14.3797L10.0308 13.5254L9.90284 12.9458L9.70337 13.8114Z"
        fill="#FFD300"
      />
      <path
        d="M16.7072 17.3457L16.7637 17.8952L16.6658 18.8398L16.6094 18.2941L16.7072 17.3457Z"
        fill="#40FFB7"
      />
      <path
        d="M20.7305 19.4982L21.8407 19.2234L22.0402 19.1406L20.9186 19.4267L20.7305 19.4982Z"
        fill="#00DCFE"
      />
      <path
        d="M24.4297 14.1573L24.52 13.5627L24.313 13.3481L24.2227 13.9503L24.4297 14.1573Z"
        fill="#FFDB00"
      />
      <path
        d="M24.5208 15.9114L24.9574 15.4372L24.8746 15.0684L24.438 15.5652L24.5208 15.9114Z"
        fill="#B7FF40"
      />
      <path
        d="M16.0974 13.5105L16.3044 13.8756L16.4851 14.8767L16.263 14.5078L16.0974 13.5105Z"
        fill="#FFE600"
      />
      <path
        d="M11.1786 21.7792L11.22 21.9298L10.8851 21.2373L10.8286 21.0566L11.1786 21.7792Z"
        fill="#0054FF"
      />
      <path
        d="M9.92136 17.7145L10.0606 18.1624L9.99287 17.2441L9.84985 16.7661L9.92136 17.7145Z"
        fill="#56FFA0"
      />
      <path
        d="M19.2588 17.9853L20.29 17.7595L20.3841 18.0719L19.3491 18.2977L19.2588 17.9853Z"
        fill="#3CFFBA"
      />
      <path
        d="M16.2669 21.0039L16.2895 21.433L15.9508 22.0916L15.9207 21.6776L16.2669 21.0039Z"
        fill="#005CFF"
      />
      <path
        d="M15.4802 11.3538L15.5404 11.6511L15.8528 12.5506L15.7474 12.2457L15.4802 11.3538Z"
        fill="#FF6400"
      />
      <path
        d="M23.0487 13.8339L23.124 13.292L22.8982 13.4839L22.8267 13.9995L23.0487 13.8339Z"
        fill="#FFD000"
      />
      <path
        d="M22.0741 14.9106L22.4354 14.4966L22.2811 14.8127L21.9236 15.1966L22.0741 14.9106Z"
        fill="#DEFF19"
      />
      <path
        d="M13.5155 8.54639L13.2559 8.84371L13.7677 9.11845L13.9559 8.82866L13.5155 8.54639Z"
        fill="#890000"
      />
      <path
        d="M23.8015 17.3832L24.5392 17.0106L24.5693 16.6455L23.8279 17.0407L23.8015 17.3832Z"
        fill="#6DFF8A"
      />
      <path
        d="M24.2267 13.951L24.317 13.3488L24.0912 13.1982L24.0046 13.8004L24.2267 13.951Z"
        fill="#FFCC00"
      />
      <path
        d="M21.1861 15.8403L21.8146 15.5166L21.7318 15.8629L21.1108 16.1639L21.1861 15.8403Z"
        fill="#ADFF49"
      />
      <path
        d="M22.5444 18.6063L23.5117 18.2977L23.6396 18.0229L22.6649 18.3466L22.5444 18.6063Z"
        fill="#2CFFCA"
      />
      <path
        d="M20.2111 16.7584L21.0655 16.5024L21.0579 16.8525L20.2036 17.0971L20.2111 16.7584Z"
        fill="#7DFF7A"
      />
      <path
        d="M18.8376 19.6225L20.0382 19.3327L19.8538 19.1709L18.6494 19.4569L18.8376 19.6225Z"
        fill="#00DCFE"
      />
      <path
        d="M18.6528 19.4602L19.8571 19.1742L19.6877 18.9709L18.4834 19.2532L18.6528 19.4602Z"
        fill="#02E8F4"
      />
      <path
        d="M23.282 13.7286L23.3611 13.1641L23.1202 13.292L23.0449 13.834L23.282 13.7286Z"
        fill="#FFC400"
      />
      <path
        d="M15.0852 23.3262L15.0099 23.5144L14.5432 23.759L14.5997 23.5821L15.0852 23.3262Z"
        fill="#0000ED"
      />
      <path
        d="M20.9185 19.4268L22.04 19.1408L22.2282 19.009L21.0953 19.3026L20.9185 19.4268Z"
        fill="#02E8F4"
      />
      <path
        d="M19.0369 19.7391L20.2337 19.4493L20.038 19.3364L18.8374 19.6262L19.0369 19.7391Z"
        fill="#00D4FF"
      />
      <path
        d="M14.0502 23.8677L13.9599 23.9203L13.4744 23.894L13.5421 23.8413L14.0502 23.8677Z"
        fill="#0000D1"
      />
      <path
        d="M24.0047 13.8L24.0912 13.1978L23.8541 13.115L23.7676 13.7096L24.0047 13.8Z"
        fill="#FFC400"
      />
      <path
        d="M19.2026 17.654L20.2339 17.4282L20.2941 17.7557L19.2629 17.9815L19.2026 17.654Z"
        fill="#53FFA4"
      />
      <path
        d="M18.4797 19.2535L19.6841 18.9712L19.5335 18.7266L18.3254 19.0051L18.4797 19.2535Z"
        fill="#0FF8E7"
      />
      <path
        d="M23.5268 13.6876L23.6096 13.1042L23.3649 13.1645L23.2859 13.729L23.5268 13.6876Z"
        fill="#FFC100"
      />
      <path
        d="M24.4414 15.5614L24.878 15.0647L24.7613 14.7222L24.3247 15.2378L24.4414 15.5614Z"
        fill="#CEFF29"
      />
      <path
        d="M23.7677 13.7102L23.8543 13.1155L23.6097 13.1042L23.5269 13.6876L23.7677 13.7102Z"
        fill="#FFBD00"
      />
      <path
        d="M19.2366 19.7996L20.4334 19.506L20.2339 19.4458L19.0371 19.7356L19.2366 19.7996Z"
        fill="#00D4FF"
      />
      <path
        d="M22.251 14.6658L22.6198 14.2217L22.4392 14.4927L22.0779 14.9067L22.251 14.6658Z"
        fill="#F1FC06"
      />
      <path
        d="M15.1565 10.5449L15.164 10.8347L15.5404 11.6514L15.4802 11.3541L15.1565 10.5449Z"
        fill="#FF2D00"
      />
      <path
        d="M18.3256 19.0053L19.5337 18.7267L19.4057 18.4482L18.1938 18.723L18.3256 19.0053Z"
        fill="#1CFFDB"
      />
      <path
        d="M10.1208 11.6102L10.1924 12.171L10.5612 11.5011L10.4897 10.9365L10.1208 11.6102Z"
        fill="#FF4E00"
      />
      <path
        d="M16.4814 14.8765L16.6132 15.3394L16.7073 16.3518L16.5718 15.8851L16.4814 14.8765Z"
        fill="#C4FF33"
      />
      <path
        d="M13.956 8.82861L13.7678 9.11841L14.2646 9.55122L14.3813 9.26519L13.956 8.82861Z"
        fill="#9B0000"
      />
      <path
        d="M9.72217 15.2947L9.87271 15.8366L9.98186 14.9409L9.83131 14.3801L9.72217 15.2947Z"
        fill="#D4FF23"
      />
      <path
        d="M10.8285 21.0527L10.885 21.2333L10.6102 20.4768L10.5425 20.2585L10.8285 21.0527Z"
        fill="#008CFF"
      />
      <path
        d="M23.8317 17.0409L24.5732 16.6457L24.5656 16.2769L23.8242 16.6908L23.8317 17.0409Z"
        fill="#87FF70"
      />
      <path
        d="M19.4396 19.8069L20.6326 19.5134L20.4331 19.5059L19.2363 19.7994L19.4396 19.8069Z"
        fill="#00D4FF"
      />
      <path
        d="M12.2473 8.7834L12.0215 9.22374L12.5747 9.15223L12.7441 8.73071L12.2473 8.7834Z"
        fill="#8D0000"
      />
      <path
        d="M21.295 15.5425L21.9273 15.1963L21.8106 15.5162L21.1821 15.8399L21.295 15.5425Z"
        fill="#C4FF33"
      />
      <path
        d="M22.665 18.3432L23.6398 18.0196L23.7377 17.7109L22.7554 18.0459L22.665 18.3432Z"
        fill="#43FFB4"
      />
      <path
        d="M21.0957 19.3062L22.2285 19.0127L22.3979 18.832L21.2575 19.1369L21.0957 19.3062Z"
        fill="#0FF8E7"
      />
      <path
        d="M15.9547 22.0876L15.9246 22.4113L15.5332 22.9307L15.552 22.6221L15.9547 22.0876Z"
        fill="#0028FF"
      />
      <path
        d="M16.6693 18.8398L16.7257 19.3555L16.5413 20.2211L16.4849 19.7168L16.6693 18.8398Z"
        fill="#02E8F4"
      />
      <path
        d="M20.2491 16.4309L21.1072 16.1599L21.0658 16.4986L20.2114 16.7546L20.2491 16.4309Z"
        fill="#94FF63"
      />
      <path
        d="M10.2115 19.073L10.3206 19.4193L10.1776 18.5461L10.0647 18.166L10.2115 19.073Z"
        fill="#1CFFDB"
      />
      <path
        d="M12.4999 23.45L12.451 23.4123L12.0144 23.0322L12.0407 23.0473L12.4999 23.45Z"
        fill="#0000FF"
      />
      <path
        d="M19.1724 17.323L20.2036 17.0972L20.2337 17.4359L19.2025 17.6617L19.1724 17.323Z"
        fill="#66FF90"
      />
      <path
        d="M12.0368 23.0475L12.0105 23.0324L11.604 22.5469V22.5356L12.0368 23.0475Z"
        fill="#0010FF"
      />
      <path
        d="M18.1938 18.7231L19.4056 18.4484L19.304 18.1436L18.0884 18.4145L18.1938 18.7231Z"
        fill="#30FFC7"
      />
      <path
        d="M24.3245 15.2376L24.7573 14.7257L24.6068 14.4209L24.1777 14.9478L24.3245 15.2376Z"
        fill="#E4FF13"
      />
      <path
        d="M14.788 9.84082L14.7354 10.1269L15.1644 10.8344L15.1569 10.5446L14.788 9.84082Z"
        fill="#E80000"
      />
      <path
        d="M14.385 9.26514L14.2683 9.55117L14.7388 10.127L14.7914 9.84097L14.385 9.26514Z"
        fill="#BB0000"
      />
      <path
        d="M11.0207 9.84844L10.9304 10.3866L11.4272 9.98393L11.4987 9.45703L11.0207 9.84844Z"
        fill="#D10000"
      />
      <path
        d="M22.4504 14.4662L22.8268 13.9995L22.6198 14.2216L22.251 14.6657L22.4504 14.4662Z"
        fill="#FEED00"
      />
      <path
        d="M12.9813 23.7362L12.9061 23.6835L12.4507 23.4163L12.4996 23.4539L12.9813 23.7362Z"
        fill="#0000F1"
      />
      <path
        d="M15.8564 12.551L16.0371 12.9123L16.3043 13.8758L16.0973 13.5107L15.8564 12.551Z"
        fill="#FF9F00"
      />
      <path
        d="M19.6355 19.7618L20.8248 19.4683L20.6291 19.5134L19.436 19.807L19.6355 19.7618Z"
        fill="#00D8FF"
      />
      <path
        d="M11.6075 22.5355V22.5468L11.2425 21.9709L11.2236 21.9258L11.6075 22.5355Z"
        fill="#0038FF"
      />
      <path
        d="M14.5432 23.7588L14.4265 23.819L13.9561 23.9244L14.0464 23.8717L14.5432 23.7588Z"
        fill="#0000E3"
      />
      <path
        d="M23.8242 16.6868L24.5656 16.2728L24.5205 15.9077L23.7791 16.3368L23.8242 16.6868Z"
        fill="#9DFF5A"
      />
      <path
        d="M18.0885 18.4145L19.3041 18.1435L19.2288 17.8198L18.0132 18.087L18.0885 18.4145Z"
        fill="#43FFB4"
      />
      <path
        d="M24.1781 14.9445L24.6072 14.4176L24.4265 14.158L24.0012 14.6924L24.1781 14.9445Z"
        fill="#F4F802"
      />
      <path
        d="M21.2573 19.1329L22.3977 18.828L22.5445 18.606L21.3966 18.9183L21.2573 19.1329Z"
        fill="#1FFFD7"
      />
      <path
        d="M21.4344 15.2758L22.0742 14.907L21.9274 15.193L21.2952 15.5393L21.4344 15.2758Z"
        fill="#D7FF1F"
      />
      <path
        d="M17.6029 19.9424L18.8374 19.6225L18.6492 19.4607L17.4072 19.7731L17.6029 19.9424Z"
        fill="#00D8FF"
      />
      <path
        d="M22.7551 18.0531L23.7374 17.7182L23.8014 17.387L22.8153 17.737L22.7551 18.0531Z"
        fill="#56FFA0"
      />
      <path
        d="M17.4072 19.7733L18.6492 19.4609L18.4761 19.2539L17.2266 19.5588L17.4072 19.7733Z"
        fill="#00E4F8"
      />
      <path
        d="M22.665 14.3232L23.0488 13.834L22.8268 13.9996L22.4504 14.4663L22.665 14.3232Z"
        fill="#FFDE00"
      />
      <path
        d="M19.1761 16.9879L20.2073 16.7583L20.2036 17.0933L19.1724 17.3191L19.1761 16.9879Z"
        fill="#7DFF7A"
      />
      <path
        d="M20.3199 16.1224L21.1818 15.8401L21.1027 16.1638L20.2446 16.4347L20.3199 16.1224Z"
        fill="#AAFF4D"
      />
      <path
        d="M13.4706 23.8942L13.3653 23.8302L12.9023 23.6797L12.9776 23.7324L13.4706 23.8942Z"
        fill="#0000E8"
      />
      <path
        d="M17.8063 20.0626L19.0332 19.7389L18.8375 19.626L17.603 19.9459L17.8063 20.0626Z"
        fill="#00D0FF"
      />
      <path
        d="M9.85352 16.7658L9.99653 17.2438L10.0153 16.3405L9.87233 15.8362L9.85352 16.7658Z"
        fill="#94FF63"
      />
      <path
        d="M17.2268 19.5586L18.4763 19.2538L18.322 19.0054L17.0649 19.2989L17.2268 19.5586Z"
        fill="#0CF4EB"
      />
      <path
        d="M19.8197 19.6643L21.009 19.3745L20.8245 19.4724L19.6353 19.7659L19.8197 19.6643Z"
        fill="#00E4F8"
      />
      <path
        d="M16.7108 16.3521L16.7672 16.9053L16.7635 17.8951L16.707 17.3456L16.7108 16.3521Z"
        fill="#83FF73"
      />
      <path
        d="M9.90649 12.9458L10.0345 13.5254L10.3205 12.7576L10.1963 12.1667L9.90649 12.9458Z"
        fill="#FF9100"
      />
      <path
        d="M15.5294 22.9346L15.4391 23.1378L15.01 23.5142L15.0853 23.326L15.5294 22.9346Z"
        fill="#0008FF"
      />
      <path
        d="M24.0048 14.6924L24.4301 14.1579L24.2268 13.9509L23.8091 14.4891L24.0048 14.6924Z"
        fill="#FFE600"
      />
      <path
        d="M18.0169 20.1264L19.2363 19.799L19.0331 19.7388L17.8062 20.0624L18.0169 20.1264Z"
        fill="#00CCFF"
      />
      <path
        d="M16.538 20.2246L16.5568 20.6725L16.2933 21.4327L16.2708 21.0037L16.538 20.2246Z"
        fill="#00A0FF"
      />
      <path
        d="M10.5387 20.2587L10.6064 20.477L10.3957 19.6716L10.3167 19.4119L10.5387 20.2587Z"
        fill="#00CCFF"
      />
      <path
        d="M22.8946 14.2368L23.286 13.7288L23.0489 13.8341L22.665 14.3234L22.8946 14.2368Z"
        fill="#FFD300"
      />
      <path
        d="M11.2202 21.9259L11.239 21.9711L10.9229 21.3162L10.8853 21.2334L11.2202 21.9259Z"
        fill="#0064FF"
      />
      <path
        d="M17.0648 19.299L18.3218 19.0054L18.1901 18.7231L16.9255 19.0054L17.0648 19.299Z"
        fill="#1CFFDB"
      />
      <path
        d="M18.0094 18.0872L19.225 17.82L19.1798 17.4888L17.9604 17.7485L18.0094 18.0872Z"
        fill="#5AFF9D"
      />
      <path
        d="M12.7438 8.73071L12.5745 9.15223L13.1428 9.24632L13.2519 8.83986L12.7438 8.73071Z"
        fill="#800000"
      />
      <path
        d="M23.7828 16.3408L24.5242 15.9118L24.4452 15.5618L23.7075 16.0059L23.7828 16.3408Z"
        fill="#B4FF43"
      />
      <path
        d="M23.8094 14.489L24.2271 13.9508L24.0051 13.8003L23.5911 14.3385L23.8094 14.489Z"
        fill="#FFDB00"
      />
      <path
        d="M21.6037 15.0537L22.251 14.666L22.0741 14.9106L21.4343 15.2795L21.6037 15.0537Z"
        fill="#E7FF0F"
      />
      <path
        d="M23.1281 14.2102L23.5271 13.687L23.2862 13.7284L22.8948 14.2365L23.1281 14.2102Z"
        fill="#FFD000"
      />
      <path
        d="M18.2241 20.1382L19.4397 19.8108L19.2365 19.8032L18.0171 20.1307L18.2241 20.1382Z"
        fill="#00D0FF"
      />
      <path
        d="M21.3967 18.9184L22.5446 18.606L22.6651 18.3425L21.5096 18.6624L21.3967 18.9184Z"
        fill="#30FFC7"
      />
      <path
        d="M23.5907 14.3384L24.0047 13.8002L23.7676 13.7136L23.3611 14.2481L23.5907 14.3384Z"
        fill="#FFD000"
      />
      <path
        d="M23.3615 14.244L23.768 13.7096L23.5271 13.687L23.1282 14.2102L23.3615 14.244Z"
        fill="#FFCC00"
      />
      <path
        d="M22.8154 17.7331L23.8015 17.3831L23.8316 17.0369L22.8418 17.3982L22.8154 17.7331Z"
        fill="#6DFF8A"
      />
      <path
        d="M19.214 16.668L20.249 16.4309L20.2114 16.7583L19.1802 16.9879L19.214 16.668Z"
        fill="#94FF63"
      />
      <path
        d="M20.4256 15.8403L21.295 15.543L21.1858 15.8403L20.324 16.1226L20.4256 15.8403Z"
        fill="#C1FF36"
      />
      <path
        d="M19.9892 19.5134L21.1785 19.2236L21.0091 19.3704L19.8198 19.6602L19.9892 19.5134Z"
        fill="#0CF4EB"
      />
      <path
        d="M16.929 19.0049L18.1935 18.7227L18.0881 18.4141L16.8198 18.685L16.929 19.0049Z"
        fill="#2CFFCA"
      />
      <path
        d="M15.5442 11.6475L15.691 12.0088L16.0372 12.9083L15.8566 12.547L15.5442 11.6475Z"
        fill="#FF6000"
      />
      <path
        d="M16.3008 13.8755L16.425 14.3384L16.6132 15.3395L16.4814 14.8766L16.3008 13.8755Z"
        fill="#FFE600"
      />
      <path
        d="M13.9564 23.9204L13.8246 23.8564L13.3655 23.8301L13.4709 23.8941L13.9564 23.9204Z"
        fill="#0000ED"
      />
      <path
        d="M10.0609 18.1622L10.1738 18.5423L10.1098 17.6579L9.99316 17.2439L10.0609 18.1622Z"
        fill="#56FFA0"
      />
      <path
        d="M17.9642 17.7485L19.1836 17.4888L19.1723 17.1538L17.9529 17.4097L17.9642 17.7485Z"
        fill="#6DFF8A"
      />
      <path
        d="M18.4271 20.0894L19.6352 19.762L19.4395 19.8071L18.2239 20.1346L18.4271 20.0894Z"
        fill="#00D4FF"
      />
      <path
        d="M10.49 10.936L10.5615 11.5005L10.9981 10.9586L10.9266 10.394L10.49 10.936Z"
        fill="#FF1A00"
      />
      <path
        d="M23.704 16.0058L24.4416 15.5617L24.325 15.238L23.5911 15.6934L23.704 16.0058Z"
        fill="#CAFF2C"
      />
      <path
        d="M12.4467 23.4125L12.3978 23.2657L11.9875 22.9082L12.0101 23.0286L12.4467 23.4125Z"
        fill="#000CFF"
      />
      <path
        d="M15.0132 23.5142L14.8777 23.5857L14.4336 23.819L14.5503 23.7588L15.0132 23.5142Z"
        fill="#0000FF"
      />
      <path
        d="M16.4063 20.2962L17.6031 19.9462L17.4074 19.7769L16.1956 20.1193L16.4063 20.2962Z"
        fill="#00D0FF"
      />
      <path
        d="M16.1954 20.1155L17.4072 19.773L17.2266 19.5547L16.0034 19.8821L16.1954 20.1155Z"
        fill="#00DCFE"
      />
      <path
        d="M12.0104 23.0324L11.9878 22.9119L11.6114 22.4565L11.6077 22.5469L12.0104 23.0324Z"
        fill="#0028FF"
      />
      <path
        d="M21.7917 14.8763L22.4503 14.4661L22.2509 14.6618L21.6035 15.0494L21.7917 14.8763Z"
        fill="#F8F500"
      />
      <path
        d="M11.4983 9.45708L11.4268 9.98398L11.9725 9.73934L12.0214 9.21997L11.4983 9.45708Z"
        fill="#A40000"
      />
      <path
        d="M16.6208 20.4201L17.8063 20.0626L17.6031 19.9421L16.4062 20.2922L16.6208 20.4201Z"
        fill="#00C8FF"
      />
      <path
        d="M16.2934 21.4326L16.252 21.7789L15.9246 22.4149L15.9547 22.0912L16.2934 21.4326Z"
        fill="#0064FF"
      />
      <path
        d="M9.83105 14.3797L9.9816 14.9405L10.1773 14.105L10.0305 13.5254L9.83105 14.3797Z"
        fill="#FFD700"
      />
      <path
        d="M10.8848 21.2372L10.9225 21.32L10.6628 20.6049L10.6101 20.4807L10.8848 21.2372Z"
        fill="#0098FF"
      />
      <path
        d="M16.8162 18.6856L18.0845 18.4146L18.0093 18.0872L16.7334 18.3468L16.8162 18.6856Z"
        fill="#43FFB4"
      />
      <path
        d="M16.0073 19.8859L17.2304 19.5585L17.0686 19.2988L15.8379 19.6112L16.0073 19.8859Z"
        fill="#06ECF1"
      />
      <path
        d="M12.9023 23.6796L12.8232 23.5178L12.3979 23.2656L12.4469 23.4124L12.9023 23.6796Z"
        fill="#0000FF"
      />
      <path
        d="M21.5095 18.6628L22.6649 18.3429L22.7553 18.0493L21.5923 18.3767L21.5095 18.6628Z"
        fill="#43FFB4"
      />
      <path
        d="M22.8418 17.402L23.8316 17.0407L23.824 16.6907L22.8342 17.0633L22.8418 17.402Z"
        fill="#83FF73"
      />
      <path
        d="M13.2561 8.8396L13.147 9.24607L13.719 9.50952L13.7717 9.1181L13.2561 8.8396Z"
        fill="#840000"
      />
      <path
        d="M20.1397 19.3178L21.3252 19.0317L21.1746 19.2274L19.9854 19.5172L20.1397 19.3178Z"
        fill="#19FFDE"
      />
      <path
        d="M16.7637 17.895L16.8164 18.4257L16.7186 19.3591L16.6621 18.8434L16.7637 17.895Z"
        fill="#40FFB7"
      />
      <path
        d="M16.8429 20.4918L18.0171 20.1267L17.8064 20.0627L16.6208 20.4203L16.8429 20.4918Z"
        fill="#00C4FF"
      />
      <path
        d="M20.5573 15.5918L21.4342 15.2756L21.2949 15.5391L20.4255 15.8364L20.5573 15.5918Z"
        fill="#D4FF23"
      />
      <path
        d="M19.2816 16.3629L20.3204 16.1221L20.2489 16.4307L19.2139 16.6678L19.2816 16.3629Z"
        fill="#AAFF4D"
      />
      <path
        d="M11.6041 22.5467L11.6078 22.4564L11.2654 21.9182L11.239 21.9747L11.6041 22.5467Z"
        fill="#004CFF"
      />
      <path
        d="M18.6192 19.9877L19.8198 19.6641L19.6354 19.7619L18.4272 20.0893L18.6192 19.9877Z"
        fill="#00E0FB"
      />
      <path
        d="M15.8341 19.6113L17.0648 19.2989L16.9256 19.0054L15.6836 19.2989L15.8341 19.6113Z"
        fill="#16FFE1"
      />
      <path
        d="M23.591 15.693L24.3249 15.2377L24.1781 14.9441L23.448 15.407L23.591 15.693Z"
        fill="#DEFF19"
      />
      <path
        d="M15.1682 10.8345L15.2736 11.1995L15.6914 12.0125L15.5446 11.6512L15.1682 10.8345Z"
        fill="#FF2900"
      />
      <path
        d="M21.999 14.7485L22.6651 14.3232L22.4506 14.4663L21.792 14.8765L21.999 14.7485Z"
        fill="#FFEA00"
      />
      <path
        d="M17.9495 17.4093L19.1689 17.1534L19.1877 16.8259L17.9645 17.0781L17.9495 17.4093Z"
        fill="#83FF73"
      />
      <path
        d="M10.3168 19.4154L10.3959 19.6751L10.2604 18.8433L10.1738 18.5422L10.3168 19.4154Z"
        fill="#1FFFD7"
      />
      <path
        d="M13.3653 23.8302L13.26 23.6571L12.8271 23.5178L12.9062 23.6797L13.3653 23.8302Z"
        fill="#0000FF"
      />
      <path
        d="M17.0647 20.4993L18.2277 20.1342L18.0207 20.1267L16.8464 20.4918L17.0647 20.4993Z"
        fill="#00C4FF"
      />
      <path
        d="M16.7373 18.3465L18.0132 18.0868L17.968 17.748L16.6921 17.9927L16.7373 18.3465Z"
        fill="#56FFA0"
      />
      <path
        d="M14.4303 23.819L14.2722 23.7588L13.8281 23.8566L13.9599 23.9206L14.4303 23.819Z"
        fill="#0000FF"
      />
      <path
        d="M15.6872 19.2985L16.9292 19.005L16.8201 18.6851L15.5706 18.9598L15.6872 19.2985Z"
        fill="#29FFCE"
      />
      <path
        d="M23.4513 15.4075L24.1815 14.9445L24.0084 14.6924L23.282 15.1628L23.4513 15.4075Z"
        fill="#EEFF09"
      />
      <path
        d="M13.7678 9.11816L13.7151 9.50958L14.2683 9.9311L14.2646 9.55098L13.7678 9.11816Z"
        fill="#920000"
      />
      <path
        d="M15.9209 22.4153L15.8156 22.6373L15.4392 23.1379L15.5295 22.9347L15.9209 22.4153Z"
        fill="#0038FF"
      />
      <path
        d="M22.2176 14.6811L22.895 14.2407L22.6654 14.3273L21.9993 14.7488L22.2176 14.6811Z"
        fill="#FFDE00"
      />
      <path
        d="M22.8346 17.0628L23.8244 16.6902L23.783 16.344L22.7932 16.7279L22.8346 17.0628Z"
        fill="#9AFF5D"
      />
      <path
        d="M11.2387 21.9712L11.2651 21.9147L10.9678 21.3013L10.9226 21.3163L11.2387 21.9712Z"
        fill="#0074FF"
      />
      <path
        d="M21.5962 18.3767L22.7591 18.0493L22.8194 17.7332L21.6526 18.0643L21.5962 18.3767Z"
        fill="#5AFF9D"
      />
      <path
        d="M15.3109 20.6763L16.4061 20.2961L16.1991 20.1155L15.0889 20.4843L15.3109 20.6763Z"
        fill="#00C4FF"
      />
      <path
        d="M15.0849 20.4842L16.1952 20.1153L16.0032 19.8857L14.8779 20.2358L15.0849 20.4842Z"
        fill="#00D0FF"
      />
      <path
        d="M9.87256 15.8361L10.0156 16.3404L10.121 15.4748L9.9817 14.9441L9.87256 15.8361Z"
        fill="#D1FF26"
      />
      <path
        d="M20.7154 15.3809L21.6036 15.0535L21.4343 15.2755L20.5574 15.5917L20.7154 15.3809Z"
        fill="#E4FF13"
      />
      <path
        d="M14.7354 10.127L14.7918 10.4958L15.2698 11.1958L15.1644 10.8307L14.7354 10.127Z"
        fill="#DF0000"
      />
      <path
        d="M20.2676 19.0804L21.4531 18.7981L21.3252 19.0352L20.1396 19.3212L20.2676 19.0804Z"
        fill="#29FFCE"
      />
      <path
        d="M16.6169 15.3396L16.6772 15.8966L16.7712 16.9052L16.7148 16.352L16.6169 15.3396Z"
        fill="#C4FF33"
      />
      <path
        d="M15.5444 20.8155L16.6245 20.4241L16.4062 20.2961L15.311 20.6763L15.5444 20.8155Z"
        fill="#00BCFF"
      />
      <path
        d="M18.7962 19.8296L19.993 19.5134L19.8236 19.664L18.623 19.9876L18.7962 19.8296Z"
        fill="#09F0EE"
      />
      <path
        d="M19.3793 16.0885L20.4256 15.8401L20.3202 16.1224L19.2815 16.3632L19.3793 16.0885Z"
        fill="#BEFF39"
      />
      <path
        d="M14.2646 9.55103L14.2684 9.93115L14.7915 10.4994L14.7351 10.1306L14.2646 9.55103Z"
        fill="#B20000"
      />
      <path
        d="M17.2791 20.4504L18.427 20.0891L18.2237 20.138L17.0608 20.5031L17.2791 20.4504Z"
        fill="#00CCFF"
      />
      <path
        d="M14.8784 20.2358L16.0037 19.8858L15.8305 19.6111L14.6902 19.9385L14.8784 20.2358Z"
        fill="#00E0FB"
      />
      <path
        d="M23.282 15.1627L24.0046 14.6922L23.8052 14.489L23.0901 14.9595L23.282 15.1627Z"
        fill="#FBF100"
      />
      <path
        d="M10.1926 12.1665L10.3168 12.7574L10.6819 12.095L10.5615 11.4966L10.1926 12.1665Z"
        fill="#FF5200"
      />
      <path
        d="M22.4428 14.6661L23.1315 14.2107L22.8981 14.237L22.2207 14.6774L22.4428 14.6661Z"
        fill="#FFDB00"
      />
      <path
        d="M16.0337 12.9121L16.1504 13.3788L16.4289 14.3423L16.3047 13.8793L16.0337 12.9121Z"
        fill="#FF9F00"
      />
      <path
        d="M10.6065 20.4805L10.6592 20.6047L10.4597 19.8444L10.3958 19.675L10.6065 20.4805Z"
        fill="#00D4FF"
      />
      <path
        d="M12.3978 23.2657L12.3903 23.0248L12.0101 22.6936L11.9875 22.9081L12.3978 23.2657Z"
        fill="#0028FF"
      />
      <path
        d="M15.7816 20.8868L16.843 20.4879L16.6209 20.4202L15.5408 20.8116L15.7816 20.8868Z"
        fill="#00B8FF"
      />
      <path
        d="M17.968 17.0785L19.1912 16.8263L19.2439 16.5139L18.0207 16.7585L17.968 17.0785Z"
        fill="#9AFF5D"
      />
      <path
        d="M16.7225 19.355L16.7376 19.8217L16.5569 20.6685L16.5381 20.2206L16.7225 19.355Z"
        fill="#02E8F4"
      />
      <path
        d="M11.9914 22.9078L12.014 22.6932L11.6603 22.2717L11.6113 22.4524L11.9914 22.9078Z"
        fill="#0040FF"
      />
      <path
        d="M16.6846 17.9928L17.9604 17.7481L17.9454 17.4094L16.6658 17.639L16.6846 17.9928Z"
        fill="#6DFF8A"
      />
      <path
        d="M15.5669 18.9601L16.8164 18.6854L16.7336 18.3467L15.4766 18.5988L15.5669 18.9601Z"
        fill="#40FFB7"
      />
      <path
        d="M23.0942 14.9591L23.8093 14.4887L23.5948 14.3381L22.8872 14.8086L23.0942 14.9591Z"
        fill="#FFE600"
      />
      <path
        d="M22.6648 14.7075L23.361 14.2446L23.1277 14.2107L22.439 14.6661L22.6648 14.7075Z"
        fill="#FFD700"
      />
      <path
        d="M13.8281 23.8566L13.6926 23.6835L13.2598 23.6609L13.3651 23.834L13.8281 23.8566Z"
        fill="#0000FF"
      />
      <path
        d="M22.8833 14.8087L23.5909 14.3382L23.3613 14.2441L22.665 14.7071L22.8833 14.8087Z"
        fill="#FFDB00"
      />
      <path
        d="M14.6936 19.9386L15.8339 19.6112L15.6872 19.2988L14.5317 19.6037L14.6936 19.9386Z"
        fill="#0FF8E7"
      />
      <path
        d="M12.8271 23.5176L12.7895 23.2617L12.3943 23.0283L12.4018 23.2692L12.8271 23.5176Z"
        fill="#0018FF"
      />
      <path
        d="M12.0213 9.22385L11.9724 9.74323L12.5482 9.66043L12.5746 9.15234L12.0213 9.22385Z"
        fill="#890000"
      />
      <path
        d="M15.439 23.1379L15.281 23.2245L14.8745 23.582L15.01 23.5105L15.439 23.1379Z"
        fill="#0018FF"
      />
      <path
        d="M22.793 16.7245L23.7829 16.3406L23.7038 16.0056L22.7178 16.397L22.793 16.7245Z"
        fill="#B1FF46"
      />
      <path
        d="M20.896 15.219L21.7917 14.8765L21.6035 15.0534L20.7153 15.3808L20.896 15.219Z"
        fill="#F1FC06"
      />
      <path
        d="M11.6115 22.4564L11.6605 22.2757L11.3405 21.7751L11.269 21.9182L11.6115 22.4564Z"
        fill="#0060FF"
      />
      <path
        d="M21.6487 18.0682L22.8154 17.737L22.8417 17.4058L21.6713 17.7407L21.6487 18.0682Z"
        fill="#6DFF8A"
      />
      <path
        d="M16.0188 20.898L17.0651 20.4991L16.8431 20.4878L15.7817 20.8867L16.0188 20.898Z"
        fill="#00B8FF"
      />
      <path
        d="M9.99316 17.2442L10.1098 17.6582L10.1287 16.7888L10.012 16.3372L9.99316 17.2442Z"
        fill="#94FF63"
      />
      <path
        d="M17.4788 20.3411L18.6191 19.9873L18.4272 20.0889L17.2793 20.4502L17.4788 20.3411Z"
        fill="#00D8FF"
      />
      <path
        d="M14.3665 21.0829L15.3074 20.6764L15.0853 20.4807L14.1218 20.8684L14.3665 21.0829Z"
        fill="#00B4FF"
      />
      <path
        d="M20.3692 18.8019L21.5547 18.5234L21.4531 18.7944L20.2676 19.0767L20.3692 18.8019Z"
        fill="#3CFFBA"
      />
      <path
        d="M18.9503 19.6262L20.1396 19.3176L19.9891 19.5133L18.7922 19.8295L18.9503 19.6262Z"
        fill="#16FFE1"
      />
      <path
        d="M14.1218 20.8721L15.0853 20.4845L14.8783 20.2361L13.896 20.6012L14.1218 20.8721Z"
        fill="#00C0FF"
      />
      <path
        d="M10.9226 21.3201L10.9678 21.305L10.7232 20.6351L10.6592 20.605L10.9226 21.3201Z"
        fill="#00A8FF"
      />
      <path
        d="M19.5036 15.8475L20.5574 15.5916L20.4257 15.8399L19.3794 16.0883L19.5036 15.8475Z"
        fill="#D1FF26"
      />
      <path
        d="M10.9304 10.3905L11.0019 10.955L11.4987 10.5561L11.431 9.98779L10.9304 10.3905Z"
        fill="#D10000"
      />
      <path
        d="M14.622 21.2329L15.5441 20.8152L15.3108 20.6797L14.3699 21.0862L14.622 21.2329Z"
        fill="#00A8FF"
      />
      <path
        d="M14.5323 19.6038L15.6877 19.2989L15.5672 18.9602L14.4043 19.2387L14.5323 19.6038Z"
        fill="#23FFD4"
      />
      <path
        d="M13.2598 23.6569L13.192 23.3897L12.7893 23.2617L12.8269 23.5176L13.2598 23.6569Z"
        fill="#0010FF"
      />
      <path
        d="M13.896 20.6011L14.8783 20.236L14.6939 19.9387L13.6965 20.2774L13.896 20.6011Z"
        fill="#00D4FF"
      />
      <path
        d="M15.4804 18.5992L16.7375 18.3471L16.6885 17.9971L15.4277 18.2267L15.4804 18.5992Z"
        fill="#56FFA0"
      />
      <path
        d="M14.8744 23.5818L14.6937 23.5366L14.2722 23.7587L14.4303 23.8189L14.8744 23.5818Z"
        fill="#0008FF"
      />
      <path
        d="M16.6697 17.6391L17.9493 17.4096L17.9681 17.0784L16.6885 17.2967L16.6697 17.6391Z"
        fill="#83FF73"
      />
      <path
        d="M18.0208 16.7581L19.2439 16.5134L19.3267 16.2236L18.1036 16.4645L18.0208 16.7581Z"
        fill="#B1FF46"
      />
      <path
        d="M22.7178 16.3969L23.7038 16.0055L23.5909 15.6931L22.6086 16.0883L22.7178 16.3969Z"
        fill="#C4FF33"
      />
      <path
        d="M14.8822 21.3162L15.7817 20.8909L15.5446 20.8157L14.6226 21.2334L14.8822 21.3162Z"
        fill="#00A8FF"
      />
      <path
        d="M21.0917 15.1061L21.9987 14.7485L21.7917 14.8727L20.896 15.2152L21.0917 15.1061Z"
        fill="#FEED00"
      />
      <path
        d="M16.5568 20.6687L16.5079 21.0375L16.252 21.7752L16.2934 21.4289L16.5568 20.6687Z"
        fill="#00A8FF"
      />
      <path
        d="M16.248 20.8456L17.2754 20.4504L17.0609 20.4994L16.0146 20.8983L16.248 20.8456Z"
        fill="#00C0FF"
      />
      <path
        d="M12.3908 23.0287L12.4698 22.7126L12.1198 22.4077L12.0144 22.6975L12.3908 23.0287Z"
        fill="#0040FF"
      />
      <path
        d="M11.265 21.9186L11.3365 21.7756L11.058 21.2073L10.9639 21.3089L11.265 21.9186Z"
        fill="#0088FF"
      />
      <path
        d="M10.1736 18.5425L10.2602 18.8436L10.1999 18.0044L10.1096 17.6619L10.1736 18.5425Z"
        fill="#5AFF9D"
      />
      <path
        d="M12.0104 22.6936L12.1158 22.4038L11.7883 22.0161L11.6528 22.2758L12.0104 22.6936Z"
        fill="#0058FF"
      />
      <path
        d="M10.0344 13.5253L10.1812 14.1049L10.4597 13.356L10.3205 12.7576L10.0344 13.5253Z"
        fill="#FF9400"
      />
      <path
        d="M13.6924 20.2772L14.6898 19.9385L14.5317 19.6035L13.5193 19.9121L13.6924 20.2772Z"
        fill="#06ECF1"
      />
      <path
        d="M13.6097 21.508L14.3662 21.0865L14.1216 20.8757L13.3425 21.2747L13.6097 21.508Z"
        fill="#00A0FF"
      />
      <path
        d="M17.6669 20.1758L18.796 19.8296L18.6191 19.9839L17.4788 20.3377L17.6669 20.1758Z"
        fill="#02E8F4"
      />
      <path
        d="M13.3428 21.2748L14.1256 20.8721L13.8998 20.6011L13.0981 20.9774L13.3428 21.2748Z"
        fill="#00B0FF"
      />
      <path
        d="M16.7673 16.9053L16.8238 17.4472L16.82 18.4258L16.7673 17.8951V16.9053Z"
        fill="#83FF73"
      />
      <path
        d="M12.7857 23.2619L12.8308 22.9269L12.4658 22.7124L12.3867 23.0285L12.7857 23.2619Z"
        fill="#0034FF"
      />
      <path
        d="M21.675 17.7367L22.8455 17.4017L22.838 17.063L21.6675 17.4017L21.675 17.7367Z"
        fill="#83FF73"
      />
      <path
        d="M14.2721 23.7587L14.1065 23.5894L13.6887 23.6834L13.8242 23.8566L14.2721 23.7587Z"
        fill="#0008FF"
      />
      <path
        d="M14.404 19.2348L15.567 18.9563L15.4804 18.595L14.3062 18.8434L14.404 19.2348Z"
        fill="#39FFBE"
      />
      <path
        d="M15.6875 12.0088L15.7891 12.4755L16.1504 13.375L16.0337 12.9083L15.6875 12.0088Z"
        fill="#FF6000"
      />
      <path
        d="M13.8886 21.6701L14.6225 21.2336L14.3666 21.083L13.6101 21.5045L13.8886 21.6701Z"
        fill="#0094FF"
      />
      <path
        d="M19.6504 15.6481L20.7155 15.3809L20.5575 15.5879L19.5037 15.8438L19.6504 15.6481Z"
        fill="#E1FF16"
      />
      <path
        d="M19.0821 19.3777L20.2714 19.0767L20.1434 19.3138L18.9541 19.6224L19.0821 19.3777Z"
        fill="#29FFCE"
      />
      <path
        d="M20.4405 18.5008L21.6298 18.2261L21.5583 18.5272L20.3728 18.8057L20.4405 18.5008Z"
        fill="#53FFA4"
      />
      <path
        d="M11.6567 22.2717L11.7922 22.012L11.4986 21.5491L11.3406 21.7674L11.6567 22.2717Z"
        fill="#0074FF"
      />
      <path
        d="M15.138 21.3273L16.0187 20.8983L15.7816 20.887L14.8821 21.3122L15.138 21.3273Z"
        fill="#00A8FF"
      />
      <path
        d="M22.6123 16.0883L23.5946 15.6932L23.4516 15.4109L22.4768 15.8098L22.6123 16.0883Z"
        fill="#D7FF1F"
      />
      <path
        d="M13.0942 20.9771L13.8959 20.6008L13.6926 20.2771L12.8684 20.6233L13.0942 20.9771Z"
        fill="#00C4FF"
      />
      <path
        d="M21.2988 15.0495L22.2171 14.6807L21.9988 14.7484L21.0918 15.1059L21.2988 15.0495Z"
        fill="#FFE600"
      />
      <path
        d="M10.3953 19.6753L10.4592 19.8446L10.3313 19.058L10.2598 18.8435L10.3953 19.6753Z"
        fill="#26FFD1"
      />
      <path
        d="M13.6927 23.6835L13.5911 23.4125L13.1921 23.3899L13.2599 23.6571L13.6927 23.6835Z"
        fill="#0014FF"
      />
      <path
        d="M15.4236 18.2262L16.6844 17.9966L16.6693 17.6428L15.4048 17.8536L15.4236 18.2262Z"
        fill="#6DFF8A"
      />
      <path
        d="M12.5752 9.14868L12.5488 9.65677L13.1435 9.74333V9.24277L12.5752 9.14868Z"
        fill="#800000"
      />
      <path
        d="M12.4694 22.7121L12.6764 22.3395L12.3565 22.061L12.1194 22.4073L12.4694 22.7121Z"
        fill="#005CFF"
      />
      <path
        d="M13.0489 21.9296L13.6097 21.5043L13.3425 21.271L12.7554 21.6737L13.0489 21.9296Z"
        fill="#008CFF"
      />
      <path
        d="M12.1196 22.4037L12.3567 22.0575L12.0593 21.7L11.7959 22.0161L12.1196 22.4037Z"
        fill="#0070FF"
      />
      <path
        d="M13.5195 19.9123L14.5319 19.6037L14.4039 19.2349L13.3765 19.5134L13.5195 19.9123Z"
        fill="#1CFFDB"
      />
      <path
        d="M12.7552 21.6737L13.3423 21.271L13.0939 20.9736L12.4805 21.3462L12.7552 21.6737Z"
        fill="#009CFF"
      />
      <path
        d="M14.1708 21.7603L14.8783 21.3162L14.6187 21.2334L13.8848 21.67L14.1708 21.7603Z"
        fill="#0094FF"
      />
      <path
        d="M13.192 23.3895L13.2071 23.0433L12.8345 22.9229L12.7893 23.2578L13.192 23.3895Z"
        fill="#002CFF"
      />
      <path
        d="M16.4287 14.3381L16.4889 14.8989L16.6771 15.8963L16.6169 15.3392L16.4287 14.3381Z"
        fill="#FFE600"
      />
      <path
        d="M16.4661 20.7292L17.4823 20.3416L17.279 20.4507L16.2478 20.8459L16.4661 20.7292Z"
        fill="#00CCFF"
      />
      <path
        d="M16.2519 21.7793L16.1352 22.0277L15.8191 22.6412L15.9245 22.4191L16.2519 21.7793Z"
        fill="#0070FF"
      />
      <path
        d="M16.6885 17.2927L17.9681 17.0744L18.0208 16.7583L16.7412 16.9615L16.6885 17.2927Z"
        fill="#9AFF5D"
      />
      <path
        d="M18.1035 16.4647L19.3267 16.2238L19.4396 15.9641L18.2164 16.2012L18.1035 16.4647Z"
        fill="#C4FF33"
      />
      <path
        d="M12.6766 22.3396L13.0492 21.9332L12.7556 21.6772L12.3567 22.0611L12.6766 22.3396Z"
        fill="#0074FF"
      />
      <path
        d="M13.3542 22.11L13.8886 21.6696L13.6101 21.5078L13.0493 21.9331L13.3542 22.11Z"
        fill="#0080FF"
      />
      <path
        d="M12.8346 22.9272L13.0114 22.5358L12.6765 22.3401L12.4695 22.7127L12.8346 22.9272Z"
        fill="#004CFF"
      />
      <path
        d="M10.6593 20.6047L10.7233 20.6348L10.5389 19.9235L10.4636 19.8445L10.6593 20.6047Z"
        fill="#00E0FB"
      />
      <path
        d="M22.4768 15.8059L23.4515 15.407L23.2822 15.1624L22.3149 15.5613L22.4768 15.8059Z"
        fill="#E7FF0F"
      />
      <path
        d="M12.353 22.0575L12.7519 21.6736L12.4809 21.3462L12.0557 21.7L12.353 22.0575Z"
        fill="#0084FF"
      />
      <path
        d="M21.5136 15.0461L22.4432 14.666L22.2174 14.6811L21.2991 15.0499L21.5136 15.0461Z"
        fill="#FFE200"
      />
      <path
        d="M12.8683 20.6234L13.6925 20.2772L13.5156 19.9121L12.6726 20.2207L12.8683 20.6234Z"
        fill="#00E0FB"
      />
      <path
        d="M12.4812 21.3499L13.0947 20.9773L12.8726 20.6235L12.2366 20.9623L12.4812 21.3499Z"
        fill="#00B4FF"
      />
      <path
        d="M11.792 22.0163L12.0554 21.7002L11.7844 21.2749L11.4946 21.5496L11.792 22.0163Z"
        fill="#008CFF"
      />
      <path
        d="M10.9679 21.3049L11.062 21.2033L10.8362 20.5823L10.7271 20.635L10.9679 21.3049Z"
        fill="#00B4FF"
      />
      <path
        d="M13.0112 22.5359L13.3537 22.1106L13.0489 21.9299L12.6763 22.3364L13.0112 22.5359Z"
        fill="#0068FF"
      />
      <path
        d="M21.664 17.4018L22.8344 17.0631L22.793 16.7244L21.6226 17.0631L21.664 17.4018Z"
        fill="#9AFF5D"
      />
      <path
        d="M11.3366 21.7717L11.4947 21.5534L11.2388 21.0303L11.0581 21.2072L11.3366 21.7717Z"
        fill="#009CFF"
      />
      <path
        d="M17.8288 19.9609L18.9504 19.626L18.7961 19.8292L17.667 20.1755L17.8288 19.9609Z"
        fill="#13FCE4"
      />
      <path
        d="M15.3902 21.2704L16.2521 20.8489L16.0225 20.9016L15.1418 21.3306L15.3902 21.2704Z"
        fill="#00B0FF"
      />
      <path
        d="M9.98169 14.9405L10.1209 15.4712L10.3129 14.662L10.1774 14.105L9.98169 14.9405Z"
        fill="#FFDB00"
      />
      <path
        d="M14.306 18.8471L15.4802 18.5987L15.4275 18.2261L14.2495 18.4444L14.306 18.8471Z"
        fill="#53FFA4"
      />
      <path
        d="M19.8199 15.494L20.8925 15.2192L20.7118 15.3811L19.6467 15.6483L19.8199 15.494Z"
        fill="#EEFF09"
      />
      <path
        d="M22.315 15.5619L23.2823 15.1629L23.0903 14.9597L22.1306 15.3587L22.315 15.5619Z"
        fill="#F4F802"
      />
      <path
        d="M12.0554 21.7002L12.4807 21.3464L12.2361 20.9587L11.7844 21.2749L12.0554 21.7002Z"
        fill="#00A0FF"
      />
      <path
        d="M21.724 15.0951L22.6649 14.7074L22.4391 14.666L21.5095 15.0461L21.724 15.0951Z"
        fill="#FFE200"
      />
      <path
        d="M13.6664 22.2079L14.1745 21.76L13.8884 21.6697L13.354 22.11L13.6664 22.2079Z"
        fill="#007CFF"
      />
      <path
        d="M14.4529 21.7753L15.1379 21.3275L14.8782 21.3162L14.1707 21.7603L14.4529 21.7753Z"
        fill="#0094FF"
      />
      <path
        d="M15.8152 22.6367L15.6383 22.7496L15.2808 23.2276L15.4388 23.141L15.8152 22.6367Z"
        fill="#0044FF"
      />
      <path
        d="M10.5613 11.4969L10.6817 12.0953L11.1145 11.5609L10.9979 10.9512L10.5613 11.4969Z"
        fill="#FF1E00"
      />
      <path
        d="M20.4782 18.1738L21.6675 17.9028L21.6261 18.2227L20.4368 18.4975L20.4782 18.1738Z"
        fill="#66FF90"
      />
      <path
        d="M13.2071 23.0438L13.3538 22.6411L13.0114 22.532L12.8345 22.9234L13.2071 23.0438Z"
        fill="#0048FF"
      />
      <path
        d="M22.1305 15.3584L23.0902 14.9595L22.8832 14.8052L21.9348 15.2004L22.1305 15.3584Z"
        fill="#FEED00"
      />
      <path
        d="M19.1837 19.0916L20.3693 18.8018L20.2677 19.0765L19.0784 19.3776L19.1837 19.0916Z"
        fill="#3CFFBA"
      />
      <path
        d="M21.9348 15.2001L22.887 14.8049L22.6687 14.707L21.7278 15.0947L21.9348 15.2001Z"
        fill="#FFE200"
      />
      <path
        d="M13.3765 19.5131L14.4039 19.2346L14.3061 18.8469L13.2673 19.0878L13.3765 19.5131Z"
        fill="#36FFC1"
      />
      <path
        d="M13.3503 22.6448L13.6627 22.2082L13.3503 22.1104L13.0078 22.5356L13.3503 22.6448Z"
        fill="#0064FF"
      />
      <path
        d="M12.2362 20.9623L12.8722 20.6235L12.6803 20.2246L12.0254 20.5219L12.2362 20.9623Z"
        fill="#00D0FF"
      />
      <path
        d="M15.2698 11.2L15.3563 11.6704L15.7891 12.4796L15.6875 12.0129L15.2698 11.2Z"
        fill="#FF2900"
      />
      <path
        d="M15.4048 17.85L16.6693 17.6392L16.6882 17.293L15.4236 17.4811L15.4048 17.85Z"
        fill="#87FF70"
      />
      <path
        d="M13.5911 23.4122L13.576 23.0659L13.2072 23.0471L13.1921 23.3934L13.5911 23.4122Z"
        fill="#0030FF"
      />
      <path
        d="M12.6768 20.2249L13.5198 19.9163L13.3768 19.5173L12.5225 19.7883L12.6768 20.2249Z"
        fill="#13FCE4"
      />
      <path
        d="M16.8203 18.4219L16.8316 18.9074L16.7375 19.8219L16.7224 19.3552L16.8203 18.4219Z"
        fill="#43FFB4"
      />
      <path
        d="M11.4987 21.5534L11.7885 21.2786L11.5552 20.7969L11.2466 21.0302L11.4987 21.5534Z"
        fill="#00ACFF"
      />
      <path
        d="M15.2807 23.2243L15.0775 23.198L14.6936 23.5367L14.8743 23.5819L15.2807 23.2243Z"
        fill="#002CFF"
      />
      <path
        d="M11.4268 9.98765L11.4945 10.556L12.0402 10.3113L11.9725 9.73926L11.4268 9.98765Z"
        fill="#A80000"
      />
      <path
        d="M16.666 20.5519L17.6671 20.1755L17.4827 20.3411L16.4666 20.7288L16.666 20.5519Z"
        fill="#00DCFE"
      />
      <path
        d="M13.147 9.24634V9.74689L13.7416 9.99905L13.719 9.50979L13.147 9.24634Z"
        fill="#800000"
      />
      <path
        d="M14.1106 23.5896L13.9826 23.3262L13.595 23.4127L13.6966 23.6837L14.1106 23.5896Z"
        fill="#0020FF"
      />
      <path
        d="M18.2129 16.2011L19.4361 15.964L19.5715 15.7419L18.3484 15.9753L18.2129 16.2011Z"
        fill="#D7FF1F"
      />
      <path
        d="M11.7848 21.2785L12.2364 20.9623L12.0256 20.522L11.5552 20.7967L11.7848 21.2785Z"
        fill="#00C0FF"
      />
      <path
        d="M14.6936 23.5364L14.5016 23.3821L14.1064 23.5891L14.272 23.7584L14.6936 23.5364Z"
        fill="#0020FF"
      />
      <path
        d="M16.7415 16.9655L18.0211 16.7623L18.1039 16.4688L16.8243 16.6607L16.7415 16.9655Z"
        fill="#B1FF46"
      />
      <path
        d="M21.6225 17.0631L22.793 16.7244L22.7177 16.397L21.551 16.7357L21.6225 17.0631Z"
        fill="#B1FF46"
      />
      <path
        d="M10.0156 16.3402L10.1323 16.7919L10.2339 15.9601L10.121 15.4746L10.0156 16.3402Z"
        fill="#CEFF29"
      />
      <path
        d="M13.9749 22.223L14.4566 21.7714L14.1743 21.7563L13.6663 22.2042L13.9749 22.223Z"
        fill="#007CFF"
      />
      <path
        d="M20.0042 15.3922L21.0882 15.1062L20.8924 15.2191L19.8198 15.4938L20.0042 15.3922Z"
        fill="#FBF100"
      />
      <path
        d="M15.6233 21.1428L16.4664 20.7288L16.2481 20.8454L15.3862 21.267L15.6233 21.1428Z"
        fill="#00BCFF"
      />
      <path
        d="M11.0619 21.2032L11.2425 21.0263L11.0317 20.4504L10.8323 20.5784L11.0619 21.2032Z"
        fill="#00C4FF"
      />
      <path
        d="M14.7275 21.7113L15.3899 21.271L15.1415 21.3274L14.4565 21.7753L14.7275 21.7113Z"
        fill="#009CFF"
      />
      <path
        d="M14.246 18.4447L15.424 18.2265L15.4052 17.8501L14.2234 18.0383L14.246 18.4447Z"
        fill="#6DFF8A"
      />
      <path
        d="M13.5757 23.0663L13.6886 22.6636L13.3499 22.6448L13.2031 23.0475L13.5757 23.0663Z"
        fill="#004CFF"
      />
      <path
        d="M17.9644 19.6976L19.0785 19.3777L18.9467 19.6261L17.8252 19.961L17.9644 19.6976Z"
        fill="#23FFD4"
      />
      <path
        d="M13.6886 22.6635L13.9708 22.227L13.6622 22.2119L13.3499 22.6485L13.6886 22.6635Z"
        fill="#0064FF"
      />
      <path
        d="M12.0218 20.522L12.6766 20.2247L12.5185 19.7881L11.8486 20.044L12.0218 20.522Z"
        fill="#09F0EE"
      />
      <path
        d="M14.7917 10.4998L14.8595 10.9777L15.3563 11.674L15.2697 11.2035L14.7917 10.4998Z"
        fill="#DF0000"
      />
      <path
        d="M20.4819 17.8345L21.6712 17.571L21.6637 17.906L20.4744 18.177L20.4819 17.8345Z"
        fill="#7DFF7A"
      />
      <path
        d="M10.7231 20.635L10.8322 20.5823L10.6591 19.9199L10.5349 19.9237L10.7231 20.635Z"
        fill="#02E8F4"
      />
      <path
        d="M10.1096 17.6577L10.1999 18.0002L10.2188 17.1722L10.1284 16.7883L10.1096 17.6577Z"
        fill="#94FF63"
      />
      <path
        d="M13.7151 9.50977L13.7377 9.99903L14.3135 10.413L14.2683 9.93129L13.7151 9.50977Z"
        fill="#920000"
      />
      <path
        d="M19.2551 18.7756L20.4406 18.4971L20.3691 18.8019L19.1836 19.0917L19.2551 18.7756Z"
        fill="#50FFA7"
      />
      <path
        d="M13.271 19.0877L14.3098 18.8468L14.2495 18.4441L13.207 18.6511L13.271 19.0877Z"
        fill="#50FFA7"
      />
      <path
        d="M12.5184 19.7842L13.3727 19.5132L13.2673 19.0879L12.3979 19.3175L12.5184 19.7842Z"
        fill="#30FFC7"
      />
      <path
        d="M21.5508 16.736L22.7175 16.3972L22.6122 16.0886L21.4492 16.4273L21.5508 16.736Z"
        fill="#C4FF33"
      />
      <path
        d="M10.4634 19.8447L10.5387 19.9237L10.4182 19.1861L10.3354 19.0581L10.4634 19.8447Z"
        fill="#2CFFCA"
      />
      <path
        d="M11.2425 21.03L11.5511 20.7967L11.3592 20.2698L11.0317 20.4542L11.2425 21.03Z"
        fill="#00D4FF"
      />
      <path
        d="M10.2604 18.8435L10.3319 19.0581L10.2755 18.2639L10.2002 18.0005L10.2604 18.8435Z"
        fill="#5AFF9D"
      />
      <path
        d="M14.2683 9.92725L14.3135 10.409L14.8592 10.9698L14.7914 10.4918L14.2683 9.92725Z"
        fill="#B20000"
      />
      <path
        d="M11.5511 20.7968L12.0215 20.522L11.8484 20.0403L11.3591 20.2699L11.5511 20.7968Z"
        fill="#00E4F8"
      />
      <path
        d="M20.2035 15.3434L21.2987 15.0498L21.0917 15.11L20.0078 15.3961L20.2035 15.3434Z"
        fill="#FFEA00"
      />
      <path
        d="M15.4236 17.4813L16.6881 17.2931L16.7408 16.9619L15.4763 17.1313L15.4236 17.4813Z"
        fill="#9DFF5A"
      />
      <path
        d="M18.3521 15.9793L19.5752 15.746L19.7333 15.5691L18.5101 15.8024L18.3521 15.9793Z"
        fill="#E7FF0F"
      />
      <path
        d="M16.8389 20.3185L17.8287 19.9609L17.6669 20.1792L16.6658 20.5556L16.8389 20.3185Z"
        fill="#09F0EE"
      />
      <path
        d="M16.7371 19.822L16.6845 20.2172L16.5076 21.0377L16.5565 20.6688L16.7371 19.822Z"
        fill="#06ECF1"
      />
      <path
        d="M13.9786 23.3259L13.9335 22.9834L13.5759 23.0624L13.591 23.4087L13.9786 23.3259Z"
        fill="#003CFF"
      />
      <path
        d="M14.2723 22.1555L14.7277 21.7114L14.4529 21.7754L13.9712 22.227L14.2723 22.1555Z"
        fill="#0088FF"
      />
      <path
        d="M16.6736 15.897L16.73 16.4502L16.8204 17.4438L16.7639 16.9018L16.6736 15.897Z"
        fill="#C4FF33"
      />
      <path
        d="M16.1501 13.3745L16.2104 13.939L16.4889 14.8988L16.4287 14.338L16.1501 13.3745Z"
        fill="#FF9F00"
      />
      <path
        d="M16.824 16.6566L18.1036 16.4646L18.2165 16.2012L16.9406 16.3856L16.824 16.6566Z"
        fill="#C7FF30"
      />
      <path
        d="M10.3167 12.7577L10.4559 13.3562L10.8134 12.7126L10.678 12.0991L10.3167 12.7577Z"
        fill="#FF5900"
      />
      <path
        d="M15.8377 20.9512L16.6619 20.5522L16.4624 20.7291L15.6194 21.1431L15.8377 20.9512Z"
        fill="#00D0FF"
      />
      <path
        d="M21.4495 16.4274L22.6124 16.0887L22.477 15.8064L21.3215 16.1414L21.4495 16.4274Z"
        fill="#D7FF1F"
      />
      <path
        d="M14.9835 21.5719L15.6233 21.1428L15.3862 21.2708L14.7275 21.7111L14.9835 21.5719Z"
        fill="#00ACFF"
      />
      <path
        d="M20.4029 15.347L21.5094 15.0459L21.2949 15.0497L20.1997 15.3432L20.4029 15.347Z"
        fill="#FFE600"
      />
      <path
        d="M14.0162 22.5881L14.2721 22.1553L13.971 22.223L13.6887 22.6596L14.0162 22.5881Z"
        fill="#0070FF"
      />
      <path
        d="M13.9334 22.9829L14.0199 22.584L13.6925 22.6593L13.5796 23.062L13.9334 22.9829Z"
        fill="#0054FF"
      />
      <path
        d="M14.2234 18.0416L15.4052 17.8534L15.4202 17.4846L14.2384 17.6465L14.2234 18.0416Z"
        fill="#87FF70"
      />
      <path
        d="M20.4558 17.4921L21.6489 17.2324L21.6752 17.5711L20.4859 17.8346L20.4558 17.4921Z"
        fill="#94FF63"
      />
      <path
        d="M11.8485 20.0402L12.5184 19.7843L12.4018 19.3176L11.7168 19.5284L11.8485 20.0402Z"
        fill="#26FFD1"
      />
      <path
        d="M18.0734 19.3926L19.1837 19.0916L19.082 19.3776L17.968 19.6975L18.0734 19.3926Z"
        fill="#39FFBE"
      />
      <path
        d="M14.5017 23.3824L14.3436 23.134L13.9785 23.326L14.1065 23.5894L14.5017 23.3824Z"
        fill="#0038FF"
      />
      <path
        d="M10.8323 20.5821L11.0318 20.4542L10.8737 19.8445L10.6592 19.9198L10.8323 20.5821Z"
        fill="#0CF4EB"
      />
      <path
        d="M19.2928 18.4365L20.4783 18.1731L20.4407 18.5005L19.2551 18.779L19.2928 18.4365Z"
        fill="#66FF90"
      />
      <path
        d="M16.5078 21.0378L16.3799 21.3126L16.1353 22.0239L16.2519 21.7755L16.5078 21.0378Z"
        fill="#00B0FF"
      />
      <path
        d="M21.3174 16.1414L22.4728 15.8064L22.311 15.5618L21.1631 15.893L21.3174 16.1414Z"
        fill="#E4FF13"
      />
      <path
        d="M13.2034 18.6517L14.246 18.4447L14.2234 18.042L13.1733 18.2114L13.2034 18.6517Z"
        fill="#6AFF8D"
      />
      <path
        d="M20.6063 15.4073L21.7241 15.0949L21.5096 15.0422L20.4031 15.3433L20.6063 15.4073Z"
        fill="#FFE600"
      />
      <path
        d="M12.4019 19.318L13.2713 19.0884L13.2036 18.6519L12.3267 18.84L12.4019 19.318Z"
        fill="#49FFAD"
      />
      <path
        d="M18.5098 15.7986L19.7329 15.5652L19.9098 15.4373L18.6904 15.6668L18.5098 15.7986Z"
        fill="#F4F802"
      />
      <path
        d="M11.9724 9.73895L12.0402 10.311L12.616 10.232L12.5482 9.65991L11.9724 9.73895Z"
        fill="#8D0000"
      />
      <path
        d="M15.0778 23.1979L14.8595 23.0662L14.502 23.3861L14.6939 23.5404L15.0778 23.1979Z"
        fill="#0040FF"
      />
      <path
        d="M11.3591 20.2697L11.8483 20.0402L11.7166 19.5283L11.2161 19.709L11.3591 20.2697Z"
        fill="#1FFFD7"
      />
      <path
        d="M11.0316 20.4544L11.359 20.27L11.216 19.7092L10.8735 19.8447L11.0316 20.4544Z"
        fill="#16FFE1"
      />
      <path
        d="M21.1668 15.8926L22.3147 15.5614L22.134 15.3582L20.9937 15.6818L21.1668 15.8926Z"
        fill="#F1FC06"
      />
      <path
        d="M15.4766 17.1309L16.7411 16.9616L16.8239 16.6567L15.5631 16.8035L15.4766 17.1309Z"
        fill="#B4FF43"
      />
      <path
        d="M20.8056 15.52L21.9346 15.2001L21.7276 15.0947L20.6099 15.4071L20.8056 15.52Z"
        fill="#FFE600"
      />
      <path
        d="M16.1312 22.0239L15.9393 22.1632L15.6382 22.7465L15.8151 22.6336L16.1312 22.0239Z"
        fill="#007CFF"
      />
      <path
        d="M20.9941 15.6822L22.1345 15.3585L21.935 15.2004L20.8059 15.5203L20.9941 15.6822Z"
        fill="#FEED00"
      />
      <path
        d="M16.986 20.0365L17.9683 19.6978L17.8328 19.9612L16.843 20.3187L16.986 20.0365Z"
        fill="#1CFFDB"
      />
      <path
        d="M15.6422 22.7463H15.4164L15.0776 23.198L15.2809 23.2243L15.6422 22.7463Z"
        fill="#0054FF"
      />
      <path
        d="M10.998 10.955L11.1147 11.5647L11.6077 11.1733L11.4948 10.5598L10.998 10.955Z"
        fill="#D60000"
      />
      <path
        d="M14.5545 22.0046L14.9835 21.5718L14.7276 21.711L14.2722 22.1551L14.5545 22.0046Z"
        fill="#0098FF"
      />
      <path
        d="M16.9373 16.3858L18.2131 16.2014L18.3524 15.9756L17.0803 16.1487L16.9373 16.3858Z"
        fill="#D7FF1F"
      />
      <path
        d="M10.1772 14.1048L10.3127 14.6618L10.5837 13.9354L10.4557 13.3521L10.1772 14.1048Z"
        fill="#FF9C00"
      />
      <path
        d="M10.5386 19.9232L10.6628 19.9194L10.5499 19.2345L10.4182 19.1855L10.5386 19.9232Z"
        fill="#30FFC7"
      />
      <path
        d="M20.3955 17.1534L21.5886 16.8975L21.645 17.2324L20.452 17.4921L20.3955 17.1534Z"
        fill="#AAFF4D"
      />
      <path
        d="M14.3438 23.1336L14.2686 22.8062L13.9336 22.9868L13.9788 23.3293L14.3438 23.1336Z"
        fill="#0050FF"
      />
      <path
        d="M16.0297 20.695L16.8389 20.3186L16.6658 20.5519L15.8416 20.9509L16.0297 20.695Z"
        fill="#00E4F8"
      />
      <path
        d="M15.2206 21.3612L15.8416 20.9509L15.6271 21.1429L14.9873 21.5719L15.2206 21.3612Z"
        fill="#00C0FF"
      />
      <path
        d="M18.6907 15.6669L19.9101 15.4373L20.102 15.3621L18.8826 15.5916L18.6907 15.6669Z"
        fill="#FEED00"
      />
      <path
        d="M14.2419 17.643L15.4237 17.4811L15.4764 17.1311L14.2946 17.2628L14.2419 17.643Z"
        fill="#A0FF56"
      />
      <path
        d="M18.1449 19.0577L19.2513 18.7754L19.1798 19.0915L18.0696 19.3926L18.1449 19.0577Z"
        fill="#50FFA7"
      />
      <path
        d="M14.325 22.4226L14.5545 22.0049L14.2723 22.1554L14.0164 22.5882L14.325 22.4226Z"
        fill="#0080FF"
      />
      <path
        d="M11.7205 19.5284L12.4055 19.3177L12.3302 18.8359L11.6377 18.9978L11.7205 19.5284Z"
        fill="#46FFB1"
      />
      <path
        d="M16.8204 17.4473L16.8317 17.9553L16.8279 18.9113L16.8167 18.4258L16.8204 17.4473Z"
        fill="#83FF73"
      />
      <path
        d="M19.2966 18.0835L20.4821 17.8313L20.4746 18.17L19.2891 18.4335L19.2966 18.0835Z"
        fill="#7DFF7A"
      />
      <path
        d="M14.2684 22.8065L14.3249 22.4226L14.0163 22.5882L13.9297 22.9871L14.2684 22.8065Z"
        fill="#0068FF"
      />
      <path
        d="M10.3318 19.058L10.4146 19.186L10.362 18.4446L10.2754 18.2639L10.3318 19.058Z"
        fill="#5DFF9A"
      />
      <path
        d="M15.7891 12.4792L15.8493 13.0475L16.2106 13.9433L16.1504 13.3787L15.7891 12.4792Z"
        fill="#FF6000"
      />
      <path
        d="M10.1208 15.471L10.2338 15.9565L10.4182 15.1812L10.3128 14.6619L10.1208 15.471Z"
        fill="#FFE200"
      />
      <path
        d="M13.1772 18.211L14.2273 18.0417L14.2423 17.6465L13.1923 17.782L13.1772 18.211Z"
        fill="#87FF70"
      />
      <path
        d="M12.3265 18.8397L13.2034 18.6515L13.1771 18.2112L12.2964 18.358L12.3265 18.8397Z"
        fill="#6AFF8D"
      />
      <path
        d="M20.3052 16.8263L21.502 16.5779L21.5886 16.8978L20.3955 17.1537L20.3052 16.8263Z"
        fill="#BEFF39"
      />
      <path
        d="M10.6629 19.9195L10.8774 19.8442L10.7758 19.2119L10.5537 19.2345L10.6629 19.9195Z"
        fill="#36FFC1"
      />
      <path
        d="M15.5632 16.8075L16.824 16.6607L16.9369 16.386L15.6799 16.5177L15.5632 16.8075Z"
        fill="#CAFF2C"
      />
      <path
        d="M17.0762 16.1526L18.3483 15.9795L18.5101 15.7988L17.2418 15.9644L17.0762 16.1526Z"
        fill="#E7FF0F"
      />
      <path
        d="M10.2002 18.0005L10.2755 18.2639L10.2943 17.4849L10.219 17.1763L10.2002 18.0005Z"
        fill="#90FF66"
      />
      <path
        d="M10.1323 16.7882L10.2226 17.172L10.3205 16.3779L10.2377 15.9526L10.1323 16.7882Z"
        fill="#CAFF2C"
      />
      <path
        d="M11.2162 19.709L11.7167 19.5284L11.6339 19.0015L11.1221 19.1294L11.2162 19.709Z"
        fill="#40FFB7"
      />
      <path
        d="M18.8826 15.5879L20.102 15.3583L20.3014 15.3357L19.0858 15.5653L18.8826 15.5879Z"
        fill="#FFE600"
      />
      <path
        d="M14.8629 23.0625L14.6785 22.8367L14.3435 23.134L14.5016 23.3824L14.8629 23.0625Z"
        fill="#0054FF"
      />
      <path
        d="M17.0987 19.7093L18.0735 19.397L17.9681 19.7018L16.9858 20.0405L17.0987 19.7093Z"
        fill="#33FFC4"
      />
      <path
        d="M10.8736 19.8445L11.2161 19.709L11.122 19.1294L10.772 19.2122L10.8736 19.8445Z"
        fill="#3CFFBA"
      />
      <path
        d="M14.814 21.7753L15.2205 21.3613L14.9872 21.5721L14.5581 22.0049L14.814 21.7753Z"
        fill="#00ACFF"
      />
      <path
        d="M12.5488 9.65991L12.6166 10.232L13.2112 10.3185L13.1435 9.74648L12.5488 9.65991Z"
        fill="#800000"
      />
      <path
        d="M16.4888 14.8989L16.5452 15.4672L16.7296 16.4495L16.6732 15.8963L16.4888 14.8989Z"
        fill="#FFEA00"
      />
      <path
        d="M20.1885 16.5249L21.3891 16.2803L21.5057 16.5776L20.3089 16.826L20.1885 16.5249Z"
        fill="#D1FF26"
      />
      <path
        d="M19.2666 17.7295L20.4559 17.4924L20.4822 17.8349L19.2967 18.0871L19.2666 17.7295Z"
        fill="#94FF63"
      />
      <path
        d="M16.1879 20.3865L16.9857 20.0327L16.839 20.315L16.0298 20.6913L16.1879 20.3865Z"
        fill="#16FFE1"
      />
      <path
        d="M15.424 21.0827L16.0262 20.6951L15.838 20.9472L15.217 21.3575L15.424 21.0827Z"
        fill="#00D8FF"
      />
      <path
        d="M18.1863 18.7004L19.2928 18.4407L19.2552 18.7794L18.1487 19.0617L18.1863 18.7004Z"
        fill="#66FF90"
      />
      <path
        d="M19.0857 15.5653L20.3013 15.3357L20.5046 15.3696L19.2889 15.5991L19.0857 15.5653Z"
        fill="#FFE200"
      />
      <path
        d="M14.2947 17.2667L15.4764 17.1349L15.563 16.8113L14.3888 16.9167L14.2947 17.2667Z"
        fill="#B7FF40"
      />
      <path
        d="M16.8314 18.9072L16.7749 19.3287L16.6846 20.2132L16.7373 19.818L16.8314 18.9072Z"
        fill="#43FFB4"
      />
      <path
        d="M10.6816 12.0989L10.8171 12.7123L11.2424 12.1892L11.1182 11.5645L10.6816 12.0989Z"
        fill="#FF2500"
      />
      <path
        d="M11.634 19.0017L12.3265 18.8399L12.2964 18.3582L11.6001 18.4748L11.634 19.0017Z"
        fill="#66FF90"
      />
      <path
        d="M14.6072 22.1703L14.8105 21.7751L14.5545 22.0047L14.325 22.4225L14.6072 22.1703Z"
        fill="#0098FF"
      />
      <path
        d="M15.4168 22.7462L15.1797 22.6409L14.8635 23.0624L15.0818 23.1941L15.4168 22.7462Z"
        fill="#0068FF"
      />
      <path
        d="M20.042 16.2543L21.2463 16.0134L21.3894 16.2806L20.1888 16.5253L20.042 16.2543Z"
        fill="#E1FF16"
      />
      <path
        d="M17.2417 15.9641L18.51 15.7985L18.6907 15.6667L17.4299 15.8286L17.2417 15.9641Z"
        fill="#F8F500"
      />
      <path
        d="M14.6785 22.8368L14.5769 22.532L14.2683 22.8067L14.3436 23.1342L14.6785 22.8368Z"
        fill="#006CFF"
      />
      <path
        d="M15.356 11.6699L15.4162 12.2382L15.849 13.0436L15.7888 12.4753L15.356 11.6699Z"
        fill="#FF2900"
      />
      <path
        d="M10.4182 19.1855L10.5499 19.2344L10.501 18.5457L10.3655 18.4441L10.4182 19.1855Z"
        fill="#60FF97"
      />
      <path
        d="M19.293 15.5997L20.5086 15.3701L20.7081 15.4567L19.4962 15.6863L19.293 15.5997Z"
        fill="#FFE600"
      />
      <path
        d="M15.6802 16.5174L16.9372 16.3857L17.0802 16.1523L15.8307 16.269L15.6802 16.5174Z"
        fill="#DEFF19"
      />
      <path
        d="M13.1921 17.7781L14.2422 17.6426L14.2949 17.2625L13.2486 17.3603L13.1921 17.7781Z"
        fill="#A4FF53"
      />
      <path
        d="M14.5769 22.532L14.607 22.1707L14.3248 22.4228L14.2683 22.8067L14.5769 22.532Z"
        fill="#0080FF"
      />
      <path
        d="M12.2964 18.3545L13.1771 18.2077L13.1921 17.7749L12.3114 17.8803L12.2964 18.3545Z"
        fill="#87FF70"
      />
      <path
        d="M19.8762 16.021L21.0806 15.7839L21.2424 16.0135L20.0381 16.2544L19.8762 16.021Z"
        fill="#EEFF09"
      />
      <path
        d="M19.4961 15.6898L20.708 15.4602L20.8999 15.5995L19.6918 15.8328L19.4961 15.6898Z"
        fill="#FFEA00"
      />
      <path
        d="M11.4946 10.5559L11.6075 11.1694L12.1457 10.9285L12.0403 10.3113L11.4946 10.5559Z"
        fill="#AD0000"
      />
      <path
        d="M19.6919 15.8286L20.9 15.5952L21.0807 15.7834L19.8763 16.0205L19.6919 15.8286Z"
        fill="#FBF100"
      />
      <path
        d="M16.6847 20.2173L16.5492 20.5259L16.3799 21.3163L16.5078 21.0415L16.6847 20.2173Z"
        fill="#0CF4EB"
      />
      <path
        d="M19.2063 17.3759L20.3994 17.1538L20.4596 17.4925L19.2703 17.7296L19.2063 17.3759Z"
        fill="#AAFF4D"
      />
      <path
        d="M17.1774 19.3478L18.1484 19.0618L18.0732 19.3967L17.0984 19.7091L17.1774 19.3478Z"
        fill="#4DFFAA"
      />
      <path
        d="M15.9433 22.1628L15.7024 22.1967L15.4202 22.7462H15.646L15.9433 22.1628Z"
        fill="#0088FF"
      />
      <path
        d="M13.1433 9.74683L13.2111 10.3189L13.8057 10.571L13.7417 9.99899L13.1433 9.74683Z"
        fill="#800000"
      />
      <path
        d="M11.1221 19.1293L11.634 19.0014L11.6001 18.4707L11.0845 18.5497L11.1221 19.1293Z"
        fill="#66FF90"
      />
      <path
        d="M16.3797 21.3162L16.1765 21.4893L15.9431 22.1667L16.1351 22.0275L16.3797 21.3162Z"
        fill="#00B8FF"
      />
      <path
        d="M15.0364 21.4707L15.424 21.083L15.217 21.3615L14.8105 21.7755L15.0364 21.4707Z"
        fill="#00C8FF"
      />
      <path
        d="M18.1902 18.3239L19.3004 18.083L19.2967 18.4368L18.1902 18.6965V18.3239Z"
        fill="#7DFF7A"
      />
      <path
        d="M10.5497 19.2347L10.7717 19.2122L10.7265 18.5761L10.5007 18.5422L10.5497 19.2347Z"
        fill="#60FF97"
      />
      <path
        d="M17.426 15.829L18.6868 15.6672L18.8788 15.5881L17.6217 15.7462L17.426 15.829Z"
        fill="#FFEA00"
      />
      <path
        d="M14.8594 10.9739L14.9234 11.5459L15.4201 12.2384L15.3599 11.6701L14.8594 10.9739Z"
        fill="#DF0000"
      />
      <path
        d="M10.7719 19.2123L11.1219 19.1295L11.0842 18.5461L10.7305 18.5763L10.7719 19.2123Z"
        fill="#63FF94"
      />
      <path
        d="M10.2754 18.2637L10.362 18.4443L10.3808 17.7142L10.2942 17.4846L10.2754 18.2637Z"
        fill="#90FF66"
      />
      <path
        d="M16.3087 20.0329L17.099 19.7092L16.9861 20.0367L16.1882 20.3904L16.3087 20.0329Z"
        fill="#2CFFCA"
      />
      <path
        d="M14.3848 16.9125L15.559 16.8071L15.6794 16.5173L14.5127 16.6001L14.3848 16.9125Z"
        fill="#D1FF26"
      />
      <path
        d="M10.4597 13.3562L10.5877 13.9396L10.9339 13.3148L10.8173 12.7126L10.4597 13.3562Z"
        fill="#FF6000"
      />
      <path
        d="M15.5967 20.7477L16.1838 20.3901L16.0258 20.6987L15.4236 21.0864L15.5967 20.7477Z"
        fill="#0CF4EB"
      />
      <path
        d="M13.7378 9.99902L13.8018 10.5711L14.3776 10.9851L14.3136 10.413L13.7378 9.99902Z"
        fill="#920000"
      />
      <path
        d="M15.8301 16.269L17.0796 16.1523L17.2452 15.9641L16.007 16.0695L15.8301 16.269Z"
        fill="#EEFF09"
      />
      <path
        d="M19.1084 17.0367L20.3052 16.8259L20.3955 17.1496L19.2025 17.3716L19.1084 17.0367Z"
        fill="#C1FF36"
      />
      <path
        d="M15.1751 22.6409L14.9681 22.4414L14.6746 22.8328L14.859 23.0586L15.1751 22.6409Z"
        fill="#007CFF"
      />
      <path
        d="M14.3135 10.4128L14.3775 10.9849L14.9194 11.5457L14.8554 10.9736L14.3135 10.4128Z"
        fill="#B20000"
      />
      <path
        d="M16.7297 16.4539L16.7448 16.9845L16.8351 17.9555L16.8238 17.4474L16.7297 16.4539Z"
        fill="#C1FF36"
      />
      <path
        d="M11.6001 18.471L12.2964 18.3543L12.3114 17.8801L11.6152 17.9479L11.6001 18.471Z"
        fill="#8AFF6D"
      />
      <path
        d="M17.6294 15.7461L18.8864 15.588L19.0897 15.5654L17.8402 15.7235L17.6294 15.7461Z"
        fill="#FFE200"
      />
      <path
        d="M13.2485 17.3641L14.2948 17.2663L14.3851 16.9163L13.3464 16.984L13.2485 17.3641Z"
        fill="#BEFF39"
      />
      <path
        d="M14.8555 21.8393L15.0399 21.4705L14.8141 21.7753L14.6108 22.1705L14.8555 21.8393Z"
        fill="#00B4FF"
      />
      <path
        d="M16.2104 13.9392L16.2707 14.5188L16.5454 15.4672L16.489 14.8989L16.2104 13.9392Z"
        fill="#FFA300"
      />
      <path
        d="M10.2188 17.1725L10.294 17.4811L10.3881 16.7322L10.3166 16.3784L10.2188 17.1725Z"
        fill="#C7FF30"
      />
      <path
        d="M12.3113 17.88L13.192 17.7746L13.2484 17.3606L12.3715 17.4246L12.3113 17.88Z"
        fill="#A7FF50"
      />
      <path
        d="M10.3127 14.666L10.4181 15.1853L10.6816 14.4891L10.5837 13.9434L10.3127 14.666Z"
        fill="#FFA300"
      />
      <path
        d="M17.2191 18.9565L18.1864 18.6968L18.1487 19.0581L17.1777 19.3441L17.2191 18.9565Z"
        fill="#63FF94"
      />
      <path
        d="M18.1562 17.9439L19.2703 17.7256L19.3004 18.0831L18.1901 18.324L18.1562 17.9439Z"
        fill="#97FF60"
      />
      <path
        d="M10.2341 15.9564L10.3169 16.3817L10.4938 15.6441L10.4185 15.1812L10.2341 15.9564Z"
        fill="#FFEA00"
      />
      <path
        d="M18.9844 16.7248L20.1887 16.5291L20.3092 16.8301L19.1123 17.0409L18.9844 16.7248Z"
        fill="#D4FF23"
      />
      <path
        d="M14.9684 22.4454L14.8442 22.1707L14.5732 22.532L14.6749 22.8368L14.9684 22.4454Z"
        fill="#0090FF"
      />
      <path
        d="M14.8442 22.1701L14.8517 21.8389L14.6034 22.1701L14.5732 22.5314L14.8442 22.1701Z"
        fill="#00A0FF"
      />
      <path
        d="M17.8364 15.7235L19.0859 15.5654L19.2929 15.5993L18.0509 15.7574L17.8364 15.7235Z"
        fill="#FFDE00"
      />
      <path
        d="M10.3655 18.4442L10.501 18.5459L10.516 17.8684L10.3805 17.7141L10.3655 18.4442Z"
        fill="#8DFF6A"
      />
      <path
        d="M14.5132 16.6L15.6799 16.5172L15.8267 16.2688L14.6675 16.3328L14.5132 16.6Z"
        fill="#E4FF13"
      />
      <path
        d="M16.0034 16.0697L17.2416 15.9643L17.4261 15.8289L16.1954 15.9267L16.0034 16.0697Z"
        fill="#FEED00"
      />
      <path
        d="M11.0845 18.5501L11.6001 18.471L11.6151 17.9517L11.0995 17.978L11.0845 18.5501Z"
        fill="#8AFF6D"
      />
      <path
        d="M15.2243 21.1018L15.5969 20.748L15.4238 21.0868L15.0361 21.4707L15.2243 21.1018Z"
        fill="#02E8F4"
      />
      <path
        d="M15.6987 22.1968L15.439 22.1252L15.1755 22.6409L15.4126 22.7462L15.6987 22.1968Z"
        fill="#0098FF"
      />
      <path
        d="M16.3912 19.6379L17.1741 19.3481L17.095 19.7094L16.3047 20.0331L16.3912 19.6379Z"
        fill="#46FFB1"
      />
      <path
        d="M18.8335 16.4383L20.0416 16.2539L20.1884 16.5249L18.984 16.7206L18.8335 16.4383Z"
        fill="#E4FF13"
      />
      <path
        d="M12.0405 10.3116L12.1459 10.9288L12.718 10.8498L12.6164 10.2288L12.0405 10.3116Z"
        fill="#920000"
      />
      <path
        d="M11.1145 11.5646L11.2387 12.1894L11.7204 11.8055L11.6038 11.1694L11.1145 11.5646Z"
        fill="#DF0000"
      />
      <path
        d="M18.0505 15.7572L19.2925 15.5991L19.4958 15.6857L18.2613 15.8475L18.0505 15.7572Z"
        fill="#FFDE00"
      />
      <path
        d="M15.7326 20.3564L16.3121 20.0327L16.1879 20.3903L15.6008 20.7478L15.7326 20.3564Z"
        fill="#26FFD1"
      />
      <path
        d="M16.8349 17.9551L16.7784 18.4067L16.7747 19.3325L16.8311 18.911L16.8349 17.9551Z"
        fill="#83FF73"
      />
      <path
        d="M18.6567 16.1979L19.8724 16.021L20.038 16.2543L18.8261 16.4388L18.6567 16.1979Z"
        fill="#F1FC06"
      />
      <path
        d="M18.2617 15.851L19.4962 15.6892L19.6919 15.8285L18.465 15.9978L18.2617 15.851Z"
        fill="#FFE600"
      />
      <path
        d="M10.5007 18.5461L10.7265 18.58L10.7416 17.9552L10.5158 17.8687L10.5007 18.5461Z"
        fill="#8DFF6A"
      />
      <path
        d="M10.7305 18.5764L11.0842 18.5463L11.0993 17.9742L10.7455 17.9517L10.7305 18.5764Z"
        fill="#8DFF6A"
      />
      <path
        d="M18.0884 17.5712L19.2062 17.3755L19.2701 17.7293L18.1561 17.9476L18.0884 17.5712Z"
        fill="#ADFF49"
      />
      <path
        d="M18.4648 15.9982L19.6918 15.8289L19.8762 16.0208L18.6606 16.1977L18.4648 15.9982Z"
        fill="#FEED00"
      />
      <path
        d="M13.3464 16.9807L14.3852 16.913L14.5131 16.6006L13.4819 16.6382L13.3464 16.9807Z"
        fill="#D7FF1F"
      />
      <path d="M17.219 18.5536L18.19 18.324L18.1862 18.7003L17.219 18.96V18.5536Z" fill="#7DFF7A" />
      <path
        d="M11.615 17.9515L12.3113 17.8838L12.3715 17.4321L11.679 17.4585L11.615 17.9515Z"
        fill="#AAFF4D"
      />
      <path
        d="M16.1766 21.4893L15.9207 21.5608L15.7024 22.2006L15.9433 22.1667L16.1766 21.4893Z"
        fill="#00C4FF"
      />
      <path
        d="M16.199 15.9269L17.4297 15.829L17.6291 15.75L16.4097 15.8403L16.199 15.9269Z"
        fill="#FFE200"
      />
      <path
        d="M12.3716 17.4283L13.2485 17.3644L13.3463 16.9805L12.477 17.0068L12.3716 17.4283Z"
        fill="#C4FF33"
      />
      <path
        d="M15.0588 21.4328L15.2244 21.1016L15.0362 21.4742L14.8518 21.843L15.0588 21.4328Z"
        fill="#00D8FF"
      />
      <path
        d="M16.7751 19.3325L16.6359 19.675L16.5493 20.5256L16.6848 20.217L16.7751 19.3325Z"
        fill="#46FFB1"
      />
      <path
        d="M10.2942 17.4809L10.3808 17.7105L10.4673 17.0104L10.3845 16.7319L10.2942 17.4809Z"
        fill="#C1FF36"
      />
      <path
        d="M15.8491 13.0435L15.9131 13.6343L16.2706 14.5188L16.2104 13.9392L15.8491 13.0435Z"
        fill="#FF6400"
      />
      <path
        d="M14.6714 16.3333L15.8306 16.2693L16.0037 16.0698L14.8558 16.1188L14.6714 16.3333Z"
        fill="#F8F500"
      />
      <path
        d="M15.4429 22.1252L15.217 21.9634L14.9724 22.4414L15.1794 22.6408L15.4429 22.1252Z"
        fill="#00A8FF"
      />
      <path
        d="M16.5491 20.5259L16.3383 20.7366L16.1765 21.4894L16.3797 21.3162L16.5491 20.5259Z"
        fill="#0FF8E7"
      />
      <path
        d="M17.9829 17.21L19.1082 17.0369L19.2023 17.3756L18.0845 17.5713L17.9829 17.21Z"
        fill="#C4FF33"
      />
      <path
        d="M16.4362 19.2125L17.219 18.9565L17.1776 19.3442L16.3948 19.634L16.4362 19.2125Z"
        fill="#63FF94"
      />
      <path
        d="M16.5457 15.4675L16.5645 16.017L16.7451 16.9805L16.7301 16.4498L16.5457 15.4675Z"
        fill="#FFEA00"
      />
      <path
        d="M10.8171 12.7126L10.9338 13.3148L11.3478 12.8104L11.2424 12.1895L10.8171 12.7126Z"
        fill="#FF3000"
      />
      <path
        d="M15.0698 21.7262L15.0585 21.4326L14.8515 21.8391L14.844 22.1703L15.0698 21.7262Z"
        fill="#00C8FF"
      />
      <path
        d="M16.4097 15.8402L17.6291 15.7499L17.8398 15.7273L16.6317 15.8176L16.4097 15.8402Z"
        fill="#FFDB00"
      />
      <path
        d="M15.2128 21.9634L15.0698 21.7263L14.844 22.1704L14.9682 22.4452L15.2128 21.9634Z"
        fill="#00B8FF"
      />
      <path
        d="M15.3714 20.6726L15.7289 20.3564L15.5972 20.7479L15.2246 21.1016L15.3714 20.6726Z"
        fill="#1CFFDB"
      />
      <path
        d="M11.0996 17.9778L11.6152 17.9515L11.6792 17.4547L11.1674 17.4321L11.0996 17.9778Z"
        fill="#ADFF49"
      />
      <path
        d="M12.6165 10.2324L12.7181 10.8534L13.3052 10.94L13.2111 10.319L12.6165 10.2324Z"
        fill="#840000"
      />
      <path
        d="M17.1814 18.1433L18.1562 17.9438L18.19 18.324L17.219 18.5535L17.1814 18.1433Z"
        fill="#97FF60"
      />
      <path
        d="M15.823 19.924L16.3951 19.6379L16.3123 20.0331L15.7327 20.3568L15.823 19.924Z"
        fill="#43FFB4"
      />
      <path
        d="M10.3203 16.382L10.3918 16.7357L10.5574 16.0395L10.4972 15.6443L10.3203 16.382Z"
        fill="#FBF100"
      />
      <path
        d="M17.8516 16.8755L18.9844 16.7212L19.1086 17.0373L17.9833 17.2105L17.8516 16.8755Z"
        fill="#D7FF1F"
      />
      <path
        d="M13.4819 16.6381L14.5132 16.6005L14.6712 16.3333L13.6551 16.3483L13.4819 16.6381Z"
        fill="#EEFF09"
      />
      <path
        d="M10.3843 17.7142L10.5198 17.8685L10.6026 17.2212L10.4708 17.0142L10.3843 17.7142Z"
        fill="#BEFF39"
      />
      <path
        d="M14.8555 16.1189L16.0034 16.07L16.1991 15.927L15.0662 15.9646L14.8555 16.1189Z"
        fill="#FFE600"
      />
      <path
        d="M16.6318 15.8135L17.8399 15.7231L18.0545 15.757L16.8614 15.8511L16.6318 15.8135Z"
        fill="#FFD700"
      />
      <path
        d="M10.5876 13.9397L10.6855 14.4854L11.0205 13.887L10.9377 13.3149L10.5876 13.9397Z"
        fill="#FF6C00"
      />
      <path
        d="M11.6792 17.4512L12.3717 17.4249L12.4771 17.0071L11.7959 16.9883L11.6792 17.4512Z"
        fill="#CAFF2C"
      />
      <path
        d="M10.7456 17.9512L11.0994 17.9738L11.1709 17.4281L10.8209 17.3528L10.7456 17.9512Z"
        fill="#B4FF43"
      />
      <path
        d="M10.4182 15.1814L10.4935 15.6443L10.7456 14.9819L10.6817 14.4851L10.4182 15.1814Z"
        fill="#FFAE00"
      />
      <path
        d="M12.4773 17.0062L13.3467 16.9799L13.4822 16.6374L12.6241 16.6299L12.4773 17.0062Z"
        fill="#DEFF19"
      />
      <path
        d="M15.4163 12.2383L15.4878 12.8405L15.9168 13.6346L15.8528 13.0437L15.4163 12.2383Z"
        fill="#FF2D00"
      />
      <path
        d="M17.6895 16.5776L18.8336 16.4421L18.9841 16.7244L17.8513 16.8787L17.6895 16.5776Z"
        fill="#EBFF0C"
      />
      <path
        d="M15.9206 21.5567L15.6496 21.5266L15.4426 22.125L15.7023 22.1965L15.9206 21.5567Z"
        fill="#00CCFF"
      />
      <path
        d="M11.6077 11.1698L11.7243 11.8059L12.2512 11.5725L12.1459 10.929L11.6077 11.1698Z"
        fill="#BB0000"
      />
      <path
        d="M10.52 17.8685L10.7458 17.9551L10.8211 17.3567L10.6028 17.2212L10.52 17.8685Z"
        fill="#B7FF40"
      />
      <path
        d="M16.8579 15.8517L18.051 15.7576L18.2617 15.8479L17.08 15.9457L16.8579 15.8517Z"
        fill="#FFD700"
      />
      <path d="M16.4365 18.7722L17.2193 18.554V18.9567L16.4365 19.2126V18.7722Z" fill="#7DFF7A" />
      <path
        d="M17.1064 17.7404L18.0887 17.571L18.1565 17.9436L17.1817 18.1431L17.1064 17.7404Z"
        fill="#B1FF46"
      />
      <path
        d="M15.2204 20.9623L15.371 20.6687L15.2242 21.0978L15.0586 21.4289L15.2204 20.9623Z"
        fill="#16FFE1"
      />
      <path
        d="M17.5015 16.3179L18.6569 16.1975L18.83 16.4421L17.6859 16.5776L17.5015 16.3179Z"
        fill="#F8F500"
      />
      <path
        d="M17.0801 15.9494L18.2618 15.8516L18.4651 15.9983L17.2984 16.1075L17.0801 15.9494Z"
        fill="#FFDB00"
      />
      <path
        d="M13.2109 10.3188L13.305 10.9398L13.8921 11.192L13.8056 10.571L13.2109 10.3188Z"
        fill="#890000"
      />
      <path
        d="M17.2983 16.1077L18.465 15.9985L18.657 16.198L17.5016 16.3184L17.2983 16.1077Z"
        fill="#FFE600"
      />
      <path
        d="M16.7449 16.9805L16.6885 17.4622L16.775 18.4031L16.8315 17.9515L16.7449 16.9805Z"
        fill="#C1FF36"
      />
      <path
        d="M15.0662 15.9643L16.199 15.9266L16.4098 15.8401L15.292 15.8702L15.0662 15.9643Z"
        fill="#FFD700"
      />
      <path
        d="M13.6553 16.3483L14.6714 16.3333L14.8559 16.1188L13.8547 16.115L13.6553 16.3483Z"
        fill="#FFEA00"
      />
      <path
        d="M15.8682 19.4607L16.4365 19.2161L16.3951 19.6376L15.823 19.9236L15.8682 19.4607Z"
        fill="#60FF97"
      />
      <path
        d="M15.4726 20.1947L15.8226 19.9199L15.7323 20.3527L15.3748 20.6689L15.4726 20.1947Z"
        fill="#3CFFBA"
      />
      <path
        d="M14.9233 11.5459L14.9986 12.1556L15.4879 12.8406L15.4164 12.2384L14.9233 11.5459Z"
        fill="#E40000"
      />
      <path
        d="M15.6461 21.5267L15.4052 21.4062L15.217 21.9595L15.4429 22.1213L15.6461 21.5267Z"
        fill="#00D8FF"
      />
      <path
        d="M16.3345 20.7363L16.0673 20.8492L15.9167 21.5606L16.1727 21.489L16.3345 20.7363Z"
        fill="#16FFE1"
      />
      <path
        d="M15.2469 21.2146L15.2206 20.9624L15.0625 21.4328L15.0738 21.7264L15.2469 21.2146Z"
        fill="#0CF4EB"
      />
      <path
        d="M11.1709 17.4319L11.6827 17.4545L11.7957 16.9916L11.2951 16.9238L11.1709 17.4319Z"
        fill="#D1FF26"
      />
      <path
        d="M13.8022 10.5713L13.8888 11.1923L14.4571 11.6025L14.3743 10.989L13.8022 10.5713Z"
        fill="#9B0000"
      />
      <path
        d="M10.3879 16.736L10.4707 17.0145L10.6288 16.3671L10.5573 16.0435L10.3879 16.736Z"
        fill="#F4F802"
      />
      <path
        d="M16.9932 17.3529L17.983 17.2136L18.0846 17.5712L17.1023 17.7405L16.9932 17.3529Z"
        fill="#C7FF30"
      />
      <path
        d="M16.2708 14.519L16.2933 15.0911L16.5605 16.0169L16.5417 15.4675L16.2708 14.519Z"
        fill="#FFA700"
      />
      <path
        d="M14.3774 10.9851L14.4602 11.5986L14.9984 12.1518L14.9232 11.5421L14.3774 10.9851Z"
        fill="#BB0000"
      />
      <path
        d="M15.4052 21.4101L15.2471 21.2144L15.074 21.7262L15.217 21.9633L15.4052 21.4101Z"
        fill="#02E8F4"
      />
      <path d="M16.775 18.407L16.6357 18.7871V19.6753L16.775 19.3328V18.407Z" fill="#80FF77" />
      <path
        d="M12.6238 16.6341L13.4819 16.6416L13.6512 16.3481L12.8082 16.3142L12.6238 16.6341Z"
        fill="#F8F500"
      />
      <path
        d="M16.3948 18.3242L17.1814 18.1436L17.219 18.5538L16.4362 18.7721L16.3948 18.3242Z"
        fill="#9AFF5D"
      />
      <path
        d="M16.636 19.675L16.4178 19.9272L16.335 20.7364L16.5457 20.5256L16.636 19.675Z"
        fill="#49FFAD"
      />
      <path
        d="M15.2922 15.8702L16.41 15.8401L16.6321 15.8137L15.5331 15.8401L15.2922 15.8702Z"
        fill="#FFD000"
      />
      <path
        d="M11.7959 16.992L12.4771 17.007L12.6239 16.6307L11.954 16.5742L11.7959 16.992Z"
        fill="#E7FF0F"
      />
      <path
        d="M11.2424 12.1892L11.3478 12.8102L11.8183 12.4414L11.7279 11.8091L11.2424 12.1892Z"
        fill="#F10800"
      />
      <path
        d="M10.821 17.3565L11.1711 17.4317L11.2953 16.9237L10.9565 16.8032L10.821 17.3565Z"
        fill="#DBFF1C"
      />
      <path
        d="M16.8464 16.9918L17.8475 16.8789L17.983 17.2139L16.9932 17.3531L16.8464 16.9918Z"
        fill="#DEFF19"
      />
      <path
        d="M13.8545 16.1147L14.8556 16.1184L15.0664 15.9641L14.0841 15.9453L13.8545 16.1147Z"
        fill="#FFD700"
      />
      <path
        d="M10.4712 17.0143L10.6029 17.2213L10.7497 16.6191L10.6293 16.3669L10.4712 17.0143Z"
        fill="#EBFF0C"
      />
      <path
        d="M12.1458 10.9286L12.2511 11.5722L12.8119 11.4969L12.7178 10.8496L12.1458 10.9286Z"
        fill="#9F0000"
      />
      <path
        d="M15.33 20.4426L15.473 20.198L15.3752 20.6722L15.2246 20.9658L15.33 20.4426Z"
        fill="#36FFC1"
      />
      <path
        d="M10.4976 15.6446L10.5578 16.0397L10.7949 15.415L10.7497 14.9822L10.4976 15.6446Z"
        fill="#FFB900"
      />
      <path
        d="M15.5293 15.8437L16.6283 15.8174L16.8541 15.855L15.7702 15.8851L15.5293 15.8437Z"
        fill="#FFCC00"
      />
      <path d="M15.8679 18.9752L16.4362 18.772V19.2123L15.8679 19.4569V18.9752Z" fill="#80FF77" />
      <path
        d="M10.6025 17.2213L10.8208 17.3568L10.9563 16.8036L10.7456 16.6191L10.6025 17.2213Z"
        fill="#E1FF16"
      />
      <path
        d="M16.6736 16.6645L17.6898 16.5742L17.8516 16.8753L16.8505 16.9882L16.6736 16.6645Z"
        fill="#F1FC06"
      />
      <path
        d="M15.5218 19.6865L15.8681 19.4607L15.8229 19.9236L15.4729 20.1984L15.5218 19.6865Z"
        fill="#5DFF9A"
      />
      <path
        d="M10.9343 13.3146L11.0171 13.8867L11.4161 13.4049L11.3483 12.8103L10.9343 13.3146Z"
        fill="#FF3B00"
      />
      <path
        d="M16.0712 20.8489L15.7889 20.8639L15.6497 21.5301L15.9206 21.5602L16.0712 20.8489Z"
        fill="#1CFFDB"
      />
      <path
        d="M16.3086 17.8835L17.1065 17.7405L17.1817 18.1432L16.3952 18.3238L16.3086 17.8835Z"
        fill="#B7FF40"
      />
      <path
        d="M15.7739 15.8817L16.8578 15.8516L17.0837 15.9494L16.0186 15.9871L15.7739 15.8817Z"
        fill="#FFCC00"
      />
      <path
        d="M10.6816 14.4854L10.7456 14.9822L11.0655 14.4139L11.0166 13.887L10.6816 14.4854Z"
        fill="#FF7A00"
      />
      <path
        d="M16.47 16.3859L17.5012 16.3181L17.6856 16.5778L16.6694 16.6681L16.47 16.3859Z"
        fill="#FFEA00"
      />
      <path
        d="M12.8123 16.3105L13.6553 16.3443L13.8548 16.111L13.0305 16.0508L12.8123 16.3105Z"
        fill="#FFDB00"
      />
      <path
        d="M16.0146 15.9869L17.0797 15.9492L17.298 16.1073L16.2518 16.16L16.0146 15.9869Z"
        fill="#FFD000"
      />
      <path
        d="M11.2954 16.9238L11.796 16.9915L11.9578 16.5775L11.4723 16.4722L11.2954 16.9238Z"
        fill="#F4F802"
      />
      <path
        d="M16.252 16.1557L17.2982 16.103L17.5015 16.3138L16.4702 16.3815L16.252 16.1557Z"
        fill="#FFDB00"
      />
      <path
        d="M14.0806 15.9416L15.0629 15.9604L15.2887 15.8663L14.3252 15.8362L14.0806 15.9416Z"
        fill="#FFC800"
      />
      <path
        d="M15.9167 13.6348L15.9469 14.2256L16.2969 15.0913L16.2743 14.5192L15.9167 13.6348Z"
        fill="#FF6C00"
      />
      <path
        d="M16.5643 16.0171L16.5154 16.5289L16.6923 17.4623L16.7487 16.9806L16.5643 16.0171Z"
        fill="#FBF100"
      />
      <path
        d="M15.3635 20.6459L15.3296 20.4426L15.2205 20.962L15.2468 21.2142L15.3635 20.6459Z"
        fill="#30FFC7"
      />
      <path
        d="M11.9536 16.5778L12.6235 16.6342L12.8079 16.3143L12.1568 16.2278L11.9536 16.5778Z"
        fill="#FFE600"
      />
      <path
        d="M15.7849 20.8647L15.5327 20.7932L15.4048 21.4104L15.6457 21.5309L15.7849 20.8647Z"
        fill="#23FFD4"
      />
      <path
        d="M15.5332 20.793L15.3638 20.6462L15.2434 21.2145L15.4015 21.4102L15.5332 20.793Z"
        fill="#29FFCE"
      />
      <path
        d="M16.1882 17.4619L16.9974 17.3528L17.1103 17.7404L16.3124 17.8834L16.1882 17.4619Z"
        fill="#D1FF26"
      />
      <path
        d="M15.8191 18.4862L16.3949 18.3281L16.4363 18.776L15.868 18.9792L15.8191 18.4862Z"
        fill="#9DFF5A"
      />
      <path
        d="M10.5537 16.0398L10.6252 16.3635L10.8473 15.7801L10.7908 15.415L10.5537 16.0398Z"
        fill="#FFC400"
      />
      <path
        d="M16.4173 19.9272L16.1463 20.0853L16.071 20.8493L16.3383 20.7364L16.4173 19.9272Z"
        fill="#4DFFAA"
      />
      <path
        d="M12.7183 10.8533L12.8124 11.5006L13.3882 11.5872L13.3054 10.9436L12.7183 10.8533Z"
        fill="#920000"
      />
      <path
        d="M14.3286 15.8401L15.2921 15.8702L15.5292 15.8438L14.5845 15.8137L14.3286 15.8401Z"
        fill="#FFC100"
      />
      <path
        d="M10.9565 16.8037L11.2953 16.9241L11.4684 16.4725L11.1447 16.3069L10.9565 16.8037Z"
        fill="#FEED00"
      />
      <path
        d="M15.3823 19.8822L15.5215 19.6865L15.4726 20.1984L15.3296 20.443L15.3823 19.8822Z"
        fill="#5AFF9D"
      />
      <path
        d="M11.7241 11.8052L11.8144 12.4375L12.3263 12.2117L12.251 11.5681L11.7241 11.8052Z"
        fill="#C80000"
      />
      <path
        d="M16.6885 17.4622L16.553 17.8799L16.6358 18.7832L16.775 18.4031L16.6885 17.4622Z"
        fill="#BEFF39"
      />
      <path d="M15.5178 19.1557L15.8641 18.9751V19.4606L15.5178 19.6864V19.1557Z" fill="#80FF77" />
      <path
        d="M13.0303 16.0545L13.8545 16.1147L14.0803 15.9454L13.2787 15.8701L13.0303 16.0545Z"
        fill="#FFC800"
      />
      <path d="M16.6358 18.7832L16.4175 19.0768V19.9236L16.6358 19.6714V18.7832Z" fill="#80FF77" />
      <path
        d="M16.0256 17.0667L16.8461 16.9915L16.9929 17.3528L16.1837 17.4619L16.0256 17.0667Z"
        fill="#E7FF0F"
      />
      <path
        d="M10.625 16.3634L10.7454 16.6155L10.9524 16.0774L10.8471 15.78L10.625 16.3634Z"
        fill="#FFD300"
      />
      <path
        d="M15.488 12.8408L15.5257 13.4468L15.9472 14.2258L15.9171 13.6349L15.488 12.8408Z"
        fill="#FF3400"
      />
      <path
        d="M10.7456 16.6191L10.9564 16.8035L11.1483 16.3067L10.9564 16.0771L10.7456 16.6191Z"
        fill="#FFDE00"
      />
      <path
        d="M14.5884 15.8137L15.533 15.8438L15.7777 15.8852L14.8556 15.8589L14.5884 15.8137Z"
        fill="#FFB900"
      />
      <path
        d="M11.4685 16.4725L11.954 16.5778L12.1572 16.2241L11.6906 16.0811L11.4685 16.4725Z"
        fill="#FFD700"
      />
      <path
        d="M12.1572 16.2242L12.8083 16.3107L13.0266 16.0548L12.3943 15.9419L12.1572 16.2242Z"
        fill="#FFCC00"
      />
      <path
        d="M10.7456 14.9821L10.7908 15.4149L11.0919 14.8805L11.0617 14.4138L10.7456 14.9821Z"
        fill="#FF8600"
      />
      <path
        d="M15.7251 18.0005L16.3085 17.8838L16.3913 18.3241L15.8154 18.4822L15.7251 18.0005Z"
        fill="#BAFF3C"
      />
      <path
        d="M13.3047 10.9397L13.3875 11.5833L13.9633 11.8317L13.8918 11.1919L13.3047 10.9397Z"
        fill="#960000"
      />
      <path
        d="M15.834 16.7095L16.6733 16.6643L16.8502 16.988L16.0297 17.0632L15.834 16.7095Z"
        fill="#FBF100"
      />
      <path
        d="M15.4237 20.0366L15.3823 19.8823L15.3296 20.4431L15.3635 20.6463L15.4237 20.0366Z"
        fill="#56FFA0"
      />
      <path
        d="M16.1465 20.0854L15.8567 20.1494L15.7852 20.8645L16.0674 20.8495L16.1465 20.0854Z"
        fill="#4DFFAA"
      />
      <path
        d="M11.3479 12.8102L11.4156 13.4049L11.8673 13.0511L11.8183 12.4414L11.3479 12.8102Z"
        fill="#FF1300"
      />
      <path
        d="M14.8518 15.8552L15.7739 15.8816L16.0148 15.9869L15.1153 15.9681L14.8518 15.8552Z"
        fill="#FFBD00"
      />
      <path
        d="M16.2968 15.0911L16.2554 15.633L16.5151 16.5325L16.564 16.0207L16.2968 15.0911Z"
        fill="#FFAE00"
      />
      <path
        d="M14.9985 12.1555L15.0475 12.7765L15.5254 13.4464L15.4878 12.8405L14.9985 12.1555Z"
        fill="#F10800"
      />
      <path
        d="M13.2783 15.8663L14.08 15.9416L14.3246 15.84L13.5455 15.7534L13.2783 15.8663Z"
        fill="#FFB900"
      />
      <path
        d="M15.6157 16.4048L16.4738 16.386L16.6733 16.6645L15.834 16.7097L15.6157 16.4048Z"
        fill="#FFDB00"
      />
      <path
        d="M11.0168 13.8868L11.0658 14.4137L11.4459 13.9545L11.4158 13.405L11.0168 13.8868Z"
        fill="#FF4A00"
      />
      <path
        d="M15.1152 15.9722L16.0147 15.991L16.2481 16.1603L15.3712 16.1566L15.1152 15.9722Z"
        fill="#FFC100"
      />
      <path
        d="M15.3713 16.1562L16.2483 16.16L16.4703 16.3858L15.6122 16.4046L15.3713 16.1562Z"
        fill="#FFCC00"
      />
      <path
        d="M13.8923 11.1919L13.9638 11.8317L14.5209 12.2306L14.4606 11.5984L13.8923 11.1919Z"
        fill="#A40000"
      />
      <path
        d="M15.4656 18.6139L15.8194 18.4822L15.8683 18.9714L15.522 19.1521L15.4656 18.6139Z"
        fill="#A0FF56"
      />
      <path
        d="M14.4604 11.6023L14.5207 12.2346L15.0476 12.7765L14.9986 12.1555L14.4604 11.6023Z"
        fill="#C40000"
      />
      <path
        d="M15.597 20.1305L15.4201 20.0364L15.3599 20.6461L15.5292 20.7929L15.597 20.1305Z"
        fill="#53FFA4"
      />
      <path d="M15.3828 19.2991L15.5221 19.1523V19.683L15.3828 19.8787V19.2991Z" fill="#80FF77" />
      <path
        d="M15.8569 20.1492L15.5972 20.1304L15.5332 20.7928L15.7854 20.8643L15.8569 20.1492Z"
        fill="#50FFA7"
      />
      <path
        d="M15.5894 17.5338L16.1878 17.4585L16.312 17.8838L15.7286 18.0005L15.5894 17.5338Z"
        fill="#D7FF1F"
      />
      <path
        d="M11.1448 16.3065L11.4684 16.4721L11.6905 16.0845L11.3856 15.885L11.1448 16.3065Z"
        fill="#FFC800"
      />
      <path
        d="M12.2551 11.5719L12.3304 12.2154L12.8724 12.1439L12.8121 11.4966L12.2551 11.5719Z"
        fill="#AD0000"
      />
      <path
        d="M10.7908 15.4147L10.8472 15.7797L11.1295 15.2792L11.0919 14.8765L10.7908 15.4147Z"
        fill="#FF9800"
      />
      <path
        d="M12.3979 15.9416L13.0302 16.0545L13.2786 15.8663L12.6689 15.7346L12.3979 15.9416Z"
        fill="#FFB600"
      />
      <path d="M16.4211 19.0806L16.1501 19.2876V20.0854L16.4211 19.9274V19.0806Z" fill="#80FF77" />
      <path
        d="M13.5498 15.757L14.3289 15.8436L14.5886 15.8135L13.8321 15.7231L13.5498 15.757Z"
        fill="#FFAE00"
      />
      <path
        d="M11.6907 16.085L12.1574 16.228L12.3982 15.9457L11.9541 15.7764L11.6907 16.085Z"
        fill="#FFB900"
      />
      <path
        d="M16.5116 16.5291L16.3799 16.9844L16.5492 17.8802L16.6847 17.4624L16.5116 16.5291Z"
        fill="#F8F500"
      />
      <path
        d="M10.9526 16.0772L11.1446 16.3068L11.3854 15.8815L11.2123 15.6143L10.9526 16.0772Z"
        fill="#FFB600"
      />
      <path
        d="M10.8472 15.7835L10.9525 16.0808L11.216 15.6179L11.1332 15.283L10.8472 15.7835Z"
        fill="#FFA700"
      />
      <path
        d="M16.5529 17.8801L16.3384 18.2188L16.4212 19.0807L16.6395 18.7871L16.5529 17.8801Z"
        fill="#BAFF3C"
      />
      <path
        d="M15.4126 17.1005L16.0261 17.0667L16.1879 17.4618L15.5895 17.5371L15.4126 17.1005Z"
        fill="#F1FC06"
      />
      <path
        d="M15.3638 18.0833L15.7251 18.0005L15.8192 18.486L15.4654 18.6177L15.3638 18.0833Z"
        fill="#C1FF36"
      />
      <path d="M15.4201 19.4006L15.3787 19.3027V19.8861L15.4201 20.0404V19.4006Z" fill="#80FF77" />
      <path
        d="M15.947 14.2251L15.9131 14.7934L16.2518 15.6327L16.2932 15.0907L15.947 14.2251Z"
        fill="#FF7300"
      />
      <path
        d="M13.8315 15.7231L14.588 15.8135L14.8515 15.8586L14.1213 15.7758L13.8315 15.7231Z"
        fill="#FFA700"
      />
      <path
        d="M11.0657 14.4138L11.0958 14.8805L11.4533 14.4477L11.4458 13.9509L11.0657 14.4138Z"
        fill="#FF5D00"
      />
      <path
        d="M15.3223 18.7081L15.4653 18.614L15.518 19.1522L15.3787 19.299L15.3223 18.7081Z"
        fill="#A4FF53"
      />
      <path
        d="M11.8145 12.4377L11.8634 13.0474L12.3564 12.8329L12.3263 12.2119L11.8145 12.4377Z"
        fill="#DA0000"
      />
      <path
        d="M15.2021 16.7095H15.8344L16.0264 17.0633L15.4129 17.0971L15.2021 16.7095Z"
        fill="#FFE200"
      />
      <path
        d="M12.6689 15.7348L13.2786 15.8665L13.5496 15.7536L12.9663 15.6106L12.6689 15.7348Z"
        fill="#FFA300"
      />
      <path d="M16.1462 19.2874L15.8564 19.404V20.1492L16.1462 20.0852V19.2874Z" fill="#80FF77" />
      <path
        d="M12.8123 11.4966L12.8725 12.1439L13.4295 12.2305L13.3881 11.5869L12.8123 11.4966Z"
        fill="#A40000"
      />
      <path
        d="M14.1218 15.7725L14.852 15.8553L15.1154 15.9719L14.4116 15.9004L14.1218 15.7725Z"
        fill="#FFAB00"
      />
      <path
        d="M11.3855 15.8815L11.6903 16.081L11.9538 15.7686L11.6753 15.5391L11.3855 15.8815Z"
        fill="#FFA700"
      />
      <path
        d="M15.5971 19.438L15.4202 19.4004L15.4239 20.0364L15.6008 20.1305L15.5971 19.438Z"
        fill="#80FF77"
      />
      <path
        d="M11.4158 13.405L11.4459 13.9545L11.8749 13.6196L11.8674 13.0513L11.4158 13.405Z"
        fill="#FF2500"
      />
      <path
        d="M14.9609 16.3745L15.6158 16.4046L15.8341 16.7095H15.2018L14.9609 16.3745Z"
        fill="#FFCC00"
      />
      <path
        d="M14.4116 15.897L15.1154 15.9685L15.3713 16.1529L14.6939 16.0964L14.4116 15.897Z"
        fill="#FFAE00"
      />
      <path d="M15.8566 19.4038L15.5969 19.4377V20.1302L15.8566 20.149V19.4038Z" fill="#80FF77" />
      <path
        d="M11.9536 15.7725L12.3977 15.9419L12.6687 15.7349L12.2509 15.543L11.9536 15.7725Z"
        fill="#FF9F00"
      />
      <path
        d="M14.6936 16.0996L15.371 16.1561L15.6119 16.4045L14.9571 16.3744L14.6936 16.0996Z"
        fill="#FFBD00"
      />
      <path
        d="M15.2131 17.5712L15.5895 17.5374L15.725 18.004L15.3637 18.0868L15.2131 17.5712Z"
        fill="#E1FF16"
      />
      <path
        d="M15.5252 13.446L15.5063 14.0369L15.9128 14.7934L15.9467 14.2251L15.5252 13.446Z"
        fill="#FF3F00"
      />
      <path
        d="M11.092 14.8806L11.1297 15.2833L11.4646 14.8806L11.4496 14.4478L11.092 14.8806Z"
        fill="#FF6F00"
      />
      <path
        d="M16.2518 15.6331L16.1313 16.1223L16.3835 16.9842L16.5152 16.5288L16.2518 15.6331Z"
        fill="#FFB600"
      />
      <path
        d="M12.9661 15.6104L13.5494 15.7534L13.8317 15.7195L13.2784 15.5728L12.9661 15.6104Z"
        fill="#FF9800"
      />
      <path
        d="M11.2124 15.6182L11.3855 15.8854L11.6716 15.5429L11.521 15.2456L11.2124 15.6182Z"
        fill="#FF9400"
      />
      <path
        d="M16.3383 18.2188L16.071 18.4747L16.1501 19.2876L16.4211 19.0806L16.3383 18.2188Z"
        fill="#B7FF40"
      />
      <path
        d="M13.3879 11.5874L13.4293 12.231L13.9863 12.4718L13.96 11.832L13.3879 11.5874Z"
        fill="#A40000"
      />
      <path
        d="M15.3561 18.7532L15.3223 18.708L15.3787 19.2989L15.4201 19.3967L15.3561 18.7532Z"
        fill="#A7FF50"
      />
      <path
        d="M15.2095 18.1244L15.3638 18.083L15.4654 18.6174L15.3224 18.7115L15.2095 18.1244Z"
        fill="#C7FF30"
      />
      <path
        d="M11.1294 15.2831L11.2122 15.618L11.5246 15.2454L11.4644 14.8804L11.1294 15.2831Z"
        fill="#FF8200"
      />
      <path
        d="M16.3835 16.9841L16.1765 17.368L16.3383 18.2186L16.5529 17.8799L16.3835 16.9841Z"
        fill="#F1FC06"
      />
      <path
        d="M15.0173 17.093L15.4125 17.1005L15.5894 17.5334L15.213 17.5672L15.0173 17.093Z"
        fill="#FBF100"
      />
      <path
        d="M15.0476 12.7766L15.04 13.3863L15.5067 14.0374L15.5255 13.4465L15.0476 12.7766Z"
        fill="#FF1300"
      />
      <path
        d="M12.3303 12.2156L12.3604 12.8365L12.8836 12.7688L12.876 12.144L12.3303 12.2156Z"
        fill="#C40000"
      />
      <path
        d="M13.9636 11.8318L13.99 12.4716L14.5319 12.8592L14.5206 12.2307L13.9636 11.8318Z"
        fill="#B60000"
      />
      <path
        d="M13.2783 15.5767L13.8316 15.7234L14.1214 15.7724L13.5945 15.6294L13.2783 15.5767Z"
        fill="#FF9400"
      />
      <path
        d="M12.251 15.5427L12.6687 15.7347L12.9623 15.6105L12.5709 15.4072L12.251 15.5427Z"
        fill="#FF8D00"
      />
      <path
        d="M11.6753 15.5389L11.9538 15.7685L12.2511 15.5427L11.999 15.2905L11.6753 15.5389Z"
        fill="#FF8D00"
      />
      <path
        d="M14.5208 12.2349L14.532 12.8634L15.0439 13.3903L15.0514 12.7806L14.5208 12.2349Z"
        fill="#D10000"
      />
      <path
        d="M11.4456 13.9546L11.4531 14.4514L11.8596 14.1352L11.8746 13.6196L11.4456 13.9546Z"
        fill="#FF3800"
      />
      <path
        d="M15.5292 18.7344L15.3599 18.7532L15.4238 19.4005L15.6007 19.4382L15.5292 18.7344Z"
        fill="#ADFF49"
      />
      <path
        d="M16.0712 18.4746L15.7852 18.644L15.8567 19.4042L16.1465 19.2875L16.0712 18.4746Z"
        fill="#B4FF43"
      />
      <path
        d="M14.7842 16.6643L15.2019 16.7132L15.4127 17.1046L15.0175 17.0971L14.7842 16.6643Z"
        fill="#FFD300"
      />
      <path
        d="M11.8674 13.0515L11.875 13.6198L12.3454 13.4166L12.3605 12.8333L11.8674 13.0515Z"
        fill="#F10800"
      />
      <path
        d="M13.595 15.6294L14.1219 15.7724L14.4117 15.9004L13.9149 15.7724L13.595 15.6294Z"
        fill="#FF9400"
      />
      <path
        d="M15.7852 18.644L15.5293 18.7344L15.597 19.4382L15.8567 19.4043L15.7852 18.644Z"
        fill="#B1FF46"
      />
      <path
        d="M15.0437 17.5603L15.2131 17.5716L15.3636 18.0834L15.2093 18.1248L15.0437 17.5603Z"
        fill="#EBFF0C"
      />
      <path
        d="M15.9133 14.7939L15.8042 15.3171L16.1316 16.1225L16.2521 15.6332L15.9133 14.7939Z"
        fill="#FF7E00"
      />
      <path
        d="M14.5168 16.2917L14.9572 16.3745L15.1981 16.7095L14.7803 16.6606L14.5168 16.2917Z"
        fill="#FFB900"
      />
      <path
        d="M13.9146 15.7686L14.4113 15.8965L14.6936 16.0998L14.2232 15.9906L13.9146 15.7686Z"
        fill="#FF9C00"
      />
      <path
        d="M15.2319 18.113L15.2056 18.1243L15.3185 18.7077L15.3523 18.7528L15.2319 18.113Z"
        fill="#CEFF29"
      />
      <path
        d="M14.2271 15.9907L14.6975 16.0999L14.9609 16.3746L14.5206 16.2918L14.2271 15.9907Z"
        fill="#FFA700"
      />
      <path
        d="M11.5249 15.2451L11.6754 15.5424L11.9991 15.294L11.8787 14.9741L11.5249 15.2451Z"
        fill="#FF7700"
      />
      <path
        d="M12.5745 15.4115L12.9659 15.6147L13.2783 15.5771L12.917 15.3701L12.5745 15.4115Z"
        fill="#FF8200"
      />
      <path
        d="M11.4531 14.4515L11.4682 14.8843L11.8483 14.5908L11.8596 14.1392L11.4531 14.4515Z"
        fill="#FF4A00"
      />
      <path
        d="M12.8721 12.144L12.8796 12.7688L13.4178 12.8516L13.4291 12.2306L12.8721 12.144Z"
        fill="#B60000"
      />
      <path
        d="M16.1764 17.3682L15.9167 17.673L16.0711 18.4747L16.3383 18.2187L16.1764 17.3682Z"
        fill="#EBFF0C"
      />
      <path
        d="M11.4646 14.8806L11.5248 15.2457L11.8786 14.9747L11.8447 14.5908L11.4646 14.8806Z"
        fill="#FF6000"
      />
      <path
        d="M11.9988 15.2904L12.2509 15.5426L12.5746 15.4071L12.3526 15.1399L11.9988 15.2904Z"
        fill="#FF7700"
      />
      <path
        d="M16.1315 16.1221L15.9358 16.5474L16.1767 17.3678L16.3837 16.9839L16.1315 16.1221Z"
        fill="#FFC100"
      />
      <path
        d="M14.8291 17.0369L15.0173 17.0933L15.213 17.5713L15.0436 17.56L14.8291 17.0369Z"
        fill="#FFE200"
      />
      <path
        d="M15.3938 18.0378L15.2319 18.1093L15.3561 18.7492L15.5255 18.7303L15.3938 18.0378Z"
        fill="#D7FF1F"
      />
      <path
        d="M15.5067 14.0374L15.4126 14.5906L15.804 15.317L15.9132 14.7938L15.5067 14.0374Z"
        fill="#FF4A00"
      />
      <path
        d="M12.9172 15.3701L13.2785 15.5771L13.5984 15.6298L13.2672 15.4266L12.9172 15.3701Z"
        fill="#FF7A00"
      />
      <path
        d="M11.8748 13.6194L11.856 14.135L12.3001 13.9468L12.3452 13.4199L11.8748 13.6194Z"
        fill="#FF1A00"
      />
      <path
        d="M12.3603 12.8364L12.3452 13.4198L12.842 13.3596L12.8834 12.7725L12.3603 12.8364Z"
        fill="#DA0000"
      />
      <path
        d="M15.0512 17.4961L15.0437 17.5601L15.2093 18.1208L15.2356 18.1096L15.0512 17.4961Z"
        fill="#F4F802"
      />
      <path
        d="M15.9167 17.6729L15.6382 17.8949L15.7812 18.6439L16.0672 18.4745L15.9167 17.6729Z"
        fill="#E4FF13"
      />
      <path
        d="M13.4293 12.231L13.418 12.8519L13.9524 13.0853L13.9863 12.4718L13.4293 12.231Z"
        fill="#BB0000"
      />
      <path
        d="M14.5732 16.5627L14.784 16.6606L15.0173 17.0896L14.8292 17.0332L14.5732 16.5627Z"
        fill="#FFC100"
      />
      <path
        d="M15.6384 17.895L15.3938 18.038L15.5293 18.7343L15.7852 18.644L15.6384 17.895Z"
        fill="#DEFF19"
      />
      <path
        d="M11.8784 14.9745L11.9989 15.2944L12.3526 15.1476L12.2623 14.8164L11.8784 14.9745Z"
        fill="#FF6000"
      />
      <path
        d="M13.2673 15.4258L13.5985 15.629L13.9184 15.7683L13.6211 15.5801L13.2673 15.4258Z"
        fill="#FF7E00"
      />
      <path
        d="M12.353 15.1438L12.5751 15.411L12.9176 15.3696L12.7256 15.0986L12.353 15.1438Z"
        fill="#FF6C00"
      />
      <path
        d="M15.0399 13.3862L14.9646 13.9658L15.4125 14.5906L15.5066 14.0373L15.0399 13.3862Z"
        fill="#FF1E00"
      />
      <path
        d="M14.2798 16.1562L14.5169 16.2917L14.7841 16.6606L14.5733 16.5627L14.2798 16.1562Z"
        fill="#FFA700"
      />
      <path
        d="M13.9899 12.4717L13.9561 13.0851L14.4754 13.4577L14.5319 12.8593L13.9899 12.4717Z"
        fill="#C80000"
      />
      <path
        d="M13.6174 15.5803L13.9148 15.7685L14.2234 15.9906L13.9562 15.825L13.6174 15.5803Z"
        fill="#FF8600"
      />
      <path
        d="M11.856 14.1353L11.8447 14.5869L12.2587 14.41L12.3001 13.9434L11.856 14.1353Z"
        fill="#FF3000"
      />
      <path
        d="M15.8076 15.3169L15.627 15.7798L15.9356 16.5438L16.1313 16.1185L15.8076 15.3169Z"
        fill="#FF8900"
      />
      <path
        d="M13.9561 15.8252L14.2233 15.9908L14.5168 16.2919L14.2797 16.1564L13.9561 15.8252Z"
        fill="#FF9400"
      />
      <path
        d="M14.532 12.8594L14.4756 13.4578L14.9649 13.9659L15.0401 13.3863L14.532 12.8594Z"
        fill="#E40000"
      />
      <path
        d="M15.9359 16.5479L15.6875 16.8979L15.9133 17.6694L16.173 17.3645L15.9359 16.5479Z"
        fill="#FFC800"
      />
      <path
        d="M11.8447 14.5866L11.8786 14.9704L12.2625 14.8086V14.4097L11.8447 14.5866Z"
        fill="#FF4A00"
      />
      <path
        d="M14.8179 16.9238L14.8292 17.0367L15.0437 17.5599L15.0512 17.4959L14.8179 16.9238Z"
        fill="#FFD300"
      />
      <path
        d="M15.1982 17.3682L15.0552 17.4961L15.2358 18.1096L15.3977 18.0381L15.1982 17.3682Z"
        fill="#FEED00"
      />
      <path
        d="M12.8832 12.769L12.8418 13.3562L13.3536 13.4352L13.4176 12.8518L12.8832 12.769Z"
        fill="#CD0000"
      />
      <path
        d="M12.7253 15.0986L12.9173 15.3696L13.2673 15.4261L13.1092 15.1626L12.7253 15.0986Z"
        fill="#FF6400"
      />
      <path
        d="M12.3455 13.416L12.3003 13.9429L12.7707 13.8865L12.8422 13.3521L12.3455 13.416Z"
        fill="#F10800"
      />
      <path
        d="M15.6912 16.8975L15.4277 17.1722L15.6385 17.8948L15.917 17.6728L15.6912 16.8975Z"
        fill="#FFD300"
      />
      <path
        d="M12.2625 14.8126L12.3528 15.1438L12.7254 15.0986L12.6689 14.7637L12.2625 14.8126Z"
        fill="#FF5200"
      />
      <path
        d="M15.4278 17.1724L15.1982 17.3718L15.3939 18.0417L15.6386 17.8987L15.4278 17.1724Z"
        fill="#FFE200"
      />
      <path
        d="M14.5356 16.4048L14.5695 16.5629L14.8254 17.0333L14.8141 16.9204L14.5356 16.4048Z"
        fill="#FFB200"
      />
      <path
        d="M15.4128 14.5908L15.251 15.0914L15.6236 15.7801L15.8042 15.3172L15.4128 14.5908Z"
        fill="#FF5900"
      />
      <path
        d="M13.1089 15.1624L13.267 15.4258L13.617 15.5801L13.4928 15.328L13.1089 15.1624Z"
        fill="#FF6400"
      />
      <path
        d="M12.3002 13.9471L12.2588 14.4138L12.6954 14.3611L12.7669 13.8906L12.3002 13.9471Z"
        fill="#FF2200"
      />
      <path
        d="M14.9421 16.7471L14.8179 16.924L15.0512 17.4998L15.1942 17.3718L14.9421 16.7471Z"
        fill="#FFC400"
      />
      <path
        d="M12.2588 14.4138V14.8127L12.6653 14.7638L12.6954 14.3611L12.2588 14.4138Z"
        fill="#FF3800"
      />
      <path
        d="M15.6268 15.78L15.3972 16.1752L15.6908 16.8978L15.9392 16.5478L15.6268 15.78Z"
        fill="#FF9400"
      />
      <path
        d="M13.418 12.8518L13.354 13.4352L13.8659 13.6572L13.9562 13.0851L13.418 12.8518Z"
        fill="#D10000"
      />
      <path
        d="M14.2156 15.9604L14.2796 16.1562L14.5731 16.5626L14.5392 16.4046L14.2156 15.9604Z"
        fill="#FF9400"
      />
      <path
        d="M13.4929 15.3281L13.6171 15.5803L13.9558 15.8249L13.8618 15.5991L13.4929 15.3281Z"
        fill="#FF6C00"
      />
      <path
        d="M13.8657 15.5991L13.9598 15.8249L14.2797 16.1561L14.2157 15.9604L13.8657 15.5991Z"
        fill="#FF7E00"
      />
      <path
        d="M14.9647 13.9658L14.8254 14.4965L15.2507 15.0911L15.4126 14.5906L14.9647 13.9658Z"
        fill="#FF3000"
      />
      <path
        d="M12.6689 14.7637L12.7254 15.0986L13.1093 15.1626L13.0829 14.8314L12.6689 14.7637Z"
        fill="#FF4A00"
      />
      <path
        d="M12.8418 13.356L12.7703 13.8904L13.252 13.9657L13.3536 13.435L12.8418 13.356Z"
        fill="#E80000"
      />
      <path
        d="M13.956 13.085L13.8657 13.657L14.3625 14.0146L14.4792 13.4613L13.956 13.085Z"
        fill="#DF0000"
      />
      <path
        d="M15.1527 16.499L14.9419 16.7474L15.1978 17.3722L15.4274 17.1727L15.1527 16.499Z"
        fill="#FFB200"
      />
      <path
        d="M14.4756 13.4617L14.3589 14.0149L14.8256 14.4967L14.9648 13.966L14.4756 13.4617Z"
        fill="#FA0F00"
      />
      <path
        d="M15.398 16.1748L15.1533 16.4947L15.4281 17.1684L15.6915 16.8936L15.398 16.1748Z"
        fill="#FFA300"
      />
      <path
        d="M14.6373 16.1829L14.5356 16.4049L14.8179 16.9205L14.9421 16.7436L14.6373 16.1829Z"
        fill="#FF9F00"
      />
      <path
        d="M15.2545 15.0911L15.0437 15.5276L15.3975 16.1787L15.6271 15.7836L15.2545 15.0911Z"
        fill="#FF6800"
      />
      <path
        d="M12.6991 14.3611L12.6689 14.7638L13.0867 14.8315L13.1507 14.4326L12.6991 14.3611Z"
        fill="#FF3000"
      />
      <path
        d="M13.0825 14.8313L13.1089 15.1625L13.4928 15.3319L13.5003 15.0157L13.0825 14.8313Z"
        fill="#FF4A00"
      />
      <path
        d="M12.7705 13.8906L12.699 14.3611L13.1506 14.4326L13.256 13.9659L12.7705 13.8906Z"
        fill="#FF1A00"
      />
      <path
        d="M14.2871 15.6968L14.2156 15.9602L14.5355 16.4043L14.6371 16.1823L14.2871 15.6968Z"
        fill="#FF7E00"
      />
      <path
        d="M13.3538 13.4353L13.2522 13.966L13.7339 14.1767L13.8657 13.6611L13.3538 13.4353Z"
        fill="#ED0400"
      />
      <path
        d="M13.5004 15.012L13.4929 15.3281L13.8655 15.5953L13.9069 15.3018L13.5004 15.012Z"
        fill="#FF5500"
      />
      <path
        d="M14.8216 15.8889L14.6372 16.1825L14.9421 16.7432L15.1528 16.4949L14.8216 15.8889Z"
        fill="#FF8D00"
      />
      <path
        d="M13.9071 15.3057L13.8657 15.5992L14.2157 15.9605L14.2872 15.6971L13.9071 15.3057Z"
        fill="#FF6800"
      />
      <path
        d="M15.0436 15.5278L14.8215 15.8929L15.1527 16.4988L15.3974 16.1789L15.0436 15.5278Z"
        fill="#FF7A00"
      />
      <path
        d="M14.8258 14.4966L14.6414 14.967L15.0441 15.5278L15.2548 15.0912L14.8258 14.4966Z"
        fill="#FF4300"
      />
      <path
        d="M13.1465 14.4324L13.0825 14.8313L13.5003 15.012L13.5981 14.6281L13.1465 14.4324Z"
        fill="#FF3400"
      />
      <path
        d="M13.8622 13.6575L13.7305 14.1731L14.1972 14.508L14.3552 14.0112L13.8622 13.6575Z"
        fill="#FA0F00"
      />
      <path
        d="M13.2524 13.9658L13.147 14.4325L13.5948 14.6282L13.7303 14.1766L13.2524 13.9658Z"
        fill="#FF1A00"
      />
      <path
        d="M14.3588 14.0146L14.2007 14.5114L14.641 14.9668L14.8254 14.4964L14.3588 14.0146Z"
        fill="#FF2200"
      />
      <path
        d="M14.4452 15.366L14.2871 15.6972L14.6371 16.1827L14.8215 15.8891L14.4452 15.366Z"
        fill="#FF6800"
      />
      <path
        d="M14.6413 14.9666L14.4456 15.3693L14.8219 15.8924L15.044 15.5273L14.6413 14.9666Z"
        fill="#FF5500"
      />
      <path
        d="M13.5983 14.6279L13.5005 15.0118L13.907 15.3054L14.0349 14.9441L13.5983 14.6279Z"
        fill="#FF3B00"
      />
      <path
        d="M14.0347 14.9441L13.9067 15.3054L14.2869 15.7006L14.4449 15.3694L14.0347 14.9441Z"
        fill="#FF4E00"
      />
      <path
        d="M13.7341 14.1768L13.5986 14.6284L14.0352 14.9445L14.2008 14.5117L13.7341 14.1768Z"
        fill="#FF2500"
      />
      <path
        d="M14.2008 14.5112L14.0352 14.944L14.4492 15.3693L14.6449 14.9666L14.2008 14.5112Z"
        fill="#FF3800"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14077_831"
          x1="16"
          y1="3"
          x2="16"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#609DEA" />
          <stop offset="1" stopColor="#3465A4" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
