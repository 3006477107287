import React from 'react'
export const susde = {
  path: (
    <>
      <g clipPath="url(#clip0_14423_2371)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0003 4H15.9997C9.37248 4 4 9.37248 4 15.9998V16.0003C4 22.6276 9.37247 28 15.9997 28H16.0003C22.6275 28 28 22.6276 28 16.0003V15.9998C28 9.37248 22.6275 4 16.0003 4ZM15.9997 3H16.0003C23.1798 3 29 8.82019 29 15.9998V16.0003C29 23.1799 23.1798 29 16.0003 29H15.9997C8.82019 29 3 23.1799 3 16.0003V15.9998C3 8.82019 8.82019 3 15.9997 3Z"
          fill="url(#paint0_linear_14423_2371)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0003 4.5H15.9998C9.64863 4.5 4.5 9.64863 4.5 15.9998V16.0002C4.5 22.3514 9.64862 27.5 15.9998 27.5H16.0003C22.3514 27.5 27.5 22.3514 27.5 16.0002V15.9998C27.5 9.64863 22.3514 4.5 16.0003 4.5ZM15.9998 4H16.0003C22.6275 4 28 9.37249 28 15.9998V16.0002C28 22.6276 22.6275 28 16.0003 28H15.9998C9.37248 28 4 22.6276 4 16.0002V15.9998C4 9.37249 9.37248 4 15.9998 4Z"
          fill="#AAAAAA"
        />
        <path
          d="M16.0003 4H15.9998C9.37248 4 4 9.37249 4 15.9998V16.0002C4 22.6276 9.37248 28 15.9998 28H16.0003C22.6275 28 28 22.6276 28 16.0002V15.9998C28 9.37249 22.6275 4 16.0003 4Z"
          fill="#F7F7F7"
        />
        <path
          d="M12 7.93546C9.03656 9.40817 7 12.4662 7 15.9999C7 19.5337 9.03656 22.5917 12 24.0644V25.1679C8.46819 23.6247 6 20.1006 6 15.9999C6 11.8993 8.46819 8.37514 12 6.83203V7.93546Z"
          fill="black"
        />
        <path
          d="M20 6.83203V7.93546C22.9634 9.40817 25 12.4662 25 15.9999C25 19.5337 22.9634 22.5917 20 24.0644V25.1679C23.5318 23.6247 26 20.1006 26 15.9999C26 11.8993 23.5318 8.37514 20 6.83203Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8L19.5 14H21V15H11V14H12.5L16 8ZM14 14H18L16 10L14 14Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5 18L16 24L12.5 18H11V17H21V18H19.5ZM18 18H14L16 22L18 18Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14423_2371"
          x1="16"
          y1="2.75471"
          x2="16"
          y2="29.2453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4F4F4" />
          <stop offset="1" stopColor="#BEBEBE" />
        </linearGradient>
        <clipPath id="clip0_14423_2371">
          <rect width="26" height="26" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '1 1 30 30',
}
