import React from 'react'
export const wld_circle_color = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="white" />
      <path
        d="M28.0677 11.1718C27.7224 10.3085 27.2851 9.49137 26.7672 8.72023C24.4307 5.26737 20.4714 3 15.9942 3C8.8123 3 3 8.82382 3 15.9942C3 23.1762 8.82381 29 15.9942 29C20.483 29 24.4307 26.7326 26.7672 23.2798C27.2851 22.5201 27.7109 21.703 28.0677 20.8398C28.6662 19.3435 29 17.7092 29 16.0058C28.9885 14.3023 28.6662 12.668 28.0677 11.1718ZM26.3298 14.6822H11.448C11.6782 13.8765 12.104 13.1629 12.6795 12.5989C13.5542 11.7242 14.7627 11.1833 16.0978 11.1833H25.2364C25.8004 12.2537 26.1687 13.4276 26.3298 14.6822ZM15.9252 5.52058C18.8831 5.52058 21.5649 6.7521 23.4754 8.73174H16.4201C14.4059 8.73174 12.5874 9.54892 11.2753 10.861C10.251 11.8853 9.53741 13.1974 9.27269 14.6822H5.53209C6.17663 9.51439 10.5848 5.52058 15.9252 5.52058ZM15.9252 26.5024C10.5848 26.5024 6.17663 22.5086 5.52058 17.3409H9.26118C9.88269 20.7247 12.8521 23.2913 16.4201 23.2913H23.4754C21.5764 25.2709 18.8946 26.5024 15.9252 26.5024ZM16.0978 20.8513C13.888 20.8513 12.0235 19.3665 11.448 17.3409H26.3298C26.1687 18.5954 25.7888 19.7694 25.2364 20.8513H16.0978Z"
        fill="#1E1E1C"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
