import React from 'react'

export const woeth_circle_color = {
  path: (
    <>
      <path
        d="M16.0435 28.9567C23.1993 28.9327 28.9807 23.1124 28.9567 15.9567C28.9327 8.80097 23.1124 3.01955 15.9567 3.04353C8.80097 3.06751 3.01955 8.8878 3.04353 16.0435C3.06751 23.1993 8.88781 28.9807 16.0435 28.9567Z"
        fill="#0074F0"
      />
      <path
        opacity="0.2"
        d="M24.0987 24.0467C28.5412 19.5746 28.519 12.3435 24.0468 7.90106C19.5746 3.45859 12.3436 3.48084 7.90112 7.95297L24.0987 24.0467Z"
        fill="white"
      />
      <path
        d="M15.4709 10.883C15.6449 10.8652 15.8214 10.8561 16 10.8561C18.84 10.8561 21.1422 13.1584 21.1422 15.9983C21.1422 18.2129 19.7423 20.1006 17.7791 20.8245L16.0343 23.2782C20.0391 23.2598 23.28 20.0075 23.28 15.9983C23.28 12.2336 20.4224 9.13639 16.7579 8.75732L15.4709 10.883Z"
        fill="white"
      />
      <path
        d="M15.8348 8.71973C11.8904 8.80755 8.71997 12.0324 8.71997 15.9979C8.71997 19.7077 11.4949 22.7694 15.0823 23.2206L16.5851 21.1071C16.3931 21.1289 16.1978 21.1401 16 21.1401C13.16 21.1401 10.8578 18.8379 10.8578 15.9979C10.8578 13.7221 12.3361 11.7916 14.3849 11.1144L15.8348 8.71973Z"
        fill="white"
      />
      <path
        d="M17.7792 20.8242C19.7425 20.1002 21.1425 18.2126 21.1425 15.998H23.2802C23.2802 20.0072 20.0394 23.2595 16.0344 23.278L17.7792 20.8242Z"
        fill="url(#paint0_linear_15216_4389)"
      />
      <path
        d="M15.8348 8.71973C11.8904 8.80755 8.71997 12.0324 8.71997 15.9979C8.71997 15.9979 10.8578 16.0286 10.8578 15.9979C10.8578 13.7221 12.3361 11.7916 14.3849 11.1144L15.8348 8.71973Z"
        fill="url(#paint1_linear_15216_4389)"
      />
      <path
        d="M15.9868 21.9493L19.6423 16.8018L15.9868 18.959L12.3335 16.8018L15.9868 21.9493Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M15.9871 21.9493L19.6424 16.8018L15.9871 18.959V21.9493Z"
        fill="#0074F0"
      />
      <path
        d="M15.9758 18.276L19.6492 16.1149L15.9758 10.0474L12.3022 16.1149L15.9758 18.276Z"
        fill="url(#paint2_linear_15216_4389)"
      />
      <path
        d="M15.9758 18.276L19.6492 16.1149L15.9758 10.0474L12.3022 16.1149L15.9758 18.276Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M15.9758 18.2758L12.3022 16.1149L15.9758 10.0474V18.2758Z"
        fill="#0074F0"
      />
      <path
        opacity="0.1"
        d="M15.9758 18.276V14.3926L12.3022 16.1148L15.9758 18.276Z"
        fill="#0074F0"
      />
      <path
        opacity="0.1"
        d="M15.9758 18.276V14.3926L19.6493 16.1148L15.9758 18.276Z"
        fill="#0074F0"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15216_4389"
          x1="11.2071"
          y1="25.1196"
          x2="19.2247"
          y2="19.8677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A82FF" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15216_4389"
          x1="19.9288"
          y1="5.36873"
          x2="12.2576"
          y2="10.9115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A82FF" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15216_4389"
          x1="8.16045"
          y1="12.2445"
          x2="14.5961"
          y2="16.172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C313F" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
