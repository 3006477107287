import React from 'react'
export const frax_circle_color = {
  path: (
    <>
      <path
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="black"
        key="ENmmChRCP"
      />
      <path
        d="M22.8476 15.9999C22.8476 14.6071 22.4292 13.3112 21.7125 12.2284L23.8385 10.1024L21.9265 8.19043L19.8064 10.3106C18.7177 9.57935 17.4082 9.15221 16.0009 9.15221C14.6081 9.15221 13.3122 9.57063 12.2293 10.2874L10.1024 8.16138L8.19043 10.0733L10.3106 12.1935C9.57935 13.2821 9.15221 14.5916 9.15221 15.999C9.15221 17.3917 9.57063 18.6877 10.2874 19.7705L8.16138 21.8975L10.0733 23.8094L12.1935 21.6892C13.2821 22.4205 14.5916 22.8476 15.999 22.8476C17.3917 22.8476 18.6877 22.4292 19.7705 21.7125L21.8975 23.8394L23.8094 21.9275L21.6892 19.8073C22.4205 18.7167 22.8476 17.4072 22.8476 15.9999ZM11.8574 15.9999C11.8574 13.7151 13.7161 11.8564 16.0009 11.8564C18.2857 11.8564 20.1444 13.7151 20.1444 15.9999C20.1444 18.2848 18.2857 20.1434 16.0009 20.1434C13.7161 20.1434 11.8574 18.2848 11.8574 15.9999Z"
        fill="white"
        key="iKrKjRlQLA"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
