import React from 'react'
export const rpl = {
  path: (
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26071 18.7393C8.98272 22.4613 15.0173 22.4613 18.7393 18.7393C22.4613 15.0173 22.4613 8.98272 18.7393 5.26071C15.0173 1.53871 8.98272 1.53871 5.26071 5.26071C1.53871 8.98272 1.53871 15.0173 5.26071 18.7393ZM4.92893 19.0711C8.83418 22.9763 15.1658 22.9763 19.0711 19.0711C22.9763 15.1658 22.9763 8.83418 19.0711 4.92893C15.1658 1.02369 8.83418 1.02369 4.92893 4.92893C1.02369 8.83418 1.02369 15.1658 4.92893 19.0711Z"
        fill="black"
      />
      <path
        d="M18.449 18.449C14.8873 22.0107 9.11269 22.0107 5.55102 18.449C1.98935 14.8873 1.98935 9.11269 5.55102 5.55102C9.11269 1.98935 14.8873 1.98935 18.449 5.55102C22.0107 9.11269 22.0107 14.8873 18.449 18.449Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0322 12.4977C16.2717 10.2582 16.1472 7.89422 16.1472 7.89422C16.1472 7.89422 13.7833 7.7698 11.5438 10.0093C11.2574 10.2957 11.0181 10.5654 10.8184 10.8179L9.51088 11.0795C9.48454 11.0847 9.46081 11.0989 9.44365 11.1195L8.56852 12.1733C8.51451 12.2383 8.54493 12.3374 8.62611 12.3609L9.87769 12.7235C9.80885 13.2129 9.94183 13.467 9.94183 13.467L10.0093 13.5345L9.59459 13.9492C9.54878 13.995 9.54878 14.0693 9.59459 14.1151L9.8849 14.4054C9.93071 14.4512 10.005 14.4512 10.0508 14.4054L10.4655 13.9907L10.5534 14.0785C10.5534 14.0785 10.8147 14.2174 11.3142 14.1508L11.6806 15.4154C11.7041 15.4965 11.8032 15.527 11.8682 15.4729L12.9219 14.5978C12.9426 14.5807 12.9567 14.5569 12.962 14.5306L13.2239 13.221C13.4767 13.0222 13.7463 12.7835 14.0322 12.4977ZM13.5552 11.5645C13.246 11.8738 12.7447 11.8738 12.4355 11.5645C12.1262 11.2553 12.1262 10.754 12.4355 10.4448C12.7447 10.1356 13.246 10.1356 13.5552 10.4448C13.8644 10.754 13.8644 11.2553 13.5552 11.5645Z"
        fill="white"
      />
      <path
        d="M7.89423 14.9031L4.97734 17.82C5.05222 17.9101 5.1291 17.9991 5.20798 18.087L8.14306 15.1519C8.18887 15.1061 8.18887 15.0318 8.14306 14.986L8.06012 14.9031C8.01431 14.8573 7.94003 14.8573 7.89423 14.9031Z"
        fill="white"
      />
      <path
        d="M9.1384 14.6542L5.44816 18.3445C5.48211 18.3795 5.5164 18.4144 5.55102 18.449C5.59953 18.4975 5.64846 18.5453 5.69778 18.5925L9.38724 14.9031C9.43305 14.8573 9.43305 14.783 9.38724 14.7372L9.30429 14.6542C9.25848 14.6084 9.18421 14.6084 9.1384 14.6542Z"
        fill="white"
      />
      <path
        d="M8.88957 15.8984L5.95685 18.8311C6.04526 18.9095 6.13483 18.9858 6.22549 19.0602L9.1384 16.1473C9.18421 16.1014 9.18421 16.0272 9.1384 15.9814L9.05546 15.8984C9.00965 15.8526 8.93538 15.8526 8.88957 15.8984Z"
        fill="white"
      />
    </>
  ),
}
