import React from 'react'
export const wbtc_circle_color = {
  path: (
    <>
      <circle cx="16" cy="15.9997" r="12.4138" fill="white" key="SOPtbe6ex" />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 8.32667C19.0398 4.70522 12.9703 4.70522 9.01012 8.32667L8.26084 7.59448C12.6306 3.56421 19.3624 3.56421 23.7322 7.59448L23 8.32667ZM23.6785 8.99529L24.4107 8.26309V8.24601C28.441 12.6158 28.441 19.3478 24.4107 23.7174L23.6785 22.9852C27.2997 19.0249 27.2997 12.9555 23.6785 8.99529ZM8.3268 22.9925C4.70998 19.0333 4.70998 12.9692 8.3268 9.00997L7.5946 8.27778C3.56433 12.6475 3.56433 19.3794 7.5946 23.7491L8.3268 22.9925ZM9.00269 23.6684C12.9629 27.2899 19.0324 27.2899 22.9926 23.6684L23.7419 24.4006C19.3721 28.4309 12.6403 28.4309 8.27049 24.4006L9.00269 23.6684Z"
        fill="#6A6672"
        key="5gF7SGLBB-"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2186 14.1352C18.2186 15.5996 15.9292 15.4215 15.2019 15.4215V12.8417C15.9292 12.8417 18.2186 12.6123 18.2186 14.1352ZM18.8166 18.1404C18.8166 19.7414 16.0732 19.5584 15.1995 19.5584V16.7174C16.0732 16.7199 18.8166 16.4685 18.8166 18.1404ZM20.6226 13.5568C20.4762 12.0289 19.1582 11.5164 17.4913 11.3602V9.25391H16.2026V11.3187C15.8633 11.3187 15.5168 11.3187 15.1726 11.3309V9.25391H13.8913V11.3724H13.0712H11.2798V12.7514C11.2798 12.7514 12.2316 12.7343 12.217 12.7514C12.5738 12.7121 12.8975 12.9625 12.9492 13.3176V19.1191C12.9336 19.3707 12.7171 19.5618 12.4655 19.5464C12.464 19.5464 12.4625 19.5464 12.4611 19.5462C12.4781 19.5608 11.5238 19.5462 11.5238 19.5462L11.2676 21.0862H12.9468H13.8693V23.2389H15.158V21.118H16.188V23.2291H17.4791V21.1009C19.6561 20.9691 21.1742 20.4321 21.3646 18.3942C21.5184 16.7541 20.7471 16.0219 19.5146 15.7265C20.2639 15.3458 20.7349 14.6746 20.6226 13.5568Z"
        fill="url(#wbtc_paint0_linear)"
        key="R43hS0E9Tv"
      />
      <mask
        id="mask0"
        key="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path d="M2.66663 2.6665H29.3333V29.3332H2.66663V2.6665Z" fill="white" key="1b9Jxcv4Pc" />
      </mask>
      <g mask="url(#mask0)">
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9975 3.70623C9.212 3.71038 3.7144 9.21431 3.71855 15.9998C3.7227 22.7854 9.22664 28.283 16.0122 28.2788C22.7931 28.2749 28.2885 22.778 28.2912 15.9974C28.2831 9.21285 22.7821 3.71623 15.9975 3.71355V3.70623ZM15.9975 29.3332C8.63381 29.332 2.66516 23.3614 2.66663 15.9974C2.66809 8.63343 8.63869 2.66504 16.0024 2.6665C23.3644 2.66797 29.3321 8.63539 29.3333 15.9974C29.3253 23.3592 23.3593 29.3251 15.9975 29.3332Z"
          fill="#6A6672"
          key="oGORrLOrOT"
        />
      </g>
      <defs>
        <linearGradient
          id="wbtc_paint0_linear"
          key="wbtc_paint0_linear"
          x1="11.2676"
          y1="9.25391"
          x2="21.2568"
          y2="20.6666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F48702" key="i8p775Ruep" />
          <stop offset="1" stopColor="#FCBD71" key="qlRDhVBXcW" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
