import React from 'react'

export const lbtc = {
  path: (
    <>
      <g clipPath="url(#clip0_15305_4353)">
        <circle cx="16" cy="16" r="12" fill="#132F2E" />
        <path
          d="M4 16C4 19.0734 5.15541 21.877 7.05557 24H18V21H20V12H18V8H7.05557C5.15541 10.123 4 12.9266 4 16Z"
          fill="#234C47"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2452 13.5846C19.2452 15.26 16.5296 15.0562 15.6669 15.0562V12.1047C16.5296 12.1047 19.2452 11.8422 19.2452 13.5846ZM19.9545 18.1668C19.9545 19.9986 16.7004 19.7892 15.664 19.7892V16.5389C16.7004 16.5417 19.9545 16.2541 19.9545 18.1668ZM22.0968 12.9229C21.9231 11.1749 20.3598 10.5885 18.3824 10.4098V8H16.8538V10.3623C16.4514 10.3623 16.0403 10.3623 15.6321 10.3763V8H14.1122V10.4237H13.1395H11.0145V12.0014C11.0145 12.0014 12.1436 11.9819 12.1262 12.0014C12.5494 11.9564 12.9333 12.2429 12.9947 12.6492V19.2866C12.9762 19.5744 12.7194 19.7931 12.4209 19.7755C12.4192 19.7755 12.4174 19.7755 12.4157 19.7752C12.436 19.792 11.304 19.7752 11.304 19.7752L11 21.5372H12.9918H14.0862V24H15.6148V21.5735H16.8365V23.9888H18.368V21.5539C20.9504 21.4031 22.7511 20.7888 22.9769 18.4572C23.1593 16.5808 22.2445 15.7431 20.7825 15.4052C21.6713 14.9696 22.23 14.2017 22.0968 12.9229Z"
          fill="#6D9792"
        />
      </g>
      <defs>
        <clipPath id="clip0_15305_4353">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
