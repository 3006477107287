import React from 'react'
export const tusd_circle_color = {
  path: (
    <g key="_vvCmxykT">
      <path
        d="M12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22Z"
        fill="#002868"
        key="YCiRv68cB"
      />
      <path
        d="M17.04 18H6.96C6.432 18 6 17.568 6 17.04V6.96C6 6.432 6.432 6 6.96 6H17.04C17.568 6 18 6.432 18 6.96V17.04C18 17.568 17.568 18 17.04 18Z"
        fill="white"
        key="-phHPdhody"
      />
      <path
        d="M8.34166 16.7923C8.31006 16.7923 8.28446 16.7635 8.28446 16.7319V15.1195C8.28446 15.0879 8.25886 15.0591 8.22726 15.0591H7.65806C7.62606 15.0591 7.59766 15.0335 7.59766 15.0019V14.6903C7.59766 14.6587 7.62646 14.6299 7.65806 14.6299H9.41006C9.44166 14.6299 9.47046 14.6583 9.47046 14.6903V15.0019C9.47046 15.0335 9.44166 15.0591 9.41006 15.0591H8.84406C8.81246 15.0591 8.78366 15.0875 8.78366 15.1195V16.7319C8.78366 16.7635 8.75806 16.7923 8.72646 16.7923H8.34166Z"
        fill="#002868"
        key="yXjioGn049"
      />
      <path
        d="M11.2193 14.6903C11.2193 14.6587 11.2449 14.6299 11.2765 14.6299H11.6581C11.6897 14.6299 11.7185 14.6583 11.7185 14.6903V15.7907C11.7185 16.4427 11.3497 16.8211 10.7645 16.8211C10.1825 16.8211 9.81689 16.4427 9.81689 15.7907V14.6903C9.81689 14.6587 9.84249 14.6299 9.87409 14.6299H10.2557C10.2873 14.6299 10.3161 14.6583 10.3161 14.6903V15.8063C10.3161 16.1275 10.4813 16.3563 10.7677 16.3563C11.0541 16.3563 11.2193 16.1275 11.2193 15.8063V14.6903Z"
        fill="#002868"
        key="CH0FQ6ehgY"
      />
      <path
        d="M13.471 15.3357C13.4202 15.3357 13.4042 15.3165 13.385 15.2785C13.3182 15.1225 13.1719 15.0241 12.9495 15.0241C12.7491 15.0241 12.5807 15.0941 12.5807 15.2277C12.5807 15.3741 12.7367 15.4345 13.0799 15.4757C13.6014 15.5361 13.9002 15.6953 13.9002 16.1085C13.9002 16.5729 13.493 16.8177 12.9719 16.8177C12.5203 16.8177 12.1323 16.5981 12.0719 16.1373C12.0655 16.0929 12.0879 16.0769 12.1323 16.0769H12.4823C12.5267 16.0769 12.5523 16.0961 12.5651 16.1309C12.6127 16.2837 12.7751 16.4013 13.0103 16.4013C13.2171 16.4013 13.3982 16.3249 13.3982 16.1629C13.3982 15.9689 13.2075 15.9341 12.8259 15.8861C12.3871 15.8289 12.0819 15.7017 12.0819 15.2977C12.0819 14.8937 12.4287 14.6045 12.9723 14.6045C13.4618 14.6045 13.8022 14.8685 13.8722 15.2629C13.8818 15.3073 13.869 15.3361 13.8214 15.3361H13.471V15.3357Z"
        fill="#002868"
        key="bqVS40CCbG"
      />
      <path
        d="M14.3326 16.7923C14.301 16.7923 14.2754 16.7635 14.2754 16.7319V14.6903C14.2754 14.6587 14.301 14.6299 14.3326 14.6299H15.1974C15.8398 14.6299 16.339 15.0719 16.339 15.7175C16.339 16.3535 15.8462 16.7923 15.1974 16.7923H14.3326ZM15.1974 16.3627C15.5442 16.3627 15.8142 16.0891 15.8142 15.7107C15.8142 15.3323 15.5438 15.0587 15.1942 15.0587H14.835C14.8034 15.0587 14.7778 15.0871 14.7778 15.1191V16.3051C14.7778 16.3367 14.8034 16.3623 14.835 16.3623H15.1974V16.3627Z"
        fill="#002868"
        key="S6ErQK-SWS"
      />
      <path
        d="M17.6799 13.4399H6.31991C6.23191 13.4399 6.15991 13.3679 6.15991 13.2799V6.95991C6.15991 6.51991 6.51991 6.15991 6.95991 6.15991H17.0399C17.4799 6.15991 17.8399 6.51991 17.8399 6.95991V13.2799C17.8399 13.3679 17.7679 13.4399 17.6799 13.4399Z"
        fill="#002868"
        key="NTY1SsdnT9"
      />
      <path
        d="M12.5892 12.1424H11.5492C11.4832 12.1424 11.4292 12.0884 11.4292 12.0224V7.62244C11.4292 7.55644 11.4832 7.50244 11.5492 7.50244H12.5892C12.6552 7.50244 12.7092 7.55644 12.7092 7.62244V12.0224C12.7092 12.0884 12.6552 12.1424 12.5892 12.1424Z"
        fill="white"
        key="fJ6SotTuR7"
      />
      <path
        d="M14.36 8.78244H9.64002C9.57402 8.78244 9.52002 8.72844 9.52002 8.66244V7.62244C9.52002 7.55644 9.57402 7.50244 9.64002 7.50244H14.36C14.426 7.50244 14.48 7.55644 14.48 7.62244V8.66244C14.48 8.72844 14.426 8.78244 14.36 8.78244Z"
        fill="white"
        key="ufDkHKLkhy"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
