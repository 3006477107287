import React from 'react'

export const pyusd = {
  path: (
    <>
      <path
        d="M29 16.0004C29 23.1803 23.1797 29.0008 16 29.0008C8.82032 29.0008 3 23.1803 3 16.0004C3 8.82047 8.82032 3 16 3C23.1797 3 29 8.82047 29 16.0004Z"
        fill="url(#paint0_linear_14635_2513)"
      />
      <path
        d="M18.0048 9H16.6872H13.7828C13.3152 9 12.9044 9.34002 12.8336 9.80757L12.536 11.7202V11.7344H11.1192C10.7367 11.7344 10.4392 12.0461 10.4392 12.4144C10.4392 12.7969 10.7509 13.0945 11.1192 13.1086H12.3235L12.111 14.4262L12.0968 14.5254H10.68C10.2975 14.5254 10 14.8371 10 15.2054C10 15.5738 10.3117 15.8855 10.68 15.8855H11.8843L11.2326 20.0083L11.0201 21.3825L10.9068 22.1193C10.8218 22.7001 11.2609 23.2243 11.856 23.2243H12.7627H14.0094H15.0295C15.497 23.2243 15.8938 22.8843 15.9788 22.4168L16.588 18.634H16.9422H18.0756C20.7533 18.634 22.9351 16.4239 22.8926 13.732C22.8501 11.0827 20.6399 9 18.0048 9ZM13.712 13.1086L17.9906 13.1228C18.3731 13.1228 18.6989 13.4345 18.6989 13.8312C18.6989 14.2137 18.3872 14.5396 17.9906 14.5396H13.4852L13.712 13.1086ZM18.0189 17.2456H17.2963H16.9564H16.2338C15.7663 17.2456 15.3695 17.5856 15.2845 18.0531L14.6754 21.8501H12.3518L13.2727 15.8997H17.9906C19.1382 15.8997 20.059 14.9646 20.059 13.8312C20.059 12.6978 19.124 11.7627 17.9906 11.7627L13.9386 11.7485L14.1511 10.3884H18.0898C20.0166 10.3884 21.575 11.9752 21.5325 13.902C21.4758 15.7721 19.9032 17.2456 18.0189 17.2456Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14635_2513"
          x1="7.5"
          y1="7.5"
          x2="26.5"
          y2="25.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0071F3" />
          <stop offset="1" stopColor="#0065D9" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
