import React from 'react'
export const syrup_usdc = {
  path: (
    <g clipPath="url(#clip0_785_37044)">
      <path
        d="M30 60C46.6251 60 60 46.6251 60 30C60 13.3749 46.6251 0 30 0C13.3749 0 0 13.3749 0 30C0 46.6251 13.3749 60 30 60Z"
        fill="url(#paint0_linear_785_37044)"
      />
      <path
        d="M30 60C46.6251 60 60 46.6251 60 30C60 13.3749 46.6251 0 30 0C13.3749 0 0 13.3749 0 30C0 46.6251 13.3749 60 30 60Z"
        fill="url(#paint1_linear_785_37044)"
      />
      <path
        d="M38.25 34.7499C38.25 30.375 35.625 28.875 30.375 28.2501C26.625 27.75 25.875 26.7501 25.875 24.9999C25.875 23.2497 27.1251 22.125 29.625 22.125C31.875 22.125 33.1251 22.875 33.75 24.75C33.8751 25.125 34.2501 25.3749 34.6251 25.3749H36.6249C37.125 25.3749 37.5 24.9999 37.5 24.5001V24.375C36.9999 21.6249 34.7499 19.5 31.875 19.2501V16.2501C31.875 15.75 31.5 15.375 30.8751 15.2499H29.0001C28.5 15.2499 28.125 15.6249 27.9999 16.2501V19.125C24.2499 19.6251 21.8751 22.125 21.8751 25.2501C21.8751 29.3751 24.375 30.9999 29.625 31.6251C33.1251 32.25 34.2501 33 34.2501 35.0001C34.2501 37.0002 32.4999 38.3751 30.1251 38.3751C26.8749 38.3751 25.7499 36.9999 25.3749 35.1249C25.2501 34.6251 24.8751 34.3749 24.5001 34.3749H22.3749C21.8751 34.3749 21.5001 34.7499 21.5001 35.25V35.3751C21.9999 38.4999 24 40.7499 28.125 41.3751V44.3751C28.125 44.8749 28.5 45.2499 29.1249 45.375H30.9999C31.5 45.375 31.875 45 32.0001 44.3751V41.3751C35.7501 40.7499 38.25 38.1249 38.25 34.7499Z"
        fill="white"
      />
      <path
        d="M23.625 47.8749C13.875 44.3751 8.8749 33.5001 12.5001 23.8749C14.3751 18.6249 18.5001 14.625 23.625 12.75C24.1251 12.5001 24.375 12.1251 24.375 11.4999V9.75C24.375 9.2499 24.1251 8.8749 23.625 8.7501C23.4999 8.7501 23.25 8.7501 23.1249 8.8749C11.25 12.6249 4.7499 25.2501 8.4999 37.125C10.7499 44.1249 16.125 49.5 23.1249 51.75C23.625 51.9999 24.1251 51.75 24.2499 51.2499C24.375 51.1251 24.375 51 24.375 50.7501V48.9999C24.375 48.6249 24 48.1251 23.625 47.8749ZM36.8751 8.8749C36.375 8.625 35.8749 8.8749 35.7501 9.375C35.625 9.5001 35.625 9.6249 35.625 9.8751V11.625C35.625 12.1251 36 12.6249 36.375 12.8751C46.125 16.3749 51.1251 27.2499 47.4999 36.8751C45.6249 42.1251 41.4999 46.125 36.375 48C35.8749 48.2499 35.625 48.6249 35.625 49.2501V51C35.625 51.5001 35.8749 51.8751 36.375 51.9999C36.5001 51.9999 36.75 51.9999 36.8751 51.8751C48.75 48.1251 55.2501 35.4999 51.5001 23.625C49.2501 16.5 43.7499 11.1249 36.8751 8.8749Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_785_37044"
          x1="60"
          y1="1.16667"
          x2="-10.8375"
          y2="27.4403"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F87A31" />
          <stop offset="1" stopColor="#FE9D66" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_785_37044"
          x1="36.7241"
          y1="30"
          x2="-17.2648"
          y2="4.97013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8C73" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8C73" />
        </linearGradient>
        <clipPath id="clip0_785_37044">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 60 60',
}
