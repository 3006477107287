import React from 'react'
export const unieth = {
  path: (
    <>
      <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_14480_4432)" />
      <path
        d="M15.8677 16.678L20.6838 11.8525L18.9342 10.0975L15.8669 7.06445V16.6772L15.8677 16.678Z"
        fill="#9FF7EC"
      />
      <path d="M15.8678 24.565L9.7417 19.3285V13.1462" fill="#BADEFF" />
      <path
        d="M15.8678 19.2985C15.8678 19.2985 11.4684 14.908 9.7417 13.1462L15.8678 19.2985Z"
        fill="#CFE6FF"
      />
      <path
        d="M21.9999 13.147V19.3083L15.8669 24.5628V19.2963C15.8669 19.2963 20.2732 14.9058 21.9999 13.144"
        fill="#BCCFFF"
      />
      <path
        d="M15.867 16.6779C15.867 16.6779 12.4947 13.3329 11.054 11.8464L12.796 10.0967L15.867 7.06519V16.6779Z"
        fill="#C5C5FF"
      />
      <path
        d="M15.8677 24.565V19.2985C15.8677 19.2985 20.2731 14.908 21.9999 13.1462L15.8677 24.5612V24.565Z"
        fill="#9077ED"
      />
      <path
        d="M9.7417 13.1462C11.4653 14.908 15.8678 19.2985 15.8678 19.2985V24.5642L9.7417 13.1462Z"
        fill="#5E9EFC"
      />
      <path d="M20.6846 11.8525L15.8669 16.6788V13.576L20.6846 11.8533V11.8525Z" fill="#A181F7" />
      <path
        d="M11.0547 11.8472C12.4969 13.3337 15.8677 16.6787 15.8677 16.6787V13.5759L11.0547 11.8472Z"
        fill="#61A9FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14480_4432"
          x1="26"
          y1="8.5"
          x2="6.5"
          y2="24.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A2435" />
          <stop offset="1" stopColor="#434B59" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
