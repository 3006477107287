import React from 'react'
export const usdc = {
  path: (
    <path
      key="wmBlVPP6V"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256 128C256 198.692 198.692 256 128 256C57.3075 256 0 198.692 0 128C0 57.3075 57.3075 0 128 0C198.692 0 256 57.3075 256 128ZM98.8 220.8C101.6 221.7 104 220 104 217V209.4C104 207.4 102.5 205.1 100.6 204.4C69.9 193.2 48 163.8 48 129.3C48 94.9 69.9 65.4 100.6 54.1C102.5 53.4 104 51.1 104 49.1V41.6C104 38.6 101.7 36.9 98.8 37.8C60 50.2 32 86.5 32 129.3C32 172.2 60 208.4 98.8 220.8ZM132 193.3C134.2 193.3 136 191.5 136 189.3H136.1V176.5C152.6 173.9 163.2 162.4 163.2 148.2C163.2 129.5 151.8 123.2 129.8 120.2C113.5 117.9 110.4 114.1 110.4 106.5C110.4 99.3 115.9 94.2 126.5 94.2C136.1 94.2 141.6 97.6 143.9 105.3C144.4 107 145.9 108.2 147.7 108.2H156.1C158.5 108.2 160.3 106.1 159.8 103.8C157.1 91.6 148.9 84.3 136 82V69.3C136 67.1 134.2 65.3 132 65.3H124C121.8 65.3 120 67.1 120 69.3V81.7C104.2 83.9 93.9 94.5 93.9 108C93.9 125.5 104.5 132.3 126.9 135.3C142.1 137.8 146.4 141.1 146.4 149.7C146.4 158.3 139 164.1 128.7 164.1C114.7 164.1 110.1 158 108.4 150.1C108 148.3 106.4 146.9 104.5 146.9H95.4C93.1 146.9 91.3 148.9 91.7 151.2C94 164.6 102.5 174.3 120 176.7V189.3C120 191.5 121.8 193.3 124 193.3H132ZM157.2 220.8C154.3 221.7 152 220 152 217V209.5C152 207.3 153.3 205.2 155.4 204.5C186 193.3 208 163.8 208 129.4C208 94.9 186.1 65.5 155.4 54.3C153.5 53.6 152 51.3 152 49.3V41.8C152 38.8 154.4 37.1 157.2 38C196 50.2 224 86.5 224 129.3C224 172.2 196 208.4 157.2 220.8Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 256 256',
}
