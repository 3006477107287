import React from 'react'
export const arb = {
  path: (
    <>
      <path
        d="M11.9619 14.9053L14.7207 19.2352L17.2692 17.7583L13.646 12.0476L11.9619 14.9053Z"
        fill="black"
      />
      <path
        d="M19.5902 16.0214L19.5884 14.8381L15.6295 8.67188L14.1606 11.1641L17.9825 17.3451L19.3643 16.5444C19.4999 16.4344 19.5819 16.2728 19.5905 16.0985L19.5902 16.0214Z"
        fill="black"
      />
      <path
        d="M3.00031 17.1373L4.95169 18.2617L11.4454 7.84773L10.3432 7.81862C9.40423 7.80534 8.39273 8.04945 7.92841 8.80735L4.24084 14.5229L3.00024 16.429V17.1373H3.00031Z"
        fill="black"
      />
      <path
        d="M15.1931 7.84766L12.2885 7.85821L5.71704 18.7024L8.01395 20.0249L8.63859 18.9655L15.1931 7.84766Z"
        fill="black"
      />
      <path
        d="M20.8142 7.83251C20.7899 7.22506 20.461 6.66894 19.9458 6.3452L12.7248 2.19262C12.2152 1.93598 11.5785 1.93567 11.0681 2.19244C11.0077 2.22285 4.04576 6.26054 4.04576 6.26054C3.94943 6.30672 3.85664 6.36177 3.76931 6.42421C3.3094 6.75385 3.02904 7.26603 3.00024 7.82879V16.4288L4.24083 14.5227L4.22997 7.88694C4.23146 7.86267 4.2345 7.83884 4.23879 7.81525C4.26678 7.65909 4.35398 7.51982 4.48525 7.42572C4.51803 7.40226 11.5989 3.30362 11.6214 3.29232C11.7878 3.20872 11.9993 3.20785 12.166 3.28978L19.2935 7.38966C19.4622 7.49704 19.5679 7.67914 19.5772 7.87819V16.0983C19.5686 16.2726 19.4997 16.4341 19.3642 16.5442L17.9823 17.3449L17.2693 17.7581L14.7209 19.235L12.1364 20.7328C11.9972 20.7831 11.8345 20.7803 11.6964 20.7241L8.63864 18.9654L8.014 20.0247L10.7619 21.6069C10.8528 21.6585 10.9338 21.7043 11.0002 21.7417C11.1031 21.7994 11.1732 21.8379 11.198 21.8499C11.3933 21.9448 11.6743 22 11.9275 22C12.1597 22 12.386 21.9574 12.6003 21.8734L20.107 17.5261C20.5378 17.1923 20.7913 16.6889 20.8142 16.1435V7.83251Z"
        fill="black"
      />
    </>
  ),
}
