import React from 'react'
export const wbtc = {
  path: (
    <g key="W-KTO-wnb">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88279 5.23761C9.34731 2.11506 14.657 2.11506 18.1216 5.23761L18.7621 4.60628C14.9393 1.13124 9.05009 1.13124 5.22729 4.60628L5.88279 5.23761ZM19.3556 5.18291L18.7151 5.81423C21.883 9.22888 21.883 14.4622 18.7151 17.8768L19.3556 18.5081C22.8815 14.7406 22.8815 8.93594 19.3556 5.16817V5.18291ZM5.2849 5.827C2.1208 9.2408 2.1208 14.4695 5.2849 17.8833L4.64435 18.5356C1.11855 14.7679 1.11855 8.96344 4.64435 5.19567L5.2849 5.827ZM18.1152 18.4663C14.6507 21.5888 9.34092 21.5888 5.8764 18.4663L5.23585 19.0976C9.05865 22.5726 14.9479 22.5726 18.7707 19.0976L18.1152 18.4663ZM11.4984 11.3568C12.2709 11.3704 13.9425 11.3997 13.9522 10.2442C13.9621 9.06142 12.3425 9.10307 11.5545 9.12333C11.4667 9.12559 11.3892 9.12758 11.3254 9.12758L11.3067 11.3544C11.3613 11.3544 11.4259 11.3556 11.4984 11.3568ZM11.493 14.9276C12.4149 14.9409 14.435 14.9701 14.4457 13.7014C14.4565 12.406 12.5247 12.4482 11.5768 12.4689C11.4687 12.4713 11.3734 12.4733 11.2952 12.4731L11.2746 14.9254C11.3371 14.9254 11.4107 14.9264 11.493 14.9276ZM13.3367 7.84877C14.7923 7.98361 15.9403 8.42603 16.0572 9.74486C16.1472 10.7098 15.7307 11.2891 15.0731 11.6178C16.1481 11.8727 16.8167 12.5047 16.6705 13.9205C16.4893 15.6796 15.1588 16.1431 13.2554 16.2569L13.24 18.094H12.1117L12.127 16.2716H11.227L11.2116 18.1024H10.0855L10.1011 16.2442H9.2949H7.82753L8.06265 14.9149C8.06265 14.9149 8.89647 14.9275 8.88164 14.9149C8.88292 14.9151 8.8842 14.9151 8.88548 14.9151C9.10526 14.9283 9.29583 14.7634 9.3113 14.5462L9.35339 9.5384C9.31075 9.23186 9.02975 9.01571 8.71765 9.04963C8.73057 9.03488 7.89866 9.04963 7.89866 9.04963L7.90866 7.85931H9.47414H10.1908L10.2061 6.03064H11.3258L11.3108 7.82349C11.6116 7.81296 11.9145 7.81296 12.2109 7.81296L12.2259 6.03064H13.352L13.3367 7.84877Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
