import React from 'react'
export const snx = {
  path: (
    <>
      <path
        d="M29 16C29 23.1797 23.1797 29 16 29C8.82029 29 3 23.1797 3 16C3 8.82029 8.82029 3 16 3C23.1797 3 29 8.82029 29 16Z"
        fill="#0E052F"
      />
      <path
        d="M11.7595 12.885C11.7081 12.8234 11.6436 12.7739 11.5707 12.7403C11.4977 12.7067 11.4182 12.6897 11.3379 12.6907H7.91708C7.90341 12.6913 7.88974 12.6891 7.87696 12.6842C7.86417 12.6793 7.85254 12.6718 7.8428 12.6622C7.83415 12.6542 7.82723 12.6445 7.82244 12.6338C7.81765 12.623 7.8151 12.6114 7.81494 12.5997V10.3135C7.81518 10.3017 7.81782 10.2901 7.82272 10.2793C7.82762 10.2686 7.83466 10.2589 7.84342 10.251C7.85316 10.2414 7.86479 10.2339 7.87758 10.229C7.89036 10.2241 7.90403 10.2219 7.9177 10.2225H11.5317C12.4436 10.2225 13.2304 10.5921 13.8921 11.3306L14.7699 12.3935L13.0595 14.4624L11.7595 12.885ZM18.0763 11.3195C18.7374 10.5884 19.5279 10.2225 20.4472 10.2225H24.0507C24.0817 10.2225 24.1039 10.23 24.1188 10.2454C24.1343 10.2603 24.1417 10.2832 24.1417 10.3135V12.5997C24.1417 12.6224 24.1338 12.6445 24.1194 12.6622C24.111 12.6718 24.1005 12.6794 24.0887 12.6843C24.0769 12.6892 24.0641 12.6914 24.0513 12.6907H20.6305C20.5501 12.6897 20.4705 12.7066 20.3974 12.7402C20.3244 12.7738 20.2598 12.8233 20.2083 12.885L17.6888 15.9252L20.22 18.9882C20.2714 19.0463 20.3346 19.0926 20.4054 19.1241C20.4762 19.1556 20.553 19.1715 20.6305 19.1709H24.0507C24.0817 19.1709 24.1039 19.1808 24.1188 19.2C24.1343 19.2185 24.1417 19.2433 24.1417 19.2742V21.5598C24.1417 21.5825 24.1338 21.6046 24.1194 21.6223C24.1039 21.6415 24.081 21.6508 24.0513 21.6508H20.4478C19.5279 21.6508 18.7411 21.2818 18.0874 20.5427L15.9901 18.0052L13.8915 20.5427C13.2304 21.2818 12.4398 21.6508 11.5206 21.6508H7.9177C7.88675 21.6508 7.86385 21.6415 7.84899 21.6223C7.83293 21.601 7.82482 21.5747 7.82608 21.548V19.2625C7.82608 19.2396 7.83351 19.2185 7.84899 19.1993C7.86385 19.1808 7.88675 19.1709 7.91708 19.1709H11.3379C11.497 19.1709 11.6376 19.1065 11.7595 18.9765L14.2338 15.9821L18.0763 11.3195Z"
        fill="#00D1FF"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
