import React from 'react'
export const xeth = {
  path: (
    <>
      <path
        d="M28.5 16C28.5 22.9036 22.9036 28.5 16 28.5C9.09644 28.5 3.5 22.9036 3.5 16C3.5 9.09644 9.09644 3.5 16 3.5C22.9036 3.5 28.5 9.09644 28.5 16Z"
        stroke="#E5E4E6"
      />
      <path
        d="M23.4749 16.0768C22.104 16.9632 20.5204 17.2173 18.5763 16.195C18.5506 16.1799 18.5292 16.1583 18.5144 16.1324C18.4996 16.1065 18.4918 16.0771 18.4918 16.0473C18.4918 16.0174 18.4996 15.9881 18.5144 15.9622C18.5292 15.9363 18.5506 15.9147 18.5763 15.8996L21.0818 14.2805C21.1302 14.249 21.1642 14.1997 21.1764 14.1432C21.1886 14.0867 21.1779 14.0277 21.1468 13.9791L19.9649 12.1473C19.9335 12.0988 19.8841 12.0648 19.8277 12.0526C19.7712 12.0405 19.7122 12.0511 19.6636 12.0823L16.2836 14.2746C16.2158 14.3183 16.1368 14.3416 16.0561 14.3416C15.9754 14.3416 15.8964 14.3183 15.8286 14.2746C12.1236 11.8282 9.00949 12.7973 7.41404 13.6364C6.58009 14.1024 5.82766 14.7011 5.18631 15.4091C5.1687 15.4249 5.14696 15.4353 5.12363 15.4391C5.1003 15.443 5.07637 15.44 5.05464 15.4307C5.03292 15.4214 5.0143 15.4061 5.00099 15.3866C4.98767 15.367 4.98022 15.3441 4.97949 15.3205C5.25722 11.775 6.70495 8.82048 10.4159 6.45684C14.8181 3.59093 21.389 5.02093 24.1663 9.31684C26.0986 12.3068 25.3481 14.8655 23.4749 16.0768Z"
        fill="url(#paint0_linear_14480_4436)"
      />
      <path
        d="M27.0146 16.7681C26.731 20.3135 25.2419 23.209 21.5842 25.5963C17.2174 28.409 10.6169 26.979 7.83373 22.6831C5.90146 19.6931 6.65191 17.1581 8.54282 15.9467C9.25601 15.4548 10.0963 15.1796 10.9623 15.1545C11.8284 15.1293 12.6832 15.3552 13.4237 15.8049C13.4495 15.82 13.4708 15.8416 13.4856 15.8675C13.5004 15.8934 13.5082 15.9228 13.5082 15.9526C13.5082 15.9825 13.5004 16.0118 13.4856 16.0377C13.4708 16.0636 13.4495 16.0852 13.4237 16.1004L10.9183 17.7254C10.8698 17.7568 10.8358 17.8062 10.8236 17.8626C10.8114 17.9191 10.8221 17.9781 10.8533 18.0267L12.0351 19.8585C12.0506 19.8827 12.0708 19.9034 12.0945 19.9196C12.1183 19.9357 12.145 19.9468 12.1732 19.9524C12.2013 19.9579 12.2303 19.9577 12.2584 19.9517C12.2864 19.9457 12.313 19.9341 12.3365 19.9176L15.7283 17.7254C15.7952 17.6821 15.8732 17.6591 15.9528 17.6591C16.0325 17.6591 16.1105 17.6821 16.1774 17.7254C19.8646 20.1658 22.9787 19.1967 24.5742 18.3458C25.3884 17.9051 26.1266 17.3365 26.7605 16.6617C26.7794 16.6362 26.8063 16.6178 26.8369 16.6094C26.8675 16.6009 26.9 16.603 26.9293 16.6153C26.9586 16.6275 26.9829 16.6492 26.9984 16.6769C27.0138 16.7047 27.0196 16.7367 27.0146 16.7681Z"
        fill="url(#paint1_linear_14480_4436)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14480_4436"
          x1="4.97949"
          y1="10.9005"
          x2="25.3009"
          y2="10.9005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D81C5C" />
          <stop offset="1" stopColor="#ED4036" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14480_4436"
          x1="6.711"
          y1="21.1054"
          x2="27.0146"
          y2="21.1054"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D81C5C" />
          <stop offset="1" stopColor="#ED4036" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 32 32',
}
