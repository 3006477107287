import React from 'react'
export const lusd_circle_color = {
  path: (
    <>
      <circle
        cx="13"
        cy="13"
        r="13"
        transform="matrix(1 0 0 -1 3 29)"
        fill="#17438C"
        key="EUmJZLAXF"
      />
      <path
        d="M14.5131 28.9158V25.3707C13.354 25.095 12.3016 24.4844 11.4871 23.6148C10.6726 22.7453 10.132 21.6553 9.93266 20.4806L9.77016 19.6795L13.3537 18.6712L13.4943 19.6653C13.6596 20.8105 14.5131 22.0439 16.0065 22.0439C17.2504 22.0439 18.5959 21.1233 18.5959 19.6336C18.5959 18.3267 17.6892 17.8453 16.0845 17.1128L16 17.0722L14.9872 16.6314C12.9637 15.7145 10.8203 14.4954 10.7854 11.5793V11.5086C10.78 10.6255 11.0078 9.75664 11.4459 8.98986C12.1291 7.83692 13.2259 6.98764 14.5131 6.61492V3.08398C8.03364 3.82175 3 9.32298 3 15.9999C3 22.6768 8.03364 28.178 14.5131 28.9158Z"
        fill="#2EB6EA"
        key="eCgiP8CgMq"
      />
      <path
        d="M28.4792 19.6701C27.8061 21.9921 26.498 24.0801 24.7021 25.6988C22.9063 27.3174 20.6941 28.4024 18.3148 28.8314V25.1317C19.1921 24.7975 19.9816 24.2676 20.6231 23.5823C20.9796 23.1999 21.2843 22.7724 21.5295 22.3107C21.9959 21.4312 22.2374 20.4499 22.2323 19.4543C22.2323 16.1832 19.8712 14.8763 17.6579 13.9066L16.5371 13.4114C15.339 12.8739 14.4221 12.3958 14.4221 11.5776C14.4221 10.4462 15.4093 9.83841 16.3404 9.83841C17.3313 9.83841 17.8935 10.204 18.4871 11.2331L18.9473 12.0305L21.9089 10.1297L21.5226 9.41307C20.8429 8.11766 19.6953 7.12983 18.3132 6.65057V3.25757C18.6012 3.30672 18.886 3.3701 19.174 3.44363C19.2021 3.45054 19.2337 3.45785 19.2654 3.46476C19.2976 3.47092 19.3294 3.4792 19.3605 3.48954C19.3701 3.49058 19.3795 3.4929 19.3885 3.49644C19.4657 3.51757 19.5466 3.53707 19.6274 3.55982C22.9373 4.52282 25.7291 6.76097 27.3891 9.78209C29.049 12.8032 29.4412 16.3599 28.4792 19.6701Z"
        fill="#745DDF"
        key="KsY1CYDAFS"
      />
      <path
        d="M22.2318 19.4545C22.2369 20.45 21.9955 21.4313 21.529 22.3108C21.2839 22.7727 20.9792 23.2003 20.6227 23.5828C19.981 24.2685 19.1912 24.7987 18.3135 25.133V28.8498L17.2748 28.9416C17.2748 28.9416 15.0465 29.0082 14.5159 28.9416L14.5102 25.3719C13.3513 25.0958 12.2992 24.4847 11.485 23.6149C10.6709 22.7451 10.1307 21.6549 9.93179 20.4802L9.76929 19.6791L13.3528 18.6708L13.4934 19.6649C13.6583 20.8101 14.5123 22.0435 16.0056 22.0435C17.2492 22.0435 18.5951 21.1229 18.5951 19.6332C18.5951 18.3263 17.6887 17.8457 16.0828 17.1108L15.9983 17.0702L14.9864 16.631C12.9628 15.7141 10.8194 14.495 10.7845 11.5789V11.5086C10.7791 10.6255 11.007 9.75664 11.4451 8.98986C12.1282 7.83692 13.225 6.98764 14.5123 6.61492V3.03627C16.8563 3.01636 17.0359 3.03017 18.3135 3.25524V6.65149C19.6957 7.13075 20.8433 8.11858 21.5229 9.41399L21.9093 10.1306L18.9477 12.0315L18.4874 11.234C17.8935 10.2045 17.3316 9.83933 16.3408 9.83933C15.4097 9.83933 14.4225 10.4487 14.4225 11.5785C14.4225 12.3971 15.3394 12.8748 16.5374 13.4123L17.6583 13.9075C19.8711 14.8764 22.2318 16.1833 22.2318 19.4545Z"
        fill="white"
        key="qgHvaVBD2Y"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
